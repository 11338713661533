import { translate } from '@lang/index';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import ContentRow from '@components/partials/ContentRow';
import GridContainer from '@components/blocks/GridContainer';
import CourseCard from '@components/cards/CourseCard';
// Modal
import CourseExpiredNavigation from '@components/modal/CourseExpiredNavigation';

interface CoursesListProps {
	categories: CategoriesRes[];
	courseProgress: OrgCourseProgressRes[];
	state: {
		isLoading: boolean;
		isError: boolean;
	};
	permission: boolean;
}

const CoursesList: React.FC<CoursesListProps> = ({
	categories,
	courseProgress,
	state,
	permission,
}) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();
	const [navigationWarningOpen, setNavigationWarningOpen] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState<number | null>(
		null
	);

	// -------------------------------------------------
	// Queries & Mutations
	const courses = useQuery(
		['organisations.courses.getMultiple', organisation?.id],
		() => {
			return api.organisations.courses.getMultiple({
				organisationId: organisation?.id || 0,
				filters: {
					courseTypeId: 1,
				},
				include: {
					items: true,
					itemsModel: true,
					itemsModelType: true,
					media: true,
					type: true,
					teamsCount: false,
					usersCount: false,
				},
				sort: {
					access: 'asc',
				},
				perPage: 4,
			});
		},
		{ enabled: !!organisation?.id && permission, keepPreviousData: true }
	);

	// -------------------------------------------------
	// Memos
	const isLoading = useMemo(() => {
		return courses.isLoading || state.isLoading;
	}, [courses.isLoading, state.isLoading]);

	const isError = useMemo(() => {
		return courses.isError || state.isError;
	}, [courses.isError, state.isError]);

	// -------------------------------------------------
	// Render
	if (!permission) return null;

	return (
		<ContentRow
			title={translate('courses_in_progress')}
			href={`${organisation?.slug}/courses`}>
			<GridContainer
				state={{
					loading: isLoading,
					error: isError,
					noData: courses.data?.data.data.length === 0,
					loadingCards: 4,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Course',
					}),
					message: translate('no_data_message', {
						name: 'course',
					}),
				}}>
				{courses.data?.data.data.map((course, index) => (
					<CourseCard
						key={index}
						course={course}
						organisationSlug={organisation?.slug || ''}
						categories={categories}
						courseProgress={courseProgress}
						functions={{
							showNavigationWarning: (courseId) => {
								setSelectedCourseId(courseId);
								setNavigationWarningOpen(true);
							},
						}}
					/>
				))}
			</GridContainer>
			<CourseExpiredNavigation
				open={navigationWarningOpen && !!selectedCourseId}
				setOpen={setNavigationWarningOpen}
				courseId={selectedCourseId as number}
			/>
		</ContentRow>
	);
};

export default CoursesList;
