import { http, createFormData } from '@utils/index';

interface Props {
	body: {
		cert_background?: File | null;
		cert_logo?: File | null;
		cert_icheme?: File | null;
		cert_signature?: File | null;
		home_hero_video_id?: string | null;
		footer_banner_html?: string | null;
		kr_course_ids?: string | null;
	};
}

const update = (props: Props) => {
	return http.post<OptionsRes>(
		`/sa/options`,
		createFormData([
			{
				key: '_method',
				value: 'put',
			},
			{
				key: 'cert_background',
				value: props.body.cert_background,
			},
			{
				key: 'cert_logo',
				value: props.body.cert_logo,
			},
			{
				key: 'cert_icheme',
				value: props.body.cert_icheme,
			},
			{
				key: 'cert_signature',
				value: props.body.cert_signature,
			},
			{
				key: 'home_hero_video_id',
				value: props.body.home_hero_video_id,
			},
			{
				key: 'footer_banner_html',
				value: props.body.footer_banner_html,
			},
			{
				key: 'kr_course_ids',
				value: props.body.kr_course_ids,
			}
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default update;
