import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface ButtonProps {
	theme:
		| 'primary'
		| 'dark'
		| 'outline'
		| 'small'
		| 'danger'
		| 'tab'
		| 'text'
		| 'icon'
		| 'icon-small';
	children: React.ReactNode;
	active?: boolean;
	type?: 'button' | 'submit' | 'link';
	onClick?: () => void;
	className?: string;
	ariaLabel?: string;
	href?: string;
	disabled?: boolean;
	target?: string;
}

const Button: React.FC<ButtonProps> = ({
	theme,
	type = 'button',
	onClick,
	active = false,
	children,
	className,
	ariaLabel,
	href,
	disabled = false,
	target,
}) => {
	// set the classnames for the button
	const classes = classNames(
		`duration-200 transition-colors disabled:opacity-40 disabled:cursor-not-allowed text-sm cursor-pointer`,
		{
			// Themes
			'bg-brandPrimary text-center hover:bg-brandPrimaryHover min-h-10 py-2 inline-flex items-center justify-center px-7 font-semibold text-brandPrimaryText rounded-[6px] focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight':
				theme === 'primary',
			'bg-uiDark text-center  hover:bg-uiDarkHover text-white h-10 inline-flex items-center justify-center px-7 font-semibold rounded-[6px] focus:outline-none focus:ring-2 focus:ring-brandPrimary':
				theme === 'dark',
			'bg-white text-center  hover:bg-brandPrimary hover:text-brandPrimaryText border border-uiDark hover:border-brandPrimary text-title h-10 inline-flex items-center font-semibold justify-center px-7 rounded-[6px] focus:outline-none focus:ring-2 focus:ring-brandPrimary':
				theme === 'outline',
			'bg-brandPrimary text- text-brandPrimaryText  hover:bg-brandPrimaryHover h-8 inline-flex items-center justify-center px-4 font-semibold rounded-[6px] focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight text-xs':
				theme === 'small',
			'bg-red text-center  hover:bg-redHover h-10 inline-flex items-center justify-center px-7 font-semibold text-white rounded-[6px] focus:outline-none focus:ring-2 focus:ring-uiDark':
				theme === 'danger',
			'w-full text-center  h-10 border-t border-x text-sm flex items-center px-5 transition-colors duration-200':
				theme === 'tab',
			'text-brandTertiary hover:text-brandTertiaryHover ':
				theme === 'text',
			'w-8 h-8 text-title bg-white hover:bg-uiLight rounded-full flex items-center justify-center group':
				theme === 'icon-small',
			'w-10 h-10 min-w-[40px] text-brandPrimaryText bg-brandPrimary text-center hover:bg-brandPrimaryHover rounded-full flex items-center justify-center group':
				theme === 'icon',

			// Active styles
			'bg-brandSecondary border-brandSecondary text-white':
				active && theme === 'tab',
			'border-border hover:bg-brandSecondary hover:border-brandSecondary hover:text-white':
				!active && theme === 'tab',

			'opacity-40 cursor-not-allowed': disabled,
		},
		className
	);

	if (type === 'button' || type === 'submit') {
		return (
			<button
				className={classes}
				onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					if (disabled) return;
					if (onClick) onClick();
				}}
				aria-label={ariaLabel}
				type={type}
				disabled={disabled}>
				{children}
			</button>
		);
	}

	if (type === 'link') {
		return (
			<Link
				to={href ? href : '/'}
				className={classes}
				onClick={onClick}
				aria-label={ariaLabel}
				target={target}>
				{children}
			</Link>
		);
	}

	return null;
};
export default Button;
