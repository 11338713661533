import { http } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
	body: {
		team_ids?: Array<number>;
		user_ids?: Array<number>;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<CourseRes>>(
		`/organisations/${props.organisationId}/courses/${props.courseId}`,
		{
			_method: 'put',
			...props.body,
		}
	);
};
export default updateSingle;
