import { http } from '@utils/index';

interface Props {
	id: number;
	organisationId: number;
	body: {
		name?: string;
		user_ids?: number[];
		course_ids?: number[];
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaQuizzesRes>>(
		`/organisations/${props.organisationId}/teams/${props.id}`,
		{
			_method: 'put',
			...props.body,
		}
	);
};
export default updateSingle;
