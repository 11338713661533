import PasswordResetAction from '@components/actions/PasswordReset';

interface PasswordResetProps {}

const PasswordReset: React.FC<PasswordResetProps> = () => {
	// --------------------------------------
	// Render
	return <PasswordResetAction callback={() => {}} />;
};

export default PasswordReset;
