import { translate } from '@lang/index';
// Types
import type { FormState } from '@hooks/useFormState';
// Utils
import { dateHelpers } from '@utils/index';

// Converts "50.00%" to 50
const convertPercentString = (progress?: string) => {
	const percentage = progress?.replace('%', '') || '0';
	return parseInt(percentage);
};

// Gets the redirect link for a course, used if invalid course preview url is wrong so we can redirect to the correct course
const courseRedirectLink = (
	mode: 'preview' | 'standard',
	courseId?: string,
	organisationSlug?: string
) => {
	if (mode === 'preview') {
		return `/courses/${courseId}/redirect`;
	} else {
		return `/${organisationSlug}/courses/${courseId}/redirect`;
	}
};

// Course specific, checks if item status is locked
const isItemLocked = (item: CourseItems, status?: CourseStatusRes[]) => {
	const statusItem = status?.find(
		(statusItem) =>
			statusItem.model_id === item.model_id &&
			statusItem.model_type === item.model_type
	);
	if (statusItem === undefined) return false;
	return !statusItem.can;
};

const courseItemsWithNoChapters = (items: CourseItems[]) => {
	return items.filter((item) => {
		if (item.model_type === 'chapter') return false;
		return true;
	}) as Array<VideoCourseItem | QuizCourseItem>;
};

const courseOnlyHasChapters = (items: CourseItems[]) => {
	const itemsRes = items.filter((item) => {
		if (item.model_type === 'chapter') return true;
		return false;
	}) as Array<ChapterCourseItem>;

	if (itemsRes.length === items.length) return true;
	return false;
};

// access dates
const courseAccessInfo = (org?: OrgRes) => {
	let start = null;
	let end = null;

	let show = false;
	let full_access = true;
	let current_access = false;

	let access_in_days = null;
	let expires_in_days = null;
	let expired_ago = null;

	if (org) {
		show = true;

		if (org.access.end_date) end = org.access.end_date;
		if (org.access.start_date) start = org.access.start_date;

		// ----------------------------
		// Set current_access - determines if the user has access to the course within the start and end date
		if (start || end) {
			current_access = dateHelpers.isBetween(
				new Date(),
				start ? new Date(start) : new Date(),
				end ? new Date(end) : undefined
			);
		} else {
			current_access = true;
		}

		// ----------------------------
		// Set expires_in_days
		if (current_access) {
			if (end) {
				expires_in_days = dateHelpers.daysBetween(
					new Date(),
					new Date(end)
				);
			} else {
				expires_in_days = -1;
			}
		}

		// ----------------------------
		// Set access_in_days
		if (!current_access && start && new Date(start) > new Date()) {
			access_in_days = dateHelpers.daysBetween(
				new Date(),
				new Date(start)
			);
		}

		// ----------------------------
		// Set expired_ago
		if (!current_access && end && new Date(end) < new Date()) {
			expired_ago = dateHelpers.daysBetween(new Date(end), new Date());
		}

		// ----------------------------
		// Set full_access - only set if no start or end date is set
		if (end === null && start === null) full_access = true;
		else full_access = false;
	}

	return {
		show,
		access: full_access || current_access,
		full_access,
		current_access,

		start,
		end,

		access_in_days,
		expires_in_days,
		expired_ago,
	};
};

// Course item tree
export interface NestedItem {
	item: CourseItems;
	children: Array<VideoCourseItem | QuizCourseItem>;
}

const courseItemTree = (items: CourseItems[]) => {
	const tree: Array<NestedItem> = [];
	let currentChapter: NestedItem | null = null;

	items.forEach((item) => {
		if (item.model_type === 'chapter') {
			// add current chapter to tree if it exists
			if (currentChapter) {
				tree.push(currentChapter);
			}
			// set new current chapter
			currentChapter = {
				item,
				children: [],
			};
		} else if (currentChapter) {
			if (!currentChapter.children) currentChapter.children = [];
			currentChapter.children.push(item);
		} else {
			tree.push({
				item,
				children: [],
			});
		}
	});

	// add last chapter to tree
	if (currentChapter) {
		tree.push(currentChapter);
	}

	return tree;
};

// Returns any updated values in obj2 compared to obj1
const deepDiff = <T>(obj1: T, obj2: T): Partial<T> => {
	const result: Partial<T> = {};

	for (const key in obj1) {
		if (Object.prototype.hasOwnProperty.call(obj1, key)) {
			if (Array.isArray(obj1[key])) {
				if (!compareArrays(obj1[key] as any, obj2[key] as any)) {
					result[key] = obj2[key];
				}
			} else if (typeof obj1[key] === 'object' && obj1[key] !== null) {
				const diff = deepDiff(obj1[key], obj2[key]);
				if (Object.keys(diff).length > 0) {
					// @ts-ignore
					result[key] = diff;
				}
			} else {
				if (obj1[key] !== obj2[key]) {
					result[key] = obj2[key];
				}
			}
		}
	}

	// go through obj2 and find keys that are not in obj1
	for (const key in obj2) {
		if (
			Object.prototype.hasOwnProperty.call(obj2, key) &&
			!Object.prototype.hasOwnProperty.call(obj1, key)
		) {
			result[key] = obj2[key];
		}
	}

	return result;
};
const compareArrays = (arr1: any[], arr2: any[]): boolean => {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (!arr2.includes(arr1[i])) {
			return false;
		}
	}

	return true;
};

// Get lowest price
const getLowestPrice = (prices: StripeProductObject) => {
	const pricesArr = Object.values(prices);

	const eurPrices = pricesArr.filter(
		(price) => price.currency.toLowerCase() === 'eur'
	);
	const gbpPrices = pricesArr.filter(
		(price) => price.currency.toLowerCase() === 'gbp'
	);
	const usdPrices = pricesArr.filter(
		(price) => price.currency.toLowerCase() === 'usd'
	);

	if (eurPrices.length > 0) {
		return eurPrices[0];
	}
	if (gbpPrices.length > 0) {
		return gbpPrices[0];
	}
	if (usdPrices.length > 0) {
		return usdPrices[0];
	}

	return undefined;
};

// Format price
const formatPrice = (price: number, currency: string) => {
	if (currency.toLowerCase() === 'eur') {
		return `€${(price / 100).toFixed(2)}`;
	}
	if (currency.toLowerCase() === 'gbp') {
		return `£${(price / 100).toFixed(2)}`;
	}
	if (currency.toLowerCase() === 'usd') {
		return `$${(price / 100).toFixed(2)}`;
	}
};

// Mutation error handler
const mutationErrorHandler = (error: AxiosAPIError, formState: FormState) => {
	if (error.response?.data.errors) {
		formState.errors.setErrors(error.response.data.errors);
	}
	formState.errorMessage.setErrorMessage(
		error.response?.data.message || translate('error_generic')
	);
};

// string to date input value
const stringToDateInputValue = (date: string | null) => {
	if (!date) return '';
	const d = new Date(date);
	
	const formatter = new Intl.DateTimeFormat('en', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'Europe/London'
	  });
	const split = formatter.format(d).split('/');
	return `${split[2]}-${split[0]}-${split[1]}`;
};

// ----------------------------
// Export all helpers
const helpers = {
	convertPercentString,
	courseRedirectLink,
	isItemLocked,
	courseItemsWithNoChapters,
	courseOnlyHasChapters,
	courseAccessInfo,
	courseItemTree,
	deepDiff,
	getLowestPrice,
	formatPrice,
	mutationErrorHandler,
	stringToDateInputValue
};

export default helpers;
