import { translate } from '@lang/index';
// Components
import Button from '@components/partials/Button';
// Actions
import ResetPasswordAction from '@components/actions/ResetPassword';

const PasswordResetToken: React.FC = () => {
	// ---------------------
	// Render
	return (
		<>
			<div className="mb-10">
				<h1 className="mb-1.5 text-title">Reset Password</h1>
				<p className="text-body">Enter your new password below.</p>
			</div>
			<div className="w-full">
				<ResetPasswordAction callback={() => {}} />

				<Button
					theme="text"
					type="link"
					className="mt-5 block !text-body hover:!text-brandTertiaryHover"
					href="/password-reset">
					{translate('request_new_password_reset_token')}
				</Button>
			</div>
		</>
	);
};

export default PasswordResetToken;
