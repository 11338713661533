import { translate } from '@lang/index';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import ContentRow from '@components/partials/ContentRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface SearchAgainListProps {
	permission: boolean;
}

const SearchAgainList: React.FC<SearchAgainListProps> = ({ permission }) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries & Mutations
	const searchHistory = useQuery(
		['auth.searches.getMultiple', organisation?.id],
		() => {
			return api.auth.searches.getMultiple({
				queryString: undefined,
				include: {
					user: false,
					value: true,
				},
				filters: [
					{
						key: 'organisation_id',
						value: organisation?.id || 0,
					},
				],
				perPage: 14,
			});
		},
		{
			enabled: !!organisation?.id && permission,
		}
	);

	// -------------------------------------------------
	// Render
	if (!permission) return null;
	return (
		<ContentRow
			title={translate('resume_search')}
			body={translate('resume_search_body')}
			href="/search">
			<DynamicBlock
				state={{
					loading: searchHistory.isLoading,
					error: searchHistory.isError,
				}}>
				{searchHistory.data?.data.data.length === 0 ? (
					<p className="text-sm text-body text-opacity-80">
						{translate('no_search_history')}
					</p>
				) : (
					<ul className="flex flex-wrap gap-2">
						{searchHistory.data?.data.data.map((search, index) => (
							<li
								key={index}
								className="inline-flex min-w-max items-center rounded-md bg-brandPrimary text-brandPrimaryText transition-colors duration-200 hover:bg-brandPrimaryHover">
								<Link
									to={`/search${
										search.value?.value
											? `?filter[s]=${search.value?.value}`
											: ''
									}`}
									className="block py-1 px-2 text-sm font-bold text-brandPrimaryText">
									{search.value?.value}
									<FontAwesomeIcon
										icon={faSearch}
										className="ml-2 text-xs"
									/>
								</Link>
							</li>
						))}
					</ul>
				)}
			</DynamicBlock>
		</ContentRow>
	);
};

export default SearchAgainList;
