import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface DeleteAwardProps {
	id: number;
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: () => void;
}

const DeleteAward: React.FC<DeleteAwardProps> = ({
	id,
	open,
	setOpen,
	onSuccess,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const deleteAward = useMutation(api.sa.awards.deleteSingle, {
		onSuccess: () => {
			// invalidate organisations
			queryClient.invalidateQueries(['sa.awards.getMultiple']);
			// toast
			addToast({
				title: translate('toast_deleted_title', {
					name: 'Award',
				}),
				message: translate('toast_deleted_message', {
					name: 'Award',
				}),
				type: 'success',
			});
			// cb
			if (onSuccess !== undefined) onSuccess();
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await deleteAward.mutateAsync({
			id: id,
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			title={T.modals.delete_award.title}
			onConfirm={onConfirm}
			type={'danger'}
			onClose={() => {
				setErrorMsg('');
				deleteAward.reset();
			}}
			formAction={{
				loading: {
					is: deleteAward.isLoading,
					message: translate('deleting'),
				},
				error: {
					is: deleteAward.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>{T.modals.delete_award.body}</p>
			</div>
		</ConfirmationModal>
	);
};

export default DeleteAward;
