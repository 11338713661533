import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import UpdateOptionsAction from '@components/actions/UpdateOptions';

const AwardsList: React.FC = () => {
	// -------------------------------------------------
	// Queries / Mutations
	const saOptions = useQuery(['sa.options.getOptions'], () => {
		return api.sa.options.getOptions();
	});

	// -----------------------------------------
	// Render
	if (saOptions.isLoading) return <Loading type="page" />;
	if (saOptions.isError) return <Error type="page" />;

	return (
		<>
			<PageHeading title={translate('options')} />
			<PageWrapper>
				<UpdateOptionsAction
					options={saOptions.data.data}
					callback={() => {}}
				/>
			</PageWrapper>
		</>
	);
};

export default AwardsList;
