import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';
import SingleImageUpload from '@components/forms/SingleImageUpload';

interface CreateChapterActionProps extends ActionProps {}

const CreateChapterAction: React.FC<CreateChapterActionProps> = ({
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [image, setImage] = useState<File | undefined | null>(undefined);

	// --------------------------------------
	// Queries / Mutations
	const createChapter = useMutation(api.sa.chapters.createSingle, {
		onSuccess: (data) => {
			resetForm();
			queryClient.invalidateQueries(['sa.chapters.getAll']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Chapter',
				}),
				message: translate('toast_created_message', {
					name: 'Chapter',
				}),
				type: 'success',
			});
			callback(true, data.data.data);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createChapter.mutateAsync({
			title: title,
			desc: description,
			image: image,
		});
	};
	const resetForm = () => {
		setTitle('');
		setDescription('');
		setImage(undefined);
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		createChapter.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<SingleImageUpload
				id="image"
				name="image"
				errors={formState.errors.value['image']}
				required={false}
				formats={['image/jpeg']}
				value={image}
				setValue={setImage}
				describedBy={translate('image_described_by', {
					width: 'min 600',
					height: 'min 338',
					type: 'jpeg',
				})}
			/>
			<Input
				id="chapterTitle"
				type="text"
				label={translate('title')}
				name="title"
				value={title}
				setValue={setTitle}
				errors={formState.errors.value['title']}
				required={true}
			/>
			<Textarea
				id="chapterDescription"
				label={translate('description')}
				name="desc"
				value={description}
				setValue={setDescription}
				errors={formState.errors.value['desc']}
				required={false}
			/>
			<FormActionModal
				loading={{
					is: createChapter.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createChapter.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateChapterAction;
