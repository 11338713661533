import { translate } from '@lang/index';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
// Assets
import certificateIconSmall from '@assets/images/certificate-icon-small.png';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Date from '@components/partials/Date';

interface CertificateRowProps {
	certificate: OrgUserAwardsRes;
	mode: 'table' | 'list';
	hideDownload?: boolean;
}

const CertificateRow: React.FC<CertificateRowProps> = ({
	certificate,
	mode,
	hideDownload = false,
}) => {
	const { organisation } = useOrganisation();

	const certificateHref = useMemo(() => {
		return api.organisations.userAwards.downloadUserAward({
			organisationId: organisation?.id || 0,
			awardId: certificate.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [certificate.award_id]);

	// -----------------------------
	// Render
	const NameColumn = () => {
		return (
			<div className="flex items-center">
				<img
					className="block w-10"
					src={certificateIconSmall}
					alt="Certificate"
					loading="lazy"
				/>
				<div className="ml-2.5">
					<h3 className="text-sm font-medium text-title">
						{certificate.award.name}
					</h3>
					<span className="text-xs">
						Issued on <Date date={certificate.created_at} />
					</span>
				</div>
			</div>
		);
	};
	const DownloadColumn = () => {
		return (
			<div className="flex items-center justify-end pr-2.5 pl-10">
				{/* Ignore noreferrer warning as this is a download link */}
				{/* eslint-disable-next-line */}
				<a
					className="text-sm transition-colors duration-200 hover:text-brandTertiaryHover hover:underline"
					href={certificateHref}
					target="_blank">
					<FontAwesomeIcon
						icon={faLink}
						className="mr-1 text-brandTertiary"
					/>
					{translate('download')}
				</a>
			</div>
		);
	};

	if (mode === 'table') {
		return (
			<tr>
				<td className="td-default">
					<NameColumn />
				</td>
				{!hideDownload && (
					<td className="td-default">
						<DownloadColumn />
					</td>
				)}
			</tr>
		);
	}

	return (
		<li className="flex items-center justify-between border-b border-border px-2.5 py-2 last:border-b-0">
			<NameColumn />
			{!hideDownload && <DownloadColumn />}
		</li>
	);
};

export default CertificateRow;
