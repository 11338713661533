import { translate } from '@lang/index';
import {
	faChevronDown,
	faCaretDown,
	faCaretUp,
	faMinus,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
// Components
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SortProps {
	sort: Array<{ key: string; value: 'asc' | 'desc' | 'off' }>;
	setSort: (key: string, value: 'asc' | 'desc' | 'off') => void;
	labels: Array<{ key: string; label: string }>;
}

const Sort: React.FC<SortProps> = ({ sort, setSort, labels }) => {
	const handleChange = (key: string, value: 'asc' | 'desc' | 'off') => {
		if (value === 'off') {
			setSort(key, 'asc');
		} else if (value === 'asc') {
			setSort(key, 'desc');
		} else if (value === 'desc') {
			setSort(key, 'off');
		}
	};

	return (
		<Popover className="relative z-30">
			{({ open }) => (
				<>
					<Popover.Button
						aria-label="Sort by"
						className={
							'flex h-10 w-52 items-center justify-between rounded-md border border-border bg-white px-2 text-sm'
						}>
						{translate('sort_by')}
						<span className="flex h-7 w-7 items-center justify-center rounded-md bg-uiDark">
							<FontAwesomeIcon
								icon={faChevronDown}
								className={classNames(
									'text-white transition-transform duration-200',
									{
										'rotate-180 transform': open,
									}
								)}
							/>
						</span>
					</Popover.Button>
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0">
						<Popover.Panel className="absolute left-0 z-10 mt-2 flex w-72 origin-top-left flex-col overflow-hidden rounded-md bg-white drop-shadow-md focus:outline-none">
							<div className="bg-brandTertiary py-3 px-4 text-left text-brandTertiaryText">
								{translate('sort')}
							</div>
							<ul className="w-full divide-y divide-border py-1 px-1">
								{sort.map((s) => (
									<li
										key={s.key}
										className="font-base w-full bg-white text-left text-sm text-title transition-colors duration-200 hover:bg-uiLight">
										<button
											aria-label={`Sort by ${
												labels.find(
													(l) => l.key === s.key
												)?.label ?? s.key
											}`}
											className="inline-flex w-full items-center justify-between px-3 py-1"
											onClick={() => {
												handleChange(s.key, s.value);
											}}>
											{labels.find((l) => l.key === s.key)
												?.label ?? s.key}
											<span
												className={classNames(
													'flex h-6 w-6 items-center justify-center rounded-md',
													{
														'bg-brandSecondary text-brandSecondaryText':
															s.value === 'off',
														'bg-brandTertiary text-brandTertiaryText':
															s.value === 'asc' ||
															s.value === 'desc',
													}
												)}>
												{s.value !== 'off' ? (
													s.value === 'desc' ? (
														<FontAwesomeIcon
															icon={faCaretDown}
														/>
													) : (
														<FontAwesomeIcon
															icon={faCaretUp}
														/>
													)
												) : (
													<FontAwesomeIcon
														icon={faMinus}
													/>
												)}
											</span>
										</button>
									</li>
								))}
							</ul>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};
export default Sort;
