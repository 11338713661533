import { http } from '@utils/index';

interface Props {
	title: string;
	desc: string;
	image?: File | null;
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaChaptersRes>>(
		`/sa/chapters`,
		{
			title: props.title,
			desc: props.desc,
			image: props.image,
		},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default createSingle;
