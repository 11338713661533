import { http } from '@utils/index';

interface Props {
	body: {
		title: string;
		name: string;
		email: string;
		message: string;
		last_name: string;
	};
}

const sendEnquiry = (props: Props) => {
	return http.post<APIResponse<{}>>(`/public/enquiry`, props.body);
};
export default sendEnquiry;
