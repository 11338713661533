import { http } from '@utils/index';

export interface Props {
	id: number | string;
	body: {
		name?: string;
		users?: number;
		seats?: number;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaPlanRes>>(`/sa/plans/${props.id}`, {
		_method: 'put',
		...props.body,
	});
};
export default updateSingle;
