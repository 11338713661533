import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		courses: boolean;
		cues: boolean;
		media: boolean;
		mediaCount: boolean;
	};
	filter?: {
		s: string;
	};
	perPage?: number;
}

const searchVideos = (props: Props) => {
	return http.get<APIResponse<VideosRes[]>>(
		`/organisations/${props.organisationId}/search/videos${buildQueryString(
			props.queryString,
			[
				{
					key: 'cues',
					include: props.include.cues,
				},
				{ key: 'media', include: props.include.media },
				{ key: 'mediaCount', include: props.include.mediaCount },
				{ key: 'courses', include: props.include.courses },
			],
			[
				{
					key: 's',
					value: props.filter?.s,
				},
			],
			props.perPage
		)}`
	);
};
export default searchVideos;
