import { http } from '@utils/index';

interface Props {
	name: string;
	active: 1 | 0;
	plan_id?: number;
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaOrganisationsRes>>(`/sa/organisations`, {
		...props,
	});
};
export default createSingle;
