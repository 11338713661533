import { http } from '@utils/index';

interface Props {
	id: number;
}

const suspend = (props: Props) => {
	return http.post<APIResponse<SaUsersRes>>(`/sa/users/${props.id}/suspend`, {
		_method: 'put',
	});
};
export default suspend;
