import classNames from 'classnames';

interface TabListProps {
	active: string;
	setActive: (active: string) => void;
	options: Array<{
		label: string;
		value: string;
		permission?: boolean;
	}>;
}

const TabList: React.FC<TabListProps> = ({ active, setActive, options }) => {
	// -------------------------------------------------
	// Classes
	const filterListItem = classNames('pr-5');
	const filterButton = classNames(
		'py-2.5 hover:text-brandTertiary duration-200 transition-colors font-medium text-sm'
	);
	const activeFilterButton =
		'border-b-2 border-brandTertiary text-brandTertiary';

	// -------------------------------------------------
	// Render
	return (
		<ul className="mb-2.5 flex border-b">
			{options.map((option, index) => {
				if (option.permission === false) {
					return null;
				}
				return (
					<li className={filterListItem} key={index}>
						<button
							type="button"
							onClick={() => setActive(option.value)}
							className={classNames(filterButton, {
								[activeFilterButton]: active === option.value,
							})}>
							{option.label}
						</button>
					</li>
				);
			})}
		</ul>
	);
};

export default TabList;
