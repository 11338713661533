import getMultiple from './get-multiple';
import updateSingle from './update-single';
import createSingle from './create-single';

const actions = {
	getMultiple,
	updateSingle,
	createSingle,
};

export default actions;
