import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
// api
import api from 'api';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import QuizResultsRow from '@components/rows/QuizResultsRow';
import TopPagination from '@components/query/FormUtilityBar';

interface QuizResultTableProps extends QueryTableProps {
	mode: 'sa' | 'organisation';
	utilityBar?: React.ReactNode;
	isSuperadmin?: boolean;
}
type SaQuizResultResT = AxiosResponse<APIResponse<SaQuizResultRes[]>, any>;
type OrgQuizResultResT = AxiosResponse<APIResponse<OrgQuizResultRes[]>, any>;
type PreviewCategoriesRes = AxiosResponse<APIResponse<SaCategoriesRes[]>, any>;
type StandardCategoriesRes = AxiosResponse<APIResponse<CategoriesRes[]>, any>;

const QuizResultTable: React.FC<QuizResultTableProps> = ({
	mode,
	searchParams,
	queryString,
	utilityBar,
	resetFilters,
	enabled,
	isSuperadmin,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Queries / Mutations
	const quizResults = useQuery<SaQuizResultResT | OrgQuizResultResT>(
		[
			mode === 'sa'
				? 'sa.quizResults.getMultiple'
				: 'organisations.quizResults.getMultiple',
			queryString,
			organisation?.id,
		],
		() => {
			if (mode === 'sa') {
				return api.sa.quizResults.getMultiple({
					queryString: queryString,
					include: {
						course: true,
						organisation: true,
						quiz: true,
						user: true,
					},
				});
			} else {
				return api.organisations.quizResults.getMultiple({
					organisationId: organisation?.id as number,
					queryString: queryString,
					include: {
						course: true,
						quiz: true,
						user: true,
					},
				});
			}
		},
		{
			enabled: enabled,
			keepPreviousData: true,
		}
	);
	const categories = useQuery<PreviewCategoriesRes | StandardCategoriesRes>(
		[
			mode === 'sa'
				? 'sa.categories.getMultiple'
				: 'organisations.categories.getAll',
			organisation?.id,
		],
		() => {
			if (mode === 'sa') {
				return api.sa.categories.getMultiple({
					include: {
						meta: true,
						metaCount: false,
					},
					perPage: -1,
				});
			}
			return api.organisations.categories.getAll({
				organisationId: organisation?.id || 0,
				include: {
					meta: true,
					metaCount: false,
				},
			});
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={quizResults.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: quizResults.isLoading || categories.isLoading,
					error: quizResults.isError || categories.isError,
					noData: quizResults.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Assessment',
					}),
					message: translate('no_data_message', {
						name: 'assessment',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('assessment'),
							type: 'standard',
						},
						{
							label: translate('passed'),
							type: 'standard',
						},
						{
							label: translate('user'),
							type: 'standard',
							hide: !manageOrganisationWithPlan && !isSuperadmin,
						},
						{
							label: translate('organisation'),
							type: 'standard',
							hide: mode === 'organisation',
						},
						{
							label: translate('course'),
							type: 'standard',
						},
						{
							label: translate('assessment_taken'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
						},
					]}
					setSort={searchParams.sort.setValue}
					scrollX={true}>
					{quizResults.data?.data.data.map((quiz, index) => (
						<QuizResultsRow
							key={index}
							mode={mode}
							result={quiz}
							categories={categories.data?.data.data || []}
							isSuperadmin={isSuperadmin}
						/>
					))}
				</Table>
				<Pagination
					meta={quizResults.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default QuizResultTable;
