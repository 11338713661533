import classNames from 'classnames';

interface BadgesProps {
	theme: 'green' | 'red' | 'grey';
	onClick?: () => void;
	className?: string;
	children: React.ReactNode;
}

const Badges: React.FC<BadgesProps> = ({
	theme,
	onClick,
	children,
	className,
}) => {
	// set the classnames for the button
	const classes = classNames(
		`duration-200 transition-colors inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium`,
		{
			'bg-green text-title': theme === 'green',
			'bg-red text-title bg-opacity-30': theme === 'red',
			'bg-uiLight text-title border-border border': theme === 'grey',
			'hover:bg-opacity-70 cursor-pointer': onClick,
		},
		className
	);

	return (
		<span className={classes} onClick={onClick}>
			{children}
		</span>
	);
};

export default Badges;
