import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		course: boolean;
		user: boolean;
		organisation: boolean;
	};
	filters?: {
		completed?: 1 | 0;
		course_id?: number;
		user_id?: number;
		organisation_id?: number;
		created_at_from?: string;
		created_at_to?: string;
		updated_at_from?: string;
		updated_at_to?: string;
	};
	sort?: {
		created_at: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaCourseProgressRes[]>>(
		`/sa/course-progress${buildQueryString(
			props.queryString,
			[
				{ key: 'course', include: props.include.course },
				{ key: 'user', include: props.include.user },
				{ key: 'organisation', include: props.include.organisation },
			],
			[
				{ key: 'completed', value: props.filters?.completed },
				{ key: 'course_id', value: props.filters?.course_id },
				{ key: 'user_id', value: props.filters?.user_id },
				{
					key: 'organisation_id',
					value: props.filters?.organisation_id,
				},
				{
					key: 'created_at_from',
					value: props.filters?.created_at_from,
				},
				{ key: 'created_at_to', value: props.filters?.created_at_to },
				{
					key: 'updated_at_from',
					value: props.filters?.updated_at_from,
				},
				{ key: 'updated_at_to', value: props.filters?.updated_at_to },
			],
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
