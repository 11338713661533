import { translate } from '@lang/index';
// Components
import Button from '@components/partials/Button';
import classNames from 'classnames';

interface PaginationProps {
	meta?: APIMeta;
	page: number;
	setPage: (page: number) => void;
}

interface PaginationButtonProps {
	page: number;
	currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ meta, page, setPage }) => {
	// -----------------------------
	// Functions
	const handleChange = (page: number) => {
		setPage(page);
	};

	const PaginationButton: React.FC<PaginationButtonProps> = ({
		page,
		currentPage,
	}) => {
		return (
			<button
				className={classNames(
					'inline-flex items-center px-3 text-sm font-medium text-title hover:text-brandTertiary',
					{
						'text-brandTertiary': currentPage === page,
					}
				)}
				disabled={currentPage === page}
				onClick={() => {
					handleChange(page);
				}}>
				{page}
			</button>
		);
	};

	// -----------------------------
	// Render

	if (meta === undefined) return null;
	if (meta.last_page === 1) return null;

	const dynamicPaginationButtons = () => {
		// 1 2 3 4 ... 10 20 30 112
		const buttons: JSX.Element[] = [];

		// left side - always incremmtal
		const leftShow = 4;
		const leftStartingPage =
			meta?.current_page === 1 ? 1 : meta?.current_page - 1;
		const leftEndingPage = leftStartingPage + leftShow;
		for (let i = leftStartingPage; i < leftEndingPage; i++) {
			if (i > meta?.last_page) break;
			buttons.push(
				<PaginationButton
					key={buttons.length + 1}
					page={i}
					currentPage={meta?.current_page}
				/>
			);
		}

		// middle
		buttons.push(
			<span
				key={buttons.length + 1}
				className="inline-flex items-center px-3 text-sm font-medium text-gray-500">
				...
			</span>
		);

		// right side
		const rightStartingPage = Math.ceil(leftEndingPage / 10) * 10;
		const rightShow = 3;
		let rightShown = 1;
		for (let i = rightStartingPage; i < meta.last_page; i += 10) {
			if (i > meta?.last_page) break;
			if (rightShown > rightShow) break;
			rightShown++;
			buttons.push(
				<PaginationButton
					key={buttons.length + 1}
					page={i}
					currentPage={meta?.current_page}
				/>
			);
		}

		// last page
		buttons.push(
			<PaginationButton
				key={buttons.length + 1}
				page={meta?.last_page}
				currentPage={meta?.current_page}
			/>
		);

		return buttons;
	};

	return (
		<>
			<nav className="mt-8 flex items-center justify-between border-t border-border pt-5">
				<div className="mr-5">
					<Button
						type={'button'}
						theme={'outline'}
						ariaLabel={'First page'}
						onClick={() => {
							handleChange(1);
						}}
						disabled={meta?.current_page === 1}
						className={'mr-2 hidden xl:inline-flex'}>
						{translate('first_page')}
					</Button>
					<Button
						type={'button'}
						theme={'outline'}
						ariaLabel={'Previous page'}
						onClick={() => {
							if (meta?.current_page === 1) return;
							handleChange(page - 1);
						}}
						disabled={meta?.current_page === 1}>
						{translate('previous_page')}
					</Button>
				</div>
				<div className="hidden md:-mt-px md:flex">
					{dynamicPaginationButtons()}
				</div>
				<div className="ml-5">
					<Button
						type={'button'}
						theme={'outline'}
						ariaLabel={'Previous page'}
						onClick={() => {
							handleChange(page + 1);
						}}
						disabled={meta?.current_page === meta?.last_page}>
						{translate('next_page')}
					</Button>
					<Button
						type={'button'}
						theme={'outline'}
						ariaLabel={'Last page'}
						onClick={() => {
							handleChange(meta?.last_page);
						}}
						disabled={meta?.current_page === meta?.last_page}
						className={'ml-2 hidden xl:inline-flex'}>
						{translate('last_page')}
					</Button>
				</div>
			</nav>
			<span className="mt-2.5 block text-sm text-body text-opacity-80">
				From <span className="font-bold">{meta?.from}</span> to{' '}
				<span className="font-bold">{meta?.to}</span> of{' '}
				<span className="font-bold">{meta?.total}</span> result(s)
			</span>
		</>
	);
};

export default Pagination;
