import { http } from '@utils/index';

interface Props {
	body: {
		name?: string;
		email?: string;
	};
}

const updateMe = (props: Props) => {
	return http.post<APIResponse<SaOrganisationsRes>>(`/user`, {
		...props.body,
		_method: 'put',
	});
};
export default updateMe;
