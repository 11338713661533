import { useMemo } from 'react';
import type { Item } from '@components/groups/course-builder/Container';
import faPlusSolid from '@assets/icons/faPlusSolid.svg';
// Components
import CategoryIcon from '@components/partials/CategoryIcon';

interface CourseBuilderListItemProps {
	type: 'quiz' | 'video' | 'chapter';
	data: SaQuizzesRes | SaVideosRes | SaChaptersRes;
	addItem: () => void;
	categories: SaCategoriesRes[];
	selectedItems: Item[];
}

const CourseBuilderListItem: React.FC<CourseBuilderListItemProps> = ({
	type,
	data,
	addItem,
	categories,
	selectedItems,
}) => {
	// --------------------------------------
	// Memos
	const category = useMemo(
		() => categories.find((category) => category.id === data.type_id),
		[categories, data.type_id]
	);
	const categoryScope = useMemo<CategoryScopes>(() => {
		if (type === 'quiz') return 'quizType';
		if (type === 'video') return 'videoType';
		return 'quizType';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const isSelected = useMemo(() => {
		return selectedItems.some(
			(item) => item.data.id === data.id && item.type === type
		);
	}, [data.id, selectedItems, type]);

	// --------------------------------------
	// Render
	if (isSelected) return null;

	return (
		<li
			className={
				'group relative flex h-full w-full cursor-pointer items-center rounded-md border border-border bg-uiLight p-3 hover:border-dashed hover:border-uiDark'
			}>
			<div className={' mr-2.5'}>
				<CategoryIcon
					colour={category?.meta?.icon_colour}
					initials={category?.meta?.icon_initials}
					label={category?.name}
					categoryScope={categoryScope}
					size={'sm'}
				/>
			</div>

			<div className="flex flex-col">
				<p className="text-sm">{data.title}</p>
				{data?.ref && (
					<span className="text-sm font-bold">{data.ref}</span>
				)}
			</div>

			<button
				onClick={addItem}
				className="absolute inset-0 flex items-center justify-center bg-uiGray bg-opacity-60 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
				<img
					src={faPlusSolid}
					alt="Add item"
					className="h-4 w-4 object-contain object-center"
				/>
			</button>
		</li>
	);
};

export default CourseBuilderListItem;
