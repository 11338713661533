import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdatePlanAction from '@components/actions/UpdatePlan';

interface UpdatePlanProps {
	id: number;
	open: boolean;
	setOpen: (state: boolean) => void;
}

const UpdatePlan: React.FC<UpdatePlanProps> = ({ id, open, setOpen }) => {
	return (
		<Modal open={open} setOpen={setOpen} title={T.modals.update_plan.title}>
			<UpdatePlanAction
				id={id}
				callback={(success, data) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default UpdatePlan;
