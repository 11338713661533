import classNames from 'classnames';

interface PageWrapperProps {
	children: React.ReactNode;
	className?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, className }) => {
	return (
		<div className={classNames('p-5 md:p-7', className)}>{children}</div>
	);
};

export default PageWrapper;
