interface InfoRowProps {
	children: React.ReactNode;
	info?: string;
}

const InfoRow: React.FC<InfoRowProps> = ({ children, info }) => {
	return (
		<div className="w-full border-b border-border last:border-b-0 mb-5 last:mb-0 pb-5 last:pb-0">
			{children}
			{info && (
				<div className="mt-2.5">
					<p className="text-sm text-body text-opacity-80">{info}</p>
				</div>
			)}
		</div>
	);
};

export default InfoRow;
