import { translate } from '@lang/index';
import {
	faBook,
	faClipboardQuestion,
	faBuildingNgo,
	faUser,
	faVideo,
	faChartSimple,
	faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
// API
import api from 'api';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import StatGrid from '@components/blocks/StatGrid';
import TablePreviews from '@components/blocks/TablePreviews';
import StartingPoints from '@components/blocks/StartingPoints';
import PageHeading from '@components/blocks/PageHeading';
// Cards
import TableCard from '@components/cards/TableCard';
import StatCard from '@components/cards/StatCard';
// Rows
import VideoViewRow from '@components/rows/VideoViewRow';
import VideoStatsRow from '@components/rows/VideoStatsRow';
import QuizResultsRow from '@components/rows/QuizResultsRow';
import QuizStatsRow from '@components/rows/QuizStatsRow';
import LoginLogRow from '@components/rows/LoginLogRow';
import ProgressRow from '@components/rows/ProgressRow';

interface SuperAdminViewProps {
	user: CurrentUserRes;
}

const SuperAdminView: React.FC<SuperAdminViewProps> = () => {
	const { convertDate, currentDate } = dateHelpers;

	// -------------------------------------------------
	// Query & Mutation
	const videoViews = useQuery(['sa.videoViews.getMultiple'], () => {
		return api.sa.videoViews.getMultiple({
			include: {
				course: true,
				organisation: true,
				user: true,
				video: true,
				videoMedia: true,
			},
			sort: {
				viewed_at: 'desc',
			},
			perPage: 5,
		});
	});
	const videoViewCount = useQuery(['sa.videoViews.getCount'], () => {
		return api.sa.statistics.videos({
			sort: {
				views_count: 'desc',
			},
			perPage: 10,
		});
	});
	const quizResults = useQuery(['sa.quizResults.getMultiple'], () => {
		return api.sa.quizResults.getMultiple({
			include: {
				course: true,
				organisation: true,
				quiz: true,
				user: true,
			},
			sort: {
				created_at: 'desc',
			},
			perPage: 5,
		});
	});
	const quizResultCount = useQuery(['sa.quizResults.getCount'], () => {
		return api.sa.statistics.quizzes({
			sort: {
				results_count: 'desc',
			},
			perPage: 10,
		});
	});
	const categories = useQuery(['sa.categories.getMultiple'], () => {
		return api.sa.categories.getMultiple({
			include: {
				meta: true,
				metaCount: false,
			},
			perPage: -1,
		});
	});
	const logs = useQuery(['sa.logs.getMultiple'], () => {
		return api.sa.logs.getMultiple({
			include: {
				type: true,
				user: true,
			},
			sort: {
				created_at: 'desc',
			},
			perPage: 5,
		});
	});
	const usersActiveToday = useQuery(
		['sa.courseProgress.getMultiple.today'],
		() => {
			return api.sa.courseProgress.getMultiple({
				include: {
					course: true,
					user: true,
					organisation: true,
				},
				filters: {
					updated_at_from: convertDate(currentDate),
				},
				sort: {
					created_at: 'desc',
				},
				perPage: 5,
			});
		}
	);

	const coursesCompleted = useQuery(
		['sa.courseProgress.getMultiple.completed'],
		() => {
			return api.sa.courseProgress.getMultiple({
				include: {
					course: false,
					user: false,
					organisation: false,
				},
				filters: {
					completed: 1,
				},
				perPage: 1,
			});
		}
	);
	const coursesInProgress = useQuery(
		['sa.courseProgress.getMultiple.inProgress'],
		() => {
			return api.sa.courseProgress.getMultiple({
				include: {
					course: false,
					user: false,
					organisation: false,
				},
				filters: {
					completed: 0,
				},
				perPage: 1,
			});
		}
	);

	// -------------------------------------------------
	// Functions
	const navigateLocation = (
		path: string,
		filter: {
			key: string;
			value: string;
		}
	) => {
		const filterString = `filter[${filter.key}]=${filter.value}`;
		return `${path}?${filterString}`;
	};

	// -------------------------------------------------
	// Memos
	const loginRows = useMemo(() => {
		return logs.data?.data.data.filter((log) => log.type.key === 'login');
	}, [logs.data?.data.data]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('dashboard')}
				tooltip={translate('dashboard_tooltip')}></PageHeading>
			<PageWrapper>
				{/* Starting Points */}
				<StartingPoints
					cards={[
						{
							title: 'Manage assessments',
							description: 'Create and manage assessments',
							icon: faClipboardQuestion,
							href: '/assessments',
						},
						{
							title: 'Manage videos',
							description: 'Create and manage videos',
							icon: faVideo,
							href: '/videos',
						},
						{
							title: 'Manage courses',
							description: 'Create and manage courses',
							icon: faBook,
							href: '/courses',
						},
						{
							title: 'Manage video library',
							description: 'Create and manage the video library',
							icon: faBook,
							href: '/courses',
						},
						{
							title: 'Manage organisations',
							description: 'Create and manage organisations',
							icon: faBuildingNgo,
							href: '/organisations',
						},
						{
							title: 'Manage user',
							description: 'Create and manage users',
							icon: faUser,
							href: '/users',
						},
					]}
				/>

				{/* Quick Stats */}
				<StatGrid>
					<StatCard
						title={'Courses Completed'}
						value={coursesCompleted.data?.data.meta?.total || 0}
						icon={faClipboardCheck}
						link={{
							href: navigateLocation('/progress', {
								key: 'completed',
								value: '1',
							}),
							text: 'View',
						}}
						state={{
							isLoading: coursesCompleted.isLoading,
							isError: coursesCompleted.isError,
						}}
					/>
					<StatCard
						title={'Courses In Progress'}
						value={coursesInProgress.data?.data.meta?.total || 0}
						icon={faChartSimple}
						link={{
							href: navigateLocation('/progress', {
								key: 'completed',
								value: '0',
							}),
							text: 'View',
						}}
						state={{
							isLoading: coursesInProgress.isLoading,
							isError: coursesInProgress.isError,
						}}
					/>
				</StatGrid>

				{/* User Stats */}
				<TablePreviews title={'User Stats'}>
					{/* Active */}
					<TableCard
						title={'Course Progress Today'}
						rows={usersActiveToday.data?.data.data.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('user'),
								type: 'standard',
							},
							{
								label: translate('organisation'),
								type: 'standard',
							},
							{
								label: translate('course'),
								type: 'standard',
							},
							{
								label: translate('progress'),
								type: 'standard',
								style: 'text-center justify-center',
							},
							{
								label: translate('started'),
								type: 'standard',
							},
							{
								label: translate('last_activity'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: usersActiveToday.isLoading,
							isError: usersActiveToday.isError,
						}}>
						{usersActiveToday.data?.data.data.map(
							(progress, index) => (
								<ProgressRow
									key={index}
									mode={'sa'}
									progress={progress}
									isSuperadmin={true}
								/>
							)
						)}
					</TableCard>
					{/* Login Activity */}
					<TableCard
						title={'Login Activity'}
						rows={loginRows?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('user'),
								type: 'standard',
							},
							{
								label: translate('ip'),
								type: 'standard',
							},
							{
								label: translate('device'),
								type: 'standard',
							},
							{
								label: translate('date'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: logs.isLoading,
							isError: logs.isError,
						}}>
						{loginRows?.map((log, index) => (
							<LoginLogRow
								key={index}
								log={log}
								isSuperadmin={true}
							/>
						))}
					</TableCard>
				</TablePreviews>

				{/* Video Stats */}
				<TablePreviews title={translate('video_views')}>
					<TableCard
						title={'Recently Viewed'}
						rows={videoViews.data?.data.data?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('video'),
								type: 'standard',
							},
							{
								label: translate('user'),
								type: 'standard',
							},
							{
								label: translate('organisation'),
								type: 'standard',
							},
							{
								label: translate('course'),
								type: 'standard',
							},
							{
								label: translate('viewed_at'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: videoViews.isLoading,
							isError: videoViews.isError,
						}}>
						{videoViews.data?.data.data.map((view, index) => (
							<VideoViewRow
								key={index}
								mode={'sa'}
								view={view}
								isSuperadmin={true}
							/>
						))}
					</TableCard>
					<TableCard
						title={'Most Viewed'}
						rows={videoViewCount.data?.data.data?.length || 0}
						rowLimit={10}
						head={[
							{
								label: translate('video'),
								type: 'standard',
							},
							{
								label: translate('views'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: videoViewCount.isLoading,
							isError: videoViewCount.isError,
						}}>
						{videoViewCount.data?.data.data.map(
							(viewCount, index) => (
								<VideoStatsRow
									key={index}
									videoStats={viewCount}
								/>
							)
						)}
					</TableCard>
				</TablePreviews>

				{/* Assessment Stats */}
				<TablePreviews title={'Assessment Stats'}>
					<TableCard
						title={'Recently Taken'}
						rows={quizResults.data?.data.data?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('assessment'),
								type: 'standard',
							},
							{
								label: translate('passed'),
								type: 'standard',
							},
							{
								label: translate('user'),
								type: 'standard',
							},
							{
								label: translate('organisation'),
								type: 'standard',
							},
							{
								label: translate('course'),
								type: 'standard',
							},
							{
								label: translate('assessment_taken'),
								type: 'standard',
							},
						]}
						state={{
							isLoading:
								quizResults.isLoading || categories.isLoading,
							isError: quizResults.isError || categories.isError,
						}}>
						{quizResults.data?.data.data.map((quiz, index) => (
							<QuizResultsRow
								key={index}
								mode={'sa'}
								result={quiz}
								categories={categories.data?.data.data || []}
								isSuperadmin={true}
							/>
						))}
					</TableCard>
					<TableCard
						title={'Most Taken'}
						rows={quizResultCount.data?.data.data?.length || 0}
						rowLimit={10}
						head={[
							{
								label: translate('assessment'),
								type: 'standard',
							},
							{
								label: translate('results'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: quizResultCount.isLoading,
							isError: quizResultCount.isError,
						}}>
						{quizResultCount.data?.data.data.map(
							(quizCount, index) => (
								<QuizStatsRow
									key={index}
									quizStats={quizCount}
								/>
							)
						)}
					</TableCard>
				</TablePreviews>
			</PageWrapper>
		</>
	);
};

export default SuperAdminView;
