import classNames from 'classnames';
import {
	faCaretDown,
	faCaretUp,
	faMinus,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@components/forms/Checkbox';
import Input from '@components/forms/Input';

export interface TableProps {
	head: Array<{
		label: string;
		type:
			| 'standard'
			| 'sortable'
			| 'sr-only'
			| 'progress'
			| 'checkbox'
			| 'date';

		style?: string;
		sort?: { key: string; value: 'asc' | 'desc' | 'off' };
		href?: string;
		checked?: boolean;
		setChecked?: (selected: boolean) => void;
		hide?: boolean;
		value?: string;
		setValue?: (val: string) => void;
	}>;
	setSort?: (key: string, value: 'asc' | 'desc' | 'off') => void;
	scrollX?: boolean;
	children: React.ReactNode;
}

interface SortableThProps {
	label: string;
	sort?: { key: string; value: 'asc' | 'desc' | 'off' };
	style?: string;
}

const Table: React.FC<TableProps> = ({
	head,
	setSort,
	scrollX = true,
	children,
}) => {
	// Set Sort
	const handleSortChange = (key?: string, value?: 'asc' | 'desc' | 'off') => {
		if (key && value && setSort) {
			if (value === 'off') {
				setSort(key, 'asc');
			} else if (value === 'asc') {
				setSort(key, 'desc');
			} else if (value === 'desc') {
				setSort(key, 'off');
			}
		}
	};

	// Sortable TH
	const SortableTHButton: React.FC<SortableThProps> = ({
		label,
		sort,
		style,
	}) => {
		return (
			<button
				className={classNames(
					'flex w-full items-center justify-start text-left',
					style
				)}
				onClick={() => {
					handleSortChange(sort?.key, sort?.value);
				}}>
				{label}
				<span
					className={
						'ml-1.5 flex h-4 w-4 items-center justify-center'
					}>
					{sort?.value !== 'off' ? (
						sort?.value === 'desc' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)
					) : (
						<FontAwesomeIcon icon={faMinus} />
					)}
				</span>
			</button>
		);
	};

	return (
		<div
			className={classNames('rounded-lg border border-border ', {
				'overflow-x-auto': scrollX,
			})}>
			<table className="table min-w-full border-collapse">
				<thead
					className={classNames('z-10 bg-uiLight', {
						'sticky top-header-height': !scrollX,
					})}>
					<tr className="rounded-t-md bg-uiLight ">
						{head.map((item, index) => {
							if (item.hide) return null;

							return (
								<th
									key={index}
									scope="col"
									className={classNames(
										'whitespace-nowrap border-b border-border px-3 py-2.5 text-left text-sm font-semibold text-gray-900',
										{
											'pl-5': index === 0,
											'pr-5': index === head.length - 1,
											'w-[200px] min-w-[200px] text-center':
												item.type === 'progress',
											'w-[40px] min-w-[40px]':
												item.type === 'checkbox',
										},
										item.style
									)}>
									{item.type === 'sr-only' && (
										<span className="sr-only">
											{item.label}
										</span>
									)}
									{item.type === 'standard' && item.label}
									{item.type === 'progress' && (
										<Link
											to={item.href || '/'}
											className="text-xs line-clamp-3 hover:underline">
											{item.label}
										</Link>
									)}
									{item.type === 'sortable' && (
										<SortableTHButton
											label={item.label}
											sort={item.sort}
											style={item.style}
										/>
									)}
									{item.type === 'checkbox' && (
										<>
											<span className="sr-only">
												{item.label}
											</span>
											<Checkbox
												name={item.label}
												checked={item.checked || false}
												setChecked={
													item.setChecked ||
													(() => {})
												}
												errors={[]}
											/>
										</>
									)}
									{item.type === 'date' && (
										<div className="flex flex-col">
											{item.label}
											<Input
												id={`global-table-date-${item.label}`}
												type="date"
												inputStyle="font-normal mt-2"
												name={item.label}
												value={item.value || ''}
												setValue={
													item.setValue || (() => {})
												}
												errors={[]}
												required={false}
												showErrors={false}
											/>
										</div>
									)}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="divide-y divide-border  bg-white">
					{children}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
