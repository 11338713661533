// Assets
import emptySVG from '@assets/images/svgs/empty.svg';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import MessageBlock from '@components/blocks/MessageBlock';

interface GridContainerProps {
	state: {
		loading: boolean;
		loadingCards?: number;
		error: boolean;
		noData?: any;
	};
	noData?: {
		title: string;
		message: string;
		image?: string;
	};
	children: React.ReactNode;
}

const GridContainer: React.FC<GridContainerProps> = ({
	state,
	noData,
	children,
}) => {
	// -------------------------------------------------
	// Classes
	const ulClass =
		'grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 list-none';

	// -------------------------------------------------
	// Render

	// Loading
	if (state.loading) {
		return (
			<ul className={ulClass}>
				{[...Array(state.loadingCards)].map((_, index) => (
					<li
						key={index}
						className="h-[220px] w-full rounded-md relative overflow-hidden">
						<Loading type="skeleton" />
					</li>
				))}
			</ul>
		);
	}

	// Error
	if (state.error) {
		return <Error type="block" />;
	}

	// No data
	if (state.noData) {
		return (
			<MessageBlock
				title={noData?.title || 'No Data'}
				message={noData?.message || 'There is no data to display'}
				image={noData?.image || emptySVG}
			/>
		);
	}

	// All good
	return <ul className={ulClass}>{children}</ul>;
};

export default GridContainer;
