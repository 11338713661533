import {
	faEnvelope,
	faCirclePause,
	faCirclePlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserColumnProps {
	name?: string;
	email?: string;
	email_verified_at?: string | null;
	suspended_at?: string | null;
}

const UserColumn: React.FC<UserColumnProps> = ({
	name,
	email,
	email_verified_at,
	suspended_at,
}) => {
	if (!name && !email) {
		return (
			<div className="flex flex-col items-start">
				<span className="text-sm text-body text-opacity-50">
					User Deleted
				</span>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-start">
			<span className="text-sm font-semibold">{name ? name : '-'}</span>
			<span className="font-base text-sm text-body">
				{suspended_at !== undefined && (
					<FontAwesomeIcon
						title={suspended_at ? 'Suspended' : 'Not Suspended'}
						icon={suspended_at ? faCirclePause : faCirclePlay}
						className={`mr-1 ${
							suspended_at === null
								? 'text-brandTertiary'
								: 'text-icon'
						}`}
					/>
				)}
				{email_verified_at !== undefined && (
					<FontAwesomeIcon
						title={email_verified_at ? 'Verified' : 'Not Verified'}
						icon={faEnvelope}
						className={`mr-1 ${
							email_verified_at
								? 'text-brandTertiary'
								: 'text-icon'
						}`}
					/>
				)}
				{email ? email : '-'}
			</span>
		</div>
	);
};

export default UserColumn;
