import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		name?: string;
		active?: 1 | 0;
		slug?: string;
		source?: string;
		type?: string;
		is_partner?: boolean;
		courses?: Array<{
			id: number;
			end_date?: string;
			start_date?: string;
		}>;
		meta?: {
			partner_text?: string | null;
		};
		partner_category_id?: number | null;
		theme_organisation_id?: number | null;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaOrganisationsRes>>(
		`/sa/organisations/${props.id}`,
		{
			...props.body,
			_method: 'put',
		}
	);
};
export default updateSingle;
