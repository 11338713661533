import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';
import ColourPicker from '@components/forms/ColourPicker';
import Checkbox from '@components/forms/Checkbox';

interface CreateCategoryActionProps extends ActionProps {
	allowScope: CategoryScopes;
}

const CreateCategoryAction: React.FC<CreateCategoryActionProps> = ({
	allowScope,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [ref, setRef] = useState<string>('');
	const [description, setDescription] = useState('');
	const [iconColour, setIconColour] = useState<string>('');
	const [iconInitials, setIconInitials] = useState<string>('');
	const [publicVal, setPublicVal] = useState<boolean>(false);

	// --------------------------------------
	// Queries / Mutations
	const categoryScopes = useQuery(
		['sa.categoryScopes.getAll'],
		api.sa.categoryScopes.getAll
	);
	const createCategory = useMutation(api.sa.categories.createSingle, {
		onSuccess: (data) => {
			resetForm();
			queryClient.invalidateQueries(['sa.categories.getMultiple']);
			queryClient.invalidateQueries(['sa.categories.getAll']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Category',
				}),
				message: translate('toast_created_message', {
					name: data.data.data.name,
				}),
				type: 'success',
			});
			callback(true, data.data.data);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		const scopeId = categoryScopes.data?.data.data.find(
			(scopeObj) => scopeObj.key === allowScope
		)?.id;
		if (!scopeId) return;

		await createCategory.mutateAsync({
			body: {
				name: name,
				ref: ref,
				category_scope_id: scopeId,
				meta: {
					desc: description || undefined,
					icon_colour: iconColour || undefined,
					icon_initials: iconInitials || undefined,
				},
				public: publicVal,
			},
		});
	};
	const resetForm = () => {
		setName('');
		setDescription('');
		setIconColour('');
		setIconInitials('');
		setPublicVal(false);
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		createCategory.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={categoryScopes.isLoading}
			isError={categoryScopes.isError}>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<Input
				id="ref"
				type="text"
				label={translate('ref')}
				name="ref"
				value={ref}
				setValue={setRef}
				errors={formState.errors.value['ref']}
				required={false}
			/>
			<Textarea
				id="description"
				label={translate('description')}
				name="meta.desc"
				value={description}
				setValue={setDescription}
				errors={formState.errors.value['meta.desc']}
				required={false}
			/>
			<Checkbox
				checked={publicVal}
				setChecked={setPublicVal}
				name={'public'}
				label={'Public'}
				errors={formState.errors.value['public']}
			/>
			<div className="mt-4 border-t border-border pt-4">
				<Input
					id="iconInitials"
					type="text"
					label={translate('icon_initials')}
					describedBy={translate('no_initials_set_description')}
					name="meta.icon_initials"
					value={iconInitials}
					setValue={setIconInitials}
					errors={formState.errors.value['meta.icon_initials']}
					required={false}
				/>
				<ColourPicker
					id="iconColour"
					label={translate('icon_colour')}
					name="meta.icon_colour"
					value={iconColour}
					setValue={setIconColour}
					errors={formState.errors.value['meta.icon_colour']}
					required={false}
				/>
			</div>

			<FormActionModal
				loading={{
					is: createCategory.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createCategory.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateCategoryAction;
