import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		type: boolean;
		user: boolean;
	};
	filters?: {
		type_id?: Array<number | string>;
		user_id?: Array<number | string>;
		created_at_from?: string;
		created_at_to?: string;
		organisation_id?: Array<number | string>;
	};
	sort?: {
		created_at: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaLogsRes[]>>(
		`/sa/logs${buildQueryString(
			props.queryString,
			[
				{ key: 'type', include: props.include.type },
				{ key: 'user', include: props.include.user },
			],
			[
				{ key: 'type_id', value: props.filters?.type_id },
				{ key: 'user_id', value: props.filters?.user_id },
				{
					key: 'created_at_from',
					value: props.filters?.created_at_from,
				},
				{ key: 'created_at_to', value: props.filters?.created_at_to },
				{
					key: 'organisation_id',
					value: props.filters?.organisation_id,
				},
			],
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
