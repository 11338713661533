import { http, createFormData } from '@utils/index';

interface Props {
	course_type_id: number;
	title: string;
	desc: string;
	ref: string;
	category_ids: Array<number>;
	items: Array<{
		model_id: number;
		model_type: 'quiz' | 'video' | 'chapter';
		sort: number;
	}>;

	image?: File | null;
	file?: File | null;

	public: 1 | 0;
	stripe_product_id: string | null;
	slug: string | null;
	popular_at: string | null;
	meta: {
		duration_text: string | null;
		about_text: string | null;
		completion_abilities: string | null;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaCourseRes>>(
		`/sa/courses`,
		createFormData([
			{
				key: 'course_type_id',
				value: props.course_type_id.toString(),
			},
			{
				key: 'title',
				value: props.title,
			},
			{
				key: 'desc',
				value: props.desc,
			},
			{
				key: 'ref',
				value: props.ref,
			},
			...props.category_ids.map((category_id) => ({
				key: `category_ids[]`,
				value: category_id.toString(),
			})),
			{
				key: 'image',
				value: props.image,
			},
			{
				key: 'file',
				value: props.file,
			},
			{
				key: 'public',
				value: props.public.toString(),
			},
			{
				key: 'stripe_product_id',
				value: props.stripe_product_id,
			},
			{
				key: 'slug',
				value: props.slug,
			},
			{
				key: 'popular_at',
				value: props.popular_at,
			},
			{
				key: 'meta[duration_text]',
				value: props.meta.duration_text,
			},
			{
				key: 'meta[about_text]',
				value: props.meta.about_text,
			},
			{
				key: 'meta[completion_abilities]',
				value: props.meta.completion_abilities,
			},

			...props.items.map((item, index) => ({
				key: `items[${index}][model_id]`,
				value: item.model_id.toString(),
			})),
			...props.items.map((item, index) => ({
				key: `items[${index}][model_type]`,
				value: item.model_type,
			})),
			...props.items.map((item, index) => ({
				key: `items[${index}][sort]`,
				value: item.sort.toString(),
			})),
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default createSingle;
