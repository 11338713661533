import { translate } from '@lang/index';
import C from '@root/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';
import Button from '@components/partials/Button';
import { useNavigate } from 'react-router-dom';

interface AcceptTermsProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	onSucess?: () => void;
}

const AcceptTerms: React.FC<AcceptTermsProps> = ({
	open,
	setOpen,
	onSucess,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);
	const navigate = useNavigate();

	// --------------------
	// Mutation
	const acceptTerms = useMutation(api.root.acceptTerms, {
		onSuccess: () => {
			queryClient.invalidateQueries(['root.getAuthenticatedUser']);
			addToast({
				title: translate('toast_terms_accepted_title'),
				message: translate('toast_terms_accepted_message'),
				type: 'success',
			});
			setOpen(false);
			onSucess && onSucess();
			navigate(`/`);
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await acceptTerms.mutateAsync();
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onConfirm={onConfirm}
			type={'warning'}
			size={'medium'}
			onClose={() => {
				setErrorMsg('');
				acceptTerms.reset();
			}}
			title={translate('accept_terms_title')}
			formAction={{
				loading: {
					is: acceptTerms.isLoading,
					message: translate('accepting_terms'),
				},
				error: {
					is: acceptTerms.isError,
					message: errorMsg,
				},
			}}
			buttons={{
				cancel: translate('decline'),
				confirm: translate('accept_terms'),
			}}>
			<div className="max-h-64 overflow-y-auto">
				<Button
					theme="outline"
					type="link"
					className="w-full"
					href={C.MARKETING_TERMS_URL}
					target={'_blank'}>
					{translate('read_terms')}
				</Button>
			</div>
		</ConfirmationModal>
	);
};

export default AcceptTerms;
