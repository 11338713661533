// Components
import Badges from '@components/partials/Badges';
import { Link } from 'react-router-dom';

interface VideoStatsRowProps {
	videoStats: StatisticsVideoRes;
}

const VideoStatsRow: React.FC<VideoStatsRowProps> = ({ videoStats }) => {
	return (
		<tr className="h-[32.5px]">
			<td className="td-default py-1">
				<Link
					to={`/videos/${videoStats.id}`}
					className="text-sm font-medium text-title hover:underline">
					{videoStats.title}
				</Link>
			</td>
			<td className="td-default py-1">
				<span className="font-semibold">
					<Badges theme="grey">{videoStats.views_count}</Badges>
				</span>
			</td>
		</tr>
	);
};

export default VideoStatsRow;
