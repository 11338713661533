import { useState } from 'react';

const useSelectedRow = <T,>(val: null = null) => {
	const [selectedRow, setSelectedRow] = useState<T | null>(val);

	return {
		selectedRow,
		setSelectedRow,
	};
};

export default useSelectedRow;
