import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	organisationId: number | string;
	include: {
		course: boolean;
		user: boolean;
		video: boolean;
		videoMedia: boolean;
	};
	filters?: {
		has_context?: 1 | 0;
		user_id?: Array<number | string>;
		video_id?: Array<number | string>;
		course_id?: Array<number | string>;
		viewed_at_from?: string;
		viewed_at_to?: string;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<OrgVideoViewsRes[]>>(
		`/organisations/${props.organisationId}/video-views${buildQueryString(
			props.queryString,
			[
				{ key: 'course', include: props.include.course },
				{ key: 'user', include: props.include.user },
				{ key: 'video', include: props.include.video },
				{ key: 'video.media', include: props.include.videoMedia },
			],
			[
				{ key: 'has_context', value: props.filters?.has_context },
				{ key: 'user_id', value: props.filters?.user_id },
				{ key: 'video_id', value: props.filters?.video_id },
				{ key: 'course_id', value: props.filters?.course_id },
				{ key: 'viewed_at_from', value: props.filters?.viewed_at_from },
				{ key: 'viewed_at_to', value: props.filters?.viewed_at_to },
			],
			props.perPage
		)}`
	);
};
export default getMultiple;
