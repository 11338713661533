import LoginAction from '@components/actions/Login';

interface LoginProps {
	testmode?: boolean;
}

const Login: React.FC<LoginProps> = ({ testmode = false }) => {
	// --------------------------------------
	// Render
	return <LoginAction testmode={testmode} callback={() => {}} />;
};

export default Login;
