/*
	Use this function when you wish to only send fields that have been edited and dont match the default state.
*/

const returnModifiedData = (
	items: Array<{ key: string; state: any; defaultState: any; useValue?: any }>
) => {
	const body: any = {};
	items.forEach((item) => {
		if (item.state === undefined) return;

		// if array
		if (Array.isArray(item.state) && Array.isArray(item.defaultState)) {
			let isDifferent = false;

			// check if the length of the array is different
			if (item.state.length !== item.defaultState.length)
				isDifferent = true;
			else {
				// compare each item in the array against the default state at the same index
				for (let i = 0; i < item.state.length; i++) {
					if (item.state[i] !== item.defaultState[i]) {
						isDifferent = true;
						break;
					}
				}
			}

			if (isDifferent) {
				if (item.useValue) body[item.key] = item.useValue;
				else body[item.key] = item.state;
			}
			return;
		}

		// if string or number
		if (item.state !== item.defaultState) {
			if (item.useValue) body[item.key] = item.useValue;
			else body[item.key] = item.state;
		}
	});

	return body;
};

export default returnModifiedData;
