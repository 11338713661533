import { translate } from '@lang/index';
import { useState } from 'react';
import C from '@root/constants';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useFormState, useAuth, useToast } from '@hooks/index';
// Util
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import AlertMessage from '@components/partials/AlertMessage';
import ButtonLoadingOverlay from '@components/partials/ButtonLoadingOverlay';

interface LoginActionProps extends ActionProps {
	testmode?: boolean;
}

const LoginAction: React.FC<LoginActionProps> = ({
	testmode = false,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { signin, state } = useAuth();
	const { addToast } = useToast();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// --------------------------------------
	// Queries / Mutations

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		signin(
			email,
			password,
			() => {
				setEmail('');
				setPassword('');
				addToast({
					title: translate('toast_login_title'),
					message: translate('toast_login_message'),
					type: 'success',
				});
				callback(true);
			},
			(error) => {
				helpers.mutationErrorHandler(error, formState);
				callback(false);
			}
		);
	};

	const onSubmitTemp = async (emailV: string, passwordV: string) => {
		signin(
			emailV,
			passwordV,
			() => {
				setEmail('');
				setPassword('');
				addToast({
					title: translate('toast_login_title'),
					message: translate('toast_login_message'),
					type: 'success',
				});
				callback(true);
			},
			(error) => {
				helpers.mutationErrorHandler(error, formState);
				callback(false);
			}
		);
	};

	// --------------------------------------
	// Render
	return (
		<>
			<div className="mb-10">
				<h1 className="mb-1.5 text-title">Welcome back</h1>
				{/* <p className="text-body">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit.
				</p> */}
			</div>
			<Form onSubmit={onSubmit} state={formState} validation={true}>
				<Input
					value={email}
					setValue={setEmail}
					name="email"
					label={translate('email')}
					id="email"
					type="email"
					autoComplete="email"
					errors={formState.errors.value['email']}
					required={true}
				/>
				<Input
					value={password}
					setValue={setPassword}
					label={translate('password')}
					name="password"
					id="password"
					type="password"
					autoComplete="current-password"
					errors={formState.errors.value['password']}
					required={true}
				/>
				<Button
					theme="text"
					onClick={() => {
						navigate('/password-reset');
					}}
					className="!-mt-2 block !text-body hover:!text-brandTertiaryHover">
					Forgot password?
				</Button>

				<div className="mt-10">
					{state === 'error' && formState.errorMessage.value && (
						<div className="mb-2.5">
							<AlertMessage
								text={formState.errorMessage.value}
								type="error"
							/>
						</div>
					)}
					<Button
						theme={'primary'}
						type={'submit'}
						className={'relative w-full overflow-hidden'}>
						{translate('login')}
						{state === 'loading' && <ButtonLoadingOverlay />}
					</Button>
				</div>
			</Form>
			{testmode && C.APP_ENVIRONMENT === 'development' && (
				<div className="fixed bottom-2.5 left-2.5 right-2.5 z-10 rounded-md bg-black bg-opacity-80 p-5">
					<div className="grid w-full grid-cols-4 gap-5">
						<Button
							theme={'primary'}
							type={'button'}
							className={'text-sm'}
							onClick={() => {
								onSubmitTemp('sa@gritdigital.com', 'password');
							}}>
							{translate('super_admin')}
						</Button>
						<Button
							theme={'primary'}
							type={'button'}
							className={'text-sm'}
							onClick={() => {
								onSubmitTemp(
									'admin@gritdigital.com',
									'password'
								);
							}}>
							{translate('admin')}
						</Button>
						<Button
							theme={'primary'}
							type={'button'}
							className={'text-sm'}
							onClick={() => {
								onSubmitTemp(
									'standard@baobablms.com',
									'password'
								);
							}}>
							{translate('user')}
						</Button>
						<Button
							theme={'primary'}
							type={'button'}
							className={'text-sm'}
							onClick={() => {
								onSubmitTemp(
									'test-user@baobablms.com',
									'password'
								);
							}}>
							{translate('test_user')}
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default LoginAction;
