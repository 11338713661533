import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		type: boolean;
		typeCount: boolean;
		media: boolean;
		mediaCount: boolean;
	};
	perPage?: number;
	sort?: {
		title?: 'asc' | 'desc';
		ref?: 'asc' | 'desc';
	};
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaQuizzesRes[]>>(
		`/sa/quizzes${buildQueryString(
			props.queryString,
			[
				{
					key: 'type',
					include: props.include.type,
				},
				{
					key: 'typeCount',
					include: props.include.typeCount,
				},
				{
					key: 'media',
					include: props.include.media,
				},
				{
					key: 'mediaCount',
					include: props.include.mediaCount,
				},
			],
			undefined,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
