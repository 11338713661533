import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		videoMedia: boolean;
		course: boolean;
		courseCount: boolean;
		video: boolean;
		videoCount: boolean;
	};
	filters?: Array<{
		key: 'user_id' | 'has_context' | 'video_id' | 'course_id';
		value: string | number | Array<string | number>;
	}>;
	sort?: {
		viewed_at?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<VideosViewsRes[]>>(
		`/organisations/${props.organisationId}/video-views${buildQueryString(
			props.queryString,
			[
				{
					key: 'video.media',
					include: props.include.videoMedia,
				},
				{
					key: 'course',
					include: props.include.course,
				},
				{
					key: 'courseCount',
					include: props.include.courseCount,
				},
				{
					key: 'video',
					include: props.include.video,
				},
				{
					key: 'videoCount',
					include: props.include.videoCount,
				},
			],
			props.filters,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
