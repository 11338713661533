import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		title?: string;
		desc?: string;
		sync_id?: string;
		ref?: string;
		type_id?: number;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaVideosRes>>(`/sa/videos/${props.id}`, {
		_method: 'PUT',
		...props.body,
	});
};
export default updateSingle;
