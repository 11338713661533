import { translate } from '@lang/index';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import CategoryTable from '@components/table/sa/CategoryTable';
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
// Modals
import CreateCategory from '@components/modal/CreateCategory';

interface CategoriesListProps {
	mode: 'course' | 'quiz' | 'video' | 'content';
}

const CategoriesList: React.FC<CategoriesListProps> = ({ mode }) => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'updated_at',
					value: 'off',
				},
				{
					key: 'ref',
					value: 'asc',
				},
			],
			filter: [],
		});

	const [openCreateCategory, setOpenCreateCategory] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const categoryScopes = useQuery(
		['sa.categoryScopes.getAll'],
		api.sa.categoryScopes.getAll
	);

	// -------------------------------------------------
	// Memos
	const categoryRouteData = useMemo(() => {
		switch (mode) {
			case 'course':
				return {
					title: translate('course_categories'),
					body: translate('course_categories_body'),
					add_btn: translate('add_category'),
					scope: 'courseCategory' as CategoryScopes,
				};
			case 'quiz':
				return {
					title: translate('assignment_types'),
					body: translate('assignment_types_body'),
					add_btn: translate('add_type'),
					scope: 'quizType' as CategoryScopes,
				};
			case 'video':
				return {
					title: translate('video_types'),
					body: translate('video_types_body'),
					add_btn: translate('add_type'),
					scope: 'videoType' as CategoryScopes,
				};
			case 'content':
				return {
					title: translate('video_library_categories'),
					add_btn: translate('add_type'),
					scope: 'courseCategory' as CategoryScopes,
				};
		}
	}, [mode]);

	// -----------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={categoryRouteData.title}
				body={categoryRouteData.body}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
							{
								key: 'updated_at',
								label: translate('updated_at'),
							},
							{
								key: 'ref',
								label: translate('ref'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_category')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<CategoryTable
					enabled={enabled}
					categoryScopes={categoryScopes.data?.data.data || []}
					queryString={queryString}
					searchParams={searchParams}
					scope={categoryRouteData.scope}
					resetFilters={resetFilters}
					utilityBar={
						<Button
							theme={'primary'}
							onClick={() => setOpenCreateCategory(true)}>
							{categoryRouteData.add_btn}
						</Button>
					}
				/>
				<CreateCategory
					allowScope={categoryRouteData.scope}
					open={openCreateCategory}
					setOpen={setOpenCreateCategory}
					onSuccess={() => {}}
				/>
			</PageWrapper>
		</>
	);
};

export default CategoriesList;
