import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		name?: string;
		ref?: string;
		meta?: {
			desc?: string;
			icon_colour?: string;
			icon_initials?: string;
		};
		public?: boolean;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaCategoriesRes>>(
		`/sa/categories/${props.id}`,
		{
			...props.body,
			_method: 'put',
		}
	);
};
export default updateSingle;
