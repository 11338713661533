import { http } from '@utils/index';

interface ResponseData {}

const sendEmailVerification = () => {
	return http.post<APIResponse<ResponseData>>(
		'/email/verification-notification'
	);
};
export default sendEmailVerification;
