import { http } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
	quizId: number;
	body: {
		percentage: number;
		pass: 1 | 0;
	};
}

const createResult = (props: Props) => {
	return http.post<APIResponse<QuizzesRes>>(
		`/organisations/${props.organisationId}/courses/${props.courseId}/quizzes/${props.quizId}/resultsc`,
		props.body
	);
};
export default createResult;
