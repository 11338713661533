import { http } from '@utils/index';

interface Props {
	body: {
		name: string;
		category_scope_id: number;
		ref: string;
		public: boolean;
		meta: {
			desc?: string;
			icon_colour?: string;
			icon_initials?: string;
		};
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaCategoriesRes>>(
		`/sa/categories`,
		props.body
	);
};
export default createSingle;
