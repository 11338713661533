import { AxiosResponse } from 'axios';

const GlobalSuccessHandler = (res: unknown) => {
	// ------------------------------------
	// State
	const axiosRes = res as AxiosResponse;
	let url = axiosRes.config.url;

	if (url?.includes('?')) url = url.split('?')[0];

	if (url) {
		switch (url) {
			case '/user': {
				break;
			}
			default: {
				break;
			}
		}
	}
};
export default GlobalSuccessHandler;
