import { http } from '@utils/index';

export interface Props {
	body: {
		name: string;
		seats: number;
		users: number;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaPlanRes>>(`/sa/plans`, {
		...props.body,
	});
};
export default createSingle;
