import { translate } from '@lang/index';
import { useMemo } from 'react';
// Hooks
import { useSearchParams } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Components
import Filter from '@components/query/Filter';
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import CourseContentTable from '@components/table/sa/CourseContentTable';

interface CoursesListProps {
	type: 'course' | 'content';
}

const CoursesList: React.FC<CoursesListProps> = ({ type }) => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
				{
					key: 'updated_at',
					value: 'off',
				},
				{
					key: 'ref',
					value: 'asc',
				},
			],
			filter: [
				{
					key: 'category_ids',
					value: '',
					type: 'select',
				},
			],
		});

	// -------------------------------------------------
	// Queries & Mutations
	const categories = useQuery(['sa.categories.getAll'], () => {
		return api.sa.categories.getAll({
			filters: [
				{
					key: 'scope',
					value: 'courseCategory',
				},
			],
			include: {
				meta: false,
				metaCount: false,
			},
			sort: {
				name: 'asc',
			},
		});
	});

	// -------------------------------------------------
	// Memos
	const content = useMemo(() => {
		switch (type) {
			case 'course':
				return {
					title: translate('courses'),
					body: translate('courses_body'),
					search_placeholder: translate('search_for_course'),
					create_button: translate('create_list'),
				};
			case 'content':
				return {
					title: translate('subjects'),
					body: translate('subjects_body'),
					search_placeholder: translate('search_for_subject'),
					create_button: translate('create_subject'),
				};
		}
	}, [type]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={content.title}
				body={content.body}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'category_ids',
								label: translate('categories'),
								allowAll: true,
								values: categories.data?.data.data.map(
									(category) => {
										return {
											label: `${category.name} - ${category.id}`,
											value: category.id.toString(),
										};
									}
								),
							},
						]}
						loading={categories.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
							{
								key: 'updated_at',
								label: translate('updated_at'),
							},
							{
								key: 'ref',
								label: translate('ref'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={content.search_placeholder}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<CourseContentTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					type={type}
					utilityBar={
						<Button
							theme={'primary'}
							type="link"
							href={`/courses/create?type=${type}`}>
							{content.create_button}
						</Button>
					}
				/>
			</PageWrapper>
		</>
	);
};

export default CoursesList;
