import T from '@lang/en_gb.json';

export const translate = (
	key: keyof typeof T,
	data?: Record<string, string | number>
) => {
	const translation = T[key as keyof typeof T];
	if (!translation) return key;
	if (!data) return translation;

	// @ts-ignore -- tempt ignore until translations file is reformatted
	return translation.replace(
		/\{\{(\w+)\}\}/g,
		// @ts-ignore -- tempt ignore until translations file is reformatted
		(match, p1) => data[p1 as keyof typeof data] as string
	);
};

export const typeT = (name?: string) => {
	if (name === 'course') return translate('single', { name: 'Course' });
	if (name === 'content') return translate('single', { name: 'Subject' });
	return '';
};

export const rolesT = (name?: string) => {
	if (name === 'admin') return translate('single', { name: 'Admin' });
	if (name === 'standard') return translate('single', { name: 'Standard' });
	return '';
};

export default T;
