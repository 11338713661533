import { http, buildQueryString } from '@utils/index';

export interface Props {
	organisationId?: string | number;
	queryString?: string;
	include: {
		award: boolean;
		user: boolean;
		organisation: boolean;
	};
	filters?: {
		award_id?: string | number | Array<string | number>;
		user_id?: string | number | Array<string | number>;
		award_type_id?: string | number | Array<string | number>;
		created_at_from?: string;
		created_at_to?: string;
		organisation_id?: string | number | Array<string | number>;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaUserAwardsRes[]>>(
		`/sa/user-awards${buildQueryString(
			props.queryString,
			[
				{ key: 'award', include: props.include.award },
				{ key: 'user', include: props.include.user },
				{ key: 'organisation', include: props.include.organisation },
			],
			[
				{
					key: 'award_id',
					value: props.filters?.award_id,
				},
				{
					key: 'user_id',
					value: props.filters?.user_id,
				},
				{
					key: 'award.award_type_id',
					value: props.filters?.award_type_id,
				},
				{
					key: 'organisation_id',
					value: props.filters?.organisation_id,
				},
			],
			props.perPage
		)}`
	);
};
export default getMultiple;
