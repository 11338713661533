import { useState, useMemo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Queries
import { useAuthenticatedUser } from '@queries/user';
// Components
import GrantedAwards from '@components/modal/GrantedAwards';

interface UseGrantedAwardsProps {
	enabled: boolean;
}

const useGrantedAwards = (props: UseGrantedAwardsProps) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const [existingIds, setExistingIds] = useState<number[]>();
	const [newAwards, setNewAwards] = useState<OrgUserAwardsRes[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);

	// -------------------------------------------------
	// Queries
	const user = useAuthenticatedUser({
		enabled: props.enabled,
	});
	const awards = useQuery(
		['organisations.userAwards.getMultiple', user.data?.data.data.id],
		() => {
			return api.organisations.userAwards.getMultiple({
				organisationId: organisation?.id,
				include: {
					user: false,
					award: true,
				},
				filters: {
					user_id: user.data?.data.data.id,
					award_type_id: 2,
				},
				perPage: -1,
			});
		},
		{
			enabled:
				!!organisation?.id &&
				!!user.data?.data.data.id &&
				props.enabled,
			keepPreviousData: true,
			onSuccess: (data) => {
				awardsOnSuccess(data.data.data);
			},
		}
	);

	// -------------------------------------------------
	// Functions
	const awardsOnSuccess = (awards: OrgUserAwardsRes[]) => {
		if (existingIds === undefined) {
			setExistingIds(awards.map((award) => award.award_id));
			return;
		}

		const allIds = awards.map((award) => award.award_id);
		const newAwards = awards.filter(
			(award) => !existingIds.includes(award.award_id)
		);

		setExistingIds(allIds);
		setNewAwards(newAwards);

		if (newAwards.length > 0) setOpenModal(true);
	};

	// -------------------------------------------------
	// Memos
	const modalOpen = useMemo(() => {
		return newAwards.length > 0 && openModal ? true : false;
	}, [newAwards, openModal]);

	// -------------------------------------------------
	// Callbacks
	const GrantedAwardsModal = useCallback(() => {
		return (
			<GrantedAwards
				awards={newAwards}
				open={modalOpen}
				setOpen={(state: boolean) => {
					setOpenModal(state);
					if (!state) setNewAwards([]);
				}}
			/>
		);
	}, [modalOpen, newAwards, setOpenModal, setNewAwards]);

	// -------------------------------------------------
	// Return
	return {
		GrantedAwardsModal,
		refetchAwards: awards.refetch,
	};
};

export default useGrantedAwards;
