import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Props as CreateSingle } from 'api/sa/awards/create-single';
// api
import api from 'api';
// Utils
import { helpers } from '@utils/index';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import Button from '@components/partials/Button';
import FormActionModal from '@components/forms/FormActionModal';
import MultiSelect from '@components/forms/MultiSelect';
import Textarea from '@components/forms/Textarea';
import Switch from '@components/forms/Switch';

interface CreateAwardActionProps extends ActionProps {}

const CreateAwardAction: React.FC<CreateAwardActionProps> = ({ callback }) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [typeId, setTypeId] = useState<number>(1);
	const [metaText, setMetaText] = useState<string>('');
	const [active, setActive] = useState<boolean>(true);
	const [metaShowIchemeLogo, setMetaShowIchemeLogo] =
		useState<boolean>(false);
	const [selectedItems, setSelectedItems] = useState<
		{
			key: string;
			label: string;
			data: SaVideosRes | SaQuizzesRes | SaCourseRes;
		}[]
	>([]);

	// --------------------------------------
	// Queries / Mutations
	const awardTypes = useQuery(
		['auth.awards.getAwardTypes'],
		api.auth.awards.getAwardTypes,
		{
			onSuccess: () => {
				setTypeId(awardTypes.data?.data.data[1].id || 1);
			},
		}
	);
	const videos = useQuery(['sa.videos.getMultiple'], () => {
		return api.sa.videos.getMultiple({
			include: {
				value: false,
				media: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const quizzes = useQuery(['sa.quizzes.getMultiple'], () => {
		return api.sa.quizzes.getMultiple({
			include: {
				type: false,
				typeCount: false,
				media: false,
				mediaCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const courses = useQuery(['sa.courses.getMultiple'], () => {
		return api.sa.courses.getMultiple({
			include: {
				items: false,
				itemsModels: false,
				itemsCount: false,
				media: false,
				mediaCount: false,
				categories: false,
				categoriesCount: false,
				categoriesMeta: false,

				organisations: false,
				organisationsCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});

	const createAward = useMutation(api.sa.awards.createSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries(['sa.awards.getMultiple']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Award',
				}),
				message: translate('toast_created_message', {
					name: 'Award',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		const createBody: CreateSingle['body'] = {
			award_type_id: typeId,
			name: name,
			items: selectedItems.map((item) => {
				let modelType = item.key.split(' - ')[0].toLowerCase();
				if (modelType === 'assessment') modelType = 'quiz';
				return {
					model_type: modelType as 'video' | 'quiz' | 'course',
					model_id: item.data.id,
				};
			}),
			meta: {
				text: metaText,
				show_icheme_logo: metaShowIchemeLogo ? 1 : 0,
			},
			active: active ? 1 : 0,
		};

		if (metaText === '') {
			delete createBody.meta?.text;
		}
		if (metaShowIchemeLogo === false) {
			delete createBody.meta?.show_icheme_logo;
		}
		if (createBody.meta && Object.keys(createBody.meta).length === 0) {
			delete createBody.meta;
		}

		await createAward.mutateAsync({
			body: createBody,
		});
	};
	const resetForm = () => {
		setName('');
		setMetaText('');
		setSelectedItems([]);
		setTypeId(awardTypes.data?.data.data[1].id || 1);
		setMetaShowIchemeLogo(false);
		createAward.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={
				awardTypes.isLoading ||
				videos.isLoading ||
				quizzes.isLoading ||
				courses.isLoading
			}
			isError={
				awardTypes.isError ||
				videos.isError ||
				quizzes.isError ||
				courses.isError
			}>
			<Select
				label={translate('type')}
				value={
					awardTypes.data?.data.data.find(
						(type) => type.id === typeId
					)?.name || ''
				}
				setValue={(state) => {
					const type = awardTypes.data?.data.data.find(
						(type) => type.name === state
					);
					setTypeId(type?.id || 1);
				}}
				errors={formState.errors.value['award_type_id']}
				options={
					awardTypes.data?.data.data.map((type) => {
						return {
							value: type.name,
							label: `${type.name} - ${type.id}`,
						};
					}) || []
				}
			/>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<MultiSelect
				label={translate('award_items')}
				name={'items'}
				id={'items'}
				values={selectedItems}
				setValues={setSelectedItems}
				errors={{}}
				options={[
					...(videos.data?.data.data.map((video) => {
						return {
							key: `video - ${video.id}`,
							label: `Video - ${video.title} - ${video.id}`,
							data: video,
						};
					}) || []),
					...(quizzes.data?.data.data.map((quiz) => {
						return {
							key: `assessment - ${quiz.id}`,
							label: `Assessment - ${quiz.title} - ${quiz.id}`,
							data: quiz,
						};
					}) || []),
					...(courses.data?.data.data.map((course) => {
						return {
							key: `course - ${course.id}`,
							label: `Course - ${course.title} - ${course.id}`,
							data: course,
						};
					}) || []),
				]}
				placeholder={translate('add_to_award')}
			/>
			{typeId === 2 && (
				<>
					<Textarea
						id="meta.text"
						label={translate('additional_certificate_info')}
						name="meta.text"
						value={metaText}
						setValue={setMetaText}
						errors={formState.errors.value['meta.text']}
						required={false}
					/>
					<Switch
						name="meta.show_icheme_logo"
						label={translate('show_icheme_logo')}
						checked={metaShowIchemeLogo}
						setChecked={setMetaShowIchemeLogo}
						errors={formState.errors.value['meta.show_icheme_logo']}
					/>
					<Switch
						name="active"
						label={translate('active')}
						checked={active}
						setChecked={setActive}
						errors={formState.errors.value['active']}
					/>
				</>
			)}

			<FormActionModal
				loading={{
					is: createAward.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createAward.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateAwardAction;
