import { http } from '@utils/index';

export interface Props {
	id: number;
	body: {
		name?: string;
		email?: string;
		organisations?: Array<{
			id: number;
			role_ids: number[];
			seated: 1 | 0;
		}>;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaUsersRes[]>>(`/sa/users/${props.id}`, {
		_method: 'put',
		...props.body,
	});
};
export default updateSingle;
