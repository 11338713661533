import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		organisation: boolean;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaTeamRes[]>>(
		`/sa/teams${buildQueryString(
			props.queryString,
			[{ key: 'organisation', include: props.include.organisation }],
			undefined,
			props.perPage
		)}`
	);
};
export default getMultiple;
