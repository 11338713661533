import classNames from 'classnames';

interface MessageBlockProps {
	title?: string;
	message?: string;
	image?: string;
	children?: React.ReactNode;
	className?: string;
}

const MessageBlock: React.FC<MessageBlockProps> = ({
	title,
	message,
	image,
	className,
	children,
}) => {
	return (
		<div
			className={classNames(
				'relative flex min-h-[192px] w-full flex-col items-center justify-center rounded-md border border-border p-10',
				className
			)}>
			{image && (
				<img
					src={image}
					alt={title}
					className="mb-7 max-h-[160px] w-1/2 max-w-[200px]"
				/>
			)}
			<h2 className="text-center text-base font-bold">{title}</h2>
			<p className="mt-1.5 max-w-[460px] text-center text-sm">
				{message}
			</p>
			{children}
		</div>
	);
};

export default MessageBlock;
