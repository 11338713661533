import { http } from '@utils/index';

interface Props {
	id: number;
	organisationId?: number | string;
	body: {
		course_ids?: number[];
		team_ids?: number[];
		seated?: 1 | 0;
		role_ids?: number[];
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaQuizzesRes>>(
		`/organisations/${props.organisationId}/users/${props.id}`,
		{
			_method: 'put',
			...props.body,
		}
	);
};
export default updateSingle;
