// Constants
import C from '@root/constants';

export interface Props {
	organisationId: string | number;
	awardId: string | number;
}

const downloadUserAward = (props: Props) => {
	return `${C.API_BASE_URL}/organisations/${props.organisationId}/user-awards/${props.awardId}/download`;
};
export default downloadUserAward;
