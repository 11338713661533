import { useState, useMemo } from 'react';
import faPen from '@assets/icons/faPen.svg';
import faTimesCircleImg from '@assets/icons/faTimesCircle.svg';
import type { Item } from '@components/groups/course-builder/Container';
import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { useMutation } from '@tanstack/react-query';
import { CSS } from '@dnd-kit/utilities';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// api
import api from 'api';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoryIcon from '@components/partials/CategoryIcon';

interface CourseBuilderSelectedItemProps {
	data: Item;
	items: Item[];
	removeItem: (item: CourseBuilderSelectedItemProps['data']) => void;
	mode?: 'create' | 'edit';
	categories: SaCategoriesRes[];
	openChapterUpdate?: () => void;
}

const CourseBuilderSelectedItem: React.FC<CourseBuilderSelectedItemProps> = ({
	data,
	items,
	removeItem,
	mode,
	categories,
	openChapterUpdate,
}) => {
	// --------------------------------------
	// State / Hooks
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: data.id });
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [deleteConfirmTimeout, setDeleteConfirmTimeout] =
		useState<NodeJS.Timeout | null>(null);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// --------------------------------------
	// Query / Mutations
	const deleteChapter = useMutation(api.sa.chapters.deleteSingle);

	// --------------------------------------
	// Functions
	const deleteChapterHandler = () => {
		if (deleteConfirmTimeout) clearTimeout(deleteConfirmTimeout);

		if (!deleteConfirmation) {
			setDeleteConfirmation(true);
			setDeleteConfirmTimeout(
				setTimeout(() => {
					setDeleteConfirmation(false);
				}, 3000)
			);
			return;
		}

		if (mode === 'create') {
			deleteChapter.mutate({
				id: data.data.id.toString(),
			});
		}
		removeItem(data);
	};

	// --------------------------------------
	// Memos
	const category = useMemo(
		() => categories.find((category) => category.id === data.type_id),
		[categories, data.type_id]
	);

	const categoryScope = useMemo<CategoryScopes>(() => {
		if (data.type === 'quiz') return 'quizType';
		if (data.type === 'video') return 'videoType';
		return 'quizType';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.type]);

	const nestedBellowChapter = useMemo(() => {
		if (data.type !== 'chapter') {
			const currentIndex = items.findIndex((item) => item.id === data.id);
			if (!currentIndex) return false;
			for (let i = currentIndex; i >= 0; i--) {
				if (items[i].type === 'chapter') return true;
			}
			return false;
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, data]);

	// --------------------------------------
	// Render

	// Type = 'chapter'
	if (data.type === 'chapter') {
		return (
			<li ref={setNodeRef}>
				<div
					style={style}
					{...attributes}
					{...listeners}
					className={classNames(
						'group flex w-full cursor-pointer items-center justify-between rounded-md border border-border bg-white p-2 hover:border-dashed hover:border-uiDark'
					)}>
					<div className="flex w-full items-center">
						<div
							className={classNames(
								'mr-2.5 flex h-5 w-5 min-w-[20px] items-center justify-center'
							)}>
							<FontAwesomeIcon
								className="flex h-5 w-5 items-center justify-center text-uiDarkGray"
								icon={faBook}
							/>
						</div>
						<div className="flex w-full flex-col pr-5 duration-200 group-hover:opacity-40">
							<p className="mb-1 text-sm font-semibold text-title">
								{data.data.title}
							</p>
							{data.data.desc && (
								<p className="text-sm text-body">
									{data.data.desc}
								</p>
							)}
						</div>
					</div>
					<div className="flex">
						<button
							onClick={() =>
								openChapterUpdate
									? openChapterUpdate()
									: undefined
							}
							className="ml-auto mr-3 text-uiGray hover:text-uiDark">
							<img
								src={faPen}
								alt="Edit chapter"
								className="block h-4 w-4 min-w-[16px] object-contain object-center"
							/>
						</button>
						<button
							onClick={deleteChapterHandler}
							className={classNames('ml-auto text-uiDark', {
								'text-red': deleteConfirmation,
							})}>
							<FontAwesomeIcon icon={faTimesCircle} />
						</button>
					</div>
				</div>
			</li>
		);
	}

	return (
		<li ref={setNodeRef}>
			<div
				style={style}
				{...attributes}
				{...listeners}
				className={classNames(
					'group flex w-full cursor-pointer items-center rounded-md border border-border bg-white p-2 hover:border-dashed hover:border-uiDark',
					{
						'ml-5 w-[calc(100%-20px)]': nestedBellowChapter,
					}
				)}>
				<div className={'mr-2.5'}>
					<CategoryIcon
						colour={category?.meta?.icon_colour}
						initials={category?.meta?.icon_initials}
						label={category?.name}
						categoryScope={categoryScope}
						size={'xs'}
					/>
				</div>
				<p
					className={
						'pr-2.5 text-sm font-semibold text-title duration-200 group-hover:opacity-40'
					}>
					{data.data.title}
				</p>
				{removeItem && (
					<button
						onClick={() => {
							removeItem(data);
						}}
						className="ml-auto text-uiGray hover:text-uiDark">
						<img
							src={faTimesCircleImg}
							alt="Remove item"
							className="block h-4 w-4 min-w-[16px] object-contain object-center"></img>
					</button>
				)}
			</div>
		</li>
	);
};

export default CourseBuilderSelectedItem;
