import classNames from 'classnames';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { faBookmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface BookmarkButtonActionProps {
	courseId?: number;
	bookmarkable_id: number;
	bookmark?: BookmarkesRes;
	type: 'course' | 'video' | 'quiz';
	simplified?: boolean;
	refetch?: () => void;
}

const BookmarkButtonAction: React.FC<BookmarkButtonActionProps> = ({
	courseId,
	bookmarkable_id,
	bookmark,
	type,
	simplified = false,
	refetch,
}) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();
	const queryClient = useQueryClient();

	// -------------------------------------------------
	// Mutations
	const updateBookmark = useMutation(
		api.organisations.bookmarks.toggleSingle,
		{
			onSuccess: () => {
				if (refetch) {
					refetch();
				} else {
					queryClient.invalidateQueries([
						'organisations.bookmarks.getMultiple.list',
					]);
					queryClient.invalidateQueries([
						'organisations.bookmarks.getMultiple',
					]);
				}
			},
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<button
			className={classNames(
				'text-sm min-w-fit px-2 py-1 border rounded-md duration-200 transition-colors flex',
				{
					'bg-brandPrimary border-brandPrimary hover:bg-brandPrimaryHover hover:border-brandPrimaryHover text-brandPrimaryText ':
						bookmark !== undefined,
					'bg-white border-border rounded-md hover:bg-brandPrimary hover:border-brandPrimary text-title hover:text-brandPrimaryText':
						bookmark === undefined,
				}
			)}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				updateBookmark.mutate({
					organisationId: organisation?.id || 0,
					body: {
						bookmarkable_type: type,
						bookmarkable_id: bookmarkable_id,
						course_id: courseId,
					},
				});
			}}>
			<span className="w-3 flex items-center justify-center h-5">
				{updateBookmark.isLoading ? (
					<FontAwesomeIcon
						icon={faSpinner}
						className="animate-spin"
					/>
				) : (
					<FontAwesomeIcon icon={faBookmark} />
				)}
			</span>
			{!simplified && (
				<span className="ml-2.5">
					{bookmark !== undefined ? 'Bookmarked' : 'Bookmark'}
				</span>
			)}
		</button>
	);
};

export default BookmarkButtonAction;
