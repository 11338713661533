import { translate } from '@lang/index';
// Components
import Button from '@components/partials/Button';

const NotFoundRoute: React.FC = () => {
	// --------------------------------------
	// Render
	return (
		<div className="flex min-h-screen w-full items-center justify-center">
			<div className="m-auto p-2.5 text-center">
				<span className="mb-5 text-center text-base font-bold text-brandTertiary">
					404
				</span>
				<h1 className="text-headere mb-5 text-5xl font-bold">
					Page not found
				</h1>
				<p>Sorry, we couldn't find the page you're looking for.</p>
				<div className="mt-10">
					<Button theme="primary" type="link" className="mr-3">
						{translate('go_back_home')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default NotFoundRoute;
