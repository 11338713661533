import { http } from '@utils/index';

interface Props {
	organisationId: number;
	body: {
		noteable_type: 'video' | 'quiz';
		noteable_id: number;
		course_id?: number;
		note: string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<NotesRes>>(
		`/organisations/${props.organisationId}/notes`,
		{
			...props.body,
		}
	);
};
export default createSingle;
