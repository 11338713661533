import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateChapterAction from '@components/actions/CreateChapter';

interface CreateChapterProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: (chapter: SaChaptersRes) => void;
}

const CreateChapter: React.FC<CreateChapterProps> = ({
	open,
	setOpen,
	onSuccess,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.create_chapter.title}>
			<CreateChapterAction
				callback={(success, data) => {
					if (success) {
						setOpen(false);
						onSuccess && onSuccess(data as SaChaptersRes);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateChapter;
