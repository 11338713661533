import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaPlanRes[]>>(
		`/sa/plans${buildQueryString(
			props.queryString,
			undefined,
			undefined,
			props.perPage
		)}`
	);
};
export default getMultiple;
