import color from 'color';
import classNames from 'classnames';
import { useMemo } from 'react';
// Assets
import faPlaySvg from '@assets/icons/faPlay.svg';
import faPlayDark from '@assets/icons/faPlayDark.svg';

interface CategoryIconProps {
	colour?: string;
	initials?: string;
	categoryScope?: CategoryScopes;

	label?: string;
	tooltip?: boolean;
	size: 'xs' | 'sm' | 'md' | 'lg';
}

const CategoryIcon: React.FC<CategoryIconProps> = ({
	colour,
	initials,
	categoryScope,
	label,
	tooltip = false,
	size,
}) => {
	// -----------------------------------------
	// Memos
	const labelStartingLetter = useMemo(() => {
		if (label) return label.charAt(0).toLowerCase();
		return '';
	}, [label]);

	const colourValue = useMemo(() => {
		if (colour) return colour;
		if (categoryScope === 'quizType') {
			switch (labelStartingLetter) {
				case 'e':
					return '#EF5043';
				case 'p':
					return '#0884C4';
				case 'i':
					return '#1FC0B2';
				default:
					return '#8EBB15';
			}
		}
		if (categoryScope === 'courseCategory') return '#D3D3D3';
		if (categoryScope === 'videoType') {
			switch (labelStartingLetter) {
				case 'c':
					return '#16D2C2';
				case 's':
					return '#0884C4';
				default:
					return '#14D2C2';
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colour, categoryScope, labelStartingLetter]);

	const initial = useMemo(() => {
		if (initials)
			return {
				type: 'text',
				value: initials,
			};
		if (categoryScope === 'quizType') {
			return {
				type: 'text',
				value: labelStartingLetter.toUpperCase() || 'Q',
			};
		}
		if (categoryScope === 'courseCategory') {
			return {
				type: 'text',
				value: 'C',
			};
		}
		if (categoryScope === 'videoType') {
			if (color(colourValue).isLight()) {
				return {
					type: 'image',
					value: faPlayDark,
				};
			} else {
				return {
					type: 'image',
					value: faPlaySvg,
				};
			}
		}
		return {
			type: 'text',
			value: 'C',
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initials, categoryScope, colourValue, labelStartingLetter]);

	// -----------------------------------------
	// Render
	return (
		<span
			className={classNames(
				'group m-0 flex min-h-min min-w-min items-center justify-center rounded-full',
				{
					'h-5 w-5 min-w-[20px]': size === 'xs',
					'h-7 w-7 ': size === 'sm',
					'h-8 w-8': size === 'md',
					'h-10 w-10': size === 'lg',
				}
			)}
			style={{
				backgroundColor: colourValue,
			}}>
			<span
				className={classNames(
					'flex items-center justify-center font-medium !no-underline',
					{
						'text-[10px]': size === 'xs',
						'text-xs': size === 'sm',
						'text-sm': size === 'md',
						'text-base': size === 'lg',
					}
				)}
				style={{
					color: color(colourValue).isLight() ? '#222222' : '#ffffff',
				}}>
				{initial.type === 'text' && initial.value}
				{initial.type === 'image' && (
					<img
						src={initial.value}
						alt=""
						className="h-1/3 w-1/3 object-contain object-center"
						loading="lazy"
					/>
				)}
			</span>

			{label && tooltip && (
				<span className="pointer-events-none absolute top-full left-1/2 z-50 mt-2 block -translate-x-1/2 rounded-md bg-uiDark px-2 py-1 text-sm text-white opacity-0 shadow-md transition-opacity duration-200 group-hover:opacity-100">
					{label}
				</span>
			)}
		</span>
	);
};

export default CategoryIcon;
