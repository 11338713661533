import { http, buildQueryString } from '@utils/index';

interface Props {
	id: string;
	include: {
		categories: boolean;
		categoriesCount: boolean;
		items: boolean;
		itemsCount: boolean;
		itemsModel: boolean;
		media: boolean;
		mediaCount: boolean;
		organisations: boolean;
		organisationsCount: boolean;
		categoriesMeta: boolean;
		type: boolean;
		meta: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaCourseRes>>(
		`/sa/courses/${props.id}${buildQueryString(undefined, [
			{ key: 'categories', include: props.include.categories },
			{ key: 'categoriesCount', include: props.include.categoriesCount },
			{ key: 'categories.meta', include: props.include.categoriesMeta },
			{ key: 'items.model', include: props.include.itemsModel },
			{ key: 'items', include: props.include.items },
			{ key: 'itemsCount', include: props.include.itemsCount },
			{ key: 'media', include: props.include.media },
			{ key: 'mediaCount', include: props.include.mediaCount },
			{ key: 'organisations', include: props.include.organisations },
			{
				key: 'organisationsCount',
				include: props.include.organisationsCount,
			},
			{ key: 'type', include: props.include.type },
			{ key: 'meta', include: props.include.meta },
		])}`
	);
};
export default getSingle;
