import { translate } from '@lang/index';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';
import Button from '@components/partials/Button';

export interface DropdownMenuColumnProps {
	items: Array<{
		label: string;
		type: 'link' | 'button';
		href?: string;
		onClick?: () => void;
		show: boolean;
	}>;
	showFirst?: boolean;
	children?: React.ReactNode;
}

const DropdownMenuColumn: React.FC<DropdownMenuColumnProps> = ({
	showFirst = true,
	items,
	children,
}) => {
	// -------------------------------------------------
	// Refs
	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	// -------------------------------------------------
	// Functions
	const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		setTimeout(() => {
			setDropdownPosition();
		}, 1);
	};

	const setDropdownPosition = () => {
		if (!menuButtonRef.current || !dropdownRef.current) {
			return;
		}

		const menuButtonRect = menuButtonRef.current.getBoundingClientRect();
		const dropdownRect = dropdownRef.current.getBoundingClientRect();

		const top = menuButtonRect.top + menuButtonRect.height;
		const left = menuButtonRect.left - dropdownRect.width + 30;

		dropdownRef.current.style.top = `${top}px`;
		dropdownRef.current.style.left = `${left}px`;
	};

	// -------------------------------------------------
	// Render
	return (
		<td className="td-default sticky right-0 top-0 bottom-0  !p-0 text-right">
			<div className="flex h-full w-full justify-end  bg-white px-3 py-3 pr-5 text-sm">
				<div className="mr-3">{children}</div>
				<div className="inline-flex h-full border-border">
					{showFirst && items[0]?.show && (
						<Button
							theme="dark"
							className="font-base mr-3 !h-8 text-sm"
							type={items[0].type}
							href={items[0].href}
							onClick={items[0].onClick}>
							{items[0].label}
						</Button>
					)}
					<Menu as="div" className="relative">
						<Menu.Button
							ref={menuButtonRef}
							onClick={toggleDropdown}
							className={
								'h-8 w-8 rounded-md bg-brandTertiary text-brandTertiaryText hover:bg-brandTertiaryHover  focus:outline-none focus:ring-2 focus:ring-brandTertiaryLight'
							}>
							<FontAwesomeIcon icon={faEllipsisV} />
							<span className="sr-only">Options</span>
						</Menu.Button>

						{createPortal(
							<Menu.Items
								ref={dropdownRef}
								as="div"
								className={
									'pointer-events-auto absolute -right-full z-10 mt-2 flex w-44 origin-top-right flex-col overflow-hidden rounded-md bg-white drop-shadow-md focus:outline-none'
								}>
								<div className="bg-brandTertiary py-3 px-4 text-left text-brandTertiaryText">
									{translate('options')}
								</div>
								<ul className="py-1">
									{items.map(
										(item, index) =>
											item.show && (
												<Menu.Item
													key={index}
													as={'li'}
													className={
														'font-base w-full bg-white text-left text-title transition-colors duration-200 hover:bg-uiLight'
													}>
													{/* Link */}
													{item.type === 'link' && (
														<Link
															to={
																item.href || '/'
															}
															className={
																'inline-flex w-full px-4 py-1 text-sm text-title'
															}>
															{item.label}
														</Link>
													)}
													{/* Button */}
													{item.type === 'button' && (
														<button
															type="button"
															onClick={
																item.onClick
															}
															className={
																'inline-flex w-full px-4 py-1 text-left text-sm text-title'
															}>
															{item.label}
														</button>
													)}
												</Menu.Item>
											)
									)}
								</ul>
							</Menu.Items>,
							document.getElementById(
								'dropdown-portal'
							) as HTMLElement
						)}
					</Menu>
				</div>
			</div>
		</td>
	);
};
export default DropdownMenuColumn;
