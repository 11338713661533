interface FormRowProps {
	index?: number;
	title?: string;
	description?: string;
	asLabel?: boolean;
	id?: string;
	children: React.ReactNode;
}

const FormRow: React.FC<FormRowProps> = ({
	index,
	title,
	asLabel,
	id,
	description,
	children,
}) => {
	return (
		<div className="sm:grid sm:grid-cols-8 sm:items-start sm:gap-4 sm:border-t sm:border-border sm:pt-5 mb-5 last:mb-0">
			<div className="flex flex-col sm:col-span-2 pr-2.5">
				{index && (
					<span className="w-8 h-8 flex items-center justify-center rounded-md bg-brandTertiary text-brandTertiaryText mb-3">
						{index}
					</span>
				)}
				{title && !asLabel && (
					<h3 className="text-body text-base font-medium">{title}</h3>
				)}
				{asLabel && (
					<label
						htmlFor={id}
						className="text-body text-base font-medium block">
						{title}
					</label>
				)}
				{description && (
					<p className="text-sm text-body mt-2">{description}</p>
				)}
			</div>
			<div className="mt-3 border-t border-border pt-3 sm:col-span-6 sm:mt-0 sm:pt-0 sm:border-t-0">
				{children}
			</div>
		</div>
	);
};

export default FormRow;
