import { Switch as SwitchComp } from '@headlessui/react';
import classNames from 'classnames';

interface SwitchProps {
	checked: boolean;
	setChecked: (checked: boolean) => void;
	name: string;
	label?: string;
	errors: Array<string> | undefined;
	onChange?: (checked: boolean) => void;
	describedBy?: string;
}

const Switch: React.FC<SwitchProps> = ({
	checked,
	setChecked,
	name,
	label,
	errors = [],
	onChange,
	describedBy,
}) => {
	return (
		<div className={'flex flex-col mb-4 last:mb-0'}>
			<div className="flex justify-between mb-1">
				<SwitchComp.Group>
					{label && (
						<SwitchComp.Label passive className="text-body block">
							{label}
						</SwitchComp.Label>
					)}
					<SwitchComp
						name={name}
						checked={checked}
						onChange={(v) => {
							setChecked(v);
							if (onChange) onChange(v);
						}}
						className={classNames(
							'relative inline-flex h-6 w-11 items-center rounded-full ',
							{
								'bg-uiGray border border-border': !checked,
								'bg-brandSecondary': checked,
							}
						)}>
						<span className="sr-only">Enable {label}</span>
						<span
							className={classNames(
								'inline-block h-4 w-4 transform rounded-full transition bg-white',
								{
									'translate-x-6 ': checked,
									'translate-x-1 ': !checked,
								}
							)}
						/>
					</SwitchComp>
				</SwitchComp.Group>
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="text-sm text-body mt-1 text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<ul>
					{errors.map((error, i) => (
						<li key={i}>{error}</li>
					))}
				</ul>
			)}
		</div>
	);
};
export default Switch;
