import { http } from '@utils/index';

interface Props {
	body: {
		current_password: string;
		new_password: string;
		new_password_confirmation: string;
	};
}
interface ResponseData {}

const updatePassword = (props: Props) => {
	return http.post<APIResponse<ResponseData>>('/user/password', {
		_method: 'put',
		...props.body,
	});
};
export default updatePassword;
