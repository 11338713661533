import getMultiple from './get-multiple';
import deleteSingle from './delete-single';
import createSingle from './create-single';
import getSingle from './get-single';
import updateSingle from './update-single';

const actions = {
	getMultiple,
	deleteSingle,
	createSingle,
	getSingle,
	updateSingle,
};

export default actions;
