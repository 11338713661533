// Components
import { Link } from 'react-router-dom';
import Badges from '@components/partials/Badges';

interface QuizStatsRowProps {
	quizStats: StatisticsQuizRes;
}

const QuizStatsRow: React.FC<QuizStatsRowProps> = ({ quizStats }) => {
	return (
		<tr className="h-[32.5px]">
			<td className="td-default py-1">
				<Link
					to={`/assessments/${quizStats.id}`}
					className="text-sm font-medium text-title hover:underline">
					{quizStats.title}
				</Link>
			</td>
			<td className="td-default py-1">
				<span className="font-semibold">
					<Badges theme="green">
						{quizStats.results_count || 0}
					</Badges>
				</span>
			</td>
		</tr>
	);
};

export default QuizStatsRow;
