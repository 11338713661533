import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface DuplicateCourseProps {
	id: number;
	open: boolean;
	setOpen: (state: boolean) => void;
}

const DuplicateCourse: React.FC<DuplicateCourseProps> = ({
	id,
	open,
	setOpen,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const duplicateCourse = useMutation(api.sa.courses.copySingle, {
		onSuccess: (data) => {
			// invalidate organisations
			queryClient.invalidateQueries(['sa.courses.getMultiple']);
			// toast
			addToast({
				title: translate('toast_course_duplicated_title'),
				message: translate('toast_course_duplicated_message'),
				type: 'success',
			});
			navigate(`/courses/${data.data.data.id}/details`);
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await duplicateCourse.mutateAsync({
			id: id,
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onClose={() => {
				setErrorMsg('');
				duplicateCourse.reset();
			}}
			title={T.modals.duplicate_course.title}
			onConfirm={onConfirm}
			type={'warning'}
			formAction={{
				loading: {
					is: duplicateCourse.isLoading,
					message: translate('duplicating'),
				},
				error: {
					is: duplicateCourse.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>{T.modals.duplicate_course.body}</p>
			</div>
		</ConfirmationModal>
	);
};

export default DuplicateCourse;
