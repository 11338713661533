import getAll from './get-all';
import createSingle from './create-single';
import deleteSingle from './delete-single';
import updateSingle from './update-single';
import getSingle from './get-single';

const actions = {
	getAll,
	createSingle,
	deleteSingle,
	updateSingle,
	getSingle,
};

export default actions;
