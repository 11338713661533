import { useQuery } from '@tanstack/react-query';
import api from 'api';

interface UseBillingPortalProps {
	organisationId?: number;
	enabled?: boolean;
}

export const useBillingPortal = (props: UseBillingPortalProps) =>
	useQuery(
		['organisations.current.getBillingPortal'],
		() => {
			return api.organisations.current.getBillingPortal({
				id: props.organisationId as number,
			});
		},
		{
			enabled: props.organisationId !== undefined && props.enabled,
		}
	);
