import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentRow from '@components/partials/ContentRow';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface StartingPointsProps {
	cards: {
		title: string;
		description: string;
		icon: IconDefinition;
		href: string;
		background?: string;
		permission?: boolean;
	}[];
}

const StartingPoints: React.FC<StartingPointsProps> = ({ cards }) => {
	return (
		<ContentRow title={''} body={''}>
			<ul className="grid grid-cols-1 gap-2.5 sm:grid-cols-2">
				{cards.map((item, index) => {
					if (item.permission === false) return null;
					return (
						<li
							key={index}
							className="flow-root rounded-lg border border-border bg-white">
							<Link to={item.href} className="block w-full">
								<div className="relative flex items-center rounded-xl p-2.5 ring-brandPrimary focus-within:ring-2 hover:bg-uiLight">
									<div
										className={classNames(
											'mr-2.5 flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg',
											{
												'bg-brandPrimary':
													item.background ===
													undefined,
											},
											item.background
										)}>
										<FontAwesomeIcon
											icon={item.icon}
											className="h-6 w-6 text-brandPrimaryText"
											aria-hidden="true"
										/>
									</div>
									<div>
										<h3 className="text-base font-medium text-title">
											<span
												className="absolute inset-0"
												aria-hidden="true"
											/>
											<span>{item.title}</span>
											<span aria-hidden="true">
												{' '}
												&rarr;
											</span>
										</h3>
										<p className="mt-1 text-sm text-body">
											{item.description}
										</p>
									</div>
								</div>
							</Link>
						</li>
					);
				})}
			</ul>
		</ContentRow>
	);
};

export default StartingPoints;
