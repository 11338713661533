import { useMemo } from 'react';
// Utils
import { helpers } from '@utils/index';
// HOoks
import { usePermissions } from '@hooks/index';
// Components
import ProgressBar from '@components/partials/ProgressBar';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface ProgressRowProps {
	mode: 'sa' | 'organisation';
	progress: SaCourseProgressRes | OrgCourseProgressRes;
	isSuperadmin?: boolean;
}

const ProgressRow: React.FC<ProgressRowProps> = ({
	mode,
	progress,
	isSuperadmin,
}) => {
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Memos
	const percentage = useMemo(() => {
		const percentage = helpers.convertPercentString(progress.percentage);
		return percentage;
	}, [progress]);

	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			{(manageOrganisationWithPlan || isSuperadmin) && (
				<td className="td-default">
					<UserColumn
						name={progress.user?.name}
						email={progress.user?.email}
					/>
				</td>
			)}
			{mode === 'sa' && (
				<td className="td-default">
					{(progress as SaCourseProgressRes)?.organisation?.name}
				</td>
			)}
			<td className="td-default">{progress.course?.title}</td>
			<td className="td-default">
				<div className="flex w-full flex-col items-center">
					<div className="mb-1 w-[150px]">
						<ProgressBar progress={percentage} />
					</div>
					<span>{percentage}%</span>
				</div>
			</td>
			<td className="td-default ">
				<DateTimeColumn date={progress.created_at || ''} />
			</td>
			<td className="td-default">
				<DateTimeColumn date={progress.updated_at || ''} />
			</td>
		</tr>
	);
};

export default ProgressRow;
