import toast from 'react-hot-toast';
// Components
import CustomToast from '@components/partials/CustomToast';

const GlobalErrorHandler = async (error: unknown) => {
	const err = error as AxiosAPIError;

	const navigate = (path: string) => {
		window.location.href = path;
	};

	if (err.response) {
		// Error toast
		let title = 'An error occurred';
		let { message } = err.response.data;
		let path = undefined;

		const status = err.response.status;

		// Handle status codes
		if (status === 401) {
			if (localStorage.getItem('auth') === 'true') {
				localStorage.setItem('auth', 'false');
				window.location.reload();
			}
		} else if (status === 403 || status === 409) {
			if (err.response.data?.status === 'suspended') {
				title = 'Suspended';
			}
			if (err.response.data?.status === 'organisation_inactive') {
				title = 'This organisation is inactive';
				message = 'Please contact your organisation administrator';
				path = '/';
			}
			if (err.response.data?.status === 'organisation_not_seated') {
				title = 'Not seated';
				message = 'Please contact your organisation administrator';
				path = '/';
			}
			if (err.response.data?.status === 'terms_acceptance') {
				title = 'Terms and Conditions';
				message =
					'Please accept the terms and conditions before continuing';
				path = '/account?accept-terms=true';
			}
			if (err.response.data?.status === 'email_verification') {
				title = 'Email Verification';
				message = 'Please verify your email before continuing';
				path = '/account';
			}
		} else if (status === 429) {
			title = 'Too many requests';
			message = 'Please wait a few minutes before trying again!';
		} else {
			title = 'An error occurred';
		}

		// Show toast
		toast.custom(
			(t) => (
				<CustomToast
					title={title}
					message={message}
					type={'error'}
					t={t}
				/>
			),
			{
				id: `error:${title}-${message}`,
			}
		);
		if (
			path &&
			path !== `${window.location.pathname}${window.location.search}`
		) {
			navigate(path);
		}
	}
};
export default GlobalErrorHandler;
