import toast, { type Toast } from 'react-hot-toast';
import classNames from 'classnames';
import {
	faCircleCheck,
	faXmark,
	faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomToastProps {
	title: string;
	message: string;
	type: 'success' | 'error';
	t: Toast;
}

const CustomToast: React.FC<CustomToastProps> = ({
	title,
	message,
	type,
	t,
}) => {
	return (
		<div
			className={classNames(
				'pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5',
				{
					'animate-enter': t.visible,
					'animate-leave': !t.visible,
				}
			)}>
			<div className="p-4">
				<div className="flex items-start">
					<div className="flex-shrink-0">
						{type === 'success' && (
							<FontAwesomeIcon
								className={classNames('h-6 w-6 text-green')}
								aria-hidden="true"
								icon={faCircleCheck}
							/>
						)}
						{type === 'error' && (
							<FontAwesomeIcon
								className={classNames('h-6 w-6 text-red')}
								aria-hidden="true"
								icon={faCircleXmark}
							/>
						)}
					</div>
					<div className="ml-3 w-0 flex-1 pt-0.5">
						<p className="text-sm font-medium text-gray-900">
							{title}
						</p>
						<p className="mt-1 text-sm text-gray-500">{message}</p>
					</div>
					<div className="ml-4 flex flex-shrink-0">
						<button
							type="button"
							className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 cursor-pointer"
							onClick={() => {
								toast.remove(t.id);
							}}>
							<span className="sr-only">Close</span>
							<FontAwesomeIcon
								icon={faXmark}
								className="h-5 w-5"
								aria-hidden="true"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomToast;
