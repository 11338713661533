import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormActionModal from '@components/forms/FormActionModal';

interface UpdatePlanActionProps extends ActionProps {
	id: number;
}

const UpdatePlanAction: React.FC<UpdatePlanActionProps> = ({
	id,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [users, setUsers] = useState(0);
	const [seats, setSeats] = useState(0);

	// --------------------------------------
	// Queries / Mutations
	const plan = useQuery(
		['sa.plans.getSingle', id],
		() => {
			return api.sa.plans.getSingle({
				id: id,
			});
		},
		{
			onSuccess: (data) => {
				setPlanData(data.data.data);
			},
		}
	);

	const updatePlan = useMutation(api.sa.plans.updateSingle, {
		onSuccess: () => {
			// make invalidate any organisation queries
			queryClient.invalidateQueries(['sa.plans.getMultiple']);
			queryClient.invalidateQueries(['sa.plans.getAll']);
			addToast({
				title: translate('toast_updated_title', {
					name: 'Plan',
				}),
				message: translate('toast_updated_message', {
					name: 'Plan',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await updatePlan.mutateAsync({
			id: id,
			body: returnModifiedData([
				{
					key: 'name',
					state: name,
					defaultState: plan.data?.data.data.name,
				},
				{
					key: 'users',
					state: users,
					defaultState: plan.data?.data.data.users,
				},
				{
					key: 'seats',
					state: seats,
					defaultState: plan.data?.data.data.seats,
				},
			]),
		});
	};
	const resetForm = () => {
		setPlanData(plan.data?.data.data);
		updatePlan.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};
	const setPlanData = (plan?: SaPlanRes) => {
		if (!plan) return;
		setName(plan.name || '');
		setUsers(plan.users || 0);
		setSeats(plan.seats || 0);
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={plan.isLoading}
			isError={plan.isError}>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={false}
			/>
			<Input
				id="seats"
				type="number"
				label={translate('seats')}
				name="seats"
				value={seats}
				setValue={(seats) => {
					setSeats(parseInt(seats));
				}}
				errors={formState.errors.value['seats']}
				required={false}
				describedBy={translate(
					'determines_how_many_users_can_be_active'
				)}
			/>
			<Input
				id="users"
				type="number"
				label={translate('users')}
				name="users"
				value={users}
				setValue={(users) => {
					setUsers(parseInt(users));
				}}
				errors={formState.errors.value['users']}
				required={false}
				describedBy={translate(
					'determines_how_many_users_can_belong_to_org'
				)}
			/>

			<FormActionModal
				loading={{
					is: updatePlan.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updatePlan.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('update')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default UpdatePlanAction;
