import { http } from '@utils/index';

export interface Props {
	id: number | string;
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaPlanRes>>(`/sa/plans/${props.id}`);
};
export default getSingle;
