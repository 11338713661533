import { http } from '@utils/index';

interface Props {
	id: number | string;
}
interface ResponseData {}

const deleteSingle = (props: Props) => {
	return http.delete<APIResponse<ResponseData>>(`/sa/awards/${props.id}`);
};
export default deleteSingle;
