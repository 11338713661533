import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect } from 'react';
// Constants
import C from '@root/constants';
// Hooks
import { useAuth } from '@hooks/index';

const http = axios.create({
	baseURL: C.API_BASE_URL,
	responseType: 'json',
	withCredentials: true,
	withXSRFToken: true,
});

// Axios Interceptor Wrapper
interface AxiosInterceptorProps {
	children: React.ReactNode;
}
export const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({
	children,
}) => {
	// State
	const { setAuth } = useAuth();

	useEffect(() => {
		// On Request
		const onRequest = (config: AxiosRequestConfig) => {
			// console.info(`[request] [${JSON.stringify(config)}]`);
			return config;
		};
		const onRequestError = (error: AxiosError): Promise<AxiosError> => {
			// console.error(`[request error] [${JSON.stringify(error)}]`);
			return Promise.reject(error);
		};

		// On Response
		const onResponse = (response: AxiosResponse): AxiosResponse => {
			return response;
		};
		const onResponseError = (
			error: AxiosAPIError
		): Promise<AxiosAPIError> => {
			// Unauthorized
			switch (error.response?.status) {
				case 401: {
					setAuth(false);
					break;
				}
				case 419: {
					break;
				}
				default:
					break;
			}
			return Promise.reject(error);
		};

		const requestInterceptor = http.interceptors.request.use(
			// @ts-expect-error
			onRequest,
			onRequestError
		);
		const responseInterceptor = http.interceptors.response.use(
			onResponse,
			onResponseError
		);

		return () => {
			http.interceptors.response.eject(requestInterceptor);
			http.interceptors.response.eject(responseInterceptor);
		};
	}, [setAuth]);

	return <>{children}</>;
};

export default http;
