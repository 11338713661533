import { useMemo } from 'react';
// Assets
import placeholderQuiz from '@assets/images/placeholder-quiz.jpg';
// Hooks
import { useOrganisation } from '@hooks/index';
// Actions
import BookmarkButtonAction from '@components/actions/BookmarkButton';

interface QuizCardProps {
	quiz: QuizzesRes;
	course?: CourseRes;
	bookmarks?: BookmarkesRes[];
	functions?: {
		refetchBookmarks?: () => void;
	};
}

const QuizCard: React.FC<QuizCardProps> = ({
	quiz,
	course,
	bookmarks,
	functions,
}) => {
	const { organisation } = useOrganisation();
	const { refetchBookmarks } = functions || {};

	// -------------------------------------------------
	// Memos
	const bookmark = useMemo(() => {
		return bookmarks?.find(
			(b) =>
				b.bookmarkable_id === quiz.id && b.bookmarkable_type === 'quiz'
		);
	}, [bookmarks, quiz]);

	// -------------------------------------------------
	// Render
	return (
		<a
			href={
				course
					? `/${organisation?.slug}/courses/${course.id}/assessment/${quiz.id}`
					: `#`
			}
			className="block w-full">
			{/* Image */}
			<div className="group relative aspect-video cursor-pointer overflow-hidden rounded-md">
				{/* Thumbnail */}
				<img
					className="block h-full w-full rounded-md border border-border object-cover"
					src={quiz?.thumbnail || placeholderQuiz}
					alt="Assessment course preview "
				/>
				{bookmarks && (
					<span className="absolute top-2.5 right-2.5">
						<BookmarkButtonAction
							courseId={course?.id}
							bookmarkable_id={quiz.id}
							bookmark={bookmark}
							type={'quiz'}
							simplified={true}
							refetch={refetchBookmarks}
						/>
					</span>
				)}
			</div>
			{/* Text */}
			<div className="pt-2.5">
				<h3 className="font-bold text-title line-clamp-1">
					{quiz.title}
				</h3>
				<p className="mt-1 text-sm text-body line-clamp-2">
					{quiz.desc}
				</p>
			</div>
		</a>
	);
};

export default QuizCard;
