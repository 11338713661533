import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateAwardAction from '@components/actions/CreateAward';

interface CreateAwardProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const CreateAward: React.FC<CreateAwardProps> = ({ open, setOpen }) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.create_award.title}>
			<CreateAwardAction
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateAward;
