import { http } from '@utils/index';

interface Props {
	id: number;
}

const getBillingPortal = (props: Props) => {
	return http.get<BillingPortalRes>(
		`/organisations/${props.id}/billing-portal`
	);
};
export default getBillingPortal;
