import { http, buildQueryString } from '@utils/index';

interface Props {
	id: number | string;
	include: {
		textTracks: boolean;
		textTracksCount: boolean;
		textTracksCues: boolean;
		media: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaVideosRes>>(
		`/sa/videos/${props.id}${buildQueryString(undefined, [
			{
				key: 'textTracks',
				include: props.include.textTracks,
			},
			{
				key: 'textTracksCount',
				include: props.include.textTracksCount,
			},
			{
				key: 'textTracks.cues',
				include: props.include.textTracksCues,
			},
			{
				key: 'media',
				include: props.include.media,
			},
		])}`
	);
};
export default getSingle;
