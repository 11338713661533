import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	organisationId?: number;
	include: {
		course: boolean;
		quiz: boolean;
		user: boolean;
	};
	filters?: {
		user_id?: Array<number | string>;
		course_id?: Array<number | string>;
		quiz_type_id?: Array<number | string>;
		quiz_id?: Array<number | string>;
		pass?: Array<number | string>;
		created_at_from?: string;
		created_at_to?: string;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<OrgQuizResultRes[]>>(
		`/organisations/${props.organisationId}/quiz-results${buildQueryString(
			props.queryString,
			[
				{ key: 'course', include: props.include.course },
				{ key: 'user', include: props.include.user },
				{ key: 'quiz', include: props.include.quiz },
			],
			[
				{ key: 'user_id', value: props.filters?.user_id },
				{ key: 'course_id', value: props.filters?.course_id },
				{ key: 'quiz.type_id', value: props.filters?.quiz_type_id },
				{ key: 'quiz_id', value: props.filters?.quiz_id },
				{ key: 'pass', value: props.filters?.pass },
				{
					key: 'created_at_from',
					value: props.filters?.created_at_from,
				},
				{ key: 'created_at_to', value: props.filters?.created_at_to },
			],
			props.perPage
		)}`
	);
};
export default getMultiple;
