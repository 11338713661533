import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
	quizId: number | string;
	include: {
		type: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<QuizzesRes>>(
		`/organisations/${props.organisationId}/courses/${
			props.courseId
		}/quizzes/${props.quizId}${buildQueryString(undefined, [
			{ key: 'type', include: props.include.type },
		])}`
	);
};
export default getSingle;
