import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
	videoId: number | string;
	include: {
		type: boolean;
		media: boolean;
		textTracks: boolean;
		textTracksCues: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<CourseVideosRes>>(
		`/organisations/${props.organisationId}/courses/${
			props.courseId
		}/videos/${props.videoId}${buildQueryString(undefined, [
			{ key: 'type', include: props.include.type },
			{ key: 'media', include: props.include.media },
			{ key: 'textTracks', include: props.include.textTracks },
			{ key: 'textTracks.cues', include: props.include.textTracksCues },
		])}`
	);
};
export default getSingle;
