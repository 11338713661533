import { http } from '@utils/index';

interface ResponseData {}

const acceptTerms = () => {
	return http.post<APIResponse<ResponseData>>('/user/terms', {
		_method: 'put',
	});
};
export default acceptTerms;
