import { translate } from '@lang/index';
import { useMemo } from 'react';
// Assets
import locationSearchSVG from '@assets/images/svgs/location_search.svg';
import accessDeniedSVG from '@assets/images/svgs/access_denied.svg';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import AccountSwitcher from '@components/partials/AccountSwitcher';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
// Groups
import SuperAdminView from '@components/groups/dashboard/SuperAdminView';
import StandardView from '@components/groups/dashboard/StandardView';
import FullMessage from '@components/groups/dashboard/FullMessage';
// Queries
import { useAuthenticatedUser } from '@queries/user';

const Dashboard: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser();

	// -------------------------------------------------
	// Memos
	const dashboardMode = useMemo(() => {
		if (organisation === undefined && user.data?.data.data.is_super_admin)
			return 'super_admin';
		else if (
			organisation === undefined &&
			!user.data?.data.data.is_super_admin
		)
			return 'no_organisation';
		else if (organisation?.active === false) return 'organisation_inactive';
		else if (organisation?.seated_at === null) return 'not_seated';
		else return 'standard';
	}, [organisation, user.data?.data.data.is_super_admin]);

	const hasOrganisations = useMemo(() => {
		if (!user.data?.data.data.organisations) return false;
		if (user.data?.data.data.organisations.length > 0) return true;
		return false;
	}, [user.data?.data.data.organisations]);

	// -----------------------------------------
	// Render
	if (user.isLoading) return <Loading type="page" />;
	if (user.isError) return <Error type="page" />;

	return (
		<>
			{dashboardMode === 'super_admin' && (
				<SuperAdminView user={user.data.data.data} />
			)}
			{dashboardMode === 'standard' && (
				<StandardView user={user.data.data.data} />
			)}
			{dashboardMode === 'no_organisation' && (
				<FullMessage
					image={locationSearchSVG}
					title={
						hasOrganisations
							? translate('no_oranisation_selected_title')
							: translate('no_organisation_title')
					}
					message={
						hasOrganisations
							? translate('no_oranisation_selected_message')
							: translate('no_organisation_message')
					}>
					{hasOrganisations && (
						<div className="mt-10 w-full max-w-[320px]">
							<AccountSwitcher theme={'inline'} />
						</div>
					)}
				</FullMessage>
			)}
			{dashboardMode === 'not_seated' && (
				<FullMessage
					image={accessDeniedSVG}
					title={translate('not_seated_title')}
					message={translate('not_seated_message')}
				/>
			)}
			{dashboardMode === 'organisation_inactive' && (
				<FullMessage
					image={accessDeniedSVG}
					title={translate('organisation_inactive_title')}
					message={translate('organisation_inactive_message')}
				/>
			)}
		</>
	);
};

export default Dashboard;
