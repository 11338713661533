import { http } from '@utils/index';

interface Props {
	organisationId: number;
	noteableId: number;
	body: {
		note: string;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<NotesRes>>(
		`/organisations/${props.organisationId}/notes/${props.noteableId}`,
		{
			_method: 'put',
			...props.body,
		}
	);
};
export default updateSingle;
