import { useCallback, useMemo } from 'react';
import { useOrganisation } from './useOrganisation';

const usePermissions = () => {
	const { organisation } = useOrganisation();

	// ---------------------------------------------
	// Memos
	const organisationAccess = useMemo(() => {
		if (!organisation) return false;
		if (organisation.active === false) return false;
		if (organisation.seated_at === null) return false;
		return true;
	}, [organisation]);

	// ---------------------------------------------
	// Functions
	const hasSuperAdminPermission = (isSuperAdmin: boolean) => {
		const perm = isSuperAdmin && !organisation;
		return perm || false;
	};

	// ---------------------------------------------
	// Callbacks
	const hasPermission = useCallback(
		(permission: OrganisationPermissions | OrganisationPermissions[]) => {
			if (organisationAccess) {
				if (Array.isArray(permission)) {
					let hasAll = false;
					permission.forEach((perm) => {
						if (!organisation?.permissions.includes(perm)) {
							hasAll = false;
						}
					});
					return hasAll;
				} else {
					return (
						organisation?.permissions.includes(permission) || false
					);
				}
			}
			return false;
		},
		[organisation, organisationAccess]
	);

	// ---------------------------------------------
	// Memos
	const viewCourses = useMemo(() => {
		return hasPermission('view_courses');
	}, [hasPermission]);

	const manageNotes = useMemo(() => {
		return hasPermission('manage_notes');
	}, [hasPermission]);

	const manageOrganisation = useMemo(() => {
		return hasPermission('manage_organisation');
	}, [hasPermission]);

	const manageOrganisationWithPlan = useMemo(() => {
		return (
			hasPermission('manage_organisation') &&
			organisation?.plan_id !== null
		);
	}, [hasPermission, organisation?.plan_id]);

	const manageTeams = useMemo(() => {
		return hasPermission('manage_teams') && organisation?.plan_id !== null;
	}, [hasPermission, organisation?.plan_id]);

	const manageUsers = useMemo(() => {
		return hasPermission('manage_users') && organisation?.plan_id !== null;
	}, [hasPermission, organisation?.plan_id]);

	const hasOrgPlan = useMemo(() => {
		return organisation?.plan_id !== null;
	}, [organisation?.plan_id]);

	return {
		hasPermission,
		hasSuperAdminPermission,
		viewCourses,
		manageNotes,
		manageOrganisation,
		manageTeams,
		manageUsers,
		organisationAccess,
		hasOrgPlan,
		manageOrganisationWithPlan,
	};
};

export default usePermissions;
