import { http } from '@utils/index';

export interface Props {
	body: {
		value: string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SeachesRes>>(`/searches`, {
		...props.body,
	});
};
export default createSingle;
