import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Button from '@components/partials/Button';
import Form from '@components/forms/Form';
import FormAction from '@components/forms/FormAction';
import SingleImageUpload from '@components/forms/SingleImageUpload';
import FormRow from '@components/forms/FormRow';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';

interface UpdateOptionsActionProps extends ActionProps {
	options: OptionsRes;
}

const UpdateOptionsAction: React.FC<UpdateOptionsActionProps> = ({
	options,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [background, setBackground] = useState<File | undefined | null>(
		undefined
	);
	const [logo, setLogo] = useState<File | undefined | null>(undefined);
	const [icheme, setIcheme] = useState<File | undefined | null>(undefined);
	const [signature, setSignature] = useState<File | undefined | null>(
		undefined
	);
	const [homeHeroVideoId, setHomeHeroVideoId] = useState(
		options.home_hero_video_id
	);
	const [footerBannerHtml, setFooterBannerHtml] = useState(
		options.footer_banner_html
	);
	const [krCourseIds, setKrCourseIds] = useState(options.kr_course_ids);

	// --------------------------------------
	// Queries / Mutations
	const updateOptions = useMutation(api.sa.options.update, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Options',
				}),
				message: translate('toast_updated_message', {
					name: 'Options',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['sa.options.getOptions']);

			setBackground(undefined);
			setLogo(undefined);
			setIcheme(undefined);
			setSignature(undefined);

			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		updateOptions.mutateAsync({
			body: {
				cert_background: background,
				cert_logo: logo,
				cert_icheme: icheme,
				cert_signature: signature,
				home_hero_video_id:
					homeHeroVideoId === '' ? null : homeHeroVideoId,
				footer_banner_html:
					footerBannerHtml === '' ? null : footerBannerHtml,
				kr_course_ids: krCourseIds === '' ? null : krCourseIds,
			},
		});
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormRow
				title={translate('certificate_customisations')}
				id={'certificate-customisations'}>
				<SingleImageUpload
					id="cert_background"
					label={translate('background')}
					name="cert_background"
					errors={formState.errors.value['image']}
					required={false}
					formats={['image/jpeg']}
					value={background}
					setValue={setBackground}
					describedBy={translate('image_desribed_by_exact', {
						width: 3508,
						height: 2481,
						type: 'jpg',
					})}
					current={options.cert_background}
				/>
				<SingleImageUpload
					id="cert_logo"
					label={translate('logo')}
					name="cert_logo"
					errors={formState.errors.value['image']}
					required={false}
					formats={['image/png']}
					value={logo}
					setValue={setLogo}
					describedBy={translate('image_desribed_by_exact', {
						width: 236,
						height: 2481,
						type: 'png',
					})}
					current={options.cert_logo}
				/>
				<SingleImageUpload
					id="cert_icheme"
					label={translate('icheme_logo')}
					name="cert_icheme"
					errors={formState.errors.value['image']}
					required={false}
					formats={['image/jpeg']}
					value={icheme}
					setValue={setIcheme}
					describedBy={translate('image_desribed_by_exact', {
						width: 236,
						height: 297,
						type: 'jpg',
					})}
					current={options.cert_icheme}
				/>
				<SingleImageUpload
					id="cert_signature"
					label={translate('signature')}
					name="cert_signature"
					errors={formState.errors.value['image']}
					required={false}
					formats={['image/png']}
					value={signature}
					setValue={setSignature}
					describedBy={translate('image_desribed_by_exact', {
						width: 785,
						height: 328,
						type: 'png',
					})}
					current={options.cert_signature}
				/>
			</FormRow>
			<FormRow
				title={translate('marketing_website')}
				id={'marketing-website'}>
				<Input
					value={homeHeroVideoId ?? ''}
					setValue={setHomeHeroVideoId}
					name="home_hero_video_id"
					label={translate('home_hero_video_id')}
					id="home_hero_video_id"
					type="text"
					errors={formState.errors.value['home_hero_video_id']}
					required={false}
				/>
				<Textarea
					value={footerBannerHtml ?? ''}
					setValue={setFooterBannerHtml}
					name="footer_banner_html"
					label={translate('footer_banner_html')}
					id="footer_banner_html"
					errors={formState.errors.value['footer_banner_html']}
					required={false}
					describedBy={translate('footer_banner_html_desribed_by')}
				/>
			</FormRow>
			<FormRow
				title={translate('kappa_redirect')}
				id={'kappa-redirect'}>
				<Input
					value={krCourseIds ?? ''}
					setValue={setKrCourseIds}
					name="kr_course_ids"
					label={translate('kappa_course_ids')}
					id="kr_course_ids"
					type="text"
					errors={formState.errors.value['kr_course_ids']}
					required={false}
					describedBy={translate('kappa_course_ids_described_by')}
				/>
			</FormRow>
			{/* Update */}
			<FormAction
				loading={{
					is: updateOptions.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateOptions.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOptionsAction;
