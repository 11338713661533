import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	filters?: {
		courseTypeId?: 1 | 2;
		categoryIds?: number[];
		recommended?: 1 | 0;
	};
	include: {
		items: boolean;
		itemsModel: boolean;
		itemsModelType: boolean;
		media: boolean;
		type: boolean;
		teamsCount: boolean;
		usersCount: boolean;
	};
	sort?: {
		progress_count?: 'asc' | 'desc';
		access?: 'asc' | 'desc';
		title?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<CourseRes[]>>(
		`/organisations/${props.organisationId}/courses${buildQueryString(
			props.queryString,
			[
				{ key: 'items', include: props.include.items },
				{ key: 'items.model', include: props.include.itemsModel },
				{
					key: 'items.model.type',
					include: props.include.itemsModelType,
				},
				{ key: 'media', include: props.include.media },
				{ key: 'type', include: props.include.type },
				{ key: 'teamsCount', include: props.include.teamsCount },
				{ key: 'usersCount', include: props.include.usersCount },
			],
			[
				{ key: 'course_type_id', value: props.filters?.courseTypeId },
				{ key: 'category_id', value: props.filters?.categoryIds },
				{ key: 'recommended', value: props.filters?.recommended },
			],
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
