import { translate } from '@lang/index';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import CourseRow from '@components/rows/CourseRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import DeleteCourse from '@components/modal/DeleteCourse';
import DuplicateCourse from '@components/modal/DuplicateCourse';

interface CourseContentTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
	type: 'course' | 'content';
}

const CourseContentTable: React.FC<CourseContentTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	type,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } = useSelectedRow<SaCourseRes | null>(
		null
	);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);
	const [openDuplicate, setOpenDuplicate] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const courses = useQuery(
		['sa.courses.getMultiple', queryString, type],
		() => {
			return api.sa.courses.getMultiple({
				queryString: queryString,
				include: {
					items: false,
					itemsModels: false,
					itemsCount: false,
					media: false,
					mediaCount: false,
					categories: true,
					categoriesCount: false,
					categoriesMeta: false,
					organisations: false,
					organisationsCount: true,
				},
				filters: {
					type: type === 'content' ? 2 : 1,
				},
			});
		},
		{ keepPreviousData: true, enabled }
	);
	const prices = useQuery(['public.stripe.getPrices'], () => {
		return api.public.stripe.getPrices();
	});
	const saOptions = useQuery(['sa.options.getOptions'], () => {
		return api.sa.options.getOptions();
	});

	// -----------------------------------------
	// Memos
	const isLoading = useMemo(() => {
		return courses.isLoading || prices.isLoading || saOptions.isLoading;
	}, [courses.isLoading, prices.isLoading, saOptions.isLoading]);
	const isError = useMemo(() => {
		return courses.isError || prices.isError || saOptions.isError;
	}, [courses.isError, prices.isError, saOptions.isError]);

	// -----------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={courses.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: isLoading,
					error: isError,
					noData: courses.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Course',
					}),
					message: translate('no_data_message', {
						name: 'course',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('title'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'title'
							),
							type: 'sortable',
						},
						{
							label: translate('ref'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'ref'
							),
							type: 'sortable',
						},
						{
							label: translate('categories'),
							type: 'standard',
							hide: type === 'content',
						},
						{
							label: translate('price'),
							type: 'standard',
						},
						{
							label: translate('date_last_modified'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'updated_at'
							),
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{courses.data?.data.data.map((course, index) => (
						<CourseRow
							key={index}
							course={course}
							type={type}
							stripePrices={prices.data?.data}
							selectRow={(course, action) => {
								setSelectedRow(course);
								switch (action) {
									case 'delete': {
										setOpenDelete(true);
										break;
									}
									case 'duplicate': {
										setOpenDuplicate(true);
										break;
									}
								}
							}}
							stripeURL={`${saOptions.data?.data.stripe_url}products/${course.stripe_product_id}`}
						/>
					))}
				</Table>
				<Pagination
					meta={courses.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{/* Modals */}
				{selectedRow !== null && (
					<>
						<DeleteCourse
							open={openDelete}
							setOpen={setOpenDelete}
							courseId={selectedRow.id}
							onSuccess={() => searchParams.page.setValue(1)}
						/>
						<DuplicateCourse
							id={selectedRow.id}
							open={openDuplicate}
							setOpen={setOpenDuplicate}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default CourseContentTable;
