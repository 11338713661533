import { faCaretDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import classNames from 'classnames';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Queries
import { useAuthenticatedUser } from '@queries/user';

interface AccountSwitcherProps {
	theme?: 'inline';
}

const AccountSwitcher: React.FC<AccountSwitcherProps> = ({ theme }) => {
	// -------------------
	// State / Hooks
	const { organisation, selectOrganisation } = useOrganisation();
	const queryClient = useQueryClient();

	// --------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser();
	// --------------------------------------
	// Memos
	const showAccountSwitcher = useMemo(() => {
		// if more than one organisation
		// and user is not a super admin
		const organisations = user.data?.data.data.organisations;
		if (!organisations) return false;

		if (organisations.length > 0 && !user.data?.data.data.is_super_admin) {
			return true;
		}
		if (organisations.length >= 1 && user.data?.data.data.is_super_admin) {
			return true;
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.data?.data.data.organisations]);

	// -------------------
	// Render
	if (user.isLoading || user.isError) return null;
	if (!showAccountSwitcher) return null;

	return (
		<Popover className="relative z-50">
			{({ open, close }) => (
				<>
					<Popover.Button
						className={classNames(
							`flex h-10 w-full items-center border-b border-border bg-uiLight px-4`,
							{
								'rounded-md border': theme === 'inline',
							}
						)}>
						<div className="flex w-full items-center justify-between text-left text-sm font-medium text-title">
							<span
								className="line-clamp-1"
								title={
									organisation?.name ||
									'Select an organisation'
								}>
								{organisation?.name || 'Select an organisation'}
							</span>
							<span className="flex items-center gap-2">
								{organisation !== undefined && (
									<FontAwesomeIcon
										icon={faXmark}
										className="mt-0.5 cursor-pointer text-sm text-current transition-colors duration-200 hover:text-red"
										onClick={(e) => {
											e.stopPropagation();
											selectOrganisation(undefined);
											queryClient.invalidateQueries();
											close();
										}}
									/>
								)}
								<FontAwesomeIcon
									icon={faCaretDown}
									className={classNames(
										'text-current transition-transform duration-200',
										{
											'rotate-180': open,
										}
									)}
								/>
							</span>
						</div>
					</Popover.Button>

					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0">
						<Popover.Panel
							className={classNames(
								'absolute left-0 right-0 z-20 border border-t-0 border-border bg-white py-2 shadow-md',
								{
									'mt-1 !rounded-md !border':
										theme === 'inline',
								}
							)}
							as="ul">
							<li className="flex flex-col">
								{user.data?.data.data.organisations?.map(
									(org) => (
										<div
											key={org.id}
											className={classNames(
												'flex cursor-pointer items-center  justify-between py-1 px-5 text-sm',
												{
													'bg-brandTertiary text-brandTertiaryText hover:bg-brandTertiaryHover':
														organisation?.id ===
														org.id,
													'bg-white text-body hover:bg-uiLight':
														organisation?.id !==
														org.id,
												}
											)}
											onClick={() => {
												selectOrganisation(org);
												queryClient.invalidateQueries();
												close();
											}}>
											<span className="line-clamp-1">
												{org.name}
											</span>
											<span className="ml-2.5 text-xs">
												{org.active
													? 'Active'
													: 'Inactive'}
											</span>
										</div>
									)
								)}
							</li>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};
export default AccountSwitcher;
