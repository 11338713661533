import { http } from '@utils/index';

interface Props {
	title: string;
	desc?: string;
	ref?: string;
	type_id?: number;
	sync_id?: string;
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaVideosRes>>(`/sa/videos`, {
		...props,
	});
};
export default createSingle;
