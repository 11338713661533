import { translate } from '@lang/index';
// Hooks
import { useAuth } from '@hooks/index';
// Components
import Button from '@components/partials/Button';

const LogOutAllDevices: React.FC = () => {
	// -------------------------------------------------
	// State
	const { signouteverywhere } = useAuth();

	// -------------------------------------------------
	// Queries & Mutations

	const logoutAllDevices = () => {
		signouteverywhere(
			() => {},
			(error) => {
				console.log(error);
			}
		);
	};

	// -------------------------------------------------
	// Render
	return (
		<Button theme={'danger'} onClick={() => logoutAllDevices()}>
			{translate('log_out_all_devices')}
		</Button>
	);
};

export default LogOutAllDevices;
