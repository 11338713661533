import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	filters?: Array<{
		key: 'scope';
		value: CategoryScopes;
	}>;
	include: {
		meta: boolean;
		metaCount: boolean;
	};
	sort?: {
		name?: 'asc' | 'desc';
		ref?: 'asc' | 'desc';
	};
}

const getAll = (props: Props) => {
	return http.get<APIResponse<CategoriesRes[]>>(
		`/organisations/${props.organisationId}/categories${buildQueryString(
			undefined,
			[
				{
					key: 'meta',
					include: props.include.meta,
				},
				{
					key: 'metaCount',
					include: props.include.metaCount,
				},
			],
			props.filters,
			-1,
			props.sort
		)}`
	);
};
export default getAll;
