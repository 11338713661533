import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
// api
import api from 'api';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import LoginLogRow from '@components/rows/LoginLogRow';
import TopPagination from '@components/query/FormUtilityBar';

interface LogsTableProps extends QueryTableProps {
	mode: 'sa' | 'organisation';
	utilityBar?: React.ReactNode;
	isSuperadmin?: boolean;
}

const LogsTable: React.FC<LogsTableProps> = ({
	mode,
	searchParams,
	queryString,
	utilityBar,
	resetFilters,
	enabled,
	isSuperadmin,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Queries / Mutations
	type SaLogsResT = AxiosResponse<APIResponse<SaLogsRes[]>, any>;
	type OrgLogsResT = AxiosResponse<APIResponse<OrgLogsRes[]>, any>;

	const logs = useQuery<SaLogsResT | OrgLogsResT>(
		[
			mode === 'sa'
				? 'sa.logs.getMultiple'
				: 'organisations.logs.getMultiple',
			queryString,
			organisation?.id,
		],
		() => {
			if (mode === 'sa') {
				return api.sa.logs.getMultiple({
					queryString: queryString,
					include: {
						type: true,
						user: true,
					},
				});
			} else {
				return api.organisations.logs.getMultiple({
					queryString: queryString,
					organisationId: organisation?.id || 0,
					include: {
						type: true,
						user: true,
					},
				});
			}
		},
		{ keepPreviousData: true, enabled: enabled }
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={logs.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: logs.isLoading,
					error: logs.isError,
					noData: logs.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Activity',
					}),
					message: translate('no_data_message', {
						name: 'activity',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('user'),
							type: 'standard',
							hide: !manageOrganisationWithPlan && !isSuperadmin,
						},
						{
							label: translate('ip'),
							type: 'standard',
						},
						{
							label: translate('device'),
							type: 'standard',
						},
						{
							label: translate('date'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
						},
					]}
					setSort={searchParams.sort.setValue}
					scrollX={true}>
					{logs.data?.data.data.map((log, index) => {
						if (log.type.key === 'login') {
							return (
								<LoginLogRow
									key={index}
									log={log}
									isSuperadmin={isSuperadmin}
								/>
							);
						}

						return null;
					})}
				</Table>
				<Pagination
					meta={logs.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default LogsTable;
