// Components
import PerPage from '@components/query/PerPage';

interface FormUtilityBarProps {
	perPage: number;
	setPerPage: (perPage: number) => void;
	options: Array<{
		value: number;
		label: string;
	}>;
	meta?: APIMeta;
	children?: React.ReactNode;
}

const FormUtilityBar: React.FC<FormUtilityBarProps> = ({
	perPage,
	setPerPage,
	options,
	meta,
	children,
}) => {
	// -----------------------------
	// Render
	return (
		<div className="mb-2.5 flex w-full max-w-[100%] justify-between">
			{children}
			{!children && <span></span>}
			{(meta?.last_page || 0) > 1 && (
				<div className="ml-5 flex min-w-max items-center">
					<span className="mr-2 block text-sm text-body text-opacity-80">
						From <span className="font-bold">{meta?.from}</span> to{' '}
						<span className="font-bold">{meta?.to}</span> of{' '}
						<span className="font-bold">{meta?.total}</span>{' '}
						result(s)
					</span>
					<PerPage
						perPage={perPage}
						setPerPage={setPerPage}
						options={options}
					/>
				</div>
			)}
		</div>
	);
};

export default FormUtilityBar;
