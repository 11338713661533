import organisations from './organisations';
import courses from './courses';
import users from './users';
import courseTypes from './course-types';
import quizzes from './quizzes';
import videos from './videos';
import chapters from './chapters';
import plans from './plans';
import roles from './roles';
import statistics from './statistics';
import categories from './categories';
import categoryScopes from './category-scopes';
import awards from './awards';
import teams from './teams';
import courseProgress from './course-progress';
import logs from './logs';
import videoViews from './video-views';
import quizResults from './quiz-results';
import options from './options';
import userAwards from './user-awards';

const actions = {
	organisations,
	courses,
	users,
	courseTypes,
	quizzes,
	videos,
	chapters,
	plans,
	roles,
	statistics,
	categories,
	categoryScopes,
	awards,
	teams,
	courseProgress,
	logs,
	videoViews,
	quizResults,
	options,
	userAwards,
};

export default actions;
