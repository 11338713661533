import { translate } from '@lang/index';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Modal from '@components/modal/Modal';
import CertificateRow from '@components/rows/CertificateRow';
import Button from '@components/partials/Button';
import ConfettiBackground from '@components/partials/ConfettiBackground';

interface GrantedAwardsProps {
	awards: OrgUserAwardsRes[];
	open: boolean;
	setOpen: (state: boolean) => void;
}

const GrantedAwards: React.FC<GrantedAwardsProps> = ({
	awards,
	open,
	setOpen,
}) => {
	// --------------------
	// State
	const { organisation } = useOrganisation();

	// --------------------
	// Render
	return (
		<>
			<Modal
				open={open}
				setOpen={setOpen}
				title={translate('modal_granted_awards_title')}
				description={translate('modal_granted_awards_description')}>
				<ul>
					{awards.map((award) => (
						<CertificateRow
							key={award.award_id}
							mode="list"
							certificate={award}
						/>
					))}
				</ul>
				<div className="mt-5 flex w-full justify-end border-t border-border pt-5">
					<div>
						<Button
							theme="outline"
							type="button"
							className="mr-3"
							onClick={() => {
								setOpen(false);
							}}>
							{translate('close')}
						</Button>
						<Button
							theme="primary"
							type="link"
							href={`/${organisation?.slug}/awards`}>
							{translate('view_my_awards')}
						</Button>
					</div>
				</div>
			</Modal>
			{open && <ConfettiBackground />}
		</>
	);
};

export default GrantedAwards;
