import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import PlanRow from '@components/rows/PlanRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import UpdatePlan from '@components/modal/UpdatePlan';
import DeletePlan from '@components/modal/DeletePlan';

interface AwardsTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const PlansTable: React.FC<AwardsTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } = useSelectedRow<SaPlanRes | null>(
		null
	);

	// modal state
	const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

	// -------------------------------------------------
	// Mutations / Queries
	const plans = useQuery(
		['sa.plans.getMultiple', queryString],
		() => {
			return api.sa.plans.getMultiple({
				queryString,
			});
		},
		{
			enabled: enabled,
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={plans.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: plans.isLoading,
					error: plans.isError,
					noData: plans.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Plans',
					}),
					message: translate('no_data_message', {
						name: 'plans',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('seats'),
							type: 'standard',
						},
						{
							label: translate('users'),
							type: 'standard',
						},
						{
							label: translate('download'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{plans.data?.data.data.map((plan, i) => {
						return (
							<PlanRow
								key={i}
								plan={plan}
								selectRow={(plan, action) => {
									setSelectedRow(plan);
									switch (action) {
										case 'update': {
											setOpenUpdateModal(true);
											break;
										}
										case 'delete': {
											setOpenDeleteModal(true);
											break;
										}
									}
								}}
							/>
						);
					})}
				</Table>
				<Pagination
					meta={plans.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{selectedRow && (
					<>
						<UpdatePlan
							id={selectedRow.id}
							open={openUpdateModal}
							setOpen={setOpenUpdateModal}
						/>
						<DeletePlan
							id={selectedRow.id}
							open={openDeleteModal}
							setOpen={setOpenDeleteModal}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default PlansTable;
