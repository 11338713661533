import { http } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
	quizId: number;
}

const createResult = (props: Props) => {
	return http.post<APIResponse<QuizzesRes>>(
		`/organisations/${props.organisationId}/courses/${props.courseId}/videos/${props.quizId}/views`
	);
};
export default createResult;
