import { translate } from '@lang/index';
import MessageBlock from '@components/blocks/MessageBlock';
// Assets
import fixingBugsSvg from '@assets/images/svgs/fixing-bugs.svg';

interface ErrorProps {
	type?: 'block' | 'page' | 'fill';
}

const Error: React.FC<ErrorProps> = ({ type = 'block' }) => {
	if (type === 'page') {
		return (
			<div className="fixed  left-0 right-0 bottom-0 top-header-height  z-50 overflow-y-auto bg-white pl-0 lg:pl-[300px]">
				<div className=" flex h-full w-full items-center justify-center">
					<div className="m-auto">
						<MessageBlock
							image={fixingBugsSvg}
							title={translate('error_title')}
							message={translate('error_message')}
							className={'border-none'}
						/>
					</div>
				</div>
			</div>
		);
	}
	if (type === 'block') {
		return (
			<div className="flex w-full items-center justify-center rounded-md border border-red bg-white">
				<MessageBlock
					image={fixingBugsSvg}
					title={translate('error_title')}
					message={translate('error_message')}
				/>
			</div>
		);
	}

	if (type === 'fill') {
		return (
			<div className="absolute inset-0 z-10 flex flex-col items-center justify-center rounded-md border border-red bg-white">
				<h2 className="mb-1 text-center text-sm font-bold">
					{translate('error_title')}
				</h2>
				<p className="text-center text-sm">
					{translate('error_message')}
				</p>
			</div>
		);
	}

	return null;
};

export default Error;
