interface IncludesProps {
	key: string;
	include: boolean;
}

interface IncludeFilterProps {
	key: string;
	value?: string | number | Array<string | number>;
}

interface SortProps {
	[key: string]: 'asc' | 'desc';
}

const buildQueryString = (
	query?: string,
	includes?: Array<IncludesProps>,
	filters?: Array<IncludeFilterProps>,
	perPage?: number,
	sort?: SortProps
) => {
	let queryString = '?';
	if (query) {
		queryString += query[0] === '?' ? query.slice(1) : query;
	}

	if (includes) {
		let includesString = '';
		includes.forEach((include, index) => {
			if (include.include) {
				includesString += `${include.key}${
					index < includes.length - 1 ? ',' : ''
				}`;
			}
		});
		if (includesString) queryString += `&include=${includesString}`;
	}

	if (filters) {
		let filtersString = '';
		filters.forEach((filter) => {
			if (filter.value !== undefined) {
				if (Array.isArray(filter.value)) {
					filtersString += `&filter[${
						filter.key
					}]=${filter.value.join(',')}`;
				} else {
					filtersString += `&filter[${filter.key}]=${filter.value}`;
				}
			}
		});
		if (filtersString) queryString += filtersString;
	}

	if (perPage) {
		queryString += `&per_page=${perPage}`;
	}

	if (sort) {
		// for each key in sort, add to queryString
		let sortString = '';
		if (Object.keys(sort).length > 0) sortString += '&sort=';
		Object.keys(sort).forEach((key, index) => {
			const value = sort[key as keyof typeof sort];
			sortString += `${value === 'asc' ? '' : '-'}${key}`;
		});
		queryString += sortString;
	}

	// if first character is ? and second character is &, remove the second character
	if (queryString[0] === '?' && queryString[1] === '&') {
		queryString = queryString.slice(0, 1) + queryString.slice(2);
	}

	return queryString;
};

export default buildQueryString;
