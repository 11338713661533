import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		cues: boolean;
		media: boolean;
	};
	sort?: {
		views_count?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<VideosRes[]>>(
		`/organisations/${props.organisationId}/videos${buildQueryString(
			props.queryString,
			[
				{
					key: 'cues',
					include: props.include.cues,
				},
				{ key: 'media', include: props.include.media },
			],
			undefined,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
