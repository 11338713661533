import { http } from '@utils/index';

interface Props {
	organisationId: number;
	body: {
		bookmarkable_type: 'course' | 'quiz' | 'video';
		bookmarkable_id: number;
		course_id?: number;
	};
}

const toggleSingle = (props: Props) => {
	return http.post<APIResponse<BookmarkesRes>>(
		`/organisations/${props.organisationId}/bookmarks/toggle`,
		{
			_method: 'put',
			...props.body,
		}
	);
};
export default toggleSingle;
