// Hooks
import { useFormState } from '@hooks/index';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';

interface FormProps {
	onSubmit: () => Promise<void>;
	onChange?: () => void;
	state: ReturnType<typeof useFormState>;
	validation: boolean;
	children: React.ReactNode;

	isLoading?: boolean;
	isError?: boolean;
	className?: string;
}

const Form: React.FC<FormProps> = ({
	children,
	onSubmit,
	onChange,
	state,
	validation = true,
	isLoading = false,
	isError = false,
	className = '',
}) => {
	const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// Lock
		state.lock.setLock(true);

		// check each form input for validation errors
		const form = e.target as HTMLFormElement;
		const inputs = form.querySelectorAll('input, textarea') as NodeListOf<
			HTMLInputElement | HTMLTextAreaElement
		>;

		// reset errors
		state.errors.setErrors({});

		if (validation) {
			const inputErrors: APIError['errors'] = {};

			// for each input, check for validation errors
			for (let i = 0; i < inputs.length; i++) {
				const input = inputs[i];
				if (input.checkValidity() === false) {
					inputErrors[input.name] = [input.validationMessage];
					continue;
				}
			}
			state.errors.setErrors(inputErrors);
			// if there are no errors, submit the form
			if (Object.keys(inputErrors).length > 0) {
				state.lock.setLock(false);
				return;
			}
		}

		state.lock.setLock(false);
		await onSubmit();
	};

	return (
		<form
			onSubmit={onSubmitHandler}
			noValidate
			className={className}
			onChange={onChange}>
			{isLoading && (
				<div className="absolute inset-0 z-10 bg-white bg-opacity-50">
					<Loading type={'fill'} />
				</div>
			)}
			{isError && (
				<div className="absolute inset-0">
					<Error type={'fill'} />
				</div>
			)}
			<div className="w-full">{children}</div>
		</form>
	);
};
export default Form;
