// Libs
import classNames from 'classnames';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TextareaProps {
	label?: string;
	id: string;
	name: string;
	value: string;
	setValue: (value: string) => void;
	errors: Array<string> | undefined;
	required: boolean;
	placeholder?: string;
	maxLength?: number;
	minLength?: number;
	hideCounter?: boolean;
	className?: string;
	describedBy?: string;
}

const Textarea: React.FC<TextareaProps> = ({
	label,
	id,
	name,
	value,
	setValue,
	errors = [],
	placeholder,
	required,
	maxLength,
	minLength,
	hideCounter = false,
	className = '',
	describedBy,
}) => {
	return (
		<div className={classNames('mb-4 flex flex-col last:mb-0')}>
			{label && (
				<label htmlFor={id} className="mb-1 block text-body">
					{label}
					{required && <span className="ml-1">(*)</span>}
				</label>
			)}
			<div className="relative">
				<textarea
					className={classNames(
						'block h-36 w-full resize-none rounded-md border border-border px-2.5 focus:border-brandPrimary focus:ring-brandPrimary',
						className,
						{
							'border-red': errors.length > 0,
						}
					)}
					id={id}
					name={name}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					placeholder={placeholder}
					required={required}
					maxLength={maxLength}
					minLength={minLength}
				/>
				{!hideCounter && (
					<>
						{value.length > 0 && (
							<span
								className={classNames(
									'absolute right-2 bottom-2 flex h-6 items-center justify-center rounded-md bg-brandTertiary px-1 text-xs text-brandTertiaryText',
									{
										'bg-red':
											minLength !== undefined
												? value.length < minLength
												: false,
									}
								)}>
								{value.length}
								{maxLength ? `/${maxLength}` : ''}
							</span>
						)}
					</>
				)}
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="mt-2.5 text-sm text-body text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};
export default Textarea;
