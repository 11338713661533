import { http } from '@utils/index';

interface Props {
	id: number;
}

const deleteSingle = (props: Props) => {
	return http.delete<APIResponse<SaCategoriesRes>>(
		`/sa/categories/${props.id}`
	);
};
export default deleteSingle;
