import getTerms from './get-terms';
import sendEnquiry from './send-enquiry';
import getThemes from './get-themes';
import stripe from './stripe';

const actions = {
	getTerms,
	sendEnquiry,
	getThemes,
	stripe,
};

export default actions;
