import { http, buildQueryString } from '@utils/index';

export interface Props {
	queryString?: string;
	organisationId?: string | number;
	include: {
		items: boolean;
		itemsModel: boolean;
	};
	filters?: {
		award_type_id?: string | number | string[] | number[];
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<OrgAwardsRes[]>>(
		`/organisations/${props.organisationId}/awards${buildQueryString(
			props.queryString,
			[
				{ key: 'items', include: props.include.items },
				{ key: 'items.model', include: props.include.itemsModel },
			],
			[{ key: 'award_type_id', value: props.filters?.award_type_id }],
			props.perPage
		)}`
	);
};
export default getMultiple;
