import classNames from 'classnames';

interface EmptyRowProps {
	height?: 'small';
	message?: string;
}

const EmptyRow: React.FC<EmptyRowProps> = ({ height, message }) => {
	return (
		<tr
			className={classNames('relative', {
				'h-[65px]': height === undefined,
				'h-[32.5px]': height === 'small',
			})}>
			{message && (
				<td className="absolute inset-0 flex w-full items-center justify-center text-body">
					{message}
				</td>
			)}
		</tr>
	);
};

export default EmptyRow;
