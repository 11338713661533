import { http } from '@utils/index';

interface Props {
	body: {
		email: string;
	};
}
interface ResponseData {}

const forgotPassword = (props: Props) => {
	return http.post<APIResponse<ResponseData>>('/forgot-password', props.body);
};
export default forgotPassword;
