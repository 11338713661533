import T from '@lang/index';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';
import Button from '@components/partials/Button';
// Queries
import { useBillingPortal } from '@root/queries/organisations';

interface CourseExpiredNavigationProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	courseId: number;
}

const CourseExpiredNavigation: React.FC<CourseExpiredNavigationProps> = ({
	open,
	setOpen,
	courseId,
}) => {
	// --------------------
	// State
	const { organisation } = useOrganisation();
	const { manageOrganisation } = usePermissions();
	const navigate = useNavigate();

	// --------------------
	// Functions
	const onConfirm = async () => {
		navigate(`/${organisation?.slug}/courses/${courseId}/redirect`);
	};

	// --------------------
	// Queries
	const billingPortal = useBillingPortal({
		organisationId: organisation?.id,
		enabled: organisation?.stripe_id !== null && manageOrganisation,
	});

	// --------------------
	// Render
	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			title={T.modals.course_expired_navigation.title}
			onConfirm={onConfirm}
			type={'warning'}
			onClose={() => {}}
			buttons={{
				cancel: 'Close',
				confirm: 'Continue to course',
			}}>
			<div>
				<p>
					{organisation?.plan_id !== null
						? T.modals.course_expired_navigation.plan_body
						: T.modals.course_expired_navigation.no_plan_body}
				</p>
				{!organisation?.plan_id && organisation?.stripe_id && (
					<Button
						type="link"
						theme="primary"
						className="mt-5"
						onClick={() => {
							window.open(
								billingPortal.data?.data.url as string,
								'_blank'
							);
						}}>
						Manage your subscriptions
					</Button>
				)}
			</div>
		</ConfirmationModal>
	);
};

export default CourseExpiredNavigation;
