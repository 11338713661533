import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdateCategoryAction from '@components/actions/UpdateCategory';

interface UpdateCategoryProps {
	category: SaCategoriesRes;
	open: boolean;
	setOpen: (state: boolean) => void;
	scope: CategoryScopes;
}

const UpdateCategory: React.FC<UpdateCategoryProps> = ({
	category,
	open,
	setOpen,
	scope,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={
				scope === 'courseCategory'
					? T.modals.update_category.title
					: T.modals.update_category.title_type
			}>
			<UpdateCategoryAction
				category={category}
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default UpdateCategory;
