import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		items: boolean;
		itemsModels: boolean;
		itemsCount: boolean;
		media: boolean;
		mediaCount: boolean;
		categories: boolean;
		categoriesCount: boolean;
		categoriesMeta: boolean;

		organisations: boolean;
		organisationsCount: boolean;
	};
	filters?: {
		type: 1 | 2;
	};
	perPage?: number;
	sort?: {
		title: 'asc' | 'desc';
	};
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaCourseRes[]>>(
		`/sa/courses${buildQueryString(
			props.queryString,
			[
				{ key: 'items', include: props.include.items },
				{ key: 'items.model', include: props.include.itemsModels },
				{ key: 'media', include: props.include.media },
				{ key: 'categories', include: props.include.categories },
				{
					key: 'categoriesCount',
					include: props.include.categoriesCount,
				},
				{
					key: 'categories.meta',
					include: props.include.categoriesMeta,
				},
				{ key: 'mediaCount', include: props.include.mediaCount },
				{ key: 'itemsCount', include: props.include.itemsCount },
				{ key: 'organisations', include: props.include.organisations },
				{
					key: 'organisationsCount',
					include: props.include.organisationsCount,
				},
			],
			[{ key: 'type', value: props.filters?.type }],
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
