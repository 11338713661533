import getMultiple from './get-multiple';
import getSingle from './get-single';
import getStatus from './get-status';
import quizzes from './quizzes';
import videos from './videos';
import progress from './progress';
import updateSingle from './update-single';

const actions = {
	getMultiple,
	getSingle,
	getStatus,
	updateSingle,
	quizzes,
	videos,
	progress,
};

export default actions;
