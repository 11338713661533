import { http } from '@utils/index';

interface Props {
	id: number;
}

const syncVideo = (props: Props) => {
	return http.post<APIResponse<SaVideosRes>>(`/sa/videos/${props.id}/sync`, {
		_method: 'PUT',
	});
};
export default syncVideo;
