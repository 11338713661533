import { useMemo } from 'react';
import { translate } from '@lang/index';
import type { TableProps } from '@components/table/Table';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import EmptyRow from '@components/rows/EmptyRow';
import Table from '@components/table/Table';

interface TableCardProps {
	title: string;
	rows: number;
	rowLimit: number;
	state: {
		isLoading: boolean;
		isError: boolean;
	};
	head: TableProps['head'];
	children: React.ReactNode;
}

const TableCard: React.FC<TableCardProps> = ({
	title,
	state,
	children,
	rows,
	rowLimit,
	head,
}) => {
	// -------------------------------------------------
	// Callbacks
	const emptyRowTotalArray = useMemo(() => {
		if (rows === undefined || rowLimit === undefined) return [];

		let remaining = rowLimit - rows;
		if (remaining <= 0) {
			remaining = 0;
		}
		const emptyArray = [];
		for (let i = 0; i < remaining; i++) {
			emptyArray.push(i);
		}
		return emptyArray;
	}, [rows, rowLimit]);

	const shoeEmptyTable = useMemo(() => {
		return emptyRowTotalArray.length === rowLimit;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowLimit, emptyRowTotalArray]);

	// ---------------------------
	// Render
	return (
		<div className="">
			<h3 className="mb-2.5 text-sm font-light leading-6 text-body text-opacity-80">
				{title}
			</h3>
			<div className="relative max-h-[382px]">
				{state.isLoading && (
					<div className="absolute inset-0 z-10 overflow-hidden rounded-md bg-white">
						<Loading type="skeleton" />
					</div>
				)}
				{state.isError && <Error type="fill" />}
				<Table head={head} scrollX={true}>
					<>
						{children}
						{shoeEmptyTable && (
							<EmptyRow
								key={0}
								message={translate('table_no_data_message')}
							/>
						)}
						{!shoeEmptyTable && (
							<>
								{emptyRowTotalArray.map((_, index) => (
									<EmptyRow key={index} />
								))}
							</>
						)}
					</>
				</Table>
				{/* {shoeEmptyTable && (
					<div className="absolute inset-0 flex w-full items-center justify-center bg-white bg-opacity-30">
						<MessageBlock
							title={translate('table_no_data_title')}
							message={translate('table_no_data_message')}
							className={'border-none'}
						/>
					</div>
				)} */}
			</div>
		</div>
	);
};

export default TableCard;
