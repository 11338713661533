/*
	undefined, we ignore
	null, we set to empty string
*/

interface CreateFormDataProps {
	data: Array<{
		key: string;
		value: string | null | File | number[] | string[] | undefined;
	}>;
}

const createFormData = (data: CreateFormDataProps['data']) => {
	const formData = new FormData();
	data.forEach((item) => {
		// if undefined
		if (item.value === undefined) {
			return;
		}

		// if null
		if (item.value === null) {
			formData.append(item.key, '');
			return;
		}

		// if its of type file
		if (item.value instanceof File) {
			const blob = new Blob([item.value], { type: item.value.type });
			formData.append(item.key, blob);
			return;
		}

		// if its an array
		if (Array.isArray(item.value)) {
			if (item.value.length === 0) {
				formData.append(item.key, '[]');
				return;
			}
			item.value.forEach((value) => {
				formData.append(item.key, value.toString());
			});
			return;
		}

		// if its of type string
		if (typeof item.value === 'string') {
			if (item.value !== '' && item.value.length > 0) {
				formData.append(item.key, item.value);
			}
		}
	});

	return formData;
};

export default createFormData;
