import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import PasswordUpdateAction from '@components/actions/PasswordUpdate';

interface PasswordResetProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const PasswordUpdate: React.FC<PasswordResetProps> = ({ open, setOpen }) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.password_update.title}>
			<PasswordUpdateAction
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default PasswordUpdate;
