import { useMemo } from 'react';
// Components
import Badges from '@components/partials/Badges';
import CategoryIcon from '@components/partials/CategoryIcon';
// Hooks
import { usePermissions } from '@hooks/index';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface QuizResultsRowProps {
	mode: 'sa' | 'organisation';
	result: SaQuizResultRes | OrgQuizResultRes;
	categories: SaCategoriesRes[] | CategoriesRes[];
	isSuperadmin?: boolean;
}

const QuizResultsRow: React.FC<QuizResultsRowProps> = ({
	mode,
	result,
	isSuperadmin,
	categories,
}) => {
	const { manageOrganisationWithPlan } = usePermissions();

	// -----------------------------------------
	// Memos
	const category = useMemo(
		() =>
			categories.find((category) => category.id === result.quiz?.type_id),
		[categories, result.quiz?.type_id]
	);

	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			<td className="td-default">
				<div className="flex items-center font-medium text-title">
					<span className="mr-2.5 block">
						<CategoryIcon
							colour={category?.meta?.icon_colour}
							initials={category?.meta?.icon_initials}
							label={category?.name}
							categoryScope={'quizType'}
							size={'sm'}
						/>
					</span>
					{result.quiz.title}
				</div>
			</td>
			<td className="td-default">
				<Badges
					theme={result.pass ? 'green' : 'red'}
					className="flex w-[48px] justify-center text-center">
					{result.pass ? 'Pass' : 'Fail'}
				</Badges>
				<span className="ml-2.5 text-sm">
					{result.raw}/{result.max || '-'} ({result.percentage}%)
				</span>
			</td>
			{(manageOrganisationWithPlan || isSuperadmin) && (
				<td className="td-default">
					<UserColumn
						name={result.user?.name}
						email={result.user?.email}
					/>
				</td>
			)}
			{mode === 'sa' && (
				<td className="td-default">
					{(result as SaQuizResultRes).organisation?.name}
				</td>
			)}
			<td className="td-default">{result.course?.title}</td>
			<td className="td-default">
				<DateTimeColumn date={result.created_at || ''} />
			</td>
		</tr>
	);
};

export default QuizResultsRow;
