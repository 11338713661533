import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface DeleteCategoryProps {
	categoryId: number;

	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: () => void;
	scope: CategoryScopes;
}

const DeleteCategory: React.FC<DeleteCategoryProps> = ({
	categoryId,
	open,
	setOpen,
	onSuccess,
	scope,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const deleteCategory = useMutation(api.sa.categories.deleteSingle, {
		onSuccess: () => {
			// invalidate organisations
			queryClient.invalidateQueries(['sa.categories.getMultiple']);
			// cb
			if (onSuccess !== undefined) onSuccess();
			// toast
			addToast({
				title: translate('toast_deleted_title', {
					name: 'Category',
				}),
				message: translate('toast_deleted_message', {
					name: 'Category',
				}),
				type: 'success',
			});
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await deleteCategory.mutateAsync({
			id: categoryId,
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			title={
				scope === 'courseCategory'
					? T.modals.delete_category.title
					: T.modals.delete_category.title_type
			}
			onConfirm={onConfirm}
			type={'danger'}
			onClose={() => {
				setErrorMsg('');
				deleteCategory.reset();
			}}
			formAction={{
				loading: {
					is: deleteCategory.isLoading,
					message: translate('deleting'),
				},
				error: {
					is: deleteCategory.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>
					{scope === 'courseCategory'
						? T.modals.delete_category.body
						: T.modals.delete_category.body_type}
				</p>
			</div>
		</ConfirmationModal>
	);
};

export default DeleteCategory;
