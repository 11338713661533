import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface AlertMessageProps {
	text?: string;
	type: 'error' | 'info';
	onClick?: () => void;
	children?: React.ReactNode;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
	text,
	type,
	onClick,
	children,
}) => {
	return (
		<div
			onClick={onClick}
			className={classNames(
				'flex w-full items-start rounded-r-md border-l-4 bg-uiLight px-2.5 py-2.5 text-sm',
				{
					'border-l-red': type === 'error',
					'border-l-brandSecondary': type === 'info',
					'cursor-pointer': onClick !== undefined,
				}
			)}
			aria-label={type === 'error' ? 'Error message' : 'Info message'}
			role="alert">
			{type === 'error' && (
				<span className="h-5">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 text-red"
					/>
				</span>
			)}
			{children}
			{text}
		</div>
	);
};

export default AlertMessage;
