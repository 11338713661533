import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// Components
import Button from '@components/partials/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ContentRowProps {
	title?: string;
	body?: string;
	href?: string;
	link?: string;
	children: React.ReactNode;
}

const ContentRow: React.FC<ContentRowProps> = ({
	title,
	body,
	href,
	link,
	children,
}) => {
	return (
		<div className="last:bg-0 mb-7 w-full border-b border-border pb-7 last:mb-0">
			{(title || body) && (
				<div className="mb-5 flex justify-between">
					<div className="flex flex-col pr-5">
						{title && (
							<h2 className="text-base font-bold capitalize text-title">
								{title}
							</h2>
						)}
						{body && (
							<p className="mt-1 text-sm text-body">{body}</p>
						)}
					</div>
					{href && !link && (
						<Button
							theme="icon"
							type="link"
							href={href}
							className="!h-8 !w-8 min-w-[32px]">
							<FontAwesomeIcon
								icon={faChevronRight}
								className="text-sm"
							/>
						</Button>
					)}
					{href && link && (
						<Button
							theme="text"
							type="link"
							href={href}
							className="!underline">
							{link}
						</Button>
					)}
				</div>
			)}
			<div>{children}</div>
		</div>
	);
};

export default ContentRow;
