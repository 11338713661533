import T from '@lang/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface NavigationWarningProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	content?: {
		title: string;
		body: string;
	};
	type?: 'danger' | 'warning';
	onSuccess?: () => void;
}

const NavigationWarning: React.FC<NavigationWarningProps> = ({
	open,
	setOpen,
	onSuccess,
	content,
	type = 'danger',
}) => {
	// --------------------
	// Functions
	const onConfirm = async () => {
		if (onSuccess !== undefined) onSuccess();
	};

	// --------------------
	// Render
	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onClose={() => {}}
			title={content?.title || T.modals.navigation_warning.title}
			onConfirm={onConfirm}
			type={type}>
			<div>
				<p>{content?.body || T.modals.navigation_warning.body}</p>
			</div>
		</ConfirmationModal>
	);
};

export default NavigationWarning;
