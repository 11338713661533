const C = {
	// Env vars
	API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
	MARKETING_CONTACT_URL: `${process.env.REACT_APP_MARKETING_URL}/contact`,
	MARKETING_COURSES_URL: `${process.env.REACT_APP_MARKETING_URL}/courses/1`,
	MARKETING_TERMS_URL: `${process.env.REACT_APP_MARKETING_URL}/terms-and-conditions`,
	APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
	API_DOCUMENTATION_URL: process.env.REACT_APP_API_DOCUMENTATION_URL,
	SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,

	FORCE_TEST_ENV_MESSAGE:
		process.env.REACT_APP_FORCE_TEST_ENV_MESSAGE === 'true',

	// Other
	NOMINATED_COURSE_FILTER: {
		courseTypeId: 1 as const,
	},
};

export default C;
