import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
// Assets
import notifySVG from '@assets/images/svgs/notify.svg';
// Components
import MessageBlock from '@components/blocks/MessageBlock';

const Error: React.FC = () => {
	// -------------------------------------------------
	// State
	const { type } = useParams();

	// -------------------------------------------------
	// Memos
	const title = useMemo(() => {
		switch (type) {
			case 'openid':
				return translate('authentication_error_title');
			case 'openid-org-id':
				return translate('openid_org_id_error_title');
			default:
				return translate('error_generic_title');
		}
	}, [type]);

	const message = useMemo(() => {
		switch (type) {
			case 'openid':
				return translate('authentication_error_message');
			case 'openid-org-id':
				return translate('openid_org_id_error_message');
			default:
				return translate('error_generic');
		}
	}, [type]);

	return (
		<div className="flex h-screen items-center">
			<div className="m-auto">
				<MessageBlock
					image={notifySVG}
					title={title}
					message={message}
					className="!border-0"
				/>
			</div>
		</div>
	);
};
export default Error;
