import { http, createFormData } from '@utils/index';

interface Props {
	typeId: number;
	title: string;
	desc: string;
	ref: string;
	file?: File | null;
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaQuizzesRes>>(
		`/sa/quizzes`,
		createFormData([
			{
				key: 'type_id',
				value: props.typeId.toString(),
			},
			{
				key: 'title',
				value: props.title,
			},
			{
				key: 'desc',
				value: props.desc,
			},
			{
				key: 'ref',
				value: props.ref,
			},
			{
				key: 'file',
				value: props.file,
			},
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default createSingle;
