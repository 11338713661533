import { Fragment, useEffect, useRef } from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
// Components
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface PageHeadingProps {
	title: string;
	body?: string;
	breadcrumbs?: React.ReactNode;
	tooltip?: string;
	filter?: React.ReactNode;
	sort?: React.ReactNode;
	search?: React.ReactNode;
	children?: React.ReactNode;
	actions?: React.ReactNode;
	className?: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({
	title,
	body,
	tooltip,
	children,
	filter,
	sort,
	search,
	breadcrumbs,
	actions,
	className,
}) => {
	const pageHeading = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleResize = () => {
			const pageHeadingHeight = pageHeading.current?.clientHeight;
			document.documentElement.style.setProperty(
				'--page-heading-height',
				`${pageHeadingHeight}px`
			);
			pageHeading.current?.setAttribute(
				'style',
				`--page-heading-height: ${pageHeadingHeight}px`
			);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div
			ref={pageHeading}
			className={classNames(
				'relative z-20 flex w-full flex-col items-start justify-center bg-uiLight',
				className
			)}>
			<div className="relative w-full p-5 md:p-7">
				{breadcrumbs && <div className="mb-3">{breadcrumbs}</div>}
				<div className='flex justify-between gap-x-10 gap-y-2.5 md:flex-row flex-col'>
					<div>
						<h1>{title}</h1>
						{body && <p className="mt-2 max-w-4xl">{body}</p>}
					</div>
					<div className='flex items-center gap-x-2.5'>
						{actions}
						{tooltip && (
							<Menu as="div" className="z-10">
								<Menu.Button
									className={'h-8 w-8 rounded-full bg-uiDark'}>
									<FontAwesomeIcon
										icon={faInfo}
										className="h-4 w-4 text-white"
									/>
									<span className="sr-only">Info</span>
								</Menu.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95">
									<Menu.Items
										className={
											'absolute right-0 mt-2 w-96 origin-top-right rounded-md bg-uiDark p-4 shadow-lg focus:outline-none'
										}>
										<p className="text-sm text-white">{tooltip}</p>
									</Menu.Items>
								</Transition>
							</Menu>
						)}
					</div>
				</div>
				{(filter || sort || search) && (
					<div className="relative z-20 mt-5 mr-10 flex w-full flex-col justify-between lg:flex-row">
						<div className="flex gap-2.5">
							{filter}
							{sort}
						</div>
						{search && (
							<div className="mt-2.5 w-full lg:mt-0 lg:w-[300px]">
								{search}
							</div>
						)}
					</div>
				)}
				{children && (
					<div className="relative z-10 mt-5 w-full">{children}</div>
				)}
			</div>
			<div className="relative z-0 h-2 w-full bg-brandPrimary"></div>
		</div>
	);
};

export default PageHeading;
