// Get the date of a number of months before a given date
const monthsBefore = (date: Date, months: number): Date => {
	const newDate = new Date(date);
	newDate.setMonth(date.getMonth() - months);
	return newDate;
};

// Get the current date
const currentDate = new Date();

const convertDate = (date: Date): string => {
	const newDate = new Date(date);
	const day = newDate.getDate();
	const month = newDate.getMonth() + 1;
	let monthString = month.toString();
	if (month < 10) {
		monthString = `0${month}`;
	}
	const year = newDate.getFullYear();
	return `${year}-${monthString}-${day}`;
};

// Weeks before
const weeksBefore = (date: Date, weeks: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() - weeks * 7);
	return newDate;
};

// Days before
const daysBefore = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() - days);
	return newDate;
};

// Get time from data
const dateTime = (val: string, locales: string) => {
	const date = new Date(val);
	return new Intl.DateTimeFormat(locales, {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	}).format(date);
};

// Format date
const formatDate = (val: string, locales: string, type?: 'short' | 'long') => {
	const date = new Date(val);
	if (type === 'short') {
		return new Intl.DateTimeFormat(locales, {
			month: 'short',
			day: 'numeric',
		}).format(date);
	}

	if (type === 'long') {
		return new Intl.DateTimeFormat(locales, {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		}).format(date);
	}

	return new Intl.DateTimeFormat(locales, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	}).format(date);
};

// Format seconds
const formatSeconds = (secondsInp: number) => {
	const date = new Date(0);
	date.setSeconds(secondsInp);
	const timeString = date.toISOString();
	const timeStringParts = timeString.split('T')[1].split(':');
	const hours = parseInt(timeStringParts[0]);
	const minutes = parseInt(timeStringParts[1]);
	const seconds = parseInt(timeStringParts[2].split('.')[0]);

	let res = '';
	if (hours > 0) res += `${hours}h `;
	if (minutes > 0) res += `${minutes}m `;
	if (seconds >= 0) res += `${seconds}s`;
	return res;
};

// Check if date is between two dates
const isBetween = (date: Date, start: Date, end?: Date) => {
	// return date >= start && date <= end;
	if (end) {
		return date >= start && date <= end;
	}
	return date >= start;
};

// Get days between two dates
const daysBetween = (date1: Date, date2: Date) => {
	const diffTime = Math.abs(date2.getTime() - date1.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays;
};

const isBefore = (date1: Date, date2: Date) => {
	return date1.getTime() < date2.getTime();
};

// ------------------------------------------------------------
// Exports
const exportObj = {
	monthsBefore,
	currentDate,
	convertDate,
	weeksBefore,
	daysBefore,
	dateTime,
	formatDate,
	formatSeconds,
	isBetween,
	daysBetween,
	isBefore,
};

export default exportObj;
