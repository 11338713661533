import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	organisationId: number;
	include: {
		course: boolean;
		user: boolean;
	};
	filters?: Array<{
		key: 'user_id' | 'completed' | 'course_id' | 'updated_at_from';
		value: string | number | 1 | 0;
	}>;
	sort?: {
		created_at: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<OrgCourseProgressRes[]>>(
		`/organisations/${
			props.organisationId
		}/course-progress${buildQueryString(
			props.queryString,
			[
				{ key: 'course', include: props.include.course },
				{ key: 'user', include: props.include.user },
			],
			props.filters,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
