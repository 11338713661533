import getMultiple from './get-multiple';
import createSingle from './create-single';
import getSingle from './get-single';
import deleteSingle from './delete-single';
import updateSingle from './update-single';
import updateSingleImages from './update-single-images';
import copySingle from './copy-single';

const actions = {
	getMultiple,
	createSingle,
	getSingle,
	deleteSingle,
	updateSingle,
	updateSingleImages,
	copySingle,
};

export default actions;
