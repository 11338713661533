import { http } from '@utils/index';

interface Props {
	id: number;
	userId: number;
	body: {
		role_ids?: number[];
		seated?: 1 | 0;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaUsersRes>>(
		`/sa/organisations/${props.id}/users/${props.userId}`,
		{
			...props.body,
			_method: 'put',
		}
	);
};
export default updateSingle;
