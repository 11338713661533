import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface DeleteUserProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	userId: number;
	onSuccess: () => void;
}

const DeleteUser: React.FC<DeleteUserProps> = ({
	open,
	setOpen,
	userId,
	onSuccess,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const deleteUser = useMutation(api.sa.users.deleteSingle, {
		onSuccess: () => {
			// invalidate organisations
			queryClient.invalidateQueries(['sa.users.getMultiple']);
			queryClient.invalidateQueries(['sa.users.getMultiple.org']);
			// toast
			addToast({
				title: translate('toast_deleted_title', {
					name: 'User',
				}),
				message: translate('toast_deleted_message', {
					name: 'User',
				}),
				type: 'success',
			});
			// cb
			onSuccess();
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await deleteUser.mutateAsync({
			id: userId,
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onClose={() => {
				setErrorMsg('');
				deleteUser.reset();
			}}
			title={T.modals.delete_user.title}
			onConfirm={onConfirm}
			type={'danger'}
			formAction={{
				loading: {
					is: deleteUser.isLoading,
					message: translate('deleting'),
				},
				error: {
					is: deleteUser.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>{T.modals.delete_user.body}</p>
			</div>
		</ConfirmationModal>
	);
};

export default DeleteUser;
