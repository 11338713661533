import { http } from '@utils/index';

export interface Props {
	body: {
		name: string;
		email: string;
		organisations?: Array<{
			id: number;
			role_ids: number[];
			seated: 1 | 0;
		}>;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaUsersRes[]>>(`/sa/users`, {
		...props.body,
	});
};
export default createSingle;
