import classNames from 'classnames';
// Components
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';

interface ModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	title?: string;
	description?: string;
	size?: 'small' | 'medium' | 'large';
	isLoading?: boolean;
	isError?: boolean;
	children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
	open,
	setOpen,
	title,
	description,
	isLoading = false,
	isError = false,
	children,
	size = 'medium',
}) => {
	if (!open) return null;
	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				onClose={() => setOpen(false)}
				className="relative z-50 cursor-pointer">
				{/* The backdrop, rendered as a fixed sibling to the panel container */}
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div
						className="fixed inset-0 bg-black/30 "
						aria-hidden="true"
					/>
				</Transition.Child>

				{/* Full-screen container to center the panel */}
				<div className="fixed inset-0 flex items-center justify-center overflow-y-auto p-4">
					{/* The actual dialog panel  */}
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95">
						<Dialog.Panel
							className={classNames(
								'relative m-auto w-full cursor-auto rounded-md bg-white p-5',
								{
									'max-w-2xl': size === 'small',
									'max-w-3xl': size === 'medium',
									'max-w-5xl': size === 'large',
								}
							)}>
							<button
								className="absolute top-5 right-5 z-50 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full"
								onClick={() => setOpen(false)}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5 text-red"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							{title && (
								<Dialog.Title className={'text-xl font-bold'}>
									{title}
								</Dialog.Title>
							)}
							{description && (
								<p className={'mt-1 text-sm text-body'}>
									{description}
								</p>
							)}
							<div
								className={classNames('relative', {
									'mt-3 border-t border-border pt-3':
										title || description,
								})}>
								{isLoading && (
									<div className="absolute inset-0 z-10 bg-white bg-opacity-50">
										<Loading type={'fill'} />
									</div>
								)}
								{isError && (
									<div className="absolute inset-0">
										<Error type={'fill'} />
									</div>
								)}
								<div>{children}</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
