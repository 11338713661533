import { http } from '@utils/index';

interface Props {
	id: number;
}

const deleteSingle = (props: Props) => {
	return http.delete<APIResponse<SaUsersRes>>(`/sa/users/${props.id}`);
};
export default deleteSingle;
