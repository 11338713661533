import { http, buildQueryString } from '@utils/index';

export interface Props {
	queryString?: string;
	include: {
		user: boolean;
		value: boolean;
	};
	filters?: Array<{
		key: 'organisation_id';
		value: string | number | Array<string | number>;
	}>;
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SeachesRes[]>>(
		`/searches${buildQueryString(
			props.queryString,
			[
				{ key: 'user', include: props.include.user },
				{ key: 'value', include: props.include.value },
			],
			props.filters,
			props.perPage
		)}&sort=-created_at`
	);
};
export default getMultiple;
