import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Components
import Date from '@components/partials/Date';

interface DateTimeColumnProps {
	date?: string | null;
}

const DateTimeColumn: React.FC<DateTimeColumnProps> = ({ date }) => {
	if (!date) return null;

	return (
		<div className="flex items-center">
			<FontAwesomeIcon
				className="text-brandTertiary"
				icon={faCalendarAlt}
			/>
			<div className="ml-2 flex flex-col">
				<Date date={date} />
			</div>
		</div>
	);
};

export default DateTimeColumn;
