import { useQuery } from '@tanstack/react-query';
import api from 'api';
// Hooks
import { useAuth, useOrganisation } from '@hooks/index';

// -------------------------------------------------
// State / Hooks

// -------------------------------------------------
// Query Hooks

interface useAuthenticatedUserProps {
	onSuccess?: (data: any) => void;
	keys?: any[];
	enabled?: boolean;
}

let syncTimeout: NodeJS.Timeout | undefined;

export const useAuthenticatedUser = (props?: useAuthenticatedUserProps) => {
	const { auth, setUserId } = useAuth();
	const { syncOrganisation } = useOrganisation();

	return useQuery(
		['root.getAuthenticatedUser', ...(props?.keys || [])],
		() => {
			return api.root.getAuthenticatedUser({
				include: {
					organisationsCount: true,
				},
			});
		},
		{
			enabled: !!auth && props?.enabled,
			onSettled: (data) => {
				if (!data) return;
				setUserId(data.data.data.id);

				// Sync organisation
				if (syncTimeout) clearTimeout(syncTimeout);
				syncTimeout = setTimeout(() => {
					syncOrganisation(data.data.data);
				}, 1);
				if (props?.onSuccess) props.onSuccess(data);
			},
		}
	);
};
