import { http } from '@utils/index';

interface Props {
	organisationId: number;
	body: {
		name: string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<TeamRes>>(
		`/organisations/${props.organisationId}/teams`,
		{
			...props.body,
		}
	);
};
export default createSingle;
