// Components
import ContentRow from '@components/partials/ContentRow';

interface StatGridProps {
	title?: string;
	children: React.ReactNode;
}

const StatGrid: React.FC<StatGridProps> = ({ title, children }) => {
	return (
		<ContentRow title={title}>
			<dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
				{children}
			</dl>
		</ContentRow>
	);
};

export default StatGrid;
