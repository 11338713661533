import { http, buildQueryString } from '@utils/index';

interface Props {
	id: number | string;
	include: {
		type: boolean;
		typeCount: boolean;
		media: boolean;
		mediaCount: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaQuizzesRes>>(
		`/sa/quizzes/${props.id}${buildQueryString(undefined, [
			{
				key: 'type',
				include: props.include.type,
			},
			{
				key: 'typeCount',
				include: props.include.typeCount,
			},
			{
				key: 'media',
				include: props.include.media,
			},
			{
				key: 'mediaCount',
				include: props.include.mediaCount,
			},
		])}`
	);
};
export default getSingle;
