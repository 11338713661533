import { http } from '@utils/index';

interface Props {
	id?: number | string;
	slug?: string;
}

interface ResponseData {
	logo_thumbnail: string;
	meta: {
		colours: {
			header_background: string;
			primary_accent: string;
			secondary_accent: string;
			tertiary_accent: string;
		};
	};
}

const getThemes = (props: Props) => {
	return http.get<APIResponse<ResponseData>>(
		`/public/themes/${props.id || props.slug}`
	);
};
export default getThemes;
