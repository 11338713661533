// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
// Assets
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Hooks
import { usePermissions, useOrganisation } from '@hooks/index';

interface VideoImageTitleColumnProps {
	thumbnail?: string;
	title: string;
	id: number;
	courseId?: number;
}

const VideoImageTitleColumn: React.FC<VideoImageTitleColumnProps> = ({
	thumbnail,
	title,
	id,
	courseId,
}) => {
	// -------------------------------------------------
	// Hooks
	const { viewCourses } = usePermissions();
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Render
	return (
		<div className="flex items-center">
			<div className="relative mr-2.5 w-16 after:block after:pt-[56%]">
				<div className="absolute inset-0">
					<img
						src={thumbnail || placeholderVideo}
						className="block h-full w-full rounded-md object-cover"
						alt={title}
						loading="lazy"
					/>
				</div>
			</div>

			{viewCourses && courseId !== undefined ? (
				<Link
					to={`/${organisation?.slug}/courses/${courseId}/video/${id}}`}
					className="group text-sm font-medium text-title">
					{title}
					<FontAwesomeIcon
						icon={faLink}
						className="ml-1.5 text-brandPrimary group-hover:text-brandPrimaryHover"
					/>
				</Link>
			) : (
				<span className="text-sm font-medium text-title">{title}</span>
			)}
		</div>
	);
};

export default VideoImageTitleColumn;
