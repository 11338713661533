import { useMemo } from 'react';
import classNames from 'classnames';
// Components
// import CategoryTypeIcon from '@components/partials/CategoryTypeIcon';
import CategoryIcon from '@components/partials/CategoryIcon';

interface CourseBreakdownIconsProps {
	size: 'xs' | 'sm';
	items: Array<ChapterCourseItem | VideoCourseItem | QuizCourseItem>;
	categories: SaCategoriesRes[] | CategoriesRes[];
}

const CourseBreakdownIcons: React.FC<CourseBreakdownIconsProps> = ({
	size,
	items,
	categories,
}) => {
	// -------------------------------------------------
	// Memos
	const itemCatGroups = useMemo(() => {
		// group unique items by their category, get the colour of the category and the total number of items in that category
		const uniqueItems: Array<{
			category_id: number;
			scope: 'quizType' | 'videoType';
			colour: string;
			initials: string;
			label: string;
			total: number;
		}> = [];

		// loop through all items
		items.forEach((item) => {
			// if the item is a chapter, skip it
			if (item.model_type === 'chapter') return;

			// get the category id
			const category_id = item.model.type_id || 0;

			// check if the category already exists in the array
			const categoryExists = uniqueItems.find(
				(uniqueItem) => uniqueItem.category_id === category_id
			);

			// if the category doesn't exist, add it to the array
			const category = categories.find(
				(category) => category.id === category_id
			);

			if (!categoryExists) {
				uniqueItems.push({
					category_id,
					scope:
						item.model_type === 'quiz' ? 'quizType' : 'videoType',
					colour: category?.meta?.icon_colour as string,
					initials: category?.meta?.icon_initials as string,
					label: category?.name as string,
					total: 1,
				});
			} else {
				// if the category already exists, increment the total
				categoryExists.total += 1;
			}
		});

		return uniqueItems;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, categories]);

	// -------------------------------------------------
	// Render
	return (
		<ul
			className={classNames('flex flex-wrap', {
				'gap-3': size === 'sm',
				'gap-2': size === 'xs',
			})}>
			{itemCatGroups.map((group, index) => (
				<li className={'group relative flex items-center'} key={index}>
					<span className={'mr-1'}>
						<CategoryIcon
							colour={group.colour}
							initials={group.initials}
							categoryScope={group.scope}
							label={group.label}
							tooltip={true}
							size={size}
						/>
					</span>
					<span
						className={classNames('text-body', {
							'text-xs leading-3': size === 'sm',
						})}>
						{group.total}
					</span>
				</li>
			))}
		</ul>
	);
};

export default CourseBreakdownIcons;
