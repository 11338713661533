import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// Assets
import bookmarksSVG from '@assets/images/svgs/bookmarks.svg';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import ContentRow from '@components/partials/ContentRow';
import GridContainer from '@components/blocks/GridContainer';
import TabList from '@components/blocks/TabList';
// Cards
import VideoCard from '@components/cards/VideoCard';
import QuizCard from '@components/cards/QuizCard';
import CourseCard from '@components/cards/CourseCard';

interface BookmarksListProps {
	categories: CategoriesRes[];
	permission: boolean;
}

const BookmarksList: React.FC<BookmarksListProps> = ({
	categories,
	permission,
}) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();
	const [active, setActive] = useState('video');

	// -------------------------------------------------
	// Queries & Mutations
	const bookmarks = useQuery(
		['organisations.bookmarks.getMultiple', active, organisation?.id],
		() => {
			return api.organisations.bookmarks.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					bookmarkable: true,
					course: true,
					courseMedia: true,
					bookmarkableMedia: true,
				},
				filters: [
					{
						key: 'bookmarkable_type',
						value: active,
					},
				],
				perPage: 4,
			});
		},
		{
			enabled: !!organisation?.id && permission,
		}
	);

	// -------------------------------------------------
	// Render
	if (!permission) return null;

	return (
		<ContentRow
			title={translate('bookmarks')}
			href={`/${organisation?.slug}/bookmarks`}>
			{/* Navigation */}
			<TabList
				active={active}
				setActive={setActive}
				options={[
					{
						label: 'Videos',
						value: 'video',
					},
					{
						label: 'Quizzes',
						value: 'quiz',
					},
					{
						label: 'Courses',
						value: 'course',
					},
				]}
			/>
			{/* List */}
			<GridContainer
				state={{
					loading: bookmarks.isLoading,
					error: bookmarks.isError,
					noData: bookmarks.data?.data.data.length === 0,
					loadingCards: 4,
				}}
				noData={{
					title: 'No Bookmarks Found',
					message: 'You have not bookmarked any content yet.',
					image: bookmarksSVG,
				}}>
				{bookmarks.data?.data.data.map((bookmark, index) => {
					if (bookmark.bookmarkable_type === 'video') {
						return (
							<VideoCard
								key={index}
								video={bookmark.bookmarkable as VideosRes}
								courseId={bookmark.course?.id}
								bookmarks={bookmarks.data?.data.data}
								functions={{
									refetchBookmarks: bookmarks.refetch,
								}}
							/>
						);
					}

					if (bookmark.bookmarkable_type === 'quiz') {
						return (
							<QuizCard
								key={index}
								quiz={bookmark.bookmarkable as QuizzesRes}
								course={bookmark.course}
								bookmarks={bookmarks.data?.data.data}
								functions={{
									refetchBookmarks: bookmarks.refetch,
								}}
							/>
						);
					}

					if (bookmark.bookmarkable_type === 'course') {
						return (
							<CourseCard
								key={index}
								course={bookmark.course as CourseRes}
								organisationSlug={organisation?.slug as string}
								bookmarks={bookmarks.data?.data.data}
								categories={categories}
								courseProgress={[]}
								functions={{
									refetchBookmarks: bookmarks.refetch,
								}}
							/>
						);
					}

					return null;
				})}
			</GridContainer>
		</ContentRow>
	);
};

export default BookmarksList;
