import { http } from '@utils/index';

interface Props {
	organisationId: number;
	body: {
		email: string;
		role_id: number;
	};
}

const create = (props: Props) => {
	return http.post<APIResponse<TeamRes>>(
		`/sa/organisations/${props.organisationId}/user-invites`,
		{
			email: props.body.email,
			role_id: props.body.role_id,
		}
	);
};
export default create;
