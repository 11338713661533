import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	sort?: {
		results_count: 'asc' | 'desc';
	};
	perPage?: number;
}

const quizzes = (props: Props) => {
	return http.get<APIResponse<StatisticsQuizRes[]>>(
		`/sa/statistics/quizzes${buildQueryString(
			props.queryString,
			undefined,
			undefined,
			props.perPage,
			props.sort
		)}`
	);
};
export default quizzes;
