import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import AwardsTable from '@components/table/sa/AwardsTable';
import Button from '@components/partials/Button';
import Search from '@components/query/Search';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
import Sort from '@components/query/Sort';
// Modals
import CreateAward from '@components/modal/CreateAward';
import UtilityRow from '@components/blocks/UtilityRow';

const AwardsList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'award_type_id',
					value: '',
					type: 'select',
				},
				{
					key: 'active',
					value: '1',
					type: 'boolean',
				},
			],
		});
	const [openCreate, setOpenCreate] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const awardTypes = useQuery(
		['auth.awards.getAwardTypes'],
		api.auth.awards.getAwardTypes
	);

	// -----------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('awards')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'award_type_id',
								label: translate('award_type'),
								values: awardTypes.data?.data.data.map(
									(type) => {
										return {
											label: `${type.name} - ${type.id}`,
											value: type.id.toString(),
										};
									}
								),
								allowAll: true,
							},
							{
								key: 'active',
								label: translate('active'),
							},
						]}
						loading={awardTypes.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<UtilityRow>
					<div className="space-x-2.5">
						<Button
							theme={'primary'}
							type="button"
							onClick={() => setOpenCreate(true)}>
							{translate('create_award')}
						</Button>
						<Button
							theme={'outline'}
							type="link"
							href="/options#certificate-customisations">
							{translate('certificate_customisations')}
						</Button>
					</div>
				</UtilityRow>
				<AwardsTable
					enabled={enabled}
					awardTypes={awardTypes.data?.data.data || []}
					queryString={queryString}
					searchParams={searchParams}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Archived',
									filters: [
										{
											key: 'active',
											value: '0',
										},
									],
								},
								{
									label: 'Certificates',
									filters: [
										{
											key: 'award_type_id',
											value: '2',
										},
									],
								},
								{
									label: 'Badges',
									filters: [
										{
											key: 'award_type_id',
											value: '1',
										},
									],
								},
							]}
						/>
					}
				/>
				<CreateAward open={openCreate} setOpen={setOpenCreate} />
			</PageWrapper>
		</>
	);
};

export default AwardsList;
