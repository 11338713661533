import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const ConfettiBackground: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const [confettiInterval, setConfettiInterval] = useState<ReturnType<
		typeof setInterval
	> | null>(null);

	// Refs
	const refAnimationInstance = useRef<confetti.CreateTypes | null>(null);

	// -------------------------------------------------
	// Functions
	const getInstance = useCallback((instance: confetti.CreateTypes | null) => {
		refAnimationInstance.current = instance;
	}, []);

	const makeShot = useCallback((particleRatio: any, opts: any) => {
		refAnimationInstance.current &&
			refAnimationInstance.current({
				...opts,
				origin: { y: 0.7 },
				particleCount: Math.floor(200 * particleRatio),
			});
	}, []);

	const fire = useCallback(() => {
		makeShot(0.25, {
			spread: 26,
			startVelocity: 55,
		});

		makeShot(0.2, {
			spread: 60,
		});

		makeShot(0.35, {
			spread: 100,
			decay: 0.91,
			scalar: 0.8,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 25,
			decay: 0.92,
			scalar: 1.2,
		});

		makeShot(0.1, {
			spread: 120,
			startVelocity: 45,
		});
	}, [makeShot]);

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		fire();
		if (confettiInterval) {
			clearInterval(confettiInterval);
		}
		setConfettiInterval(
			setInterval(() => {
				fire();
			}, 3000)
		);

		return () => {
			if (confettiInterval) {
				clearInterval(confettiInterval);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// -------------------------------------------------
	// Render
	return (
		<div className="fixed inset-0 z-50">
			<ReactCanvasConfetti
				refConfetti={getInstance}
				style={{
					position: 'absolute',
					pointerEvents: 'none',
					width: '100%',
					height: '100%',
					top: 0,
					left: 0,
					zIndex: 2,
				}}
			/>
		</div>
	);
};

export default ConfettiBackground;
