import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	id: number;
	include: {
		courses: boolean;
		coursesCount: boolean;
		users: boolean;
		usersCount: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<TeamRes>>(
		`/organisations/${props.organisationId}/teams/${
			props.id
		}${buildQueryString(undefined, [
			{ key: 'courses', include: props.include.courses },
			{ key: 'coursesCount', include: props.include.coursesCount },
			{ key: 'users', include: props.include.users },
			{ key: 'usersCount', include: props.include.usersCount },
		])}`
	);
};
export default getSingle;
