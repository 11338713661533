import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/useOrganisation';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import UserAwardsRow from '@components/rows/UserAwardsRow';
import TopPagination from '@components/query/FormUtilityBar';

interface UserAwardsTableProps extends QueryTableProps {
	mode: 'sa' | 'organisation';
	awardTypes: AwardTypesRes[];
	utilityBar?: React.ReactNode;
}

const UserAwardsTable: React.FC<UserAwardsTableProps> = ({
	mode,
	searchParams,
	queryString,
	awardTypes,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	type SaUserAwardsResT = AxiosResponse<APIResponse<SaUserAwardsRes[]>, any>;
	type OrgUserAwardsResT = AxiosResponse<
		APIResponse<OrgUserAwardsRes[]>,
		any
	>;

	const useAwards = useQuery<SaUserAwardsResT | OrgUserAwardsResT>(
		[
			mode === 'sa'
				? 'sa.userAwards.getMultiple'
				: 'organisations.userAwards.getMultiple',
			queryString,
			organisation?.id,
		],
		() => {
			if (mode === 'sa') {
				return api.sa.userAwards.getMultiple({
					queryString: queryString,
					include: {
						award: true,
						user: true,
						organisation: true,
					},
				});
			} else {
				return api.organisations.userAwards.getMultiple({
					queryString: queryString,
					organisationId: organisation?.id || 0,
					include: {
						award: true,
						user: true,
					},
				});
			}
		},
		{
			enabled: enabled,
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={useAwards.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: useAwards.isLoading,
					error: useAwards.isError,
					noData: useAwards.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Awards',
					}),
					message: translate('no_data_message', {
						name: 'awards',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('user'),
							type: 'standard',
						},
						{
							label: translate('organisation'),
							type: 'standard',
							hide: mode === 'organisation',
						},
						{
							label: translate('award'),
							type: 'standard',
						},
						{
							label: translate('download'),
							type: 'standard',
						},
						{
							label: translate('award_type'),
							type: 'standard',
						},
						{
							label: translate('earned_on'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
						},
					]}
					setSort={searchParams.sort.setValue}
					scrollX={true}>
					{useAwards.data?.data.data.map((award, index) => (
						<UserAwardsRow
							key={index}
							mode={mode}
							award={award}
							awardTypes={awardTypes}
						/>
					))}
				</Table>
				<Pagination
					meta={useAwards.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default UserAwardsTable;
