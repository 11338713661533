import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
	links: Array<{
		href: string;
		label: string;
	}>;
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ links }) => {
	return (
		<nav
			className="hidden sm:flex border-b border-border pb-3"
			aria-label="Breadcrumb">
			<ol className="flex items-center space-x-2">
				{links.map((link, index) => {
					return (
						<li key={index}>
							<div className="flex items-center">
								{index !== 0 && (
									<FontAwesomeIcon
										className="h-2 w-2 flex-shrink-0 text-body"
										aria-hidden="true"
										icon={faChevronRight}
									/>
								)}
								<Link
									to={link.href}
									className={classNames(
										' text-sm font-medium text-body hover:text-brandTertiary transition-colors duration-200',
										{
											'ml-2': index !== 0,
										}
									)}>
									{link.label}
								</Link>
							</div>
						</li>
					);
				})}
			</ol>
		</nav>
	);
};

export default Breadcrumbs;
