import { http, buildQueryString } from '@utils/index';

interface Props {
	id: string;
	include: {
		courses: boolean;
		coursesCount: boolean;
		media: boolean;
		mediaCount: boolean;
		plan: boolean;
		planCount: boolean;
		partnerCourseCategories: boolean;
		meta: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaOrganisationsRes>>(
		`/sa/organisations/${props.id}${buildQueryString(undefined, [
			{ key: 'courses', include: props.include.courses },
			{ key: 'coursesCount', include: props.include.coursesCount },
			{ key: 'media', include: props.include.media },
			{ key: 'mediaCount', include: props.include.mediaCount },
			{ key: 'plan', include: props.include.plan },
			{ key: 'planCount', include: props.include.planCount },
			{
				key: 'partnerCourseCategories',
				include: props.include.partnerCourseCategories,
			},
			{
				key: 'meta',
				include: props.include.meta,
			},
		])}`
	);
};
export default getSingle;
