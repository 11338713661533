import { translate } from '@lang/index';
// Assets
import videoSteamingIllustration from '@assets/images/svgs/video-streaming.svg';
// Components
import ContentRow from '@components/partials/ContentRow';
import GridContainer from '@components/blocks/GridContainer';
import VideoCard from '@components/cards/VideoCard';

interface LastViewedListProps {
	views: VideosViewsRes[];
	state: {
		isLoading: boolean;
		isError: boolean;
	};
	permission: boolean;
}

const LastViewedList: React.FC<LastViewedListProps> = ({
	views,
	state,
	permission,
}) => {
	// -------------------------------------------------
	// Render
	if (!permission) return null;

	return (
		<ContentRow title={translate('last_viewed')}>
			<GridContainer
				state={{
					loading: state.isLoading,
					error: state.isError,
					noData: views.length === 0,
					loadingCards: 4,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Views',
					}),
					message: translate('no_data_message', {
						name: 'views',
					}),
					image: videoSteamingIllustration,
				}}>
				{views.map((view, index) => (
					<VideoCard
						key={index}
						video={view.video as VideosRes}
						courseId={view.course_id}
					/>
				))}
			</GridContainer>
		</ContentRow>
	);
};

export default LastViewedList;
