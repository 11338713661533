import type { Props } from 'api/sa/users/get-multiple';
import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import UsersRow from '@components/rows/UsersRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import DeleteUser from '@components/modal/DeleteUser';
import SuspendUser from '@components/modal/SuspendUser';
import CreateUpdateUser from '@components/modal/CreateUpdateUser';

interface UsersTableProps extends QueryTableProps {
	filters: Props['filters'];
	utilityBar?: React.ReactNode;
}

const UsersTable: React.FC<UsersTableProps> = ({
	searchParams,
	queryString,
	filters,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// Queries / Mutations
	const { selectedRow, setSelectedRow } = useSelectedRow<SaUsersRes | null>(
		null
	);
	const users = useQuery(
		['sa.users.getMultiple', queryString],
		() => {
			return api.sa.users.getMultiple({
				queryString: queryString,
				filters: filters,
				include: {
					firstLogin: false,
					lastLogin: true,
					organisations: true,
					rolesIgnoringTeams: true,
				},
			});
		},
		{
			enabled: enabled,
			keepPreviousData: true,
		}
	);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);
	const [openSuspend, setOpenSuspend] = useState(false);
	const [openCreateUpdate, setOpenCreateUpdate] = useState(false);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={users.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: users.isLoading,
					error: users.isError,
					noData: users.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'User',
					}),
					message: translate('no_data_message', {
						name: 'user',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('organisations'),
							type: 'standard',
						},
						{
							label: translate('last_login'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'last_login'
							),
						},
						{
							label: translate('created'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{users.data?.data.data.map((user, index) => (
						<UsersRow
							key={index}
							user={user}
							selectRow={(user, action) => {
								setSelectedRow(user);
								switch (action) {
									case 'update': {
										setOpenCreateUpdate(true);
										break;
									}
									case 'delete': {
										setOpenDelete(true);
										break;
									}
									case 'suspend': {
										setOpenSuspend(true);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={users.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
			{/* Modals */}
			{selectedRow !== null && (
				<>
					<DeleteUser
						open={openDelete}
						setOpen={setOpenDelete}
						userId={selectedRow.id}
						onSuccess={() => {
							// searchParams.page.setValue(1);
						}}
					/>
					<SuspendUser
						open={openSuspend}
						setOpen={setOpenSuspend}
						userId={selectedRow.id}
						suspended={
							selectedRow.suspended_at === null ? false : true
						}
						onSuccess={() => {
							// searchParams.page.setValue(1);
						}}
					/>
					<CreateUpdateUser
						mode="update"
						open={openCreateUpdate}
						setOpen={setOpenCreateUpdate}
						userId={selectedRow.id}
						onSuccess={() => {
							// searchParams.page.setValue(1);
							// users.refetch();
						}}
					/>
				</>
			)}
		</>
	);
};

export default UsersTable;
