import { translate } from '@lang/index';
import { useMemo } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Badges from '@components/partials/Badges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface UserAwardsRowProps {
	mode: 'sa' | 'organisation';
	award: SaUserAwardsRes | OrgUserAwardsRes;
	awardTypes: AwardTypesRes[];
}

const UserAwardsRow: React.FC<UserAwardsRowProps> = ({
	mode,
	award,
	awardTypes,
}) => {
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Memos
	const awardType = useMemo(() => {
		return award.award.award_type_id
			? awardTypes.find(
					(awardType) => awardType.id === award.award.award_type_id
			  )
			: undefined;
	}, [award.award.award_type_id, awardTypes]);

	const certificateHref = useMemo(() => {
		if (mode === 'sa') {
			return api.sa.userAwards.downloadUserAward({
				awardId: award.id,
			});
		} else {
			return api.organisations.userAwards.downloadUserAward({
				organisationId: organisation?.id || 0,
				awardId: award.id,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [award.award_id]);

	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			<td className="td-default">
				<UserColumn name={award.user.name} email={award.user.email} />
			</td>
			{mode === 'sa' && (
				<td className="td-default">
					{(award as SaUserAwardsRes).organisation.name}
				</td>
			)}
			<td className="td-default">{award.award.name}</td>
			<td className="td-default">
				{awardType?.name === 'certificate' && (
					// Ignore noreferrer warning as this is a download link
					// eslint-disable-next-line
					<a
						className="text-sm transition-colors duration-200 hover:text-brandTertiaryHover hover:underline"
						href={certificateHref}
						target="_blank">
						<FontAwesomeIcon
							icon={faLink}
							className="mr-1 text-brandTertiary"
						/>
						{translate('download')}
					</a>
				)}
			</td>
			<td className="td-default">
				<Badges theme="green">{awardType?.name}</Badges>
			</td>
			<td className="td-default">
				<DateTimeColumn date={award.created_at || ''} />
			</td>
		</tr>
	);
};

export default UserAwardsRow;
