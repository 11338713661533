import { translate } from '@lang/index';
import { useState } from 'react';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
// Table
import UsersTable from '@components/table/sa/UsersTable';
// Modals
import CreateUpdateUser from '@components/modal/CreateUpdateUser';

const UserList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'email',
					value: 'off',
				},
				{
					key: 'last_login',
					value: 'desc',
				},
				{
					key: 'created_at',
					value: 'off',
				},
			],
			filter: [],
		});
	const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('users')}
				body={translate('users_body')}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
							{
								key: 'email',
								label: translate('email'),
							},
							{
								key: 'last_login',
								label: translate('last_login'),
							},
							{
								key: 'created_at',
								label: translate('created'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_user')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<UsersTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					filters={[
						/*
						{
							key: 'hide_self',
							value: 1,
						},
						*/
					]}
					utilityBar={
						<Button
							theme="primary"
							onClick={() => setCreateUserModalOpen(true)}
							type="button">
							{translate('create_user')}
						</Button>
					}
				/>
			</PageWrapper>
			<CreateUpdateUser
				mode="create"
				open={createUserModalOpen}
				setOpen={setCreateUserModalOpen}
			/>
		</>
	);
};
export default UserList;
