import { translate } from '@lang/index';
// Components
import Badges from '@components/partials/Badges';

interface OrgCourseRowProps {
	course: CourseRes;
}

const OrgCourseRow: React.FC<OrgCourseRowProps> = ({ course }) => {
	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			<td className="td-default">{course.title}</td>
			<td className="td-default">
				<Badges theme="grey">
					{course.course_type_id === 1
						? translate('course')
						: translate('content')}
				</Badges>
			</td>
			<td className="td-default">
				<Badges theme="grey">{course.progress_count}</Badges>
			</td>
		</tr>
	);
};

export default OrgCourseRow;
