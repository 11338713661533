import { http, createFormData } from '@utils/index';

interface Props {
	id: number;
	body: {
		image?: File | null;
	};
}

const updateSingleImages = (props: Props) => {
	return http.post<APIResponse<SaCourseRes>>(
		`/sa/courses/${props.id}`,
		createFormData([
			{
				key: '_method',
				value: 'put',
			},
			{
				key: 'image',
				value: props.body.image,
			},
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default updateSingleImages;
