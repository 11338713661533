import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// Assets
import certificatesSVG from '@assets/images/svgs/certificate.svg';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/useOrganisation';
// Components
import ContentRow from '@components/partials/ContentRow';
import CertificateRow from '@components/rows/CertificateRow';
import DynamicBlock from '@components/blocks/DynamicBlock';

interface CertificatesListProps {
	user: CurrentUserRes;
	permission: boolean;
}

const CertificatesList: React.FC<CertificatesListProps> = ({
	user,
	permission,
}) => {
	// -----------------------------
	// State
	const { organisation } = useOrganisation();

	// -----------------------------
	// Queries / Mutations
	const awards = useQuery(
		['organisations.userAwards.getMultiple', organisation?.id],
		() => {
			return api.organisations.userAwards.getMultiple({
				organisationId: organisation?.id,
				include: {
					user: true,
					award: true,
				},
				filters: {
					award_type_id: 2,
					user_id: user.id,
				},
				perPage: 5,
			});
		},
		{
			enabled: !!organisation?.id && permission,
		}
	);

	// -----------------------------
	// Render
	if (!permission) return null;

	return (
		<ContentRow
			title={translate('my_certificates')}
			href={`/${organisation?.slug}/awards?filter[award.award_type_id]=2`}>
			<DynamicBlock
				state={{
					loading: awards.isLoading,
					error: awards.isError,
					noData: awards.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Certificates',
					}),
					message: translate('no_data_message', {
						name: 'certificates',
					}),
					image: certificatesSVG,
				}}>
				<ul className="rounded-md border border-border">
					{awards.data?.data.data.map((award, index) => {
						return (
							<CertificateRow
								key={index}
								mode="list"
								certificate={award}
							/>
						);
					})}
				</ul>
			</DynamicBlock>
		</ContentRow>
	);
};

export default CertificatesList;
