import { useState } from 'react';
import classNames from 'classnames';
import {
	faTriangleExclamation,
	faTimes,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkWhite from '@assets/images/svgs/check-white.svg';
// Components
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Input from '@components/forms/Input';

interface MultiSelectProps {
	label?: string;
	id: string;
	name: string;
	values: Array<{
		key: string;
		label: string;
		data: any;
	}>;
	setValues: (
		state: Array<{
			key: string;
			label: string;
			data: any;
		}>
	) => void;
	options: Array<{
		key: string;
		label: string;
		data: any;
	}>;
	errors: {
		[key: string]: string[];
	};
	placeholder?: string;
	disabled?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
	label,
	id,
	name,
	values,
	setValues,
	options,
	errors = {},
	placeholder,
	disabled = false,
}) => {
	const [search, setSearch] = useState<string>('');

	return (
		<div className={'mb-4 flex w-full flex-col last:mb-0'}>
			{/* Label */}
			{label && (
				<label
					htmlFor={`multiselect-${id}`}
					className="mb-1 block text-body">
					{label}
				</label>
			)}
			<Popover className="relative w-full">
				{({ open }) => (
					<>
						{/* Container */}
						<Popover.Button
							type="button"
							className={
								'relative flex min-h-[40px] w-full flex-wrap items-center gap-1 rounded-md border border-border py-1.5 px-2.5 pr-12 focus:border-brandPrimary focus:ring-brandPrimary'
							}>
							{values.map((value, index) => (
								<span
									key={index}
									className={classNames(
										'mr-1 flex h-6 items-center rounded-md bg-brandPrimary pl-2 text-sm font-medium text-brandPrimaryText',
										{
											'bg-red !text-white':
												errors[`${name}.${index}`]
													?.length > 0,
										}
									)}>
									{value.label}
									<span
										className={classNames(
											'ml-2 flex h-6 items-center justify-center rounded-r-md bg-brandPrimaryHover px-2 text-brandPrimaryText',
											{
												'!bg-red !text-white':
													errors[`${name}.${index}`]
														?.length > 0,
												'!text-opacity-40': disabled,
											}
										)}
										onClick={(e) => {
											e.preventDefault();
											if (!disabled) {
												setValues(
													values.filter(
														(v, i) => i !== index
													)
												);
											}
										}}>
										<FontAwesomeIcon
											icon={faTimes}
											className="h-3"
										/>
									</span>
								</span>
							))}
							{values.length === 0 && (
								<span className="text-sm text-body text-opacity-60">
									{placeholder}
								</span>
							)}
							<span className="absolute top-1/2 right-1.5 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-md bg-uiDark">
								<FontAwesomeIcon
									icon={faChevronDown}
									className={classNames(
										'text-white transition-transform duration-200',
										{
											'rotate-180 transform': open,
										}
									)}
								/>
							</span>
						</Popover.Button>
						{/* Dropdown */}
						<Transition
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0">
							<Popover.Panel className="absolute z-10 mt-1  w-full overflow-hidden rounded-md bg-white text-base capitalize shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								<div className="w-full rounded-md border border-border bg-white shadow-lg">
									<div className="p-2 pb-1">
										<Input
											id={`multiselect-${id}`}
											disabled={disabled}
											name={'search'}
											type="search"
											placeholder="Search"
											value={search}
											setValue={setSearch}
											errors={[]}
											required={false}
											inputStyle={'text-sm h-[30px]'}
										/>
									</div>
									<div className="max-h-60 overflow-y-auto">
										{options
											.filter((option) =>
												option.label
													.toLowerCase()
													.includes(
														search.toLowerCase()
													)
											)
											.map((option, index) => (
												<button
													type="button"
													key={index}
													className="flex w-full items-center px-2 py-1 text-left text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
													onClick={() => {
														if (!disabled) {
															// If the option is already selected, remove it
															if (
																values.some(
																	(value) =>
																		value.key ===
																		option.key
																)
															) {
																setValues(
																	values.filter(
																		(
																			value
																		) =>
																			value.key !==
																			option.key
																	)
																);
															} else {
																setValues([
																	...values,
																	option,
																]);
															}

															setSearch('');
														}
													}}>
													<span
														className={classNames(
															'mr-2 flex h-5 w-5 items-center justify-center rounded',
															{
																'bg-brandSecondary text-white ':
																	values.some(
																		(
																			value
																		) =>
																			value.key ===
																			option.key
																	),
																'border border-border bg-white text-black':
																	!values.some(
																		(
																			value
																		) =>
																			value.key ===
																			option.key
																	),
															}
														)}>
														<img
															src={checkWhite}
															alt="check"
															className="h-3 w-3"
															loading="lazy"
														/>
													</span>
													{option.label}
												</button>
											))}
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
			{/* Errors */}
			{errors[name]?.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[name][0]}</p>
				</div>
			)}
		</div>
	);
};
export default MultiSelect;
