import { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
	faRotateLeft,
	faTimes,
	faTriangleExclamation,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '@components/partials/Loading';

interface SingleImageUploadProps {
	label?: string;
	id: string;
	name: string;
	errors: Array<string> | undefined;
	required: boolean;
	formats: Array<string>;
	current?: string;
	value: File | undefined | null;
	setValue: (file: File | undefined | null) => void;
	describedBy?: string;
	onUpload?: (file: File) => void;
	uploading?: boolean;
	showActionButtons?: boolean;
}

const SingleImageUpload: React.FC<SingleImageUploadProps> = ({
	label,
	id,
	name,
	errors = [],
	required,
	current,
	formats,
	value,
	setValue,
	describedBy,
	onUpload,
	uploading,
	showActionButtons = true,
}) => {
	const [hover, setHover] = useState(false);
	const fileInput = useRef<HTMLInputElement>(null);

	// -----------------------------------------
	// Memos
	const showDeleteButton = useMemo(() => {
		if (value instanceof File) {
			return true;
		} else if (value === undefined && current) {
			return true;
		}
		return false;
	}, [value, current]);

	const showBlankImage = useMemo(() => {
		if (value === null) {
			return true;
		} else if (value === undefined && !current) {
			return true;
		}
		return false;
	}, [value, current]);

	const showResetButton = useMemo(() => {
		return value === null && current;
	}, [value, current]);

	// -----------------------------------------
	// Render
	return (
		<div className={classNames('mb-4 flex flex-col last:mb-0')}>
			{label && (
				<label htmlFor={id} className="mb-1 block text-body">
					{label}
				</label>
			)}
			<div
				className={classNames(
					'group relative flex h-52 w-full flex-col items-center justify-center rounded-md border border-border bg-uiLight',
					{
						'border-uiRed': errors.length > 0,
						'bg-uiGray bg-opacity-40': hover,
						'pb-8': value === undefined && current,
					}
				)}
				role={'button'}
				onClick={() => {
					if (fileInput.current) {
						fileInput.current.click();
					}
				}}
				onDrop={(e) => {
					e.preventDefault();
					if (e.dataTransfer.files) {
						const file = e.dataTransfer.files[0];
						if (formats.includes(file.type)) {
							setValue(file);
							if (fileInput.current) {
								fileInput.current.value = '';
								fileInput.current.files = e.dataTransfer.files;
							}
							if (onUpload) {
								onUpload(file);
							}
						}
					}
					setHover(false);
				}}
				onDragOver={(e) => {
					e.preventDefault();
					setHover(true);
				}}
				onDragLeave={(e) => {
					e.preventDefault();
					setHover(false);
				}}
				onDragEnter={(e) => {
					e.preventDefault();
					setHover(true);
				}}>
				<input
					ref={fileInput}
					type="file"
					id={id}
					name={name}
					className="hidden"
					accept={
						formats.length > 0
							? formats.map((format) => `${format}`).join(',')
							: ''
					}
					multiple={false}
					onChange={(e) => {
						if (e.target.files) {
							setValue(e.target.files[0]);
							if (fileInput.current) {
								fileInput.current.value = '';
								fileInput.current.files = e.target.files;
							}
							if (onUpload) {
								onUpload(e.target.files[0]);
							}
						}
					}}
					required={required}
				/>
				{/* Image Container */}
				<div
					className={
						'relative max-h-[128px] max-w-[200px] rounded-md border border-border bg-uiDark bg-opacity-10'
					}>
					{/* image */}
					{current && value === undefined && (
						<img
							src={current}
							alt="preview"
							className="h-full rounded-md object-contain"
						/>
					)}
					{value && (
						<img
							src={URL.createObjectURL(value)}
							alt="preview"
							className="h-full rounded-md object-contain"
						/>
					)}
					{showBlankImage && (
						<div className="h-32 w-32  rounded-md bg-uiDark bg-opacity-10"></div>
					)}
					<span className="absolute inset-0 flex items-center justify-center rounded-md bg-uiDark bg-opacity-60 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
						<FontAwesomeIcon
							icon={faUpload}
							className="h-5 w-5 text-white"
						/>
					</span>
					{uploading && (
						<span className="absolute inset-0 bg-white bg-opacity-70">
							<Loading type="fill" />
						</span>
					)}
				</div>
				{/* Preview Button */}
				{value === undefined && current && (
					// eslint-disable-next-line react/jsx-no-target-blank
					<a
						href={current}
						target="_blank"
						className="absolute bottom-0 left-0 right-0 flex h-8 items-center justify-center rounded-b-md bg-uiGray text-sm text-body transition-colors duration-200 hover:bg-brandTertiaryHover hover:text-brandTertiaryText"
						onClick={(e) => {
							e.stopPropagation();
						}}>
						Preview
					</a>
				)}
				{/* Reset/Delete */}
				{showActionButtons && (
					<div className="absolute right-2.5 top-2.5">
						{showDeleteButton && (
							<button
								type="button"
								className="flex h-6 w-6 items-center justify-center rounded-full bg-red transition-colors duration-200 hover:bg-redHover"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setValue(null);
								}}>
								<FontAwesomeIcon
									icon={faTimes}
									className="h-3 w-3 text-white"
								/>
							</button>
						)}
						{showResetButton && (
							<button
								type="button"
								className="hover:bg-brandTertiaryH flex h-6 w-6 items-center justify-center rounded-full bg-brandTertiary text-brandTertiaryText transition-colors duration-200"
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setValue(undefined);
								}}>
								<FontAwesomeIcon
									icon={faRotateLeft}
									className="h-3 w-3 text-white"
								/>
							</button>
						)}
					</div>
				)}
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="mt-1 text-sm text-body text-opacity-60">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};

export default SingleImageUpload;
