import organisations from './organisations';
import root from './root';
import sa from './sa';
import auth from './auth';
import publicRoutes from './public';

const actions = {
	organisations,
	root,
	sa,
	auth,
	public: publicRoutes,
};

export default actions;
