import { translate } from '@lang/index';
import { useMemo } from 'react';
import classNames from 'classnames';
import {
	faChair,
	faUserShield,
	faUser,
	faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import { Link } from 'react-router-dom';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface UsersRowProps {
	user: SaUsersRes;
	selectRow: (
		course: SaUsersRes,
		action: 'delete' | 'update' | 'suspend'
	) => void;
}

const UsersRow: React.FC<UsersRowProps> = ({ user, selectRow }) => {
	return (
		<tr>
			<td className="td-default">
				<UserColumn
					name={user.name}
					email={user.email}
					email_verified_at={user.email_verified_at}
					suspended_at={user.suspended_at}
				/>
			</td>
			<td className="td-default">
				<ul>
					{user.organisations?.map((org, index) => (
						<UserOrgRow
							key={index}
							org={org}
							roles={user.roles || []}
						/>
					))}
				</ul>
			</td>
			<td className="td-default">
				{user.last_login?.created_at ? (
					<>
						<DateTimeColumn
							date={user.last_login?.created_at || ''}
						/>
						<Link
							to={`/logins?sort=-created_at&filter[user_id]=${user.id}`}
							className="mt-1 block text-sm hover:underline">
							View all login history
						</Link>
					</>
				) : (
					<span>-</span>
				)}
			</td>
			<td className="td-default">
				<DateTimeColumn date={user.created_at || ''} />
			</td>
			<DropdownMenuColumn
				showFirst={true}
				items={[
					{
						label: translate('update'),
						type: 'button',
						onClick: () => {
							selectRow(user, 'update');
						},
						show: true,
					},
					{
						label: translate('progress'),
						type: 'link',
						href: `/progress?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('video_views'),
						type: 'link',
						href: `/video-views?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('assessment_results'),
						type: 'link',
						href: `/assessment-results?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('awards'),
						type: 'link',
						href: `/user-awards?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('logins'),
						type: 'link',
						href: `/logins?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label:
							user.suspended_at === null
								? translate('suspend')
								: translate('unsuspend'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(user, 'suspend');
						},
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(user, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

const UserOrgRow: React.FC<{ org: SaOrganisationsRes; roles: RoleRes[] }> = ({
	org,
	roles,
}) => {
	// -------------------------------------------------
	// Memos
	const isAdmin = useMemo(() => {
		return (
			roles.find(
				(role) =>
					role.organisation_id === org.id && role.name === 'admin'
			) !== undefined
		);
	}, [roles, org]);

	const isStandard = useMemo(() => {
		return (
			roles.find(
				(role) =>
					role.organisation_id === org.id && role.name === 'standard'
			) !== undefined
		);
	}, [roles, org]);

	// -------------------------------------------------
	// Render
	return (
		<li className="flex items-center">
			<FontAwesomeIcon
				title="Plan"
				icon={faRectangleList}
				className={classNames('mr-4 text-sm', {
					'text-brandTertiaryHover': org.plan_id !== null,
					'text-icon': org.plan_id === null,
				})}
			/>
			<FontAwesomeIcon
				title={
					org.seated_at !== null
						? translate('seated')
						: translate('not_seated')
				}
				icon={faChair}
				className={classNames('mr-2 text-sm', {
					'text-brandTertiaryHover': org.seated_at !== null,
					'text-icon': org.seated_at === null,
				})}
			/>
			<FontAwesomeIcon
				title="Admin user"
				icon={faUserShield}
				className={classNames('mr-2 text-sm', {
					'text-brandTertiaryHover': isAdmin,
					'text-icon': !isAdmin,
				})}
			/>
			<FontAwesomeIcon
				title="Standard user"
				icon={faUser}
				className={classNames('mr-2 text-sm', {
					'text-brandTertiaryHover': isStandard,
					'text-icon': !isStandard,
				})}
			/>
			<Link
				to={`/organisations/${org.id}`}
				className="block max-w-[200px] overflow-hidden text-ellipsis text-sm text-title hover:underline">
				{org.name}
			</Link>
		</li>
	);
};

export default UsersRow;
