// Components
import Badges from '@components/partials/Badges';
import VideoImageTitleColumn from '@components/table-columns/VideoImageTitleColumn';

interface OrgVideoViewRowProps {
	video: VideosRes;
}

const OrgVideoViewRow: React.FC<OrgVideoViewRowProps> = ({ video }) => {
	return (
		<tr className="relative h-[65px] items-center">
			<td className="td-default">
				<VideoImageTitleColumn
					thumbnail={video.thumbnail || undefined}
					title={video.title}
					id={video.id}
				/>
			</td>
			<td className="td-default">
				<span className="font-semibold">
					<Badges theme="grey">{video.views_count}</Badges>
				</span>
			</td>
		</tr>
	);
};

export default OrgVideoViewRow;
