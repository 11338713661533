import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
// api
import api from 'api';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import VideoViewRow from '@components/rows/VideoViewRow';
import TopPagination from '@components/query/FormUtilityBar';

interface VideoViewTableProps extends QueryTableProps {
	mode: 'sa' | 'organisation';
	utilityBar?: React.ReactNode;
	isSuperadmin?: boolean;
}

const VideoViewTable: React.FC<VideoViewTableProps> = ({
	mode,
	searchParams,
	queryString,
	utilityBar,
	resetFilters,
	enabled,
	isSuperadmin,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Queries / Mutations
	type SaVideoViewsResT = AxiosResponse<APIResponse<SaVideoViewsRes[]>, any>;
	type OrgVideoViewsResT = AxiosResponse<
		APIResponse<OrgVideoViewsRes[]>,
		any
	>;

	const videoViews = useQuery<SaVideoViewsResT | OrgVideoViewsResT>(
		[
			mode === 'sa'
				? 'sa.videoViews.getMultiple'
				: 'organisations.videoViews.getMultiple',
			queryString,
			organisation?.id,
		],
		() => {
			if (mode === 'sa') {
				return api.sa.videoViews.getMultiple({
					queryString: queryString,
					include: {
						course: true,
						organisation: true,
						user: true,
						video: true,
						videoMedia: true,
					},
				});
			} else {
				return api.organisations.videoViews.getMultiple({
					organisationId: organisation?.id as number,
					queryString: queryString,
					include: {
						course: true,
						user: true,
						video: true,
						videoMedia: true,
					},
				});
			}
		},
		{
			keepPreviousData: true,
			enabled: enabled,
			onSuccess: (data) => {
				console.log(data);
			},
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={videoViews.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: videoViews.isLoading,
					error: videoViews.isError,
					noData: videoViews.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Video View',
					}),
					message: translate('no_data_message', {
						name: 'video view',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('video'),
							type: 'standard',
						},
						{
							label: translate('user'),
							type: 'standard',
							hide: !manageOrganisationWithPlan && !isSuperadmin,
						},
						{
							label: translate('organisation'),
							type: 'standard',
							hide: mode === 'organisation',
						},
						{
							label: translate('course'),
							type: 'standard',
						},
						{
							label: translate('viewed_at'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'viewed_at'
							),
						},
					]}
					setSort={searchParams.sort.setValue}
					scrollX={true}>
					{videoViews.data?.data.data.map((view, index) => (
						<VideoViewRow
							key={index}
							mode={mode}
							view={view}
							isSuperadmin={isSuperadmin}
						/>
					))}
				</Table>
				<Pagination
					meta={videoViews.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default VideoViewTable;
