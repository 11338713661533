import { http, buildQueryString } from '@utils/index';

interface Props {
	include: {
		organisationsCount: boolean;
	};
}

const getAuthenticatedUser = (props: Props) => {
	return http.get<APIResponse<CurrentUserRes>>(
		`/user${buildQueryString(undefined, [
			{ key: 'organisations', include: true },
			{ key: 'organisations.meta', include: true },
			{
				key: 'organisations.media',
				include: true,
			},
			{
				key: 'organisations.theme.media',
				include: true,
			},
			{
				key: 'organisations.theme.meta',
				include: true,
			},
			{
				key: 'organisationsCount',
				include: props.include.organisationsCount,
			},
		])}`
	);
};
export default getAuthenticatedUser;
