import { http } from '@utils/index';

interface Props {
	body: {
		email: string;
		password: string;
		password_confirmation: string;
		token: string;
	};
}
interface ResponseData {}

const resetPassword = (props: Props) => {
	return http.post<APIResponse<ResponseData>>('/reset-password', props.body);
};
export default resetPassword;
