import { translate } from '@lang/index';
import C from '@root/constants';
import classNames from 'classnames';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Button from '@components/partials/Button';

interface Props {
    pulse?: boolean;
    classes?: string;
}

const UpsellButton: React.FC<Props> = ({ pulse, classes }) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

    // --------------------------------------
    // Render
    if(organisation?.plan_id === null) {
        return (
            <Button
                className={classNames("w-full", classes, {
                    "animate-pulse-light hover:animate-none": pulse
                })}
                theme="primary"
                type="link"
                target='_blank'
                href={C.MARKETING_COURSES_URL}>
                {translate('explore_all_our_content')}
            </Button>
        )
    }

    return null;
}

export default UpsellButton;