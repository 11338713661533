import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface UseSearchParamsDefaults {
	page: number;
	search: string;
	searchKey?: string;
	sort: Array<{ key: string; value: 'asc' | 'desc' | 'off' }>;
	perPage: number;
	filter: Array<{
		key: string;
		value: string;
		type?: 'boolean' | 'input' | 'select' | 'date';
	}>;
	noLocation?: boolean;
}

// --------------------
// Utility
// --------------------

// Create: sort=name,-active
const encodeSortQuery = (sort: UseSearchParamsDefaults['sort']) => {
	let sortQuery: String[] = [];
	for (let i = 0; i < sort.length; i++) {
		const s = sort[i];
		if (s.value !== 'off') {
			if (s.value === 'desc') {
				sortQuery.push(`-${s.key}`);
			} else {
				sortQuery.push(`${s.key}`);
			}
		}
	}
	return sortQuery.join(',');
};
// Decode sort query to Array<{ key: string; value: boolean }>
const decodeSortQuery = (
	sort: string,
	sortState: UseSearchParamsDefaults['sort']
): UseSearchParamsDefaults['sort'] => {
	const urlSort: UseSearchParamsDefaults['sort'] = sort
		.split(',')
		.filter((s) => s !== '')
		.map((s) => {
			if (s.startsWith('-')) {
				return {
					key: s.replace(/^-/, ''),
					value: 'desc',
				};
			} else {
				return {
					key: s,
					value: 'asc',
				};
			}
		});

	if (sort.length === 0) {
		return sortState;
	}

	// merge with state
	return sortState.map((s) => {
		const urlSortItem = urlSort.find((u) => u.key === s.key);
		if (urlSortItem) {
			return urlSortItem;
		}
		return {
			key: s.key,
			value: 'off',
		};
	});
};

// Decode filter query to Array<{ key: string; value: string }>
const decodeFilterQuery = (
	searchParams: URLSearchParams,
	filterState: UseSearchParamsDefaults['filter']
): UseSearchParamsDefaults['filter'] => {
	const locationSearch = window.location.search;

	const urlFilter: UseSearchParamsDefaults['filter'] = [];
	for (let i = 0; i < filterState.length; i++) {
		const filter = filterState[i];
		const urlFilterValue = searchParams.get(`filter[${filter.key}]`);
		if (urlFilterValue) {
			urlFilter.push({
				key: filter.key,
				value: urlFilterValue,
				type: filter.type,
			});
		} else {
			urlFilter.push({
				key: filter.key,
				value: locationSearch.length > 0 ? '' : filter.value,
				type: filter.type,
			});
		}
	}
	return urlFilter;
};

// --------------------
// Hook
// --------------------

const useSearchParams = (
	defaultsInp: UseSearchParamsDefaults
): {
	searchParams: SearchParams;
	queryString: string;
	resetFilters: () => void;
	enabled: boolean;
} => {
	const location = useLocation();
	const navigate = useNavigate();

	// --------------------
	// State
	const [defaults] = useState<UseSearchParamsDefaults>(defaultsInp);
	const [enabled, setEnabled] = useState<boolean>(false);

	const [lockEffect, setLockEffect] = useState(false);
	const [queryString, setQueryString] = useState(location.search);
	const [page, setPage] = useState(defaults.page);
	const [search, setSearch] = useState(defaults.search);
	const [perPage, setPerPage] = useState(defaults.perPage);
	const [sort, setSort] = useState<UseSearchParamsDefaults['sort']>([]);
	const [filter, setFilter] = useState<UseSearchParamsDefaults['filter']>([]);
	const [lockTimout, setLockTimout] =
		useState<ReturnType<typeof setTimeout>>();

	const [searchKey] = useState(defaults.searchKey || 'search');

	// --------------------
	// Functions
	type SearchParams = { key: string; value: string };
	const addSearchParam = (params: SearchParams | SearchParams[]) => {
		setLockEffect(true);
		if (lockTimout) {
			clearTimeout(lockTimout);
		}

		const searchParams = new URLSearchParams(mergeSearchParams());

		if (Array.isArray(params)) {
			params.forEach((p) => {
				if (p.value === '') {
					searchParams.delete(p.key);
				} else {
					searchParams.set(p.key, p.value);
				}
				// reset page if sort or filter changes
				if (p.key === 'sort' || p.key.includes('filter')) {
					searchParams.set('page', defaults.page.toString());
				}
			});
		} else {
			if (params.value === '') {
				searchParams.delete(params.key);
			} else {
				searchParams.set(params.key, params.value);
			}
			// reset page if sort or filter changes
			if (params.key === 'sort' || params.key.includes('filter')) {
				searchParams.set('page', defaults.page.toString());
			}
		}

		if (!defaults.noLocation) navigate({ search: searchParams.toString() });

		setQueryString(searchParams.toString());

		setLockTimout(
			setTimeout(() => {
				setLockEffect(false);
			}, 100)
		);
	};
	const resetFilters = () => {
		setPage(defaults.page);
		setSearch(defaults.search);
		setFilter(defaults.filter);

		const searchParams = new URLSearchParams(location.search);
		searchParams.delete('page');
		searchParams.delete(`filter[${searchKey}]`);
		filter.forEach((f) => {
			searchParams.delete(`filter[${f.key}]`);
		});

		navigate({ search: searchParams.toString() });
		setQueryString(searchParams.toString());
	};

	// Merges search params with state and returns a string
	const mergeSearchParams = (
		filtersOpt?: UseSearchParamsDefaults['filter'],
		sortsOpt?: UseSearchParamsDefaults['sort']
	) => {
		const searchParams = new URLSearchParams(location.search);
		// sort
		const sortQuery = encodeSortQuery(sortsOpt || sort);
		if (sortQuery) searchParams.set('sort', sortQuery);
		// filter
		const filters = filtersOpt || filter;
		filters.forEach((f) => {
			if (f.value !== '') searchParams.set(`filter[${f.key}]`, f.value);
		});

		return searchParams.toString();
	};

	// --------------------
	// Effects - update search params on location change
	useEffect(() => {
		if (!lockEffect) {
			const searchParams = new URLSearchParams(location.search);
			setPage(Number(searchParams.get('page')) || defaults.page);
			setPerPage(
				Number(searchParams.get('per_page')) || defaults.perPage
			);
			setSearch(
				searchParams.get(`filter[${searchKey}]`) || defaults.search
			);
			const newSorts = decodeSortQuery(
				searchParams.get('sort') || '',
				defaults.sort
			);
			setSort(newSorts);
			const newFilters = decodeFilterQuery(searchParams, defaults.filter);
			setFilter(newFilters);
			setQueryString(mergeSearchParams(newFilters, newSorts));
			setEnabled(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, defaults, searchKey]);

	// --------------------
	// Return
	return {
		searchParams: {
			page: {
				value: page,
				setValue: (value: number) => {
					setPage(value);
					addSearchParam({
						key: 'page',
						value: value.toString(),
					});
				},
			},
			search: {
				value: search,
				setValue: (value: string) => {
					setSearch(value);
					addSearchParam({
						key: `filter[${searchKey}]`,
						value,
					});
				},
			},
			sort: {
				value: sort,
				setValue: (key: string, value: 'asc' | 'desc' | 'off') => {
					const newSort = sort.map((s) => {
						if (s.key === key) {
							return { key, value };
						} else {
							return {
								key: s.key,
								value: 'off' as const,
							};
						}
					});
					setSort(newSort);
					addSearchParam({
						key: 'sort',
						value: encodeSortQuery(newSort),
					});
				},
			},
			filter: {
				value: filter,
				setValue: (key: string, value: string) => {
					const newFilter = filter.map((s) => {
						if (s.key === key) {
							return { key, value, type: s.type };
						} else {
							return s;
						}
					});
					setFilter(newFilter);
					addSearchParam({
						key: `filter[${key}]`,
						value,
					});
				},
				setMultipleValues: (
					values: Array<{ key: string; value: string }>
				) => {
					const newFilter = filter.map((s) => {
						const value = values.find(
							(v) => v.key === s.key
						)?.value;

						if (value !== undefined) {
							return { key: s.key, value: value, type: s.type };
						} else {
							return s;
						}
					});
					setFilter(newFilter);
					addSearchParam(
						values.map((v) => ({
							key: `filter[${v.key}]`,
							value: v.value,
						}))
					);
				},
			},
			perPage: {
				value: perPage,
				setValue: (value: number) => {
					setPerPage(value);
					addSearchParam({
						key: 'per_page',
						value: value.toString(),
					});
				},
			},
		},
		queryString: queryString,
		resetFilters,
		enabled,
	};
};
export default useSearchParams;
