import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		noteable: boolean;
		course: boolean;
	};
	filters?: {
		s?: string;
		has_context?: 1 | 2;
		noteable_type?: 'video' | 'quiz';
		noteable_id?: number;
		course_id?: number;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<NotesRes[]>>(
		`/organisations/${props.organisationId}/notes${buildQueryString(
			props.queryString,
			[
				{ key: 'noteable', include: props.include.noteable },
				{ key: 'course', include: props.include.course },
			],
			[
				{ key: 'has_context', value: props.filters?.has_context },
				{ key: 's', value: props.filters?.s },
				{ key: 'noteable_type', value: props.filters?.noteable_type },
				{ key: 'noteable_id', value: props.filters?.noteable_id },
				{ key: 'course_id', value: props.filters?.course_id },
			],
			props.perPage
		)}`
	);
};
export default getMultiple;
