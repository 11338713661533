import { http } from '@utils/index';

interface ResponseData {
	html: string;
}

const getTerms = () => {
	return http.get<ResponseData>('/public/terms');
};
export default getTerms;
