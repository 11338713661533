// Libs
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import {
	faTriangleExclamation,
	faEye,
	faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface InputProps {
	label?: string;
	id: string;
	name: string;
	value: string | number;
	setValue: (value: string) => void;
	errors: Array<string> | undefined;
	type:
		| 'email'
		| 'password'
		| 'text'
		| 'number'
		| 'search'
		| 'tel'
		| 'url'
		| 'date';
	required: boolean;
	autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
	placeholder?: string;
	describedBy?: string;
	inputStyle?: string;
	disabled?: boolean;
	showErrors?: boolean;
	onBlur?: () => void;
}

const Input: React.FC<InputProps> = ({
	label,
	id,
	type,
	name,
	value,
	setValue,
	errors = [],
	autoComplete,
	placeholder,
	required,
	describedBy,
	inputStyle,
	disabled = false,
	showErrors = true,
	onBlur,
}) => {
	// --------------------------------
	// States
	const [showPassword, setShowPassword] = useState(false);

	// --------------------------------
	// Memos
	const inputType = useMemo(() => {
		if (type === 'password') {
			return showPassword ? 'text' : 'password';
		}
		return type;
	}, [type, showPassword]);

	// --------------------------------
	// Render
	return (
		<div className={'mb-4 flex w-full flex-col last:mb-0'}>
			{label && (
				<label htmlFor={id} className="mb-1 block text-body">
					{label}
					{required && <span className="ml-1">(*)</span>}
				</label>
			)}
			<div className="relative w-full">
				<input
					className={classNames(
						'h-10 w-full rounded-md border border-border px-2.5 focus:border-brandPrimary focus:ring-brandPrimary',
						{
							'border-red': errors.length > 0,
							'pr-8': type === 'password',
						},
						inputStyle
					)}
					id={id}
					disabled={disabled}
					name={name}
					type={inputType}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					autoComplete={autoComplete}
					placeholder={placeholder}
					required={required}
					onBlur={onBlur}
				/>
				{type === 'password' && (
					<div className="absolute right-2.5 top-1/2 -translate-y-1/2">
						<FontAwesomeIcon
							icon={showPassword ? faEyeSlash : faEye}
							className="h-4 w-4 cursor-pointer text-icon text-opacity-80"
							onClick={() => setShowPassword(!showPassword)}
						/>
					</div>
				)}
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="mt-2.5 text-sm text-body text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{showErrors && errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};
export default Input;
