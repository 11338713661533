// Utils
import { dateHelpers } from '@utils/index';

interface DateProps {
	date: string;
}

const Date: React.FC<DateProps> = ({ date }) => {
	return <>{dateHelpers.formatDate(date, 'en-gb', 'long')}</>;
};

export default Date;
