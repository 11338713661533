import { translate } from '@lang/index';
import { useState, useEffect } from 'react';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import FormRow from '@components/forms/FormRow';
import InfoRow from '@components/blocks/InfoRow';
import Button from '@components/partials/Button';
import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
import LogOutAllDevices from '@components/partials/LogOutAllDevices';
// Actions
import UpdateCurrentUserAction from '@components/actions/UpdateCurrentUser';
// import GenerateAPIAccessAction from '@components/actions/GenerateAPIAccess';
// Modal
import AcceptTerms from '@components/modal/AcceptTerms';
import PasswordUpdate from '@components/modal/PasswordUpdate';
// Queries
import { useAuthenticatedUser } from '@queries/user';

const Account: React.FC = () => {
	// -------------------------------------------------
	// State
	const [showTerms, setShowTerms] = useState(false);
	const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);

	// -------------------------------------------------
	// Queries & Mutations
	const user = useAuthenticatedUser();

	// -------------------------------------------------
	// On Load
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const acceptTerms = urlParams.get('accept-terms');
		if (acceptTerms === 'true') {
			setShowTerms(true);
		}
	}, []);

	// -------------------------------------------------
	// Render
	if (user.isLoading) return <Loading type="page" />;
	if (user.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('account')}
				body={translate('account_body')}
			/>
			<PageWrapper>
				{/* Details */}
				<FormRow title={translate('details')}>
					<UpdateCurrentUserAction
						user={user.data.data.data}
						callback={(success) => {
							if (success) {
								user.refetch();
							}
						}}
					/>
				</FormRow>
				{/* API */}
				{/* <FormRow title={translate('api')}>
					<GenerateAPIAccessAction
						user={user.data.data.data}
						callback={() => {}}
					/>
				</FormRow> */}
				{/* Management */}
				<FormRow title={translate('management')}>
					<InfoRow info={translate('update_password_msg')}>
						<Button
							theme="danger"
							type="button"
							onClick={() =>
								setShowPasswordUpdate(!showPasswordUpdate)
							}>
							{translate('password_update')}
						</Button>
					</InfoRow>
					<InfoRow info={translate('sign_out_everywhere_msg')}>
						<LogOutAllDevices />
					</InfoRow>
				</FormRow>
			</PageWrapper>
			{/* Modals */}
			<AcceptTerms
				open={showTerms}
				setOpen={(state) => {
					setShowTerms(state);
					if (!state) {
						window.history.replaceState(
							{},
							document.title,
							window.location.pathname
						);
					}
				}}
			/>
			<PasswordUpdate
				open={showPasswordUpdate}
				setOpen={setShowPasswordUpdate}
			/>
		</>
	);
};
export default Account;
