import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// Components
import Button from '@components/partials/Button';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import MessageBlock from '@components/blocks/MessageBlock';

interface DynamicBlockProps {
	state: {
		loading: boolean;
		error: boolean;
		noData?: any;
	};
	noData?: {
		title: string;
		message: string;
		image?: string;
	};
	resetFilters?: () => void;
	children: React.ReactNode;
}

const DynamicBlock: React.FC<DynamicBlockProps> = ({
	children,
	state,
	noData,
	resetFilters,
}) => {
	const location = useLocation();

	// -------------------------------------------------
	// Memos
	const filtersActive = useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		let active = false;
		for (let pair of searchParams.entries()) {
			if (pair[0].includes('filter[')) {
				active = true;
				break;
			}
		}
		return active;
	}, [location.search]);

	// -------------------------------------------------
	// Render
	if (state.loading) {
		return <Loading />;
	}

	if (state.error) {
		return <Error />;
	}

	if (state.noData) {
		return (
			<MessageBlock
				title={noData?.title}
				message={noData?.message}
				image={noData?.image}>
				{filtersActive && resetFilters && (
					<Button
						onClick={() => resetFilters()}
						theme="primary"
						type="button"
						className="mt-5">
						Reset Filters
					</Button>
				)}
			</MessageBlock>
		);
	}

	return <>{children}</>;
};

export default DynamicBlock;
