import getAll from './get-all';
import getMultiple from './get-multiple';
import createSingle from './create-single';
import deleteSingle from './delete-single';
import updateSingle from './update-single';

const actions = {
	getAll,
	getMultiple,
	createSingle,
	deleteSingle,
	updateSingle,
};

export default actions;
