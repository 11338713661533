import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Components
import CourseBuilderList from '@components/groups/course-builder/List';
import CourseBuilderSelected from '@components/groups/course-builder/Selected';
// Modals
import CreateChapter from '@components/modal/CreateChapter';

export interface Item {
	id: string;
	type: 'quiz' | 'video' | 'chapter';
	type_id?: number;
	data: SaQuizzesRes | SaVideosRes | SaChaptersRes;
}

interface CourseBuilderContainerProps {
	selectedItems: Item[];
	setSelectedItems: React.Dispatch<React.SetStateAction<Item[]>>;
	mode: 'create' | 'edit';
}

const CourseBuilderContainer: React.FC<CourseBuilderContainerProps> = ({
	selectedItems,
	setSelectedItems,
	mode,
}) => {
	// -------------------------------------------
	// Modals
	const [openCreateChapter, setOpenCreateChapter] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const quizzes = useQuery(['sa.quizzes.getMultiple'], () => {
		return api.sa.quizzes.getMultiple({
			include: {
				type: false,
				typeCount: false,
				media: false,
				mediaCount: false,
			},
			sort: {
				ref: 'asc',
			},
			perPage: -1,
		});
	});
	const videos = useQuery(['sa.videos.getMultiple'], () => {
		return api.sa.videos.getMultiple({
			include: {
				value: false,
				media: false,
			},
			sort: {
				ref: 'asc',
			},
			perPage: -1,
		});
	});
	const categories = useQuery(['sa.categories.getMultiple'], () => {
		return api.sa.categories.getMultiple({
			include: {
				meta: true,
				metaCount: false,
			},
			perPage: -1,
			sort: {
				name: 'asc',
			},
		});
	});

	// -------------------------------------------
	// Functions
	const removeSelectedItem = (item: Item) => {
		setSelectedItems((items) =>
			items.filter(
				(_item) =>
					_item.data.id !== item.data.id || _item.type !== item.type
			)
		);
	};
	const addItem = (
		data: SaQuizzesRes | SaVideosRes | SaChaptersRes,
		type: 'quiz' | 'video' | 'chapter'
	) => {
		setSelectedItems((items) => [
			...items,
			{
				id: `${type}_${data.id}`,
				type: type,
				type_id: data.type_id,
				data,
			},
		]);
	};

	// -------------------------------------------
	// Render
	return (
		<>
			<div className="page-content-builder grid grid-cols-3">
				{/* Course Builder */}
				<div className="col-span-1 overflow-y-auto border-r border-border bg-uiLightGray">
					<CourseBuilderSelected
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						removeSelectedItem={removeSelectedItem}
						setOpenCreateChapter={setOpenCreateChapter}
						mode={mode}
						categories={categories.data?.data.data || []}
					/>
				</div>
				{/* Quiz & Video Items */}
				<CourseBuilderList
					quizzes={quizzes.data?.data.data || []}
					videos={videos.data?.data.data || []}
					addItem={addItem}
					selectedItems={selectedItems}
					isLoading={quizzes.isLoading || videos.isLoading}
					isError={quizzes.isError || videos.isError}
					categories={categories.data?.data.data || []}
				/>
			</div>
			{/* Modals */}
			<CreateChapter
				open={openCreateChapter}
				setOpen={setOpenCreateChapter}
				onSuccess={(chapter) => {
					addItem(chapter, 'chapter');
				}}
			/>
		</>
	);
};

export default CourseBuilderContainer;
