import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// API
import api from 'api';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import AlertMessage from '@components/partials/AlertMessage';
import ButtonLoadingOverlay from '@components/partials/ButtonLoadingOverlay';

interface PasswordResetActionProps extends ActionProps {}

const PasswordResetAction: React.FC<PasswordResetActionProps> = ({
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const forgotPassword = useMutation(api.auth.forgotPassword, {
		onSuccess: () => {
			addToast({
				title: translate('toast_password_forgot_title'),
				message: translate('toast_password_forgot_message'),
				type: 'success',
			});
			navigate('/login');
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmitForgot = async () => {
		await api.root.crsf();
		await forgotPassword.mutateAsync({
			body: {
				email: email,
			},
		});
	};

	// --------------------------------------
	// Render
	return (
		<>
			<div className="mb-10">
				<h1 className="mb-1.5 text-title">Reset password</h1>
				<p className="text-body">
					Enter your email address and we'll send you a link to reset
				</p>
			</div>
			<Form onSubmit={onSubmitForgot} state={formState} validation={true}>
				<Input
					value={email}
					setValue={setEmail}
					name="email"
					label={translate('email')}
					id="email"
					type="email"
					autoComplete="email"
					errors={formState.errors.value['email']}
					required={true}
				/>
				<Button
					theme="text"
					onClick={() => {
						navigate('/login');
					}}
					className="!-mt-2 block !text-body hover:!text-brandTertiaryHover">
					Back
				</Button>

				<div className="mt-10">
					{forgotPassword.isError && (
						<div className="mb-2.5">
							<AlertMessage
								text={formState.errorMessage.value}
								type="error"
							/>
						</div>
					)}
					<Button
						theme={'primary'}
						type={'submit'}
						className={'relative w-full overflow-hidden'}>
						{translate('send_reset_link')}
						{forgotPassword.isLoading && <ButtonLoadingOverlay />}
					</Button>
				</div>
			</Form>
		</>
	);
};

export default PasswordResetAction;
