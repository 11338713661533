// Libs
import {
	faChevronDown,
	faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ValueT = string | number | undefined | null;

interface SelectProps {
	label?: string;
	value: ValueT;
	setValue: (value: ValueT) => void;
	options: Array<string | SelectOption>;
	errors: Array<string> | undefined;
	inputStyle?: string;
	disabled?: boolean;
	describedBy?: string;
}

interface SelectOption {
	value?: ValueT;
	label: string;
}

const Select: React.FC<SelectProps> = ({
	label,
	value,
	setValue,
	options,
	errors = [],
	inputStyle,
	disabled = false,
	describedBy,
}) => {
	// --------------------------------------
	// Memos
	const optionsType = useMemo(() => {
		if (typeof options[0] === 'string') return 'string';
		return 'object';
	}, [options]);
	const currentLabel = useMemo(() => {
		if (optionsType === 'string') return value as string;
		return (
			options.find(
				(opt) => (opt as SelectOption).value === value
			) as SelectOption
		)?.label;
	}, [options, optionsType, value]);

	// --------------------------------------
	// Render
	return (
		<div className={'mb-4 flex w-full flex-col last-of-type:mb-0'}>
			<Listbox
				value={value}
				onChange={(value) => {
					if (!disabled) setValue(value);
				}}>
				{({ open }) => (
					<>
						{label && (
							<Listbox.Label className="mb-1 block text-body">
								{label}
							</Listbox.Label>
						)}
						<div className={'relative'}>
							<Listbox.Button
								className={classNames(
									'h-10 w-full rounded-md border border-border px-2.5 text-left text-base capitalize focus:border-brandPrimary focus:ring-brandPrimary',
									{
										'border-red': errors.length > 0,
									},
									inputStyle
								)}>
								{currentLabel}
								<span className="absolute top-1.5 right-1.5 flex h-7 w-7 items-center justify-center rounded-md bg-uiDark">
									<FontAwesomeIcon
										icon={faChevronDown}
										className={classNames(
											'text-white transition-transform duration-200',
											{
												'rotate-180 transform': open,
											}
										)}
									/>
								</span>
							</Listbox.Button>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0">
								<Listbox.Options
									className={`absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base capitalize shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
									{options.map((opt, index) => (
										<Listbox.Option
											key={index}
											value={
												typeof opt === 'string'
													? opt
													: opt.value
											}>
											{({ selected }) => (
												<>
													<div
														className={classNames(
															'cursor-pointer px-2.5 py-1 text-sm hover:bg-brandSecondary hover:text-white',
															{
																'bg-brandSecondary text-white':
																	selected,
																'text-title':
																	!selected,
															}
														)}>
														{typeof opt === 'string'
															? opt
															: opt.label}
													</div>
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
			{/* Described By */}
			{describedBy && (
				<p className="mt-2.5 text-sm text-body text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};
export default Select;
