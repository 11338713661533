import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		course: boolean;
		courseCount: boolean;
		quiz: boolean;
		quizCount: boolean;
		user: boolean;
		userCount: boolean;
	};
	filters?: Array<{
		key: 'quiz.type_id' | 'quiz_id' | 'user_id' | 'pass' | 'course_id';
		value: string | number | Array<string | number>;
	}>;
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<QuizzesResultsRes[]>>(
		`/organisations/${props.organisationId}/quiz-results${buildQueryString(
			props.queryString,
			[
				{
					key: 'course',
					include: props.include.course,
				},
				{
					key: 'courseCount',
					include: props.include.courseCount,
				},
				{
					key: 'quiz',
					include: props.include.quiz,
				},
				{
					key: 'quizCount',
					include: props.include.quizCount,
				},
				{
					key: 'user',
					include: props.include.user,
				},
				{
					key: 'userCount',
					include: props.include.userCount,
				},
			],
			props.filters,
			props.perPage
		)}`
	);
};
export default getMultiple;
