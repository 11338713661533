import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		courses: boolean;
		coursesCount: boolean;
		users: boolean;
		usersCount: boolean;
	};
	sort?: {
		name?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<TeamRes[]>>(
		`/organisations/${props.organisationId}/teams${buildQueryString(
			props.queryString,
			[
				{ key: 'courses', include: props.include.courses },
				{ key: 'coursesCount', include: props.include.coursesCount },
				{ key: 'users', include: props.include.users },
				{ key: 'usersCount', include: props.include.usersCount },
			],
			undefined,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
