import { http, createFormData } from '@utils/index';

interface Props {
	id: number;
	body: {
		title?: string;
		desc?: string;
		ref?: string;
		type_id?: number;
		file?: File | null;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaQuizzesRes>>(
		`/sa/quizzes/${props.id}`,
		createFormData([
			{
				key: '_method',
				value: 'put',
			},
			{
				key: 'title',
				value: props.body.title,
			},
			{
				key: 'desc',
				value: props.body.desc,
			},
			{
				key: 'ref',
				value: props.body.ref,
			},
			{
				key: 'type_id',
				value: props.body.type_id?.toString(),
			},
			{
				key: 'file',
				value: props.body.file,
			},
		]),
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default updateSingle;
