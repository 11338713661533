import { http, buildQueryString } from '@utils/index';

export interface Props {
	id: number;
	include: {
		organisations: boolean;
		rolesIgnoringTeams: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaUsersRes>>(
		`/sa/users/${props.id}${buildQueryString(undefined, [
			{
				key: 'organisations',
				include: props.include.organisations,
			},
			{
				key: 'rolesIgnoringTeams',
				include: props.include.rolesIgnoringTeams,
			},
		])}`
	);
};
export default getSingle;
