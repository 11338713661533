import update from './update';
import get from './get';
import getBillingPortal from './get-billing-portal';

const actions = {
	update,
	get,
	getBillingPortal,
};

export default actions;
