import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		course_type_id?: number | null;
		title?: string | null;
		desc?: string | null;
		ref?: string | null;
		category_ids?: number[] | null;
		items?: Array<{
			model_id: number;
			model_type: 'quiz' | 'video' | 'chapter';
			sort: number;
		}>;

		image?: File | null;
		file?: File | null;

		public?: 1 | 0 | number;
		stripe_product_id?: string | null;
		slug?: string | null;
		popular_at?: string | null;
		meta?: {
			price_from?: string | null;
			duration_text?: string;
			about_text?: string;
			completion_abilities?: string;
		};
	};
	contentType?: 'multipart/form-data' | 'application/json';
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaCourseRes>>(
		`/sa/courses/${props.id}`,
		{
			_method: 'put',
			...props.body,
		},
		{
			headers: {
				'Content-Type': props.contentType || 'multipart/form-data',
			},
		}
	);
};
export default updateSingle;
