import { faChair, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OrgPlanCountProps {
	organisation?: OrganisationRes;
}

const OrgPlanCount: React.FC<OrgPlanCountProps> = ({ organisation }) => {
	if (!organisation?.plan_id) return null;

	return (
		<div className="flex items-center text-sm text-body">
			<span className="mr-4 flex items-center">
				<FontAwesomeIcon
					icon={faChair}
					className="mr-1.5 text-brandTertiary"
				/>
				{organisation?.seated_users_count || 0} /
				{organisation?.plan?.seats || 0}
			</span>
			<span className="flex items-center">
				<FontAwesomeIcon
					icon={faUsers}
					className="mr-1.5 text-brandTertiary"
				/>
				{organisation?.users_count || 0}/
				{organisation?.plan?.users || 0}
			</span>
		</div>
	);
};

export default OrgPlanCount;
