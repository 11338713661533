import { useMemo } from 'react';
// Hooks
import { usePermissions } from '@hooks/index';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface LoginLogRowProps {
	log: SaLogsRes | OrgLogsRes;
	isSuperadmin?: boolean;
}

const LoginLogRow: React.FC<LoginLogRowProps> = ({ log, isSuperadmin }) => {
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Memos
	const device = useMemo(() => {
		// get device name from user agent
		const ua = log?.properties?.ua;
		if (!ua) return 'Unknown';
		const uaParts = ua.split(' ');
		const deviceName = uaParts[uaParts.length - 1];
		return deviceName;
	}, [log?.properties?.ua]);

	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			{(manageOrganisationWithPlan || isSuperadmin) && (
				<td className="td-default">
					<UserColumn name={log.user?.name} email={log.user?.email} />
				</td>
			)}
			<td className="td-default">
				{log?.properties?.ip ? (
					<span>{log?.properties?.ip}</span>
				) : (
					<>-</>
				)}
			</td>
			<td className="td-default">
				{device ? <span>{device}</span> : <>-</>}
			</td>
			<td className="td-default">
				<DateTimeColumn date={log.created_at || ''} />
			</td>
		</tr>
	);
};

export default LoginLogRow;
