import T, { rolesT, translate } from '@lang/index';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { faTimes, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// api
import api from 'api';
// Components
import Modal from '@components/modal/Modal';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import MultiSelect from '@components/forms/MultiSelect';
import Checkbox from '@components/forms/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/partials/Button';
import Form from '@components/forms/Form';
import FormActionModal from '@components/forms/FormActionModal';

interface CreateUpdateUserProps {
	mode: 'create' | 'update';
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: () => void;
	userId?: number;
}

interface OrganisationRowT {
	organisationId: number;
	roles: number[];
	seated: boolean;
}

const CreateUpdateUser: React.FC<CreateUpdateUserProps> = ({
	mode,
	open,
	setOpen,
	onSuccess,
	userId,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [organisationRows, setOrganisationRows] = useState<
		OrganisationRowT[]
	>([]);
	const [lockUserSet, setLockUserSet] = useState<boolean>(false);

	// -------------------------------------------------
	// Queries / Mutations
	const user = useQuery(
		['sa.users.getSingle', userId, open],
		() => {
			return api.sa.users.getSingle({
				id: userId as number,
				include: {
					organisations: true,
					rolesIgnoringTeams: true,
				},
			});
		},
		{
			enabled: mode === 'update' && open,
			onSuccess: (data) => {
				if (!lockUserSet) {
					setLockUserSet(true);

					setName(data.data.data.name || '');
					setEmail(data.data.data.email || '');
					if (data.data.data.organisations) {
						setOrganisationRows(
							data.data.data.organisations.map((org) => {
								return {
									organisationId: org.id,
									roles: getOrgRoles(
										data?.data?.data?.roles || [],
										org.id
									),
									seated:
										typeof org.seated_at === 'string'
											? true
											: false,
								};
							}) || []
						);
					}
				}
			},
		}
	);
	const roles = useQuery(['sa.roles.getAll'], () => {
		return api.sa.roles.getAll({});
	});
	const organisations = useQuery(['sa.organisations.getMultiple'], () => {
		return api.sa.organisations.getMultiple({
			include: {
				courses: false,
				coursesCount: false,
				media: false,
				mediaCount: false,
				plan: false,
				planCount: false,
				users: false,
				usersCount: false,
				subscriptionsCount: false,
				subscriptionsItems: false,
				seatedUsersCount: false,
			},
			perPage: -1,
			sort: {
				name: 'asc',
			},
		});
	});

	const createUser = useMutation(api.sa.users.createSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries(['sa.users.getMultiple']);
			addToast({
				title: translate('toast_created_title', {
					name: 'User',
				}),
				message: translate('toast_created_message', {
					name: 'User',
				}),
				type: 'success',
			});
			closeModal();
			if (onSuccess) {
				onSuccess();
			}
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
		},
	});
	const updateUser = useMutation(api.sa.users.updateSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries(['sa.users.getMultiple']);
			queryClient.invalidateQueries(['sa.users.getSingle', userId]);
			addToast({
				title: translate('toast_updated_title', {
					name: 'User',
				}),
				message: translate('toast_updated_message', {
					name: 'User',
				}),
				type: 'success',
			});
			closeModal();
			if (onSuccess) {
				onSuccess();
			}
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
		},
	});

	const closeModal = () => {
		resetForm();
		setOpen(false);
	};

	// -------------------------------------------------
	// Functions
	const addNewOrganisationRow = () => {
		setOrganisationRows((prev) => {
			const standardRoleId = roles.data?.data?.data.find(
				(role) => role.name === 'standard'
			)?.id;

			return [
				...prev,
				{
					organisationId: organisations.data?.data?.data[0].id || 0,
					roles: standardRoleId ? [standardRoleId] : [],
					seated: false,
				},
			];
		});
	};
	const updateOrganisationRow = (index: number, row: OrganisationRowT) => {
		setOrganisationRows((prev) => {
			const newRows = [...prev];
			newRows[index] = row;
			return newRows;
		});
	};
	const submitForm = async () => {
		if (mode === 'create') {
			createUser.mutate({
				body: {
					name,
					email,
					organisations: organisationRows.map((row) => {
						return {
							id: row.organisationId,
							role_ids: row.roles,
							seated: row.seated ? 1 : 0,
						};
					}),
				},
			});
		}
		if (mode === 'update') {
			updateUser.mutate({
				id: userId as number,
				body: {
					...returnModifiedData([
						{
							key: 'name',
							state: name,
							defaultState: user.data?.data.data.name,
						},
						{
							key: 'email',
							state: email,
							defaultState: user.data?.data.data.email,
						},
					]),
					organisations: organisationRows.map((row) => {
						return {
							id: row.organisationId,
							role_ids: row.roles,
							seated: row.seated ? 1 : 0,
						};
					}),
				},
			});
		}
	};
	const resetForm = () => {
		if (mode === 'update') {
			setName(user.data?.data?.data.name || '');
			setEmail(user.data?.data?.data.email || '');
			if (user.data?.data?.data.organisations) {
				setOrganisationRows(
					user.data?.data?.data.organisations.map((org) => {
						return {
							organisationId: org.id,
							roles: getOrgRoles(
								user?.data?.data?.data?.roles || [],
								org.id
							),
							seated:
								typeof org.seated_at === 'string'
									? true
									: false,
						};
					}) || []
				);
			}
		} else {
			setName('');
			setEmail('');
			setOrganisationRows([]);
			createUser.reset();
		}

		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		setLockUserSet(false);
	};
	const getOrgRoles = (roles: RoleRes[], orgId: number) => {
		const orgRoles = roles.filter((role) => role.organisation_id === orgId);
		return orgRoles.map((role) => role.id);
	};

	// -------------------------------------------------
	// Render
	return (
		<Modal
			open={open}
			setOpen={(state) => {
				if (!state) {
					closeModal();
				} else {
					setOpen(true);
				}
			}}
			title={
				mode === 'create'
					? T.modals.create_user.title
					: T.modals.update_user.title
			}
			size={'large'}
			isLoading={
				roles.isLoading ||
				organisations.isLoading ||
				(mode === 'update' && user.isLoading)
			}
			isError={roles.isError}>
			<Form onSubmit={submitForm} state={formState} validation={true}>
				<Input
					id="name"
					type="text"
					label={translate('name')}
					name="name"
					value={name}
					setValue={setName}
					errors={formState.errors.value['name']}
					required={true}
				/>
				<Input
					id="email"
					type="email"
					label={translate('email')}
					name="email"
					value={email}
					setValue={setEmail}
					errors={formState.errors.value['email']}
					required={true}
				/>
				<div className="mt-5">
					<ul>
						{organisationRows.map((organisation, index) => (
							<OrganisationRow
								key={index}
								row={organisation}
								organisations={
									organisations.data?.data?.data || []
								}
								roles={roles.data?.data?.data || []}
								setRow={(state) =>
									updateOrganisationRow(index, state)
								}
								removeRow={() => {
									setOrganisationRows((prev) => {
										const newRows = [...prev];
										newRows.splice(index, 1);
										return newRows;
									});
								}}
							/>
						))}
					</ul>
					<Button theme="text" onClick={addNewOrganisationRow}>
						<FontAwesomeIcon
							icon={faPlusCircle}
							className="mr-2 mt-2"
						/>
						{translate('add_account')}
					</Button>
				</div>

				<FormActionModal
					loading={{
						is: createUser.isLoading,
						message: translate('creating'),
					}}
					error={{
						is: createUser.isError,
						message: formState.errorMessage.value,
					}}>
					<Button
						theme="outline"
						type="button"
						className="mr-3"
						onClick={resetForm}>
						{translate('reset')}
					</Button>
					<Button theme="primary" type="submit">
						{mode === 'create'
							? translate('create')
							: translate('update')}
					</Button>
				</FormActionModal>
			</Form>
		</Modal>
	);
};

interface OrganisationRowProps {
	row: OrganisationRowT;
	setRow: (state: OrganisationRowT) => void;
	removeRow: () => void;
	organisations: SaOrganisationsRes[];
	roles: RoleRes[];
}

const OrganisationRow: React.FC<OrganisationRowProps> = ({
	row,
	organisations,
	setRow,
	removeRow,
	roles,
}) => {
	// -------------------------------------------------
	// Functions

	// -------------------------------------------------
	// Render
	return (
		<li className="last:last-mb-0 relative  mb-2.5 grid grid-cols-create-user gap-5">
			<div>
				<span className="mb-1.5 block">Organisation</span>
				<Select
					value={row.organisationId}
					setValue={(state) => {
						setRow({ ...row, organisationId: state as number });
					}}
					errors={[]}
					options={organisations.map((org) => {
						return {
							value: org.id,
							label: `${org.name} - ${org.id}`,
						};
					})}
				/>
			</div>
			<div>
				<span className="mb-1.5 block">Roles</span>
				<MultiSelect
					name={'items'}
					id={'items'}
					values={row.roles.map((roleId) => {
						const name =
							roles.find((role) => role.id === roleId)?.name ||
							'';

						return {
							key: name,
							label: rolesT(name),
							data: roleId,
						};
					})}
					setValues={(state) => {
						setRow({ ...row, roles: state.map((s) => s.data) });
					}}
					errors={{}}
					options={
						roles.map((role) => {
							return {
								key: role.name,
								label: rolesT(role.name),
								data: role.id,
							};
						}) || []
					}
					placeholder={translate('assign_roles')}
				/>
			</div>
			<div className="min-w-[150px] pr-[48px]">
				<span className="mb-1.5 block">Seated</span>
				<div className="flex h-10 items-center">
					<Checkbox
						checked={row.seated}
						setChecked={() => {
							setRow({ ...row, seated: !row.seated });
						}}
						errors={[]}
					/>
				</div>
			</div>
			<div className="absolute right-0 bottom-0 flex h-10 items-center">
				<button
					type="button"
					className={
						'bg-red-500 flex w-8 min-w-[32px] items-center justify-center rounded-full border border-border bg-uiLight text-body transition-colors duration-200 hover:bg-slate-100 hover:text-red disabled:pointer-events-none disabled:opacity-50 md:top-[32px] md:h-8'
					}
					onClick={removeRow}>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			</div>
		</li>
	);
};

export default CreateUpdateUser;
