import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		title?: string;
		desc?: string;
		image?: File | null;
	};
}

const updateSingle = (props: Props) => {
	return http.post<APIResponse<SaChaptersRes>>(
		`/sa/chapters/${props.id}`,
		{
			...props.body,
			_method: 'put',
		},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default updateSingle;
