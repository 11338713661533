import { useState, useContext, createContext, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams, useLocation } from 'react-router-dom';
import api from 'api';
// Constants
import C from '@root/constants';
// Utils
import { organisationTheme } from '@utils/index';
// Hooks
import { useOrganisation } from '@hooks/index';
import { useNavigate } from 'react-router-dom';

interface AuthContextProps {
	auth: boolean;
	setAuth: (state: boolean) => void;
	state: 'initial' | 'loading' | 'error' | 'success';

	userId: number | undefined;
	setUserId: (id: number) => void;

	signin: (
		email: string,
		password: string,
		onSuccess: () => void,
		onError: (error: AxiosAPIError) => void
	) => void;
	signout: (
		onSuccess: () => void,
		onError: (error: AxiosAPIError) => void
	) => void;
	signouteverywhere: (
		onSuccess: () => void,
		onError: (error: AxiosAPIError) => void
	) => void;
}

const AuthContext = createContext<AuthContextProps>({
	auth: false,
	setAuth: () => {},
	state: 'initial',

	userId: undefined,
	setUserId: () => {},

	signin: () => {},
	signout: () => {},
	signouteverywhere: () => {},
});

const useProvideAuth = () => {
	// -------------------------------------------------
	// State
	const [auth, setAuthState] = useState(
		localStorage.getItem('auth') === 'true' || false
	);
	const [userId, setUserId] = useState<number | undefined>(undefined);
	const queryClient = useQueryClient();
	const params = useParams();
	const location = useLocation();

	const [state, setState] = useState<
		'initial' | 'loading' | 'error' | 'success'
	>('initial');

	const [successTimeout, setSuccessTimeout] =
		useState<ReturnType<typeof setTimeout>>();
	const { organisation, clearOrganisation, initialOrgLs } = useOrganisation();
	const navigate = useNavigate();

	// -------------------------------------------------
	// Queries & Mutations
	const loginMutation = useMutation(api.auth.login);
	const logoutMutation = useMutation(api.auth.logout);
	const logoutAllDevicesMutation = useMutation(api.auth.logoutAllDevices);

	// -------------------------------------------------
	// Internal methods
	const resetState = () => {
		if (successTimeout) {
			clearTimeout(successTimeout);
		}
		setSuccessTimeout(
			setTimeout(() => {
				setState('initial');
			}, 1000)
		);
	};

	const authCheck = (mode: 'get' | 'set', value?: boolean) => {
		const authCheck = localStorage.getItem('auth_check');
		if (mode === 'get') {
			if (authCheck === null) {
				return false;
			}
			return authCheck === 'true' ? true : false;
		}
		if (mode === 'set') {
			localStorage.setItem('auth_check', value?.toString() || 'false');
		}
	};

	const checkUserLoggedIn = async () => {
		if (!authCheck('get')) {
			authCheck('set', true);
			try {
				const userRes = await fetch(`${C.API_BASE_URL}/user`, {
					method: 'GET',
					credentials: 'include',
				});
				if (userRes.status === 200) {
					setAuth(true);
					navigate('/');
				}
			} catch (error) {}
			authCheck('set', false);
		}
	};

	// -------------------------------------------------
	// Public methods
	const setAuth = (state: boolean) => {
		localStorage.setItem('auth', state.toString());
		setAuthState(state);
		if (!state) {
			queryClient.cancelQueries();

			if (location.pathname.includes('login')) return;

			if (!organisation) {
				navigate('/login');
			} else {
				if (params.organisationSlug !== undefined) {
					navigate(`/${params.organisationSlug}/login`);
				} else navigate(`/${organisation?.slug}/login`);
			}

			clearOrganisation();
			queryClient.clear();
		}
	};

	const signin: AuthContextProps['signin'] = async (
		email,
		password,
		onSuccess,
		onError
	) => {
		setState('loading');
		await api.root.crsf();
		await loginMutation.mutateAsync(
			{
				body: {
					email: email,
					password: password,
				},
			},
			{
				onSuccess: () => {
					sessionStorage.removeItem('pause_org_sync');
					initialOrgLs('set', false);
					setAuth(true);
					setState('success');
					onSuccess();

					organisationTheme.resetTheme();
				},
				onError: (error) => {
					initialOrgLs('set', false);
					setAuth(false);
					setState('error');
					onError(error as AxiosAPIError);
				},
			}
		);
		resetState();
	};

	const signout: AuthContextProps['signout'] = async (onSuccess, onError) => {
		setState('loading');
		await logoutMutation.mutateAsync(undefined, {
			onSuccess: () => {
				sessionStorage.removeItem('pause_org_sync');
				initialOrgLs('set', false);
				setAuth(false);
				setState('success');
				onSuccess();
			},
			onError: (error) => {
				initialOrgLs('set', false);
				setAuth(false);
				setState('error');
				onError(error as AxiosAPIError);
			},
		});
		resetState();
	};

	const signouteverywhere: AuthContextProps['signouteverywhere'] = async (
		onSuccess,
		onError
	) => {
		setState('loading');
		await logoutAllDevicesMutation.mutateAsync(undefined, {
			onSuccess: () => {
				initialOrgLs('set', false);
				setAuth(false);
				setState('success');
				onSuccess();
			},
			onError: (error) => {
				initialOrgLs('set', false);
				setAuth(false);
				setState('error');

				onError(error as AxiosAPIError);
			},
		});
		resetState();
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (!auth) {
			checkUserLoggedIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	// -------------------------------------------------
	// Return
	return {
		auth,
		setAuth,
		state,
		userId,
		setUserId,
		signin,
		signout,
		signouteverywhere,
	};
};

interface ProvideAuthProps {
	children: React.ReactNode;
}

export const ProvideAuth: React.FC<ProvideAuthProps> = ({ children }) => {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	return useContext(AuthContext);
};
