import crsf from './csrf';
import status from './status';
import getAuthenticatedUser from './get-authenticated-user';
import acceptTerms from './accept-terms';

const actions = {
	crsf,
	status,
	getAuthenticatedUser,
	acceptTerms,
};

export default actions;
