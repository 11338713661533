import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';
import SingleImageUpload from '@components/forms/SingleImageUpload';

interface UpdateChapterActionProps extends ActionProps {
	chapterId: number;
	modalOpen: boolean;
}

const UpdateChapterAction: React.FC<UpdateChapterActionProps> = ({
	callback,
	chapterId,
	modalOpen,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [initialDataSet, setInitialDataSet] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [image, setImage] = useState<File | undefined | null>(undefined);

	// --------------------------------------
	// Queries / Mutations
	const chapter = useQuery(
		['sa.chapters.getSingle', chapterId],
		() => {
			return api.sa.chapters.getSingle({
				id: chapterId,
				include: {
					media: true,
				},
			});
		},
		{
			onSuccess: (data) => {
				if (initialDataSet) return;
				setInitialDataSet(true);
				setTitle(data.data.data.title);
				setDescription(data.data.data.desc || '');
			},
		}
	);

	const updateChapter = useMutation(api.sa.chapters.updateSingle, {
		onSuccess: (data) => {
			formState.errors.setErrors({});
			updateChapter.reset();
			queryClient.invalidateQueries(['sa.chapters.getAll']);
			queryClient.invalidateQueries(['sa.chapters.getSingle', chapterId]);
			addToast({
				title: translate('toast_updated_title', {
					name: 'Chapter',
				}),
				message: translate('toast_updated_message', {
					name: 'Chapter',
				}),
				type: 'success',
			});
			setTitle(data.data.data.title);
			setDescription(data.data.data.desc || '');
			callback(true, data.data.data);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		const body: Partial<{
			title: string;
			desc: string;
			image: File | undefined | null;
		}> = helpers.deepDiff(
			{
				title: chapter.data?.data.data.title,
				desc: chapter.data?.data.data.desc || '',
			},
			{
				title: title,
				desc: description,
			}
		);
		body.image = image;

		await updateChapter.mutateAsync({
			id: chapterId,
			body: body,
		});
	};
	const resetForm = () => {
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		updateChapter.reset();
		setTitle(chapter.data?.data.data.title || '');
		setDescription(chapter.data?.data.data.desc || '');
		setImage(undefined);
	};

	// --------------------------------------
	// Effects
	useEffect(() => {
		if (!modalOpen) {
			resetForm();
			setInitialDataSet(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOpen]);

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={chapter.isLoading}
			isError={chapter.isError}>
			<SingleImageUpload
				id="image"
				name="image"
				errors={formState.errors.value['image']}
				required={false}
				formats={['image/jpeg']}
				value={image}
				setValue={setImage}
				describedBy={translate('image_described_by', {
					width: 'min 600',
					height: 'min 338',
					type: 'jpeg',
				})}
				current={chapter.data?.data.data.thumbnail || undefined}
			/>
			<Input
				id="chapterTitle"
				type="text"
				label={translate('title')}
				name="title"
				value={title}
				setValue={setTitle}
				errors={formState.errors.value['title']}
				required={true}
			/>
			<Textarea
				id="chapterDescription"
				label={translate('description')}
				name="desc"
				value={description}
				setValue={setDescription}
				errors={formState.errors.value['desc']}
				required={false}
			/>
			<FormActionModal
				loading={{
					is: updateChapter.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateChapter.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('update')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default UpdateChapterAction;
