import { translate } from '@lang/index';
import { useState } from 'react';
import {
	DndContext,
	pointerWithin,
	DragEndEvent,
	useSensor,
	PointerSensor,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/partials/Button';
import type { Item } from '@components/groups/course-builder/Container';
import { DragOverlay } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SelectedItem from '@components/groups/course-builder/SelectedItem';
import UpdateChapter from '@components/modal/UpdateChapter';

interface CourseBuilderSelectedProps {
	selectedItems: Item[];
	setSelectedItems: React.Dispatch<React.SetStateAction<Item[]>>;
	removeSelectedItem: (item: Item) => void;
	setOpenCreateChapter: (value: boolean) => void;
	mode: 'create' | 'edit';
	categories: SaCategoriesRes[];
}

const CourseBuilderSelected: React.FC<CourseBuilderSelectedProps> = ({
	setOpenCreateChapter,
	selectedItems,
	setSelectedItems,
	removeSelectedItem,
	mode,
	categories,
}) => {
	// -------------------------------------------------
	// State
	const [activeItem, setActiveItem] = useState<Item | null>(null);

	const [openChapterUpdate, setOpenChapterUpdate] = useState(false);
	const [targetChapterId, setTargetChapterId] = useState<number | undefined>(
		undefined
	);
	const [targetChapterIndex, setTargetChapterIndex] = useState<
		number | undefined
	>(undefined);

	// -------------------------------------------------
	// Functions
	const onDragStart = (event: DragEndEvent) => {
		const id = event.active.id.toString();
		// set the drag item
		const item = selectedItems.find((item) => item.id === id);
		setActiveItem(item ? item : null);
	};
	const onDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (active.id !== over?.id) {
			const oldIndex = selectedItems.findIndex(
				(item) => item.id === active.id
			);
			const newIndex = selectedItems.findIndex(
				(item) => item.id === over?.id
			);
			const newItems = arrayMove(selectedItems, oldIndex, newIndex);
			setSelectedItems(newItems);
		}
	};
	const onDragCancel = () => {
		setActiveItem(null);
	};

	const sensor = useSensor(PointerSensor, {
		activationConstraint: {
			distance: 8,
		},
	});

	// -------------------------------------------------
	// Render
	return (
		<>
			<DndContext
				sensors={[sensor]}
				collisionDetection={pointerWithin}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragCancel={onDragCancel}>
				<div className={'h-full w-full'}>
					{/* Add chapter button */}
					<div className="w-full border-b border-border bg-white p-7">
						<Button
							theme="dark"
							className="!block w-full bg-uiDarkGray !text-left"
							onClick={() => setOpenCreateChapter(true)}>
							{translate('add_chapter')}
							<FontAwesomeIcon
								icon={faPlus}
								className="ml-2 text-sm"
							/>
						</Button>
					</div>
					{/* Selected */}
					<div className="h-[calc(100%-97px)]">
						<ul className={'grid grid-cols-1 gap-2 p-7'}>
							<SortableContext
								items={selectedItems}
								strategy={verticalListSortingStrategy}>
								{selectedItems.map((item, index) => (
									<SelectedItem
										key={index}
										data={item}
										items={selectedItems}
										removeItem={removeSelectedItem}
										mode={mode}
										categories={categories}
										openChapterUpdate={() => {
											setOpenChapterUpdate(true);
											setTargetChapterId(item.data.id);
											setTargetChapterIndex(index);
										}}
									/>
								))}
							</SortableContext>
						</ul>
					</div>

					<DragOverlay>
						{activeItem ? (
							<ul className="list-style-none opacity-30">
								<SelectedItem
									removeItem={() => {}}
									data={activeItem}
									items={[]}
									categories={[]}
								/>
							</ul>
						) : null}
					</DragOverlay>
				</div>
			</DndContext>

			{targetChapterId !== undefined && (
				<UpdateChapter
					open={openChapterUpdate}
					setOpen={setOpenChapterUpdate}
					onSuccess={(chapter) => {
						if (targetChapterIndex === undefined) return;
						setSelectedItems((prev) => {
							const newItems = [...prev];
							newItems[targetChapterIndex] = {
								id: selectedItems[targetChapterIndex].id,
								type: 'chapter',
								data: chapter,
							};
							return newItems;
						});
					}}
					chapterId={targetChapterId}
				/>
			)}
		</>
	);
};

export default CourseBuilderSelected;
