import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number | string;
	courseId: number | string;
	include: {
		items: boolean;
		itemsCount: boolean;
		itemsModel: boolean;
		itemsModelType: boolean;
		media: boolean;
		mediaCount: boolean;
		type: boolean;
		typeCount: boolean;
		teams: boolean;
		users: boolean;
		meta: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<CourseRes>>(
		`/organisations/${props.organisationId}/courses/${
			props.courseId
		}${buildQueryString(undefined, [
			{ key: 'items', include: props.include.items },
			{ key: 'itemsCount', include: props.include.itemsCount },
			{ key: 'items.model', include: props.include.itemsModel },
			{ key: 'items.model.type', include: props.include.itemsModelType },
			{ key: 'media', include: props.include.media },
			{ key: 'mediaCount', include: props.include.mediaCount },
			{ key: 'type', include: props.include.type },
			{ key: 'typeCount', include: props.include.typeCount },
			{ key: 'teams', include: props.include.teams },
			{ key: 'users', include: props.include.users },
			{ key: 'meta', include: props.include.meta },
		])}`
	);
};
export default getSingle;
