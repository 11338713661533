import { http } from '@utils/index';

interface Props {
	organisationId: number | string;
	videoId: number | string;
	body: {
		course_id: number | string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<VideosViewsRes>>(
		`/organisations/${props.organisationId}/videos/${props.videoId}/viewed`,
		props.body
	);
};
export default createSingle;
