import { useState, useMemo } from 'react';
import classNames from 'classnames';
import placeholderCourseImg from '@assets/images/placeholder-course.jpg';
// Utils
import { helpers } from '@utils/index';
// Components
import Button from '@components/partials/Button';
import CourseBreakdownIcons from '@components/partials/CourseBreakdownIcons';
import AccessDates from '@components/partials/AccessDates';
import Badges from '@components/partials/Badges';
// Actions
import BookmarkButtonAction from '@components/actions/BookmarkButton';

interface CourseCardProps {
	course: CourseRes;
	organisationSlug: string;
	bookmarks?: BookmarkesRes[];
	categories: CategoriesRes[];
	courseProgress: OrgCourseProgressRes[];
	functions?: {
		refetchBookmarks?: () => void;
		showNavigationWarning?: (courseId: number) => void;
	};
}

const CourseCard: React.FC<CourseCardProps> = ({
	course,
	organisationSlug,
	bookmarks,
	categories,
	functions,
	courseProgress,
}) => {
	// -------------------------------------------------
	// State
	const { refetchBookmarks, showNavigationWarning } = functions || {};
	const [overButton, setOverButton] = useState(false);

	// -------------------------------------------------
	// Memos
	const bookmark = useMemo(() => {
		return bookmarks?.find(
			(b) =>
				b.bookmarkable_id === course.id &&
				b.bookmarkable_type === 'course'
		);
	}, [bookmarks, course.id]);

	const accessInfo = useMemo(() => {
		const org = course.organisations ? course.organisations[0] : undefined;
		return helpers.courseAccessInfo(org);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course.organisations]);

	const progress = useMemo(() => {
		return courseProgress.find(
			(progress) => progress.course_id === course.id
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseProgress, course]);

	const percent = useMemo(() => {
		return helpers.convertPercentString(progress?.percentage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [progress]);

	// -------------------------------------------------
	// Render
	return (
		<li
			className={classNames(
				'flex flex-col justify-between overflow-hidden rounded-md border border-border',
				{
					'opacity-60': accessInfo.access === false,
				}
			)}>
			<div className="w-full flex-col">
				{/* Top Row */}
				<div className="relative w-full pb-[58%] ">
					{bookmarks && (
						<span className="absolute top-2.5 right-2.5 z-10">
							<BookmarkButtonAction
								courseId={course.id}
								bookmarkable_id={course.id}
								bookmark={bookmark}
								type={'course'}
								simplified={true}
								refetch={refetchBookmarks}
							/>
						</span>
					)}
					<div className="absolute inset-0 z-[5] p-2.5">
						{percent > 0 && (
							<Badges theme="green">{percent}%</Badges>
						)}
					</div>
					{/* Image */}
					<div className="absolute inset-0 z-0 overflow-hidden">
						<img
							src={course.thumbnail || placeholderCourseImg}
							alt={course.title}
							className={classNames(
								'h-full w-full scale-100 object-cover transition-transform duration-200',
								{
									'scale-110': overButton,
								}
							)}
						/>
					</div>
				</div>
				<span className="block h-2 w-full bg-uiLightGray">
					<span
						className={classNames('block h-full', {
							'bg-red': percent <= 25,
							'bg-[#FFC107]': percent > 25 && percent < 100,
							'bg-greenMid': percent === 100,
						})}
						style={{
							width: `${percent}%`,
						}}></span>
				</span>
				{/* Textarea */}
				<div className="p-2.5">
					<div className="mb-2.5 flex flex-wrap items-center gap-2.5">
						<AccessDates
							start={accessInfo.start}
							end={accessInfo.end}
						/>
					</div>
					<h3 className="mb-1.5 min-h-[48px] font-bold text-title line-clamp-2">
						{course.title}
					</h3>
					<div className="min-h-[40px]">
						{course.desc && (
							<p className="text-sm line-clamp-2">
								{course.desc}
							</p>
						)}
					</div>
					<div className="mt-5">
						{/* Course Breakdown */}
						<CourseBreakdownIcons
							size="xs"
							items={course.items || []}
							categories={categories}
						/>
					</div>
				</div>
			</div>
			<div className="p-2.5 pt-2.5">
				<div
					className=""
					onMouseOver={() => {
						setOverButton(true);
					}}
					onMouseLeave={() => {
						setOverButton(false);
					}}>
					{accessInfo.access ? (
						<Button
							theme="outline"
							className={'w-full'}
							href={`/${organisationSlug}/courses/${course.id}/redirect`}
							type={'link'}>
							Go to course
						</Button>
					) : (
						<Button
							theme="outline"
							className={'w-full'}
							onClick={() => {
								if (showNavigationWarning)
									showNavigationWarning(course.id);
							}}
							type={'button'}>
							Go to course
						</Button>
					)}
				</div>
			</div>
		</li>
	);
};

export default CourseCard;
