import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import CategoryRow from '@components/rows/CategoryRow';
import Pagination from '@components/query/Pagination';
// Modals
import DeleteCategory from '@components/modal/DeleteCategory';
import UpdateCategory from '@components/modal/UpdateCategory';
import TopPagination from '@components/query/FormUtilityBar';

interface CategoryTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
	categoryScopes: SaCategoryScopesRes[];
	scope: CategoryScopes;
}

const CategoryTable: React.FC<CategoryTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	categoryScopes,
	scope,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } =
		useSelectedRow<SaCategoriesRes | null>(null);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);
	const [openUpdate, setOpenUpdate] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const categories = useQuery(
		['sa.categories.getMultiple', queryString, scope],
		() => {
			return api.sa.categories.getMultiple({
				queryString,
				include: {
					meta: true,
					metaCount: false,
				},
				filters: [
					{
						key: 'scope',
						value: scope,
					},
				],
			});
		},
		{
			enabled,
		}
	);

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={categories.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: categories.isLoading,
					error: categories.isError,
					noData: categories.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Category',
					}),
					message: translate('no_data_message', {
						name: 'category',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							type: 'standard',
						},
						{
							label: translate('ref'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'ref'
							),
							type: 'sortable',
						},
						{
							label: translate('date_last_modified'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'updated_at'
							),
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{categories.data?.data.data.map((category, index) => (
						<CategoryRow
							key={index}
							category={category}
							categoryScopes={categoryScopes}
							selectRow={(category, action) => {
								setSelectedRow(category);
								switch (action) {
									case 'delete': {
										setOpenDelete(true);
										break;
									}
									case 'update': {
										setOpenUpdate(true);
										setSelectedRow(category);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={categories.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{/* Modals */}
				{selectedRow !== null && (
					<>
						<DeleteCategory
							scope={scope}
							categoryId={selectedRow.id}
							open={openDelete}
							setOpen={setOpenDelete}
							onSuccess={() => searchParams.page.setValue(1)}
						/>
						<UpdateCategory
							scope={scope}
							category={selectedRow}
							open={openUpdate}
							setOpen={setOpenUpdate}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default CategoryTable;
