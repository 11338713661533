import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Utils
import { helpers } from '@utils/index';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormActionModal from '@components/forms/FormActionModal';

interface CreatePlanActionProps extends ActionProps {}

const CreatePlanAction: React.FC<CreatePlanActionProps> = ({ callback }) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [seats, setSeats] = useState(0);
	const [users, setUsers] = useState(0);

	// --------------------------------------
	// Queries / Mutations
	const createPlan = useMutation(api.sa.plans.createSingle, {
		onSuccess: () => {
			// make invalidate any organisation queries
			queryClient.invalidateQueries(['sa.plans.getMultiple']);
			queryClient.invalidateQueries(['sa.plans.getAll']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Plan',
				}),
				message: translate('toast_created_message', {
					name: 'Plan',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createPlan.mutateAsync({
			body: {
				name,
				seats,
				users,
			},
		});
	};
	const resetForm = () => {
		setName('');
		setSeats(0);
		setUsers(0);
		createPlan.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<Input
				id="seats"
				type="number"
				label={translate('seats')}
				name="seats"
				value={seats}
				setValue={(seat) => {
					setSeats(parseInt(seat));
				}}
				errors={formState.errors.value['seats']}
				required={true}
				describedBy={translate(
					'determines_how_many_users_can_be_active'
				)}
			/>
			<Input
				id="users"
				type="number"
				label={translate('users')}
				name="users"
				value={users}
				setValue={(user) => {
					setUsers(parseInt(user));
				}}
				errors={formState.errors.value['users']}
				required={true}
				describedBy={translate(
					'determines_how_many_users_can_belong_to_org'
				)}
			/>
			<FormActionModal
				loading={{
					is: createPlan.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createPlan.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreatePlanAction;
