import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		courses: boolean;
		coursesCount: boolean;
		media: boolean;
		mediaCount: boolean;
		plan: boolean;
		planCount: boolean;
		users: boolean;
		usersCount: boolean;
		subscriptionsCount: boolean;
		subscriptionsItems: boolean;
		seatedUsersCount: boolean;
	};
	sort?: {
		name?: 'asc' | 'desc';
	};
	filters?: {
		has_plan?: 1 | 0;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaOrganisationsRes[]>>(
		`/sa/organisations${buildQueryString(
			props.queryString,
			[
				{ key: 'courses', include: props.include.courses },
				{ key: 'coursesCount', include: props.include.coursesCount },
				{ key: 'media', include: props.include.media },
				{ key: 'mediaCount', include: props.include.mediaCount },
				{ key: 'plan', include: props.include.plan },
				{ key: 'planCount', include: props.include.planCount },
				{ key: 'users', include: props.include.users },
				{ key: 'usersCount', include: props.include.usersCount },
				{
					key: 'subscriptionsCount',
					include: props.include.subscriptionsCount,
				},
				{
					key: 'subscriptions.items',
					include: props.include.subscriptionsItems,
				},
				{
					key: 'seatedUsersCount',
					include: props.include.seatedUsersCount,
				},
			],
			[{ key: 'has_plan', value: props.filters?.has_plan }],
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
