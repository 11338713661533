import toast from 'react-hot-toast';
// Components
import CustomToast from '@components/partials/CustomToast';

interface ToastOptions {
	title: string;
	message: string;
	type: 'success' | 'error';
}

const useToast = () => {
	const addToast = ({ title, message, type }: ToastOptions) => {
		toast.custom(
			(t) => (
				<CustomToast
					title={title}
					message={message}
					type={type}
					t={t}
				/>
			),
			{
				id: `${type}:${title}-${message}`,
			}
		);
	};
	return { addToast };
};

export default useToast;
