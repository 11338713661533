import classNames from 'classnames';
import { useState } from 'react';
// Components
import { NavLink } from 'react-router-dom';
import NavigationWarning from '@components/modal/NavigationWarning';

/*
    Tabbed buttons for changing the page content. Ineded to be used in the PageHeading component.
*/

interface HeadingTabsProps {
	activeKey?: string;
	tabs: Array<{
		name: string;
		href?: string;
		key?: string;
		navigationWarning?: boolean;
		onClick?: () => void;
	}>;
}

const HeadingTabs: React.FC<HeadingTabsProps> = ({ tabs, activeKey }) => {
	const [openNavigationWarning, setOpenNavigationWarning] = useState(false);
	const [activeTab, setActiveTab] = useState(tabs[0]);

	return (
		<>
			<ul className="flex w-max min-w-max overflow-hidden rounded-[6px] border border-border p-[1px]">
				{tabs.map((tab, index) => (
					<li key={index}>
						{tab.href && (
							<NavLink
								to={tab.href}
								className={({ isActive }) =>
									classNames(
										'mr-[2px] inline-flex cursor-pointer items-center px-5 py-2.5 text-sm font-medium transition-colors duration-200 hover:bg-brandPrimaryHover focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight',
										{
											'bg-brandPrimary text-brandPrimaryText':
												isActive,
											'bg-white text-title hover:text-brandPrimaryText':
												!isActive,
											'rounded-l-[6px]': index === 0,
											'!mr-0 rounded-r-[6px]':
												index === tabs.length - 1,
										}
									)
								}
								end>
								{tab.name}
							</NavLink>
						)}
						{tab.onClick && tab.key && activeKey && (
							<button
								onClick={() => {
									if (tab.onClick) {
										if (tab.navigationWarning) {
											setActiveTab(tab);
											setOpenNavigationWarning(true);
										} else {
											tab.onClick();
										}
									}
								}}
								className={classNames(
									'mr-[2px] inline-flex cursor-pointer items-center px-5 py-2.5 text-sm font-medium transition-colors duration-200 hover:bg-brandPrimaryHover focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight',
									{
										'bg-brandPrimary text-brandPrimaryText':
											tab.key === activeKey,
										'bg-white text-title hover:text-brandPrimaryText':
											tab.key !== activeKey,
										'rounded-l-[6px]': index === 0,
										'!mr-0 rounded-r-[6px]':
											index === tabs.length - 1,
									}
								)}>
								{tab.name}
							</button>
						)}
						{tab.onClick && !tab.key && (
							<button
								onClick={() => {
									if (tab.onClick) {
										if (tab.navigationWarning) {
											setActiveTab(tab);
											setOpenNavigationWarning(true);
										} else {
											tab.onClick();
										}
									}
								}}
								className={classNames(
									'mr-[2px] inline-flex cursor-pointer items-center bg-white px-5 py-2.5 text-sm font-medium text-title transition-colors duration-200 hover:bg-brandPrimaryHover hover:text-brandPrimaryText focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight',
									{
										'rounded-l-[6px]': index === 0,
										'!mr-0 rounded-r-[6px]':
											index === tabs.length - 1,
									}
								)}>
								{tab.name}
							</button>
						)}
					</li>
				))}
			</ul>
			<NavigationWarning
				open={openNavigationWarning}
				setOpen={setOpenNavigationWarning}
				onSuccess={() => {
					if (activeTab.onClick) activeTab.onClick();
				}}
			/>
		</>
	);
};

export default HeadingTabs;
