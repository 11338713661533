// Constants
import C from '@root/constants';

export interface Props {
	id: string | number;
}

const previewAward = (props: Props) => {
	return `${C.API_BASE_URL}/sa/awards/${props.id}/preview`;
};
export default previewAward;
