import { http } from '@utils/index';

interface Props {
	body: {
		email: string;
		password: string;
	};
}
interface ResponseData {}

const login = (props: Props) => {
	return http.post<APIResponse<ResponseData>>('/login', props.body);
};
export default login;
