import { http } from '@utils/index';

interface Props {
	id: number;
	body: {
		name?: string;
		slug?: string;
		logo?: File | null;
		meta?: {
			support_url?: string;
			colours?: {
				header_background?: string;
				primary_accent?: string;
				secondary_accent?: string;
				tertiary_accent?: string;
			};
		};
	};
}

const update = (props: Props) => {
	return http.post<APIResponse<OrganisationRes>>(
		`/organisations/${props.id}`,
		{
			_method: 'put',
			...props.body,
		},
		{
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}
	);
};
export default update;
