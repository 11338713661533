import { useRef, useMemo } from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
// Assets
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Actions
import BookmarkButtonAction from '@components/actions/BookmarkButton';

interface VideoCardProps {
	video: VideosRes;
	courseId?: number;
	bookmarks?: BookmarkesRes[];
	functions?: {
		refetchBookmarks?: () => void;
	};
}

const VideoCard: React.FC<VideoCardProps> = ({
	video,
	courseId,
	bookmarks,
	functions,
}) => {
	const previewRef = useRef<HTMLImageElement>(null);
	const { organisation } = useOrganisation();
	const { refetchBookmarks } = functions || {};

	// -------------------------------------------------
	// Memos
	const bookmark = useMemo(() => {
		return bookmarks?.find(
			(b) =>
				b.bookmarkable_id === video.id &&
				b.bookmarkable_type === 'video'
		);
	}, [bookmarks, video]);

	// -------------------------------------------------
	// Render
	return (
		<a
			href={
				courseId
					? `/${organisation?.slug}/courses/${courseId}/video/${video.id}`
					: `#`
			}
			className="block w-full"
			onMouseOver={() => {
				// Resets the webp preview to first frame
				const target = previewRef.current;
				// eslint-disable-next-line
				if (target) previewRef.current.src = previewRef.current?.src;
			}}>
			{/* Image */}
			<div className="group  relative aspect-video cursor-pointer overflow-hidden rounded-md border border-border">
				{/* Preview */}
				{video.meta?.preview_animation_url && (
					<div className="absolute inset-0 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
						<img
							ref={previewRef}
							className="block h-full w-full object-cover"
							src={video.meta?.preview_animation_url}
							alt="Video preview"
						/>
					</div>
				)}
				{/* Icon */}
				<div className="pointer-events-none absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 opacity-100 transition-opacity duration-200 group-hover:opacity-40">
					<span className="flex h-10 w-10 items-center justify-center rounded-full bg-black bg-opacity-60">
						<FontAwesomeIcon
							icon={faPlay}
							className="text-md text-white"
						/>
					</span>
				</div>
				{/* Thumbnail */}
				<img
					className="block h-full w-full object-cover"
					src={video.thumbnail || placeholderVideo}
					alt="Video preview thumbnail"
				/>
				{bookmarks && (
					<span className="absolute top-2.5 right-2.5">
						<BookmarkButtonAction
							courseId={courseId}
							bookmarkable_id={video.id}
							bookmark={bookmark}
							type={'video'}
							simplified={true}
							refetch={refetchBookmarks}
						/>
					</span>
				)}
			</div>
			{/* Text */}
			<div className="pt-2.5">
				<h3 className="font-bold text-title line-clamp-1">
					{video.title}
				</h3>
				<p className="mt-1 text-sm text-body line-clamp-2">
					{video.desc}
				</p>
			</div>
		</a>
	);
};

export default VideoCard;
