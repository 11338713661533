import { http } from '@utils/index';

interface Props {
	body: {
		name: string;
	};
}

const createToken = (props: Props) => {
	return http.post<{
		token: string;
	}>('/tokens', props.body);
};
export default createToken;
