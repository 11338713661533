// Constants
import C from '@root/constants';

export interface Props {
	awardId: string | number;
}

const downloadUserAward = (props: Props) => {
	return `${C.API_BASE_URL}/sa/user-awards/${props.awardId}/download`;
};
export default downloadUserAward;
