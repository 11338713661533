import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdateAwardAction from '@components/actions/UpdateAward';

interface UpdateAwardProps {
	id: number;
	open: boolean;
	setOpen: (state: boolean) => void;
}

const UpdateAward: React.FC<UpdateAwardProps> = ({ id, open, setOpen }) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.update_award.title}>
			<UpdateAwardAction
				id={id}
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default UpdateAward;
