import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Button from '@components/partials/Button';
import Input from '@components/forms/Input';
import Form from '@components/forms/Form';
import FormAction from '@components/forms/FormAction';

interface UpdateCurrentUserActionProps extends ActionProps {
	user: CurrentUserRes;
}

const UpdateCurrentUserAction: React.FC<UpdateCurrentUserActionProps> = ({
	user,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const [name, setName] = useState(user?.name || '');
	const [email, setEmail] = useState(user?.email || '');

	// --------------------------------------
	// Queries / Mutations
	const updateUser = useMutation(api.auth.updateMe, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Account',
				}),
				message: translate('toast_account_updated_message'),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateUser.mutateAsync({
			body: returnModifiedData([
				{
					key: 'name',
					state: name,
					defaultState: user?.name,
				},
				{
					key: 'email',
					state: email,
					defaultState: user?.email,
				},
			]),
		});
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				value={name}
				setValue={setName}
				name="name"
				label={translate('name')}
				id="name"
				type="text"
				autoComplete="name"
				errors={formState.errors.value['name']}
				required={true}
			/>
			<Input
				value={email}
				setValue={setEmail}
				name="email"
				label={translate('email')}
				id="email"
				type="email"
				autoComplete="email"
				errors={formState.errors.value['email']}
				required={true}
				describedBy={translate('account_email_describedby')}
			/>
			{/* Update */}
			<FormAction
				loading={{
					is: updateUser.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateUser.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateCurrentUserAction;
