import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	queryString?: string;
	include: {
		bookmarkable: boolean;
		course: boolean;
		courseMedia: boolean;
		bookmarkableMedia: boolean;
	};
	filters?: Array<{
		key:
			| 'bookmarkable_type'
			| 'bookmarkable_id'
			| 'has_context'
			| 'course_id';
		value: 'course' | 'quiz' | 'video' | number | 1 | 0 | string | number[];
	}>;
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<BookmarkesRes[]>>(
		`/organisations/${props.organisationId}/bookmarks${buildQueryString(
			props.queryString,
			[
				{ key: 'bookmarkable', include: props.include.bookmarkable },
				{ key: 'course', include: props.include.course },
				{ key: 'course.media', include: props.include.courseMedia },
				{
					key: 'bookmarkable.media',
					include: props.include.bookmarkableMedia,
				},
			],
			props.filters,
			props.perPage
		)}`
	);
};
export default getMultiple;
