import { http } from '@utils/index';

export interface Props {
	body: {
		award_type_id: number;
		name: string;
		items: Array<{
			model_type: 'video' | 'quiz' | 'course';
			model_id: number;
		}>;
		meta?: {
			text?: string;
			show_icheme_logo?: 1 | 0;
		};
		active: 1 | 0;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SaAwardsRes>>(`/sa/awards`, {
		...props.body,
	});
};
export default createSingle;
