import { useMemo } from 'react';
import classNames from 'classnames';

// -------------------------------------------------
// Preset list / dropdown
interface FilterPresetsRowProps {
	filter: Array<{
		key: string;
		value: string;
		type?: 'boolean' | 'input' | 'select' | 'date';
	}>;
	setMultipleFilters: (
		filters: Array<{ key: string; value: string }>
	) => void;
	presets: Array<{
		label: string;
		filters: Array<{ key: string; value: string }>;
		permission?: boolean;
	}>;
}

const FilterPresetsRow: React.FC<FilterPresetsRowProps> = ({
	filter,
	setMultipleFilters,
	presets,
}) => {
	return (
		<div className="no-scrollbar w-auto overflow-x-auto ">
			<ul className="flex w-max justify-start">
				{presets.map((p, index) => (
					<FilterPresetButton
						key={index}
						filter={filter}
						setMultipleFilters={setMultipleFilters}
						preset={p}
					/>
				))}
			</ul>
		</div>
	);
};

// -------------------------------------------------
// Preset Button
interface FilterPresetButtonProps {
	filter: FilterPresetsRowProps['filter'];
	setMultipleFilters: FilterPresetsRowProps['setMultipleFilters'];
	preset: {
		label: string;
		filters: Array<{ key: string; value: string }>;
		permission?: boolean;
	};
}

const FilterPresetButton: React.FC<FilterPresetButtonProps> = ({
	filter,
	setMultipleFilters,
	preset,
}) => {
	// -------------------------------------------------
	// Memos
	const isActive = useMemo(() => {
		// for each filter in preset, check if the current filter has the same key and value.
		// If all filters match, then the preset is active.
		return preset.filters.every((p) => {
			const res = filter.some((f) => {
				return f.key === p.key && f.value === p.value;
			});
			return res;
		});
	}, [filter, preset]);

	// -------------------------------------------------
	// Render
	if (preset?.permission === false) return null;

	return (
		<li className="mr-2.5 min-w-max last:mr-0">
			<button
				className={classNames(
					'font-base rounded-md border px-2 py-1 text-sm text-title transition-colors duration-200 hover:bg-brandPrimaryHover hover:text-brandPrimaryText',
					{
						'border-brandPrimary bg-brandPrimary': isActive,
						'border-border': !isActive,
					}
				)}
				onClick={() => {
					if (isActive) {
						setMultipleFilters(
							preset.filters.map((f) => ({ ...f, value: '' }))
						);
					} else setMultipleFilters(preset.filters);
				}}>
				{preset.label}
			</button>
		</li>
	);
};

export default FilterPresetsRow;
