import { translate } from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';

interface ConfirmationModalProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	title?: string;
	onConfirm: () => Promise<void>;
	type?: 'danger' | 'warning';
	onClose: () => void;
	formAction?: {
		loading: {
			is: boolean;
			message: string;
		};
		error: {
			is: boolean;
			message: string;
		};
	};
	children: React.ReactNode;
	buttons?: {
		cancel: string;
		confirm: string;
	};
	size?: 'small' | 'medium' | 'large';
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	open,
	setOpen,
	title,
	children,
	formAction,
	type = 'warning',
	onConfirm,
	onClose,
	buttons = {
		cancel: translate('cancel'),
		confirm: translate('confirm'),
	},
	size = 'small',
}) => {
	// ---------------------
	// Functions
	const toggleModal = (val: boolean) => {
		setOpen(val);
		if (val === false) if (onClose) onClose();
	};

	// ---------------------
	// Render
	return (
		<Modal open={open} setOpen={toggleModal} title={title} size={size}>
			<div>{children}</div>
			<FormActionModal
				loading={{
					is: formAction?.loading.is || false,
					message: formAction?.loading.message || '',
				}}
				error={{
					is: formAction?.error.is || false,
					message: formAction?.error.message || '',
				}}>
				<div className=" min-w-max">
					<Button
						theme="outline"
						type="button"
						className="mr-3"
						onClick={() => {
							setOpen(false);
						}}>
						{buttons.cancel}
					</Button>
					<Button
						theme={type === 'warning' ? 'primary' : 'danger'}
						onClick={async () => {
							if (onConfirm !== undefined) {
								await onConfirm();
								setOpen(false);
							}
						}}>
						{buttons.confirm}
					</Button>
				</div>
			</FormActionModal>
		</Modal>
	);
};

export default ConfirmationModal;
