import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateCategoryAction from '@components/actions/CreateCategory';

interface CreateCategoryProps {
	allowScope: CategoryScopes;
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: (category: SaCategoriesRes) => void;
}

const CreateCategory: React.FC<CreateCategoryProps> = ({
	allowScope,
	open,
	setOpen,
	onSuccess,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={
				allowScope === 'courseCategory'
					? T.modals.create_category.title
					: T.modals.create_category.title_type
			}>
			<CreateCategoryAction
				allowScope={allowScope}
				callback={(success, data) => {
					if (success) {
						setOpen(false);
						onSuccess && onSuccess(data as SaCategoriesRes);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateCategory;
