import { translate } from '@lang/index';
import { useMemo } from 'react';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
// Utils
import { helpers } from '@utils/index';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';
import { Link } from 'react-router-dom';
import IconDropdown from '@components/partials/IconDropdown';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface CourseRowProps {
	course: SaCourseRes;
	stripePrices?: StripePriceRes;
	selectRow: (course: SaCourseRes, action: 'delete' | 'duplicate') => void;
	stripeURL: string;
	type?: 'course' | 'content';
}

const CourseRow: React.FC<CourseRowProps> = ({
	course,
	selectRow,
	stripeURL,
	stripePrices,
	type,
}) => {
	// -------------------------------------------------
	// Memos
	const lowestPrice = useMemo(() => {
		if (!stripePrices) return undefined;
		const prices = stripePrices[`${course.stripe_product_id}`];
		if (!prices) return undefined;
		const lowestPrice = helpers.getLowestPrice(prices);
		return lowestPrice;
	}, [course, stripePrices]);

	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<div className="flex flex-col">
					<Link
						to={`/courses/${course.id}/details`}
						className="text-sm font-semibold hover:underline">
						{course.title}
					</Link>
					<Link
						to={`/courses/${course.id}/redirect`}
						className="text-sm text-brandTertiary hover:underline">
						Preview
					</Link>
				</div>
			</td>
			<td className="td-default">
				{course.ref ? (
					<span className="text-sm text-title">{course.ref}</span>
				) : (
					<>-</>
				)}
			</td>
			{type !== 'content' && (
				<td className="td-default">
					{course.categories && course.categories?.length > 0 ? (
						<Badges theme="grey">
							{course.categories[0].name}
						</Badges>
					) : (
						<>-</>
					)}
				</td>
			)}
			<td className="td-default">
				{lowestPrice !== undefined ? (
					<a href={stripeURL} target="_blank" rel="noreferrer">
						<Badges theme="grey">
							{helpers.formatPrice(
								lowestPrice.unit_amount,
								lowestPrice.currency
							)}
						</Badges>
					</a>
				) : (
					<>-</>
				)}
			</td>

			<td className="td-default">
				{course.updated_at && (
					<DateTimeColumn date={course.updated_at} />
				)}
			</td>

			<DropdownMenuColumn
				showFirst={false}
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/courses/${course.id}/details`,
						show: true,
					},
					{
						label: translate('preview'),
						type: 'link',
						href: `/courses/${course.id}/redirect`,
						show: true,
					},
					{
						label: translate('duplicate'),
						type: 'button',
						show: true,
						onClick: () => selectRow(course, 'duplicate'),
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => selectRow(course, 'delete'),
					},
				]}>
				<IconDropdown
					items={[
						{
							label: translate('progress'),
							href: `/progress?filter[course_id]=${course.id}`,
						},
						{
							label: translate('video_views'),
							href: `/video-views?filter[course_id]=${course.id}`,
						},
						{
							label: translate('assessment_results'),
							href: `/assessment-results?filter[course_id]=${course.id}`,
						},
					]}
					icon={faChartBar}
					title={translate('progress')}
				/>
			</DropdownMenuColumn>
		</tr>
	);
};

export default CourseRow;
