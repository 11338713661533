import { translate } from '@lang/index';
import { useState } from 'react';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import Sort from '@components/query/Sort';
import PlansTable from '@components/table/sa/PlansTable';
// Modals
import CreatePlan from '@components/modal/CreatePlan';
import Search from '@components/query/Search';

const PlanList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
			],
			filter: [],
		});
	const [openCreate, setOpenCreate] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations

	// -----------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('plans')}
				body={translate('plans_body')}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_plan')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<PlansTable
					enabled={enabled}
					queryString={queryString}
					searchParams={searchParams}
					resetFilters={resetFilters}
					utilityBar={
						<Button
							theme={'primary'}
							type="button"
							onClick={() => setOpenCreate(true)}>
							{translate('create_plan')}
						</Button>
					}
				/>
				<CreatePlan open={openCreate} setOpen={setOpenCreate} />
			</PageWrapper>
		</>
	);
};

export default PlanList;
