import { http, buildQueryString } from '@utils/index';

interface Props {
	organisationId: number;
	id: number;
	include: {
		courses: boolean;
		lastLogin: boolean;
		lastLoginCount: boolean;
		teams: boolean;
		teamsCount: boolean;
		roles: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<UserRes>>(
		`/organisations/${props.organisationId}/users/${
			props.id
		}${buildQueryString(undefined, [
			{ key: 'courses', include: props.include.courses },
			{ key: 'teams', include: props.include.teams },
			{ key: 'teamsCount', include: props.include.teamsCount },
			{ key: 'lastLogin', include: props.include.lastLogin },
			{
				key: 'lastLoginCount',
				include: props.include.lastLoginCount,
			},
			{ key: 'roles', include: props.include.roles },
		])}`
	);
};
export default getSingle;
