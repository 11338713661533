import color from 'color';

// -----------------------------
// Types
interface OrganisationThemeProps {
	colours: {
		ui: {
			header?: string;
		};
		accents: {
			primary?: string;
			secondary?: string;
			tertiary?: string;
		};
	};
}

// -----------------------------
// Utility functions
const getColourText = (colour: string) => {
	const colourText = color(colour).isLight() ? '#222222' : '#ffffff';
	return colourText;
};

// -----------------------------
// Public functions
const setTheme = (theme: OrganisationThemeProps) => {
	const root = document.documentElement;

	// -----------------------------
	// Set the logo

	// -----------------------------
	// Set the UI colours
	if (theme.colours.ui.header) {
		root.style.setProperty(
			'--theme-ui-header-background',
			theme.colours.ui.header
		);
		root.style.setProperty(
			'--theme-ui-header-text',
			getColourText(theme.colours.ui.header)
		);
	} else {
		root.style.removeProperty('--theme-ui-header-background');
		root.style.removeProperty('--theme-ui-header-text');
	}

	// -----------------------------
	// Set the accent colours

	// primary
	if (theme.colours.accents.primary) {
		root.style.setProperty(
			'--theme-primary-base',
			theme.colours.accents.primary
		);
		root.style.setProperty(
			'--theme-primary-hover',
			color(theme.colours.accents.primary).darken(0.1).hex()
		);
		root.style.setProperty(
			'--theme-primary-light',
			color(theme.colours.accents.primary).lighten(0.1).hex()
		);
		root.style.setProperty(
			'--theme-primary-text',
			getColourText(theme.colours.accents.primary)
		);
	} else {
		root.style.removeProperty('--theme-primary-base');
		root.style.removeProperty('--theme-primary-hover');
		root.style.removeProperty('--theme-primary-light');
		root.style.removeProperty('--theme-primary-text');
	}

	// secondary
	if (theme.colours.accents.secondary) {
		root.style.setProperty(
			'--theme-secondary-base',
			theme.colours.accents.secondary
		);
		root.style.setProperty(
			'--theme-secondary-hover',
			color(theme.colours.accents.secondary).darken(0.1).hex()
		);
		root.style.setProperty(
			'--theme-secondary-light',
			color(theme.colours.accents.secondary).lighten(0.1).hex()
		);
		root.style.setProperty(
			'--theme-secondary-text',
			getColourText(theme.colours.accents.secondary)
		);
	} else {
		root.style.removeProperty('--theme-secondary-base');
		root.style.removeProperty('--theme-secondary-hover');
		root.style.removeProperty('--theme-secondary-light');
		root.style.removeProperty('--theme-secondary-text');
	}

	// tertiary
	if (theme.colours.accents.tertiary) {
		root.style.setProperty(
			'--theme-tertiary-base',
			theme.colours.accents.tertiary
		);
		root.style.setProperty(
			'--theme-tertiary-hover',
			color(theme.colours.accents.tertiary).darken(0.1).hex()
		);
		root.style.setProperty(
			'--theme-tertiary-light',
			color(theme.colours.accents.tertiary).lighten(0.1).hex()
		);
		root.style.setProperty(
			'--theme-tertiary-text',
			getColourText(theme.colours.accents.tertiary)
		);
	} else {
		root.style.removeProperty('--theme-tertiary-base');
		root.style.removeProperty('--theme-tertiary-hover');
		root.style.removeProperty('--theme-tertiary-light');
		root.style.removeProperty('--theme-tertiary-text');
	}
};
const resetTheme = () => {
	const root = document.documentElement;

	// remove all the theme variables
	root.style.removeProperty('--theme-ui-header-background');
	root.style.removeProperty('--theme-ui-header-text');

	root.style.removeProperty('--theme-primary-base');
	root.style.removeProperty('--theme-primary-hover');
	root.style.removeProperty('--theme-primary-light');
	root.style.removeProperty('--theme-primary-text');

	root.style.removeProperty('--theme-secondary-base');
	root.style.removeProperty('--theme-secondary-hover');
	root.style.removeProperty('--theme-secondary-light');
	root.style.removeProperty('--theme-secondary-text');

	root.style.removeProperty('--theme-tertiary-base');
	root.style.removeProperty('--theme-tertiary-hover');
	root.style.removeProperty('--theme-tertiary-light');
	root.style.removeProperty('--theme-tertiary-text');
};
const getColourByKey = (key: string) => {
	const root = document.documentElement;
	const colour = getComputedStyle(root).getPropertyValue(key);
	return colour;
};

const exportObj = {
	setTheme,
	resetTheme,
	getColourByKey,
};

export default exportObj;
