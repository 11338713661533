import { http, buildQueryString } from '@utils/index';

export interface Props {
	queryString?: string;
	include: {
		firstLogin: boolean;
		lastLogin: boolean;
		organisations: boolean;
		rolesIgnoringTeams: boolean;
	};
	filters?: Array<{
		key: 'organisation_ids' | 'is_super_admin' | 'hide_self';
		value: string | number;
	}>;
	sort?: {
		name?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaUsersRes[]>>(
		`/sa/users${buildQueryString(
			props.queryString,
			[
				{ key: 'firstLogin', include: props.include.firstLogin },
				{ key: 'lastLogin', include: props.include.lastLogin },
				{ key: 'organisations', include: props.include.organisations },
				{
					key: 'rolesIgnoringTeams',
					include: props.include.rolesIgnoringTeams,
				},
			],
			props.filters,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
