import { http } from '@utils/index';

interface Props {
	organisationId: number;
	courseId: number;
}

const getStatus = (props: Props) => {
	return http.get<CourseStatusRes[]>(
		`/organisations/${props.organisationId}/courses/${props.courseId}/status`
	);
};
export default getStatus;
