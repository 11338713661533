import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import Textarea from '@components/forms/Textarea';
import ColourPicker from '@components/forms/ColourPicker';
import Checkbox from '@components/forms/Checkbox';

interface UpdateCategoryActionProps extends ActionProps {
	category: SaCategoriesRes;
}

const UpdateCategoryAction: React.FC<UpdateCategoryActionProps> = ({
	category,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	// Inputs

	const [name, setName] = useState(category.name || '');
	const [ref, setRef] = useState(category.ref || '');
	const [description, setDescription] = useState(category.meta?.desc || '');
	const [iconColour, setIconColour] = useState(
		category.meta?.icon_colour || ''
	);
	const [iconInitials, setIconInitials] = useState(
		category.meta?.icon_initials || ''
	);
	const [publicVal, setPublicVal] = useState<boolean>(
		category.public || false
	);

	// -------------------------------------------------
	// Queries / Mutations
	const updateCategory = useMutation(api.sa.categories.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Category',
				}),
				message: translate('toast_updated_message', {
					name: 'Category',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['sa.categories.getMultiple']);
			queryClient.invalidateQueries(['sa.categories.getAll']);
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		const updateBody: any = {
			meta: {},
		};
		if (name !== category.name) {
			updateBody['name'] = name;
		}
		if (ref !== category.ref) {
			updateBody['ref'] = ref;
		}
		if (description !== category.meta?.desc) {
			updateBody.meta.desc = description;
		}
		if (iconColour !== category.meta?.icon_colour) {
			updateBody.meta.icon_colour = iconColour;
		}
		if (iconInitials !== category.meta?.icon_initials) {
			updateBody.meta.icon_initials = iconInitials;
		}
		if (publicVal !== category.public) {
			updateBody.public = publicVal;
		}

		await updateCategory.mutateAsync({
			id: category.id,
			body: updateBody,
		});
	};

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<Input
				id="ref"
				type="text"
				label={translate('ref')}
				name="ref"
				value={ref}
				setValue={setRef}
				errors={formState.errors.value['ref']}
				required={false}
			/>
			<Textarea
				id="desc"
				label={translate('description')}
				name="meta.desc"
				value={description}
				setValue={setDescription}
				errors={formState.errors.value['meta.desc']}
				required={false}
			/>
			<Checkbox
				checked={publicVal}
				setChecked={setPublicVal}
				name={'public'}
				label={'Public'}
				errors={formState.errors.value['public']}
			/>
			<div className="mt-4 border-t border-border pt-4">
				<Input
					id="iconInitials"
					type="text"
					label={translate('icon_initials')}
					describedBy={translate('no_initials_set_description')}
					name="meta.icon_initials"
					value={iconInitials}
					setValue={setIconInitials}
					errors={formState.errors.value['meta.icon_initials']}
					required={false}
				/>
				<ColourPicker
					id="iconColour"
					label={translate('icon_colour')}
					name="meta.icon_colour"
					value={iconColour}
					setValue={setIconColour}
					errors={formState.errors.value['meta.icon_colour']}
					required={false}
				/>
			</div>

			{/* Update */}
			<FormAction
				loading={{
					is: updateCategory.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateCategory.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateCategoryAction;
