import { http, buildQueryString } from '@utils/index';

export interface Props {
	id: number | string;
	include: {
		items: boolean;
		itemsModel: boolean;
		meta: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaAwardsRes>>(
		`/sa/awards/${props.id}${buildQueryString(undefined, [
			{ key: 'items', include: props.include.items },
			{ key: 'items.model', include: props.include.itemsModel },
			{ key: 'meta', include: props.include.meta },
		])}`
	);
};
export default getSingle;
