import courses from './courses';
import invites from './invites';
import roles from './roles';
import teams from './teams';
import users from './users';
import videos from './videos';
import current from './current';
import notes from './notes';
import categories from './categories';
import quizzes from './quizzes';
import userAwards from './user-awards';
import bookmarks from './bookmarks';
import searches from './searches';
import logs from './logs';
import videoViews from './video-views';
import quizResults from './quiz-results';
import awards from './awards';

const actions = {
	courses,
	invites,
	roles,
	teams,
	users,
	videos,
	current,
	notes,
	categories,
	quizzes,
	userAwards,
	bookmarks,
	searches,
	logs,
	videoViews,
	quizResults,
	awards,
};

export default actions;
