import { translate } from '@lang/index';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast, useFormState } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';

interface PasswordUpdateActionProps extends ActionProps {}

const PasswordUpdateAction: React.FC<PasswordUpdateActionProps> = ({
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const { addToast } = useToast();
	const formState = useFormState();
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const updatePassword = useMutation(api.auth.updatePassword, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Password',
				}),
				message: translate('toast_updated_message', {
					name: 'Password',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await updatePassword.mutateAsync({
			body: {
				current_password: password,
				new_password: newPassword,
				new_password_confirmation: newPasswordConfirmation,
			},
		});
	};
	const resetForm = () => {
		setPassword('');
		setNewPassword('');
		setNewPasswordConfirmation('');
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		updatePassword.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				value={password}
				setValue={setPassword}
				label={translate('current_password')}
				name="current_password"
				id="current_password"
				type="password"
				autoComplete="current-password"
				errors={formState.errors.value['current_password']}
				required={true}
			/>
			<Input
				value={newPassword}
				setValue={setNewPassword}
				label={translate('new_password')}
				name="new_password"
				id="new_password"
				type="password"
				errors={formState.errors.value['new_password']}
				describedBy={translate('password_requirements_description')}
				required={true}
			/>
			<Input
				value={newPasswordConfirmation}
				setValue={setNewPasswordConfirmation}
				label={translate('confirm_password')}
				name="newPasswordConfirmation"
				id="newPasswordConfirmation"
				type="password"
				errors={formState.errors.value['newPasswordConfirmation']}
				describedBy={translate('confirm_password_description')}
				required={true}
			/>
			<FormActionModal
				loading={{
					is: updatePassword.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updatePassword.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('update_password')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default PasswordUpdateAction;
