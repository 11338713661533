import { translate } from '@lang/index';
// Libs
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// API
import api from 'api';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import AlertMessage from '@components/partials/AlertMessage';
import ButtonLoadingOverlay from '@components/partials/ButtonLoadingOverlay';

interface ResetPasswordActionProps extends ActionProps {}

const ResetPasswordAction: React.FC<ResetPasswordActionProps> = ({
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const { token } = useParams<{ token: string }>();
	const navigate = useNavigate();
	const formState = useFormState();
	const { addToast } = useToast();

	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const resetPassword = useMutation(api.auth.resetPassword, {
		onSuccess: () => {
			setPassword('');
			setPasswordConfirmation('');
			addToast({
				title: translate('toast_password_reset_title'),
				message: translate('toast_password_reset_message'),
				type: 'success',
			});
			navigate('/login');
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await api.root.crsf();
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get('email');
		await resetPassword.mutateAsync({
			body: {
				email: email || '',
				password: password,
				password_confirmation: passwordConfirmation,
				token: token || '',
			},
		});
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				value={password}
				setValue={setPassword}
				label={translate('password')}
				name="password"
				id="password"
				type="password"
				autoComplete="current-password"
				errors={formState.errors.value['password']}
				required={true}
				describedBy={translate('password_requirements_description')}
			/>
			<Input
				value={passwordConfirmation}
				setValue={setPasswordConfirmation}
				label={translate('confirm_password')}
				name="password_confirmation"
				id="password_confirmation"
				type="password"
				autoComplete="current-password"
				errors={formState.errors.value['password_confirmation']}
				required={true}
				describedBy={translate('confirm_password_description')}
			/>
			<div className="mt-10 w-full">
				{resetPassword.isError && (
					<div className="mb-2.5">
						<AlertMessage
							text={formState.errorMessage.value}
							type="error"
						/>
					</div>
				)}
				<Button
					theme={'primary'}
					type={'submit'}
					className={'relative w-full overflow-hidden'}>
					{translate('reset_and_login')}
					{resetPassword.isLoading && <ButtonLoadingOverlay />}
				</Button>
			</div>
		</Form>
	);
};

export default ResetPasswordAction;
