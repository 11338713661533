import { http, buildQueryString } from '@utils/index';

export interface Props {
	queryString?: string;
	include: {
		items: boolean;
		itemsModel: boolean;
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaAwardsRes[]>>(
		`/sa/awards${buildQueryString(
			props.queryString,
			[
				{ key: 'items', include: props.include.items },
				{ key: 'items.model', include: props.include.itemsModel },
			],
			undefined,
			props.perPage
		)}`
	);
};
export default getMultiple;
