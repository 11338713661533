import { Children } from 'react';
import classNames from 'classnames';
import ContentRow from '@components/partials/ContentRow';

interface TablePreviewsProps {
	title: string;
	children: React.ReactNode;
	permission?: boolean;
}

const TablePreviews: React.FC<TablePreviewsProps> = ({
	title,
	children,
	permission,
}) => {
	if (permission === false) return null;

	return (
		<ContentRow title={title}>
			<div
				className={classNames('mt-2.5 grid grid-cols-1 gap-5 ', {
					'lg:grid-cols-1': Children.count(children) === 1,
					'lg:grid-cols-2': Children.count(children) === 2,
					'sm:grid-cols-2 lg:grid-cols-3':
						Children.count(children) >= 3,
				})}>
				{children}
			</div>
		</ContentRow>
	);
};

export default TablePreviews;
