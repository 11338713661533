import login from './login';
import register from './register';
import logout from './logout';
import logoutAllDevices from './logout-all-devices';
import forgotPassword from './forgot-password';
import resetPassword from './reset-password';
import sendEmailVerification from './send-email-verification';
import updateMe from './update-me';
import updatePassword from './update-password';
import awards from './awards';
import searches from './searches';
import createToken from './create-token';

const actions = {
	login,
	register,
	logout,
	logoutAllDevices,
	forgotPassword,
	resetPassword,
	sendEmailVerification,
	updateMe,
	updatePassword,
	createToken,
	awards,
	searches,
};

export default actions;
