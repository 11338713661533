// Utils
import { dateHelpers } from '@utils/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';

interface AccessDatesProps {
	start: string | null;
	end: string | null;
}

const AccessDates: React.FC<AccessDatesProps> = ({ start, end }) => {
	// -------------------------------------------------
	// Helpers
	const { formatDate } = dateHelpers;

	// -------------------------------------------------
	// Render
	return (
		<>
			{start && (
				<div className="flex items-center">
					<FontAwesomeIcon
						icon={faCalendarDay}
						className="mr-1 text-icon"
					/>
					<span
						className="text-sm text-body"
						title={formatDate(start, 'en-gb')}>
						S: {formatDate(start, 'en-gb', 'short')}
					</span>
				</div>
			)}
			{end && (
				<div className="flex items-center">
					<FontAwesomeIcon
						icon={faCalendarDay}
						className="mr-1 text-icon"
					/>
					<span
						className="text-sm text-body"
						title={formatDate(end, 'en-gb')}>
						E: {formatDate(end, 'en-gb', 'short')}
					</span>
				</div>
			)}
		</>
	);
};

export default AccessDates;
