import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import Sort from '@components/query/Sort';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
// Tables
import UserAwardsTable from '@components/table/shared/UserAwardsTable';

const Progress: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'created_at',
					value: 'desc',
				},
			],
			filter: [
				{
					key: 'award_id',
					value: '',
					type: 'select',
				},
				{
					key: 'award.award_type_id',
					value: '',
					type: 'select',
				},
				{
					key: 'user_id',
					value: '',
					type: 'select',
				},
				{
					key: 'organisation_id',
					value: '',
					type: 'select',
				},
				{
					key: 'created_at_from',
					value: '',
					type: 'date',
				},
				{
					key: 'created_at_to',
					value: '',
					type: 'date',
				},
			],
		});
	const { convertDate, currentDate, monthsBefore } = dateHelpers;

	// -------------------------------------------------
	//	Query / Mutation
	const users = useQuery(['sa.users.getMultiple'], () => {
		return api.sa.users.getMultiple({
			include: {
				firstLogin: false,
				lastLogin: false,
				organisations: false,
				rolesIgnoringTeams: false,
			},
			perPage: -1,
		});
	});
	const organisations = useQuery(['sa.organisations.getMultiple'], () => {
		return api.sa.organisations.getMultiple({
			include: {
				courses: false,
				coursesCount: false,
				media: false,
				mediaCount: false,
				plan: false,
				planCount: false,
				users: false,
				usersCount: false,
				subscriptionsCount: false,
				subscriptionsItems: false,
				seatedUsersCount: false,
			},
			perPage: -1,
		});
	});
	const awards = useQuery(['sa.awards.getMultiple'], () => {
		return api.sa.awards.getMultiple({
			include: {
				items: false,
				itemsModel: false,
			},
			perPage: -1,
		});
	});
	const awardTypes = useQuery(
		['auth.awards.getAwardTypes'],
		api.auth.awards.getAwardTypes
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title="User Awards"
				body=""
				tooltip=""
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						labels={[
							{
								key: 'award_id',
								label: translate('awards'),
								allowAll: true,
								values: awards.data?.data.data.map((award) => {
									return {
										label: award.name,
										value: award.id.toString(),
									};
								}),
							},
							{
								key: 'award.award_type_id',
								label: translate('award_types'),
								allowAll: true,
								values: awardTypes.data?.data.data.map(
									(awardType) => {
										return {
											label: awardType.name,
											value: awardType.id.toString(),
										};
									}
								),
							},
							{
								key: 'user_id',
								label: translate('users'),
								allowAll: true,
								values: users.data?.data.data.map((user) => {
									return {
										label: user.name || user.email,
										value: user.id.toString(),
									};
								}),
							},
							{
								key: 'organisation_id',
								label: translate('organisations'),
								allowAll: true,
								values: organisations.data?.data.data.map(
									(organisation) => {
										return {
											label: organisation.name,
											value: organisation.id.toString(),
										};
									}
								),
							},
							{
								key: 'created_at_from',
								label: translate('started_at'),
							},
							{
								key: 'created_at_to',
								label: translate('started_from'),
							},
						]}
						loading={
							users.isLoading ||
							organisations.isLoading ||
							awards.isLoading ||
							awardTypes.isLoading
						}
						resetFilters={resetFilters}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'created_at',
								label: translate('earned_at'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<UserAwardsTable
					mode={'sa'}
					enabled={enabled}
					awardTypes={awardTypes.data?.data.data || []}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Certificates',
									filters: [
										{
											key: 'award.award_type_id',
											value: '2',
										},
									],
								},
								{
									label: 'Badges',
									filters: [
										{
											key: 'award.award_type_id',
											value: '1',
										},
									],
								},
								{
									label: 'Earned in the last 3 months',
									filters: [
										{
											key: 'created_at_from',
											value: convertDate(
												monthsBefore(currentDate, 3)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
							]}
						/>
					}
				/>
			</PageWrapper>
		</>
	);
};
export default Progress;
