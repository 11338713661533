import getMultiple from './get-multiple';
import getSingle from './get-single';
import deleteSingle from './delete-single';
import createSingle from './create-single';
import updateSingle from './update-single';
import previewAward from './preview-award';

const actions = {
	getMultiple,
	getSingle,
	deleteSingle,
	createSingle,
	updateSingle,
	previewAward,
};

export default actions;
