import { http } from '@utils/index';

interface Props {
	id: number;
}

const copySingle = (props: Props) => {
	return http.post<APIResponse<SaCourseRes>>(`/sa/courses/${props.id}/copy`);
};
export default copySingle;
