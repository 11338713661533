import { http } from '@utils/index';

interface Props {
	id: number;
}
interface ResponseData {}

const deleteSingle = (props: Props) => {
	return http.delete<APIResponse<ResponseData>>(
		`/sa/organisations/${props.id}`
	);
};
export default deleteSingle;
