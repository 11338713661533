import getAll from './get-all';
import getMultiple from './get-multiple';
import createSingle from './create-single';
import getSingle from './get-single';
import updateSingle from './update-single';
import deleteSingle from './delete-single';

const actions = {
	getAll,
	getMultiple,
	createSingle,
	getSingle,
	updateSingle,
	deleteSingle,
};

export default actions;
