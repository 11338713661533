import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdateChapterAction from '@components/actions/UpdateChapter';

interface UpdateChapterProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess: (chapter: SaChaptersRes) => void;
	chapterId: number;
}

const UpdateChapter: React.FC<UpdateChapterProps> = ({
	open,
	setOpen,
	onSuccess,
	chapterId,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.update_chapter.title}>
			<UpdateChapterAction
				chapterId={chapterId}
				modalOpen={open}
				callback={(success, data) => {
					if (success) {
						setOpen(false);
						onSuccess(data as SaChaptersRes);
					}
				}}
			/>
		</Modal>
	);
};

export default UpdateChapter;
