import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import classNames from 'classnames';

export interface StatCardProps {
	title: string;
	value: number | string | React.ReactNode;
	icon: IconDefinition;
	className?: string;
	link?: {
		href: string;
		text: string;
	};
	state: {
		permission?: boolean;
		isLoading: boolean;
		isError: boolean;
	};
}

const StatCard: React.FC<StatCardProps> = ({
	title,
	value,
	icon,
	link,
	state,
	className,
}) => {
	// -------------------------------------------------
	// Render

	if (state.permission === false) return null;

	return (
		<div
			className={classNames(
				'relative overflow-hidden rounded-lg border border-border bg-white px-5 pb-[26px] pt-5',
				className
			)}>
			<dt>
				<div className="absolute flex items-center justify-center rounded-md bg-brandPrimary p-3">
					<FontAwesomeIcon
						icon={icon}
						className="h-6 w-6 text-brandPrimaryText"
						aria-hidden="true"
					/>
				</div>
				<p className="ml-16 truncate text-sm font-medium text-body">
					{title}
				</p>
			</dt>
			<dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
				<div className="text-2xl font-semibold text-title">{value}</div>
				<div className="absolute inset-x-0 bottom-0 min-h-[36px] bg-uiLight px-5 py-2">
					<div className="text-sm">
						{link && (
							<Link
								to={link.href}
								className="hover:brandTertiaryH text-sm font-medium text-brandTertiary">
								{link.text ? link.text : 'View all'}
								<span className="sr-only">
									{' '}
									{link.text} stats
								</span>
							</Link>
						)}
					</div>
				</div>
			</dd>
			{state.isLoading && <Loading type="skeleton" />}
			{state.isError && <Error type="fill" />}
		</div>
	);
};

export default StatCard;
