import { http, buildQueryString } from '@utils/index';

interface Props {
	id: number;
	include: {
		media: boolean;
		mediaCount: boolean;
		plan: boolean;
		planCount: boolean;
		meta: boolean;
		usersCount: boolean;
		seatedUsersCount: boolean;
	};
}

const get = (props: Props) => {
	return http.get<APIResponse<OrganisationRes>>(
		`/organisations/${props.id}${buildQueryString(undefined, [
			{ key: 'media', include: props.include.media },
			{ key: 'mediaCount', include: props.include.mediaCount },
			{ key: 'plan', include: props.include.plan },
			{ key: 'planCount', include: props.include.planCount },
			{ key: 'meta', include: props.include.meta },
			{ key: 'usersCount', include: props.include.usersCount },
			{
				key: 'seatedUsersCount',
				include: props.include.seatedUsersCount,
			},
		])}`
	);
};
export default get;
