import getMultiple from './get-multiple';
import getSingle from './get-single';
import updateSingle from './update-single';
import deleteSingle from './delete-single';
import createSingle from './create-single';
import invites from './invites';
import users from './users';

const actions = {
	getMultiple,
	getSingle,
	updateSingle,
	deleteSingle,
	createSingle,
	invites,
	users,
};

export default actions;
