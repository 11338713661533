import { translate } from '@lang/index';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
// Components
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PerPageProps {
	perPage: number;
	setPerPage: (perPage: number) => void;
	options: Array<{
		value: number;
		label: string;
	}>;
}

const PerPage: React.FC<PerPageProps> = ({ perPage, setPerPage, options }) => {
	return (
		<div className="relative z-20">
			<Listbox value={perPage} onChange={setPerPage}>
				{({ open }) => (
					<>
						<Listbox.Button
							aria-label="Sort by"
							className={
								'flex h-7 w-32 items-center justify-between rounded-md border border-border bg-white px-2 text-sm'
							}>
							{translate('show')}{' '}
							{
								options.find(
									(option) => option.value === perPage
								)?.label
							}
							<span className="flex h-5 w-5 items-center justify-center rounded-md bg-uiDark">
								<FontAwesomeIcon
									icon={faChevronDown}
									className={classNames(
										'h-3 text-white transition-transform duration-200',
										{
											'rotate-180 transform': open,
										}
									)}
								/>
							</span>
						</Listbox.Button>
						<Transition
							enter="transition duration-100 ease-out"
							enterFrom="transform scale-95 opacity-0"
							enterTo="transform scale-100 opacity-100"
							leave="transition duration-75 ease-out"
							leaveFrom="transform scale-100 opacity-100"
							leaveTo="transform scale-95 opacity-0">
							<Listbox.Options className="absolute right-0 z-10 mt-2 flex w-full origin-top-right flex-col overflow-hidden rounded-md border border-border bg-white py-1 drop-shadow-md focus:outline-none">
								{options.map((option, index) => (
									<Listbox.Option
										key={index}
										value={option.value}
										disabled={option.value === perPage}
										className={({ active }) =>
											classNames(
												'flex cursor-pointer justify-between px-2 py-1 text-sm',
												{
													'bg-uiLight text-title':
														active,
													'text-gray-900': !active,
												}
											)
										}>
										{option.label}
										<span
											className={classNames(
												'flex h-5 w-5 items-center justify-center rounded-md border border-border',
												{
													'bg-brandSecondary text-white':
														option.value ===
														perPage,
												}
											)}>
											<FontAwesomeIcon
												icon={faCheck}
												className="h-3"
											/>
										</span>
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</>
				)}
			</Listbox>
		</div>
	);
};
export default PerPage;
