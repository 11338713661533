import getMultiple from './get-multiple';
import getSingle from './get-single';
import updateSingle from './update-single';
import syncVideo from './sync-video';
import deleteSingle from './delete-single';
import createSingle from './create-single';

const actions = {
	getMultiple,
	getSingle,
	updateSingle,
	syncVideo,
	deleteSingle,
	createSingle,
};

export default actions;
