import { translate } from '@lang/index';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
	faTriangleExclamation,
	faUpload,
	faUndo,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SingleFileUploadProps {
	label?: string;
	id: string;
	name: string;
	errors: Array<string> | undefined;
	required: boolean;
	formats: Array<string>;
	value: File | undefined | null;
	setValue: React.Dispatch<React.SetStateAction<File | undefined | null>>;
	describedBy?: string;
	current?: string;
	downloadText?: string;

	clearFile?: boolean;
	setClearFile?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
	label,
	id,
	name,
	errors = [],
	required,
	formats,
	value,
	setValue,
	describedBy,
	current,
	clearFile,
	downloadText,
	setClearFile,
}) => {
	const [hover, setHover] = useState(false);
	const [mode, setMode] = useState<'view' | 'edit'>(
		current ? 'view' : 'edit'
	);
	const fileInput = useRef<HTMLInputElement>(null);

	return (
		<div className={classNames('mb-4 flex flex-col last:mb-0')}>
			{label && (
				<label htmlFor={id} className="mb-1 block text-body">
					{label}
					{required && <span className="ml-1">(*)</span>}
				</label>
			)}

			{/* View */}
			{mode === 'view' && (
				<div className="group flex h-44 w-full flex-col items-center justify-center overflow-hidden rounded-md border border-dashed border-uiGray">
					{/* eslint-disable-next-line */}
					<a
						className="block w-full break-words px-2.5 text-center text-base font-semibold text-title transition-colors duration-200 hover:text-brandTertiary"
						href={current}
						target="_blank">
						{downloadText ? downloadText : 'Download current file'}
					</a>
					<button
						type="button"
						className="mt-3 block rounded-md border border-border bg-white px-2 py-1 text-sm text-title transition-colors duration-200 hover:border-brandPrimary hover:bg-brandPrimary "
						onClick={(e) => {
							e.stopPropagation();
							if (setClearFile) setClearFile(true);
							setValue(null);
							setMode('edit');
							if (fileInput.current) {
								fileInput.current.click();
							}
						}}>
						<FontAwesomeIcon
							icon={faUpload}
							className="mr-2 h-3 w-3 text-title"
						/>
						{translate('upload_new_file')}
					</button>
				</div>
			)}

			{/* Current */}
			<div
				className={classNames(
					'group relative flex h-44 w-full cursor-pointer flex-col items-center justify-center rounded-md border border-dashed border-uiGray',
					{
						'border-uiRed': errors.length > 0,
						'bg-uiGray bg-opacity-40': hover,
						hidden: mode === 'view',
					}
				)}
				role={'button'}
				onClick={() => {
					if (fileInput.current) {
						fileInput.current.click();
					}
				}}
				onDrop={(e) => {
					e.preventDefault();
					if (e.dataTransfer.files) {
						const file = e.dataTransfer.files[0];
						if (formats.includes(file.type)) {
							setValue(file);
							if (fileInput.current) {
								fileInput.current.value = '';
								fileInput.current.files = e.dataTransfer.files;
							}
						}
					}
					setHover(false);
				}}
				onDragOver={(e) => {
					e.preventDefault();
					setHover(true);
				}}
				onDragLeave={(e) => {
					e.preventDefault();
					setHover(false);
				}}
				onDragEnter={(e) => {
					e.preventDefault();
					setHover(true);
				}}>
				{current && (
					<button
						type="button"
						className="absolute top-2.5 left-2.5 z-20 flex h-8 w-8 items-center justify-center rounded-full bg-uiLightGray transition-colors duration-200 hover:bg-uiGray hover:bg-opacity-40"
						onClick={(e) => {
							e.stopPropagation();
							setMode('view');
							if (setClearFile) setClearFile(false);
						}}>
						<FontAwesomeIcon
							icon={faUndo}
							className="h-4 w-4 text-body opacity-50"
						/>
					</button>
				)}
				<input
					ref={fileInput}
					type="file"
					id={id}
					name={name}
					className="hidden"
					accept={
						formats.length > 0
							? formats.map((format) => `${format}`).join(',')
							: ''
					}
					multiple={false}
					onChange={(e) => {
						if (e.target.files) {
							setValue(e.target.files[0]);
						}
					}}
					required={required}
				/>
				<h3 className="text-base font-semibold text-title">
					{value ? value.name : 'No file selected'}
				</h3>
				{!value && (
					<p className="mt-1 max-w-xs text-center text-sm text-body">
						{translate('drag_and_drop')}
						{current && clearFile && translate('if_blank_removed')}
					</p>
				)}
				{value && (
					<button
						type="button"
						className="mt-3 block rounded-md border border-border bg-white px-2 py-1 text-sm text-title transition-colors duration-200 hover:border-brandPrimary hover:bg-brandPrimary group-hover:border-brandPrimary group-hover:bg-brandPrimary"
						onClick={(e) => {
							e.stopPropagation();
							setValue(null);
							if (fileInput.current) fileInput.current.value = '';
						}}>
						{translate('remove_file')}
					</button>
				)}
				{!value && (
					<button
						type="button"
						className="mt-3 block rounded-md border border-border bg-white px-2 py-1 text-sm text-title transition-colors duration-200 hover:border-brandPrimary hover:bg-brandPrimary group-hover:border-brandPrimary group-hover:bg-brandPrimary">
						<FontAwesomeIcon
							icon={faUpload}
							className="mr-2 h-3 w-3 text-title"
						/>
						{translate('choose_file')}
					</button>
				)}
			</div>

			{/* Described By */}
			{describedBy && (
				<p className="mt-1 text-sm text-body text-opacity-60">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};

export default SingleFileUpload;
