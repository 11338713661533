import { translate } from '@lang/index';
import { useMemo } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
// API
import api from 'api';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AwardsRowProps {
	award: SaAwardsRes;
	awardTypes: AwardTypesRes[];
	selectRow: (award: SaAwardsRes, action: 'delete' | 'update') => void;
}

const AwardsRow: React.FC<AwardsRowProps> = ({
	award,
	awardTypes,
	selectRow,
}) => {
	// -------------------------------------------------
	// Memos
	const certificateHref = useMemo(() => {
		return api.sa.awards.previewAward({
			id: award.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [award.id]);

	const awardType = useMemo(() => {
		return award.award_type_id
			? awardTypes.find(
					(awardType) => awardType.id === award.award_type_id
			  )
			: undefined;
	}, [award.award_type_id, awardTypes]);

	// -----------------------------
	// Render

	return (
		<tr>
			<td className="td-default">{award.name}</td>
			<td className="td-default">
				{awardType?.name === 'certificate' && (
					// Ignore noreferrer warning as this is a download link
					// eslint-disable-next-line
					<a
						className="text-sm transition-colors duration-200 hover:text-brandTertiaryHover hover:underline"
						href={certificateHref}
						target="_blank">
						<FontAwesomeIcon
							icon={faLink}
							className="mr-1 text-brandTertiary"
						/>
						{translate('preview')}
					</a>
				)}
			</td>
			<td className="td-default">
				<Badges theme="green">{awardType?.name}</Badges>
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('update'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(award, 'update');
						},
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(award, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

export default AwardsRow;
