/*
    Issue: https://github.com/videojs/video.js/issues/8170
    Fix: updating the production browserlist targets
*/

import { useEffect, useRef, useCallback } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import { useLocation } from 'react-router-dom';

interface useVideoPlayerProps {
	options: {
		html5: {
			hls: {
				enableLowInitialPlaylist: boolean;
			};
		};
		autoplay: boolean;
		controls: boolean;
		responsive: boolean;
		fluid: boolean;
		volume: number;
		preload: string;
		poster?: string;
		sources: {
			src?: string;
			type: string;
		}[];
	};
	tracks?: {
		srclang: string;
		label: string;
		src: string;
	}[];
	target?: number;

	setStarted?: (started: boolean) => void;
	setTargetMet?: (targetMet: boolean) => void;
	setCompleted?: (completed: boolean) => void;
	setTimestamp?: (timestamp: number) => void;
}

const useVideoPlayer = (props: useVideoPlayerProps) => {
	// -----------------------------------------
	// State
	const location = useLocation();
	const videoRef = useRef<HTMLDivElement>(null);
	const playerRef = useRef<Player | null>(null);

	// -----------------------------------------
	// Functions
	const getTimestampParam = () => {
		if (!location.search) return;
		const params = new URLSearchParams(location.search);
		const timestamp = params.get('timestamp');
		if (!timestamp) return;

		return timestamp;
	};

	// -----------------------------------------
	// Effects
	useEffect(() => {
		const timestamp = getTimestampParam();

		if (!playerRef.current) {
			const videoElement = document.createElement('video-js');

			videoElement.classList.add('vjs-big-play-centered');
			if (videoRef.current) videoRef.current.appendChild(videoElement);

			// Set player
			const player = (playerRef.current = videojs(
				videoElement,
				props.options,
				() => {
					player.addClass('vjs-ei-skin');
					player.volume(props.options.volume);
					player.autoplay(props.options.autoplay);
					player.setAttribute('data-started', 'false');
					player.setAttribute('data-target-met', 'false');
					player.setAttribute('data-completed', 'false');
					player.setAttribute('data-timestamp', '0');
					player.playbackRates([0.5, 1, 1.5, 2]);

					// -------------------------
					// Tracks
					if (props.tracks)
						props.tracks.forEach((track) => {
							player.addRemoteTextTrack(track, false);
						});
					// -------------------------
					// Events
					player.on('play', () => {
						player.setAttribute('data-started', 'true');
						if (props.setStarted) props.setStarted(true);
					});
					player.on('timeupdate', () => {
						const duration = player.duration();
						const currentTime = player.currentTime();
						if(duration === undefined)	return;
						if(currentTime === undefined)	return;
						if (props.target !== undefined) {


							const targetV =
								duration * props.target || 0.5;
							if (currentTime >= targetV!) {
								player.setAttribute('data-target-met', 'true');
								if (props.setTargetMet)
									props.setTargetMet(true);
							}
						}
						player.setAttribute(
							'data-timestamp',
							currentTime.toString()
						);
						if (props.setTimestamp)
							props.setTimestamp(currentTime);
					});
					player.on('ended', () => {
						player.setAttribute('data-completed', 'true');
						if (props.setCompleted) props.setCompleted(true);
					});
					player.ready(function () {
						// @ts-ignore
						this.tech_.on('loadedmetadata', function () {
							// @ts-ignore
							const qualityLevels = player.qualityLevels();
							const levels = qualityLevels.levels_;
							// @ts-ignore
							levels.forEach((level) => {
								level.enabled =
									level === levels[levels.length - 1];
							});
						});

						if (timestamp) {
							player.currentTime(Number(timestamp));
							player.play();
						}
					});
				}
			));

			// @ts-ignore
			player.reloadSourceOnError();
		} else {
			const player = playerRef.current;
			if (player) {
				player.currentTime(0);
				player.autoplay(props.options.autoplay);
				player.setAttribute('data-started', 'false');
				player.setAttribute('data-target-met', 'false');
				player.setAttribute('data-completed', 'false');
				player.setAttribute('data-timestamp', '0');
				if (props.setStarted) props.setStarted(false);
				if (props.setTargetMet) props.setTargetMet(false);
				if (props.setCompleted) props.setCompleted(false);
				if (props.setTimestamp) props.setTimestamp(0);

				// -------------------------
				// Tracks
				if (props.tracks)
					props.tracks.forEach((track) => {
						player.addRemoteTextTrack(track, false);
					});

				if (timestamp) {
					player.currentTime(Number(timestamp));
					player.play();
				}
			}
		}
		// eslint-disable-next-line
	}, []);

	// Dispose the Video.js player when the functional component unmounts
	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [playerRef]);

	// -----------------------------------------
	// Render
	const VideoPlayer = useCallback(({ ...props }) => {
		return (
			<div className="relative mx-auto aspect-video max-h-[800px] overflow-hidden rounded-md bg-black">
				<div data-vjs-player>
					<div ref={videoRef}></div>
				</div>
			</div>
		);
	}, []);

	return {
		VideoPlayer,
		playerRef: playerRef,
	};
};

export default useVideoPlayer;
