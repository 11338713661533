import { http, buildQueryString } from '@utils/index';

export interface Props {
	id: number;
	include: {
		media: boolean;
	};
}

const getSingle = (props: Props) => {
	return http.get<APIResponse<SaChaptersRes>>(
		`/sa/chapters/${props.id}${buildQueryString(undefined, [
			{ key: 'media', include: props.include.media },
		])}`
	);
};
export default getSingle;
