import { http } from '@utils/index';

const status = () => {
	return http.get<{
		name: string;
		hash: string;
		version: string;
		env: string;
	}>(`/status`);
};
export default status;
