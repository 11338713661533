import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreatePlanAction from '@components/actions/CreatePlan';

interface CreatePlanProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const CreatePlan: React.FC<CreatePlanProps> = ({ open, setOpen }) => {
	return (
		<Modal open={open} setOpen={setOpen} title={T.modals.create_plan.title}>
			<CreatePlanAction
				callback={(success, data) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default CreatePlan;
