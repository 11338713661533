import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchParams {
	search: string;
	setSearch: (search: string) => void;
	placeholder?: string;
	width?: 'full' | 'sm';
}

const Search: React.FC<SearchParams> = ({
	search,
	setSearch,
	placeholder,
	width = 'sm',
}) => {
	const [searchQuery, setSearchQuery] = useState(search);

	useEffect(() => {
		setSearchQuery(search);
	}, [search]);

	return (
		<form
			className={classNames('relative flex h-10 w-full items-center', {
				'max-w-sm': width === 'sm',
				'w-full': width === 'full',
			})}
			onSubmit={(e) => {
				e.preventDefault();
				setSearch(searchQuery);
			}}>
			<input
				aria-label="Search"
				type={'search'}
				className="h-10 w-full rounded-md border border-border px-2.5 pr-16 text-sm text-body focus:border-brandPrimary focus:ring-brandPrimary"
				placeholder={placeholder || 'Search'}
				value={searchQuery}
				onChange={(e) => {
					setSearchQuery(e.target.value);
				}}
			/>
			<div className="absolute right-3 top-0 bottom-0 flex">
				<button
					type="reset"
					className={classNames(
						'mr-2.5 flex h-10 items-center justify-center text-white transition-opacity duration-200',
						{
							'opacity-0': searchQuery === '',
							'opacity-100': searchQuery !== '',
						}
					)}
					onClick={() => {
						setSearchQuery('');
						setSearch('');
					}}>
					<FontAwesomeIcon icon={faTimes} className="h-5 text-red" />
				</button>
				<button
					aria-label="Search"
					className="flex h-10 items-center justify-center text-white"
					type="submit">
					<FontAwesomeIcon
						icon={faSearch}
						className="h-5 text-brandTertiary"
					/>
				</button>
			</div>
		</form>
	);
};
export default Search;
