// Components
import MessageBlock from '@components/blocks/MessageBlock';

interface FullMessageProps {
	image: string;
	title: string;
	message: string;
	children?: React.ReactNode;
}

const FullMessage: React.FC<FullMessageProps> = ({
	image,
	title,
	message,
	children,
}) => {
	return (
		<div className="flex h-page-screen items-center justify-center">
			<div className="m-auto">
				<MessageBlock
					image={image}
					title={title}
					message={message}
					className={'border-0'}
					children={children}
				/>
			</div>
		</div>
	);
};

export default FullMessage;
