import { translate } from '@lang/index';
import { useMemo } from 'react';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import CategoryIcon from '@components/partials/CategoryIcon';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface CategoryRowProps {
	category: SaCategoriesRes;
	selectRow: (course: SaCategoriesRes, action: 'delete' | 'update') => void;
	categoryScopes: SaCategoryScopesRes[];
}

const CategoryRow: React.FC<CategoryRowProps> = ({
	category,
	selectRow,
	categoryScopes,
}) => {
	// -----------------------------------------
	// Memos
	const scope = useMemo(() => {
		return categoryScopes.find(
			(scope) => scope.id === category.category_scope_id
		);
	}, [category, categoryScopes]);

	// -----------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<span className="flex items-center">
					<CategoryIcon
						colour={category.meta?.icon_colour}
						initials={category.meta?.icon_initials}
						label={category?.name}
						categoryScope={scope?.key}
						size="sm"
					/>
					<span className="ml-2.5 text-sm font-semibold">
						{category.name}
					</span>
				</span>
			</td>
			<td className="td-default">
				<span>{category.ref ? category.ref : '-'}</span>
			</td>
			<td className="td-default">
				<DateTimeColumn date={category.updated_at} />
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('update'),
						type: 'button',
						show: true,
						onClick: () => selectRow(category, 'update'),
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => selectRow(category, 'delete'),
					},
				]}
			/>
		</tr>
	);
};

export default CategoryRow;
