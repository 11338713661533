import { http } from '@utils/index';

interface Props {
	id: string;
}

const deleteSingle = (props: Props) => {
	return http.delete<APIResponse<SaChaptersRes>>(`/sa/chapters/${props.id}`);
};
export default deleteSingle;
