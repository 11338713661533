import { http, buildQueryString } from '@utils/index';

export interface Props {
	organisationId?: number;
	queryString?: string;
	include: {
		lastLogin: boolean;
		roles: boolean;
	};
	filters?: Array<{
		key: 'teams.id';
		value: string | number;
	}>;
	sorts?: {
		name?: 'asc' | 'desc';
	};
	perPage?: number;
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<UserRes[]>>(
		`/organisations/${props.organisationId}/users${buildQueryString(
			props.queryString,
			[
				{ key: 'roles', include: props.include.roles },
				{ key: 'lastLogin', include: props.include.lastLogin },
			],
			props.filters,
			props.perPage,
			props.sorts
		)}`
	);
};
export default getMultiple;
