import { translate } from '@lang/index';
// Components
import MultiSelect from '@components/forms/MultiSelect';

interface SelectedCategoriesProps {
	categories: SaCategoriesRes[];

	selelected: SaCategoriesRes[];
	setSelected: React.Dispatch<React.SetStateAction<SaCategoriesRes[]>>;
}

const SelectCategories: React.FC<SelectedCategoriesProps> = ({
	categories,
	selelected,
	setSelected,
}) => {
	return (
		<MultiSelect
			label={translate('categories')}
			name={'categoriy_ids'}
			id={'categories'}
			values={selelected.map((category) => {
				return {
					key: category.name,
					label: `${category.name} - ${category.id}`,
					data: category,
				};
			})}
			setValues={(state) => {
				setSelected(state.map((category) => category.data));
			}}
			errors={{}}
			options={categories.map((category) => {
				return {
					key: category.name,
					label: `${category.name} - ${category.id}`,
					data: category,
				};
			})}
			placeholder={translate('add_to_categories')}
		/>
	);
};

export default SelectCategories;
