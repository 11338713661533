import { http } from '@utils/index';

export interface Props {
	organisationId: string | number;
	body: {
		value: string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<SeachesRes>>(
		`/organisations/${props.organisationId}/searches`,
		{
			...props.body,
		}
	);
};
export default createSingle;
