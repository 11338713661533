import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Props as UpdateSingle } from 'api/sa/awards/update-single';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormActionModal from '@components/forms/FormActionModal';
import MultiSelect from '@components/forms/MultiSelect';
import Textarea from '@components/forms/Textarea';
import Switch from '@components/forms/Switch';

interface UpdateAwardActionProps extends ActionProps {
	id: number;
}

const UpdateAwardAction: React.FC<UpdateAwardActionProps> = ({
	id,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [active, setActive] = useState<boolean>(true);
	const [metaText, setMetaText] = useState<string>('');
	const [metaShowIchemeLogo, setMetaShowIchemeLogo] =
		useState<boolean>(false);
	const [name, setName] = useState('');
	const [selectedItems, setSelectedItems] = useState<
		{
			key: string;
			label: string;
			data: SaVideosRes | SaQuizzesRes | SaCourseRes;
		}[]
	>([]);

	// --------------------------------------
	// Queries / Mutations
	const award = useQuery(
		['sa.awards.getSingle', id],
		() => {
			return api.sa.awards.getSingle({
				id: id,
				include: {
					items: true,
					itemsModel: true,
					meta: true,
				},
			});
		},
		{
			onSuccess: (data) => {
				setAwardData(data.data.data);
			},
		}
	);

	const videos = useQuery(['sa.videos.getMultiple'], () => {
		return api.sa.videos.getMultiple({
			include: {
				value: false,
				media: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const quizzes = useQuery(['sa.quizzes.getMultiple'], () => {
		return api.sa.quizzes.getMultiple({
			include: {
				type: false,
				typeCount: false,
				media: false,
				mediaCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const courses = useQuery(['sa.courses.getMultiple'], () => {
		return api.sa.courses.getMultiple({
			include: {
				items: false,
				itemsModels: false,
				itemsCount: false,
				media: false,
				mediaCount: false,
				categories: false,
				categoriesCount: false,
				categoriesMeta: false,

				organisations: false,
				organisationsCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const updateAward = useMutation(api.sa.awards.updateSingle, {
		onSuccess: () => {
			// make invalidate any organisation queries
			queryClient.invalidateQueries(['sa.awards.getMultiple']);
			addToast({
				title: translate('toast_updated_title', {
					name: 'Award',
				}),
				message: translate('toast_updated_message', {
					name: 'Award',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		let updateBody: UpdateSingle['body'] = returnModifiedData([
			{
				key: 'name',
				state: name,
				defaultState: award.data?.data.data.name,
			},
			{
				key: 'active',
				state: active ? 1 : 0,
				defaultState: award.data?.data.data.active,
			},
		]);
		if (metaText !== '' && metaText !== award.data?.data.data.meta?.text) {
			updateBody['meta'] = {
				text: metaText,
			};
		}
		if (
			metaShowIchemeLogo !==
			(award.data?.data.data.meta?.show_icheme_logo === '1'
				? true
				: false)
		) {
			updateBody['meta'] = {
				...updateBody['meta'],
				show_icheme_logo: metaShowIchemeLogo ? 1 : 0,
			};
		}

		if (hasItemsChanged()) {
			updateBody['items'] = selectedItems.map((item) => {
				let modelType = item.key.split(' - ')[0].toLowerCase();
				if (modelType === 'assessment') modelType = 'quiz';

				return {
					model_type: modelType as 'video' | 'quiz' | 'course',
					model_id: item.data.id,
				};
			});
		}
		await updateAward.mutateAsync({
			id: id,
			body: updateBody,
		});
	};
	const resetForm = () => {
		setAwardData(award.data?.data.data);
		updateAward.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};
	const setAwardData = (award?: SaAwardsRes) => {
		if (!award) return;

		setName(award.name || '');
		setMetaText(award.meta?.text || '');
		setMetaShowIchemeLogo(
			award.meta?.show_icheme_logo === '1' ? true : false
		);

		setActive(award.active);

		let items: {
			key: string;
			label: string;
			data: SaVideosRes | SaQuizzesRes | SaCourseRes;
		}[] = [];

		award.items.forEach((item) => {
			if (item.model_type === 'video') {
				items.push({
					key: `video - ${item.model.id}`,
					label: `Video - ${item.model.title} - ${item.model.id}`,
					data: item.model as SaVideosRes,
				});
			} else if (item.model_type === 'quiz') {
				items.push({
					key: `assessment - ${item.model.id}`,
					label: `Assessment - ${item.model.title} - ${item.model.id}`,
					data: item.model as SaQuizzesRes,
				});
			} else if (item.model_type === 'course') {
				items.push({
					key: `course - ${item.model.id}`,
					label: `Course - ${item.model.title} - ${item.model.id}`,
					data: item.model,
				});
			}
		});
		setSelectedItems(items);
	};
	const hasItemsChanged = () => {
		const items = award.data?.data.data.items || [];
		let changed = false;

		if (items.length !== selectedItems.length) {
			changed = true;
			return changed;
		}

		items.forEach((item) => {
			if (
				!selectedItems.find((selectedItem) => {
					let modelType = selectedItem.key
						.split(' - ')[0]
						.toLowerCase();
					if (modelType === 'assessment') modelType = 'quiz';
					return (
						selectedItem.data.id === item.id &&
						modelType === item.model_type
					);
				})
			) {
				changed = true;
			}
		});

		return changed;
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={
				videos.isLoading ||
				quizzes.isLoading ||
				courses.isLoading ||
				award.isLoading
			}
			isError={
				videos.isError ||
				quizzes.isError ||
				courses.isError ||
				award.isError
			}>
			<Input
				id="name"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={false}
			/>
			<MultiSelect
				label={translate('award_items')}
				name={'items'}
				id={'items'}
				values={selectedItems}
				setValues={setSelectedItems}
				errors={{}}
				options={[
					...(videos.data?.data.data.map((video) => {
						return {
							key: `video - ${video.id}`,
							label: `Video - ${video.title} - ${video.id}`,
							data: video,
						};
					}) || []),
					...(quizzes.data?.data.data.map((quiz) => {
						return {
							key: `assessment - ${quiz.id}`,
							label: `Assessment - ${quiz.title} - ${quiz.id}`,
							data: quiz,
						};
					}) || []),
					...(courses.data?.data.data.map((course) => {
						return {
							key: `course - ${course.id}`,
							label: `Course - ${course.title} - ${course.id}`,
							data: course,
						};
					}) || []),
				]}
				placeholder={translate('add_to_award')}
			/>
			{award.data?.data.data.award_type_id === 2 && (
				<>
					<Textarea
						id="meta.text"
						label={translate('additional_certificate_info')}
						name="meta.text"
						value={metaText}
						setValue={setMetaText}
						errors={formState.errors.value['meta.text']}
						required={false}
					/>
					<Switch
						name="meta.show_icheme_logo"
						label={translate('show_icheme_logo')}
						checked={metaShowIchemeLogo}
						setChecked={setMetaShowIchemeLogo}
						errors={formState.errors.value['meta.show_icheme_logo']}
					/>
					<Switch
						name="active"
						label={translate('active')}
						checked={active}
						setChecked={setActive}
						errors={formState.errors.value['active']}
					/>
				</>
			)}
			<FormActionModal
				loading={{
					is: updateAward.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateAward.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('update')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default UpdateAwardAction;
