// Libs
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ColourPickerProps {
	label?: string;
	id: string;
	name: string;
	value: string;
	setValue: (value: string) => void;
	errors: Array<string> | undefined;

	required: boolean;
	describedBy?: string;
	showErrors?: boolean;
}

const ColourPicker: React.FC<ColourPickerProps> = ({
	label,
	id,
	name,
	value,
	setValue,
	errors = [],
	required,
	describedBy,
	showErrors = true,
}) => {
	return (
		<div className={'flex flex-col mb-2 last:mb-0 w-full'}>
			<div className="flex justify-between items-center">
				{label && (
					<label htmlFor={id} className="text-body block">
						{label}
					</label>
				)}
				<input
					className={''}
					id={id}
					name={name}
					type={'color'}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					required={required}
				/>
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="text-sm text-body mt-2.5 text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{showErrors && errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 text-red h-3 w-3 mt-1"
					/>
					<p className="text-red text-sm">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};
export default ColourPicker;
