import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@headlessui/react';

export interface IconDropdownProps {
	items: Array<{
		label: string;
		href: string;
	}>;
	icon: IconDefinition;
	title: string;
}

const IconDropdown: React.FC<IconDropdownProps> = ({ items, icon, title }) => {
	// -------------------------------------------------
	// Refs
	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	// -------------------------------------------------
	// Functions
	const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setTimeout(() => {
			setDropdownPosition();
		}, 1);
	};

	const setDropdownPosition = () => {
		if (!menuButtonRef.current || !dropdownRef.current) {
			return;
		}

		const menuButtonRect = menuButtonRef.current.getBoundingClientRect();
		const dropdownRect = dropdownRef.current.getBoundingClientRect();

		const top = menuButtonRect.top + menuButtonRect.height;
		const left = menuButtonRect.left - dropdownRect.width + 30;

		dropdownRef.current.style.top = `${top}px`;
		dropdownRef.current.style.left = `${left}px`;
	};

	// -------------------------------------------------
	// Render
	return (
		<Menu as="div" className="relative">
			<Menu.Button
				ref={menuButtonRef}
				onClick={toggleDropdown}
				className={
					'h-8 w-8 rounded-md bg-brandTertiary text-brandTertiaryText hover:bg-brandTertiaryHover  focus:outline-none focus:ring-2 focus:ring-brandTertiaryLight'
				}>
				<FontAwesomeIcon icon={icon} />
			</Menu.Button>

			{createPortal(
				<Menu.Items
					ref={dropdownRef}
					as="div"
					className={
						'pointer-events-auto absolute -right-full z-10 mt-2 flex w-44 origin-top-right flex-col overflow-hidden rounded-md bg-white drop-shadow-md focus:outline-none'
					}>
					<div className="bg-brandTertiary py-3 px-4 text-left text-brandTertiaryText">
						{title}
					</div>
					<ul className="py-1">
						{items.map((item, index) => (
							<Menu.Item
								key={index}
								as={'li'}
								className={
									'font-base w-full bg-white text-left text-title transition-colors duration-200 hover:bg-uiLight'
								}>
								<Link
									to={item.href || '/'}
									className={
										'inline-flex w-full px-4 py-1 text-sm text-title'
									}>
									{item.label}
								</Link>
							</Menu.Item>
						))}
					</ul>
				</Menu.Items>,
				document.getElementById('dropdown-portal') as HTMLElement
			)}
		</Menu>
	);
};
export default IconDropdown;
