import getMultiple from './get-multiple';
import views from './views';
import searchVideos from './search-videos';

const actions = {
	getMultiple,
	views,
	searchVideos,
};

export default actions;
