import { useState } from 'react';

export interface FormState {
	errors: {
		value: {
			[key: string]: string[];
		};
		setErrors: React.Dispatch<
			React.SetStateAction<{
				[key: string]: string[];
			}>
		>;
	};
	errorMessage: {
		value: string;
		setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
	};
	lock: {
		value: boolean;
		setLock: React.Dispatch<React.SetStateAction<boolean>>;
	};
}

const useFormState = (): FormState => {
	// State
	const [errors, setErrors] = useState<APIError['errors']>({});
	const [errMessage, setErrMessage] = useState<string>('');
	const [lock, setLock] = useState(false);

	return {
		errors: {
			value: errors,
			setErrors,
		},
		errorMessage: {
			value: errMessage,
			setErrorMessage: setErrMessage,
		},
		lock: {
			value: lock,
			setLock,
		},
	};
};

export default useFormState;
