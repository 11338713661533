import { translate } from '@lang/index';
import type { Item } from '@components/groups/course-builder/Container';
import { useState, useMemo, useEffect } from 'react';
// Components
import Search from '@components/query/Search';
import Select from '@components/forms/Select';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import CourseBuilderListItem from '@components/groups/course-builder/ListItem';

interface CourseBuilderListProps {
	selectedItems: Item[];
	quizzes: SaQuizzesRes[];
	videos: SaVideosRes[];
	isLoading: boolean;
	isError: boolean;
	addItem: (
		item: SaQuizzesRes | SaVideosRes | SaChaptersRes,
		type: 'quiz' | 'video' | 'chapter'
	) => void;
	categories: SaCategoriesRes[];
}

const CourseBuilderList: React.FC<CourseBuilderListProps> = ({
	selectedItems,
	quizzes,
	videos,
	isLoading,
	isError,
	addItem,
	categories,
}) => {
	// -------------------------------------------
	// State & Hooks
	const [selectFilter, setSelectFilter] = useState('All');
	const [selectedCategory, setSelectedCategory] = useState('all');
	const [searchQuery, setSearchQuery] = useState('');

	// -------------------------------------------
	// Memos
	const quizResults = useMemo(() => {
		if (selectFilter === 'All' || selectFilter === 'Quizzes') {
			let res = quizzes.filter((quiz) => {
				if (selectedCategory === 'all') return true;
				else return quiz.type_id === parseInt(selectedCategory);
			});
			if (searchQuery !== '') {
				res = res.filter(
					(quiz) =>
						quiz.title
							.toLowerCase()
							.includes(searchQuery.toLowerCase()) ||
						quiz.ref
							?.toLowerCase()
							.includes(searchQuery.toLowerCase())
				);
			}
			return res;
		} else return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quizzes, selectFilter, selectedCategory, searchQuery]);

	const videoResults = useMemo(() => {
		if (selectFilter === 'All' || selectFilter === 'Videos') {
			let res = videos.filter((video) => {
				if (selectedCategory === 'all') return true;
				else return video.type_id === parseInt(selectedCategory);
			});
			if (searchQuery !== '') {
				res = res.filter(
					(video) =>
						video.title
							.toLowerCase()
							.includes(searchQuery.toLowerCase()) ||
						video.ref
							?.toLowerCase()
							.includes(searchQuery.toLowerCase())
				);
			}
			return res;
		} else return [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos, selectFilter, selectedCategory, searchQuery]);

	const categoryOptions = useMemo(() => {
		switch (selectFilter) {
			case 'All':
				return categories.filter(
					(category) => category.category_scope_id !== 1
				);
			case 'Quizzes':
				return categories.filter(
					(category) => category.category_scope_id === 2
				);
			case 'Videos':
				return categories.filter(
					(category) => category.category_scope_id === 3
				);
			default:
				return [];
		}
	}, [categories, selectFilter]);

	// -------------------------------------------
	/// Effects
	useEffect(() => {
		setSelectedCategory('all');
	}, [selectFilter]);

	// -------------------------------------------
	// Render
	return (
		<div className="col-span-2 overflow-y-auto">
			<div className="sticky top-0 z-10 flex flex-col border-b border-border bg-white p-7">
				<div className="grid w-full grid-cols-2 gap-2.5">
					<div className="mt-2 flex w-full flex-col ">
						<label className="mr-5 mb-1 hidden min-w-max text-sm text-body xl:block">
							{translate('select_from')}
						</label>
						<Select
							options={['All', 'Quizzes', 'Videos']}
							value={selectFilter}
							setValue={(state) =>
								setSelectFilter(state as string)
							}
							errors={[]}
						/>
					</div>
					<div className="mt-2 flex w-full flex-col ">
						<label className="mr-5 mb-1 hidden min-w-max text-sm text-body xl:block">
							{translate('type')}
						</label>
						<Select
							options={[
								{
									value: 'all',
									label: 'All',
								},
								...(categoryOptions?.map((category) => ({
									value: category.id,
									label: `${category.name} - ${category.id}`,
								})) || []),
							]}
							value={selectedCategory}
							setValue={(state) =>
								setSelectedCategory(state as string)
							}
							errors={[]}
						/>
					</div>
				</div>
				<div className="mt-2.5 w-full">
					<Search
						search={searchQuery}
						setSearch={setSearchQuery}
						placeholder="Search"
						width="full"
					/>
				</div>
			</div>
			<div className="relative z-0 h-[calc(100%-145px)] xl:h-[calc(100%-97px)]">
				{isLoading && <Loading type="fill" />}
				{isError && <Error type="fill" />}
				{!isLoading && !isError && (
					<ul className="grid grid-cols-1 gap-2 p-7 lg:grid-cols-2 xl:grid-cols-3">
						{quizResults?.map((quiz) => (
							<CourseBuilderListItem
								key={quiz.id}
								type={'quiz'}
								data={quiz as SaQuizzesRes}
								addItem={() => addItem(quiz, 'quiz')}
								categories={categories}
								selectedItems={selectedItems}
							/>
						))}
						{videoResults?.map((video) => (
							<CourseBuilderListItem
								key={video.id}
								type={'video'}
								data={video as SaVideosRes}
								addItem={() => addItem(video, 'video')}
								categories={categories}
								selectedItems={selectedItems}
							/>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default CourseBuilderList;
