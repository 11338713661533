import { http } from '@utils/index';

interface Props {
	organisationId?: number;
	body: {
		email: string;
	};
}

const createSingle = (props: Props) => {
	return http.post<APIResponse<UserRes>>(
		`/organisations/${props.organisationId}/users`,
		{
			...props.body,
		}
	);
};
export default createSingle;
