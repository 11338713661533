import { http, buildQueryString } from '@utils/index';

interface Props {
	queryString?: string;
	include: {
		meta: boolean;
		metaCount: boolean;
	};
	perPage?: number;
	filters?: Array<{
		key: 'scope';
		value: CategoryScopes | string;
	}>;
	sort?: {
		name: 'asc' | 'desc';
	};
}

const getMultiple = (props: Props) => {
	return http.get<APIResponse<SaCategoriesRes[]>>(
		`/sa/categories${buildQueryString(
			props.queryString,
			[
				{ key: 'meta', include: props.include.meta },
				{ key: 'metaCount', include: props.include.metaCount },
			],
			props.filters,
			props.perPage,
			props.sort
		)}`
	);
};
export default getMultiple;
