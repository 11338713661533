import { usePermissions } from '@hooks/index';
// Table Columns
import DateTimeColumn from '@components/table-columns/DateTimeColumn';
import UserColumn from '@components/table-columns/UserColumn';
import VideoImageTitleColumn from '@components/table-columns/VideoImageTitleColumn';

interface VideoViewRowProps {
	mode: 'sa' | 'organisation';
	view: SaVideoViewsRes | OrgVideoViewsRes;
	isSuperadmin?: boolean;
}

const VideoViewRow: React.FC<VideoViewRowProps> = ({
	mode,
	view,
	isSuperadmin,
}) => {
	const { manageOrganisationWithPlan } = usePermissions();

	// -------------------------------------------------
	// Render
	return (
		<tr className="relative h-[65px] items-center">
			<td className="td-default">
				<VideoImageTitleColumn
					thumbnail={view.video.thumbnail || undefined}
					title={view.video.title}
					id={view.video.id}
					courseId={view.course_id}
				/>
			</td>
			{(manageOrganisationWithPlan || isSuperadmin) && (
				<td className="td-default">
					<UserColumn
						name={view.user?.name}
						email={view.user?.email}
					/>
				</td>
			)}
			{mode === 'sa' && (
				<td className="td-default">
					{(view as SaVideoViewsRes).organisation?.name}
				</td>
			)}
			<td className="td-default">{view.course?.title}</td>
			<td className="td-default">
				<DateTimeColumn date={view.viewed_at || ''} />
			</td>
		</tr>
	);
};

export default VideoViewRow;
