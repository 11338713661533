import { http } from '@utils/index';

interface Props {
	organisationId: number;
}

const getAll = (props: Props) => {
	return http.get<APIResponse<RoleRes[]>>(
		`/organisations/${props.organisationId}/roles?per_page=-1`
	);
};
export default getAll;
