import classNames from 'classnames';

interface ProgressBarProps {
	progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
	return (
		<span className="w-full h-3 rounded-md bg-[#DEDEDE] overflow-hidden block">
			<span
				className={classNames(
					'h-full w-0 transition-all duration-400 block',
					{
						'bg-red': progress <= 25,
						'bg-[#FFC107]': progress > 25 && progress < 100,
						'bg-greenMid': progress === 100,
					}
				)}
				style={{
					width: `${progress}%`,
				}}></span>
		</span>
	);
};

export default ProgressBar;
