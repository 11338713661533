import { http } from '@utils/index';

interface Props {
	organisationId: number;
	body: {
		email: string;
		role_id: number;
		team_ids: number[];
	};
}

const create = (props: Props) => {
	return http.post<APIResponse<TeamRes>>(
		`/organisations/${props.organisationId}/user-invites`,
		{
			email: props.body.email,
			role_id: props.body.role_id,
			team_ids: props.body.team_ids,
		}
	);
};
export default create;
