import { translate } from '@lang/index';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';

interface PlanRowProps {
	plan: SaPlanRes;
	selectRow: (plan: SaPlanRes, action: 'delete' | 'update') => void;
}

const PlanRow: React.FC<PlanRowProps> = ({ plan, selectRow }) => {
	// -----------------------------
	// Render

	return (
		<tr>
			<td className="td-default">{plan.name}</td>
			<td className="td-default">
				<Badges theme="green">{plan.seats}</Badges>
			</td>
			<td className="td-default">
				<Badges theme="green">{plan.users}</Badges>
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('update'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(plan, 'update');
						},
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(plan, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

export default PlanRow;
