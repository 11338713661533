import {
	faCheck,
	faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
// Components
import { Switch } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CheckboxProps {
	checked: boolean;
	setChecked: (checked: boolean) => void;
	name?: string;
	label?: string;
	errors: Array<string> | undefined;
	labelClassName?: string;
	disabled?: boolean;
	describedBy?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
	checked,
	setChecked,
	name,
	label,
	errors = [],
	labelClassName,
	describedBy,
	disabled = false,
}) => {
	return (
		<div className={'mb-4 flex w-full flex-col last:mb-0'}>
			<div
				className={classNames('mb-1 flex items-center last:mb-0', {
					'pointer-events-none select-none opacity-50': disabled,
				})}>
				<Switch.Group>
					<Switch
						name={name}
						checked={checked}
						onChange={setChecked}
						className={`${
							checked
								? 'border-brandTertiary bg-brandPrimary'
								: 'border-border bg-border'
						} relative flex h-5 w-5  min-w-[20px] items-center justify-center rounded border`}>
						<span className="sr-only">Enable {label}</span>
						{checked && (
							<FontAwesomeIcon
								icon={faCheck}
								className="text-sm text-brandPrimaryText"
							/>
						)}
					</Switch>
					{label && (
						<Switch.Label
							className={classNames(
								'ml-2.5 cursor-pointer',
								labelClassName
							)}>
							{label}
						</Switch.Label>
					)}
				</Switch.Group>
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="mt-2.5 text-sm text-body text-opacity-80">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 mt-1 h-3 w-3 text-red"
					/>
					<p className="text-sm text-red">{errors[0]}</p>
				</div>
			)}
		</div>
	);
};
export default Checkbox;
