import { translate } from '@lang/index';
import { faSliders, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
// Components
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '@components/partials/Loading';
import { useMemo } from 'react';

interface FilterProps {
	filter: Array<{
		key: string;
		value: string;
		type?: 'boolean' | 'input' | 'select' | 'date';
	}>;
	setFilter: (key: string, value: string) => void;
	resetFilters: () => void;
	labels?: Array<{
		key: string;
		label: string;
		values?: Array<{ label: string; value: string }>;
		allowAll?: boolean;
		permission?: boolean;
	}>;
	loading?: boolean;
}

const Filter: React.FC<FilterProps> = ({
	filter,
	setFilter,
	labels,
	loading,
	resetFilters,
}) => {
	// -------------------------------------------------
	// Functions
	const getTotlaActiveFilters = useMemo(() => {
		return filter.filter((f) => f.value !== '').length;
	}, [filter]);

	// -------------------------------------------------
	// Memos
	const mergeFilterInfo = useMemo(() => {
		const merged: {
			key: string;
			value: string;
			type?: 'boolean' | 'input' | 'select' | 'date';
			label?: string;
			values?: Array<{ label: string; value: string }>;
			allowAll?: boolean;
			permission?: boolean;
		}[] = [];
		// for filter
		for (const f of filter) {
			const label = labels?.find((l) => l.key === f.key);
			if (label) {
				merged.push({ ...f, ...label });
			} else {
				merged.push(f);
			}
		}
		return merged;
	}, [filter, labels]);

	// -------------------------------------------------
	// Render

	// boolean filter item
	const FilterItemBoolean = ({
		filterKey,
		value,
		label,
	}: {
		filterKey: string;
		value: string;
		label: string;
	}) => {
		return (
			<button
				aria-label={`Filter by ${filterKey}`}
				className={
					'inline-flex w-full items-center justify-between px-4 py-1 '
				}
				onClick={() => {
					if (value === '') {
						setFilter(filterKey, '1');
					} else if (value === '1') {
						setFilter(filterKey, '0');
					} else if (value === '0') {
						setFilter(filterKey, '');
					}
				}}>
				{label}
				<span
					className={classNames(
						'flex h-6 w-6 items-center justify-center rounded-md border border-border text-sm',
						{
							'bg-brandTertiary text-brandTertiaryText':
								value === '1',
							'bg-red text-white': value === '0',
							'bg-uiLight text-title': value === '',
						}
					)}>
					{(value === '' || value === '1') && (
						<FontAwesomeIcon
							icon={faCheck}
							className={classNames('h-3 w-3', {
								'opacity-50': value === '',
							})}
						/>
					)}
					{value === '0' && (
						<FontAwesomeIcon icon={faTimes} className="h-3 w-3" />
					)}
				</span>
			</button>
		);
	};

	return (
		<Popover className="relative z-30 inline-block">
			{({ open }) => (
				<>
					<Popover.Button
						aria-label="Filter"
						className={
							'flex h-10 items-center justify-between rounded-md border border-border bg-white px-3 text-sm'
						}>
						<div className="mr-2 flex h-5 w-5 items-center justify-start">
							{getTotlaActiveFilters > 0 ? (
								<span
									onClick={(e) => {
										e.stopPropagation();
										resetFilters();
									}}
									className="duration-400 group relative flex h-5 w-5 items-center justify-center rounded-full bg-brandTertiary text-xs text-brandTertiaryText transition-colors hover:bg-red">
									<span className="absolute inset-0 flex items-center justify-center opacity-100 transition-opacity duration-100 group-hover:opacity-0">
										{getTotlaActiveFilters}
									</span>
									<span className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-100 group-hover:opacity-100">
										<FontAwesomeIcon
											icon={faTimes}
											className="h-3 w-3"
										/>
									</span>
								</span>
							) : (
								<FontAwesomeIcon
									icon={faSliders}
									className="h-4 w-4"
								/>
							)}
						</div>
						{translate('filter')}
					</Popover.Button>
					{loading && (
						<div className="absolute inset-0 overflow-hidden rounded-md">
							<Loading type="skeleton" />
						</div>
					)}
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0">
						<Popover.Panel className="absolute left-0 z-10 mt-2 flex w-80 origin-top-left flex-col overflow-hidden rounded-md bg-white drop-shadow-md focus:outline-none">
							<div className="flex justify-between bg-brandTertiary py-3 px-4 text-left text-brandTertiaryText">
								{translate('filter')}
								<button
									className="text-sm hover:underline"
									onClick={resetFilters}>
									reset
								</button>
							</div>
							<ul className="w-full py-1">
								{mergeFilterInfo.map((f) => {
									if (f.permission === false) return null;

									return (
										<li
											key={f.key}
											className="font-base w-full bg-white text-left text-sm text-title transition-colors duration-200 hover:bg-uiLight">
											{f.type === 'boolean' ? (
												<FilterItemBoolean
													filterKey={f.key}
													value={f.value}
													label={f.label || f.key}
												/>
											) : null}
											{/* Input */}
											{f.type === 'input' ? (
												<div className="px-4 py-1">
													<label>
														<span className="mb-1 block">
															{f.label || f.key}
														</span>
														<input
															className="h-10 w-full rounded border border-border px-2"
															type="text"
															value={f.value}
															onChange={(e) => {
																setFilter(
																	f.key,
																	e.target
																		.value
																);
															}}
														/>
													</label>
												</div>
											) : null}
											{/* Select */}
											{f.type === 'select' ? (
												<div className="px-4 py-1">
													<label>
														<span className="mb-1 block">
															{f.label || f.key}
														</span>
														<select
															className="h-10 w-full rounded border border-border px-2 text-sm capitalize"
															value={f.value}
															onChange={(v) => {
																setFilter(
																	f.key,
																	v.target
																		.value
																);
															}}>
															{f?.allowAll && (
																<option value="">
																	All
																</option>
															)}
															{f?.values?.map(
																(v) => (
																	<option
																		key={
																			v.value
																		}
																		value={
																			v.value
																		}>
																		{
																			v.label
																		}
																	</option>
																)
															) ?? (
																<option value="">
																	{f.key}
																</option>
															)}
														</select>
													</label>
												</div>
											) : null}
											{/* Date */}
											{f.type === 'date' ? (
												<div className="px-4 py-1">
													<label>
														<span className="mb-1 block">
															{f?.label || f.key}
														</span>
														<input
															className="h-10 w-full rounded border border-border px-2"
															type="date"
															value={f.value}
															onChange={(e) => {
																setFilter(
																	f.key,
																	e.target
																		.value
																);
															}}
														/>
													</label>
												</div>
											) : null}
										</li>
									);
								})}
							</ul>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};
export default Filter;
